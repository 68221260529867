import React, { useState } from "react";
import Step from "./Step";
import "./Stepper.css";

const Stepper = ({ steps = [], section, stepperClickHandler }) => {
  return (
    <div className="stepper align-items-end overflow-auto mt-4 mt-md-0">
      {steps.map((label, index) => (
        <Step
          key={index}
          label={label}
          isActive={label === section}
          onClick={() => stepperClickHandler(label)}
        />
      ))}
    </div>
  );
};

export default Stepper;