import React, { useEffect, useState } from "react";
import "./LandingPageNavbar.css";
import { RiContactsFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import b1CLogo from "../../assets/images/b1c-logo.svg";
import { AiOutlineMenu } from "react-icons/ai";
import ContactUsSidebar from "../ContactUsSidebar/ContactUsSidebar";
import ActionButton from "../FAB/ActionButton";
import { AiFillHome, AiFillMedicineBox } from "react-icons/ai";
import { BsQuestion } from "react-icons/bs";
import { MdMiscellaneousServices, MdArticle } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import Button from "../Buttons/Button";
import translator from "../../assets/images/Translator.png";
import { LandingPageNavbarTranslation } from "../../constants/Translation";

const NavbarMenu = ({ closeHandler, navbarValues, scrollToSection }) => {
  const history = useNavigate();
  useEffect(() => {
    setTimeout(
      () =>
        (document.getElementById("landingpage-navbar-menu").style.transform =
          "translateX(0)"),
      100
    );
  }, []);
  return (
    <>
      <div className="landingpage-navbar-menu-dark-overlay"></div>
      <div className="landingpage-navbar-menu" id="landingpage-navbar-menu">
        <div className="landingpage-navbar-menu-box">
          <div className="landingpage-navbar-menu-close-button-box">
            <IoMdClose
              className="landingpage-navbar-menu-close-button"
              onClick={closeHandler}
            />
          </div>
          {/* <h4 className="landingpage-navbar-menu-heading">BharatOne Care</h4> */}
          <ul className="landingpage-navbar-menu-nav-links">
            <li>
              <a href="/hospitals" onClick={closeHandler}>
                <AiFillHome className="landingpage-navbar-menu-nav-links-icon" />{" "}
                {navbarValues.hospitals}
              </a>
            </li>
            <li>
              <a href="/doctors" onClick={closeHandler}>
                <AiFillMedicineBox className="landingpage-navbar-menu-nav-links-icon" />{" "}
                {navbarValues.doctors}
              </a>
            </li>
            <li>
              <a href="/procedures" onClick={closeHandler}>
                <MdMiscellaneousServices className="landingpage-navbar-menu-nav-links-icon" />{" "}
                {navbarValues.procedures}
              </a>
            </li>
            <li>
              <a href="#reviews" onClick={closeHandler}>
                <BsQuestion className="landingpage-navbar-menu-nav-links-icon" />{" "}
                {navbarValues.reviews}
              </a>
            </li>
            <li>
              <a href="/blogs" onClick={closeHandler}>
                <MdArticle className="landingpage-navbar-menu-nav-links-icon" />{" "}
                {navbarValues.blog}
              </a>
            </li>
          </ul>
        </div>
        <Button
          text={`${navbarValues.partner}`}
          task={() => history("/business")}
        />
        <Button
          text={`${navbarValues.signup}`}
          task={() => history("/signup")}
        />
      </div>
    </>
  );
};

const LandingPageNavbar = ({
  setLanguage,
  language,
  handleLanguageChange,
  scrollToSection,
}) => {
  const [navbarMenuToggle, setNavbarMenuToggle] = useState(false);
  const [navbarValues, setNavbarValues] = useState({});
  const [contactUsSidebarToggle, setContactUsSidebarToggle] = useState(false);

  const setLanguageHandler = (e) => {
    setLanguage(e.target.value);
    handleLanguageChange(e);
  };
  const openMenuHandler = () => {
    setNavbarMenuToggle(true);
  };
  const closeMenuHandler = () => {
    document.getElementById("landingpage-navbar-menu").style.transform =
      "translateX(100%)";
    setTimeout(() => setNavbarMenuToggle(false), 500);
  };
  const openContactUsSidebarHandler = () => {
    setContactUsSidebarToggle(true);
  };
  const closeContactUsSidebarHandler = () => {
    document.getElementById("portfolio-contact-us-sidebar").style.transform =
      "translateX(100%)";
    setTimeout(() => setContactUsSidebarToggle(false), 500);
  };
  const history = useNavigate();
  window.addEventListener("scroll", () => {
    let navbarElement = document.getElementById("landingpage-navbar");
    if (navbarElement) {
      if (window.scrollY >= 80) {
        navbarElement.style.boxShadow = "0 1px 20px rgb(229, 229, 229)";
      } else {
        navbarElement.style.boxShadow = "";
      }
    }
  });

  useEffect(() => {
    if (language === "English") {
      setNavbarValues(LandingPageNavbarTranslation.en);
    } else if (language === "Hindi") {
      setNavbarValues(LandingPageNavbarTranslation.hi);
    } else if (language === "Arabic") {
      setNavbarValues(LandingPageNavbarTranslation.ar);
    } else {
      setNavbarValues(LandingPageNavbarTranslation.en);
    }
  }, [language]);

  return (
    <>
      <div className="landingpage-navbar" id="landingpage-navbar">
        <Link to={"/"}>
          <img src={b1CLogo} alt="" className="landingpage-b1c-logo" />
        </Link>
        <div>
          <ul className="nav-links">
            <motion.li
              initial={{ y: "-100%", opacity: 0 }}
              whileInView={{ y: "0%", opacity: 1 }}
              transition={{ delay: 0 }}
            >
              <a href={"/hospitals"}>{navbarValues.hospitals}</a>
            </motion.li>
            <motion.li
              initial={{ y: "-100%", opacity: 0 }}
              whileInView={{ y: "0%", opacity: 1 }}
              transition={{ delay: 0.1 }}
            >
              <a href={"/doctors"}>{navbarValues.doctors}</a>
            </motion.li>
            <motion.li
              initial={{ y: "-100%", opacity: 0 }}
              whileInView={{ y: "0%", opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              <a href="/procedures">{navbarValues.procedures}</a>
            </motion.li>
            {window.location.pathname === "/" ? (
              <motion.li
                initial={{ y: "-100%", opacity: 0 }}
                whileInView={{ y: "0%", opacity: 1 }}
                transition={{ delay: 0.2 }}
                onClick={scrollToSection}
              >
                <span>{navbarValues.reviews}</span>
              </motion.li>
            ) : (
              ""
            )}
            <motion.li
              initial={{ y: "-100%", opacity: 0 }}
              whileInView={{ y: "0%", opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              <a href="/blogs">{navbarValues.blog}</a>
            </motion.li>
            <motion.li
              initial={{ y: "-100%", opacity: 0 }}
              whileInView={{ y: "0%", opacity: 1 }}
              transition={{ delay: 0.3 }}
              onClick={openContactUsSidebarHandler}
            >
              <Link href="#">{navbarValues.contact}</Link>
            </motion.li>
          </ul>
          <div className="landingpage-navbar-buttons-box">
            {/*TODO: ENABLE TRANSLATION ONCE WE HAVE BETTER TRANSLATED TEXT */}
            {/* <div className="language-translator">
              <img
                src={translator}
                alt="Translator"
                style={{ width: "30px", height: "30px" }}
              />
              <select
                className="dropdown-content"
                id="dropdown-content"
                value={localStorage.getItem("i18nextLng")}
                onChange={(e) => {
                  setLanguageHandler(e);
                }}
              >
                <option value="English">ENGLISH</option>
                <option value="Hindi">हिन्दी</option>
                <option value="Arabic">العربية</option>
              </select>
            </div> */}
            {/* <button
              className="landingpage-partner-button"
              onClick={() => history("/customer-support/signup")}
            >
              {navbarValues.contactSupport}
            </button> */}
            <button
              className="landingpage-partner-button"
              onClick={() => {
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                history("/business");
              }}
            >
              {navbarValues.partner}
            </button>
            <button
              className="landingpage-navbar-button"
              onClick={() => history("/signup")}
            >
              {navbarValues.signup}
            </button>
          </div>
          <AiOutlineMenu
            className="landingpage-navbar-menu-button"
            onClick={openMenuHandler}
          />
          {navbarMenuToggle && (
            <NavbarMenu
              closeHandler={closeMenuHandler}
              navbarValues={navbarValues}
              scrollToSection={scrollToSection}
            />
          )}
        </div>
      </div>
      {window.innerWidth <= 1024 && (
        <ActionButton
          text={`${navbarValues.contact}`}
          icon={<RiContactsFill />}
          task={openContactUsSidebarHandler}
          styling={{
            position: "fixed",
            bottom: "25px",
            right: "25px",
            borderRadius: "12px",
            backgroundColor: "var(--secondaryColor)",
            fontWeight: 600,
            zIndex: 1,
          }}
        />
      )}
      {contactUsSidebarToggle && (
        <ContactUsSidebar closeHandler={closeContactUsSidebarHandler} />
      )}
    </>
  );
};

export default LandingPageNavbar;
