import { Link } from "react-router-dom";
import DashboardTableNameColumn from "../components/DashboardTableNameColumn/DashboardTableNameColumn";
import Tooltip from "../components/Tooltip/Tooltip";

export const hospitalTableConfig = [
  {
    columnName: "Hospital Name",
    // value: "hospitalName",
    style: { width: "210px", marginLeft: "20px", marginRight: "max(10px,3vw)" },
    render: (data) => {
      return (
        <DashboardTableNameColumn
          title={data.hospitalName}
          subtitle={data.location}
          hospitalLogo={data.hospitalLogo}
        />
      );
    },
  },
  // { columnName: "Location", value: "location" },
  { columnName: "Contact Number", value: "contactNumber" },
  {
    columnName: "Email",
    style: { width: "200px" },
    render: (item) => {
      return (
        <Tooltip text={item.email}>
          <p
            className="dashboard-table-row-description"
            style={{
              color: "black",
              width: "200px",
            }}
          >
            <a href={`mailto:${item.email}`} style={{ color: "black" }}>
              {item.email}
            </a>
          </p>
        </Tooltip>
      );
    },
  },
  { columnName: "Date of Onboarding", value: "dateOfOnboarding" },
  { columnName: "Agent Who Onboarded", value: "agentWhoOnboarded" },
  // { columnName: "Contact Person", value: "contactPerson" },
  // { columnName: "Contact Person Name", value: "contactPersonName" },
  // {
  //   columnName: "Contact Person Email",
  //   value: "contactPersonEmail",
  //   style: { maxWidth: "210px" },
  //   render: (item) => {
  //     return (
  //       <a
  //         href={`mailto:${item.contactPersonEmail}`}
  //         style={{
  //           textDecoration: "none",
  //           maxWidth: "210px",
  //           overflow: "clip",
  //         }}
  //       >
  //         {item.contactPersonEmail}
  //       </a>
  //     );
  //   },
  // },
  // {
  //   columnName: "Contact Person Email",
  //   value: "contactPersonEmail",
  //   style: { width: "200px" },
  //   render: (item) => {
  //     return (
  //       <Tooltip text={item.contactPersonEmail}>
  //         <p
  //           className="dashboard-table-row-description"
  //           style={{
  //             color: "black",
  //             width: "200px",
  //           }}
  //         >
  //           <a
  //             href={`mailto:${item.contactPersonEmail}`}
  //             style={{ color: "black" }}
  //           >
  //             {item.contactPersonEmail}
  //           </a>
  //         </p>
  //       </Tooltip>
  //     );
  //   },
  // },
  {
    columnName: "status",
    render: (item) => {
      let colour = "rgb(152, 152, 7)";
      if (item.status === "arrived") colour = "pink";
      if (item.status === "Payment Recieved") colour = "green";
      return (
        <div
          className="dashboard-table-row-description"
          style={{ color: colour }}
        >
          {item.status}
        </div>
      );
    },
  },
];
export const dummyHospitalTableData = [
  {
    hospitalName: "St. Mary's Hospital",
    location: "New York",
    contactNumber: "123-456-7890",
    email: "stmary@example.com",
    dateOfOnboarding: "2023-01-15",
    agentWhoOnboarded: "John Doe",
    contactPerson: "Jane Smith",
    contactPersonName: "Jane",
    contactPersonEmail: "jane@example.com",
    status: "arrived",
  },
  {
    hospitalName: "City General Hospital",
    location: "Los Angeles",
    contactNumber: "987-654-3210",
    email: "citygeneral@example.com",
    dateOfOnboarding: "2023-02-20",
    agentWhoOnboarded: "Alice Johnson",
    contactPerson: "Bob Anderson",
    contactPersonName: "Bob",
    contactPersonEmail: "bob@example.com",
    status: "Payment Received",
  },
  {
    hospitalName: "Sunset Medical Center",
    location: "Miami",
    contactNumber: "567-890-1234",
    email: "sunsetmed@example.com",
    dateOfOnboarding: "2023-03-10",
    agentWhoOnboarded: "Eva Williams",
    contactPerson: "Charlie Davis",
    contactPersonName: "Charlie",
    contactPersonEmail: "charlie@example.com",
    status: "Processing",
  },
  {
    hospitalName: "Unity Health Clinic",
    location: "Chicago",
    contactNumber: "222-333-4444",
    email: "unityhealth@example.com",
    dateOfOnboarding: "2023-04-05",
    agentWhoOnboarded: "Mark Taylor",
    contactPerson: "Diana Martinez",
    contactPersonName: "Diana",
    contactPersonEmail: "diana@example.com",
    status: "Shipped",
  },
  {
    hospitalName: "Central Medical Center",
    location: "Houston",
    contactNumber: "555-666-7777",
    email: "centralmed@example.com",
    dateOfOnboarding: "2023-05-20",
    agentWhoOnboarded: "Sarah Adams",
    contactPerson: "Edward Johnson",
    contactPersonName: "Edward",
    contactPersonEmail: "edward@example.com",
    status: "Delivered",
  },
  {
    hospitalName: "Westside Health Institute",
    location: "San Francisco",
    contactNumber: "111-222-3333",
    email: "westsidehealth@example.com",
    dateOfOnboarding: "2023-06-12",
    agentWhoOnboarded: "Michael Brown",
    contactPerson: "Grace Thompson",
    contactPersonName: "Grace",
    contactPersonEmail: "grace@example.com",
    status: "Pending",
  },
  {
    hospitalName: "Evergreen Wellness Center",
    location: "Seattle",
    contactNumber: "999-888-7777",
    email: "evergreenwellness@example.com",
    dateOfOnboarding: "2023-07-08",
    agentWhoOnboarded: "Olivia Miller",
    contactPerson: "Frank Wilson",
    contactPersonName: "Frank",
    contactPersonEmail: "frank@example.com",
    status: "Returned",
  },
  {
    hospitalName: "Northern Regional Hospital",
    location: "Detroit",
    contactNumber: "777-888-9999",
    email: "northernregional@example.com",
    dateOfOnboarding: "2023-08-25",
    agentWhoOnboarded: "Daniel White",
    contactPerson: "Linda Davis",
    contactPersonName: "Linda",
    contactPersonEmail: "linda@example.com",
    status: "Cancelled",
  },
  {
    hospitalName: "Eastside Medical Center",
    location: "Atlanta",
    contactNumber: "333-444-5555",
    email: "eastside@example.com",
    dateOfOnboarding: "2023-09-18",
    agentWhoOnboarded: "Emma Wilson",
    contactPerson: "James Smith",
    contactPersonName: "James",
    contactPersonEmail: "james@example.com",
    status: "In Transit",
  },
  {
    hospitalName: "Southern Health Services",
    location: "Dallas",
    contactNumber: "666-555-4444",
    email: "southernhealth@example.com",
    dateOfOnboarding: "2023-10-10",
    agentWhoOnboarded: "Sophia Johnson",
    contactPerson: "Robert Miller",
    contactPersonName: "Robert",
    contactPersonEmail: "robert@example.com",
    status: "Awaiting Shipment",
  },
];
