import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import DownloadIcon from "@mui/icons-material/Download";
import TablePen from "../../assets/images/table-pen-button.svg";
import Search from "../../assets/images/search-lg.svg";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { API } from "../../constants/api";
import FilterPopup from "../FilterPopup/FilterPopup";
import BookingFilterModel from "../BookingFilterModel/BookingFilterModel";
import AddBookingModal from "../AddBookingModal/AddBookingModal";
import { sidebarFacilitatorItems } from "../../constants/SidebarItems";
import DashboardLayout from "../../pages/DashboardSidebar/DashboardLayout";
import { BookingsTableConfig } from "../../constants/BookingsTableConfig";
import DashboardTablePagination from "../DashboardTablePagination/DashboardTablePagination";
import DashboardTable from "../DashboardTable/DashboardTable";
import BookingConfirmationPopup from "../BookingConfirmationPopup/BookingConfirmationPopup";

import Tooltip from "../Tooltip/Tooltip";
import MenuDropdown from "../MenuDropdown/MenuDropdown";
import BookingMenuDropdown from "../BookingMenuDropdown/BookingMenuDropdown";
import EditBooking from "../EditBooking/EditBooking";



const Bookings = () => {
  const [bookingsData, setBookingsData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(null);
  const [editBookingModelOpen, setEditBookingModelOpen] = useState(null);
  const [searchedInput, setSearchedInput] = useState("");
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    // setTableData((tableData) =>
    //   tableData.filter((item) => item.name.includes(searchedInput))
    // );
    // const filteredData = bookingsData.filter((item) => {
    //   return Object.entries(selectedFilters).every(
    //     ([filterKey, filterValues]) => {
    //       if (filterValues.length > 0) {
    //         if (filterKey === "totalStaff") {
    //           return filterValues.some((filterValue) => {
    //             const [min, max] = filterValue.split("-");
    //             console.log(item);
    //             const staffCount = parseInt(item[filterKey], 10);
    //             console.log(staffCount);
    //             if (min && max === "max") {
    //               return staffCount >= parseInt(min, 10);
    //             } else if (min && max) {
    //               return (
    //                 staffCount >= parseInt(min, 10) &&
    //                 staffCount <= parseInt(max, 10)
    //               );
    //             } else if (min) {
    //               return staffCount >= parseInt(min, 10);
    //             } else if (max) {
    //               return staffCount <= parseInt(max, 10);
    //             }

    //             return false;
    //           });
    //         }
    //         return filterValues.includes(item[filterKey]);
    //       }
    //       return true;
    //     }
    //   );
    // });
    if (searchedInput.length > 0) {
      const filteredData2 = bookingsData.filter(
        (item) =>
          item.hospitalName.toLowerCase().includes(searchedInput) ||
          item.patientName.toLowerCase().includes(searchedInput)
      );
      setTableData(filteredData2);
    }
  }, [searchedInput]);

  // Menu Dropdown
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleMenuItemClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const size = 5;
  const updatedRows = tableData.slice(size * index + 0, size * index + size);

  const handleEditClick = (data) => {
    setEditBookingModelOpen(data);
  };

  console.log(editBookingModelOpen);

  const editAppointmentSubmitHandler = async (date) => {
    const prevData = editBookingModelOpen;
    // let status = prevData.status;
    // if (status !== "In Progress") {
    //   status = "Scheduled";
    // }
    const payload = { statusDate: date, status: "" };
    // payload.statusDate = date;

    try {
      setLoader(true);

      // setEditBookingModelOpen(null);
      await getDataFromFirebase();
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }

    console.log(payload);
  };
  const handleDeleteHospitalModal = (item) => {
    setDeleteModal(item);
  };
  const handleDownloadInvoice = () => {};

  const handleDeleteClick = async (item) => {
    if (item === "Yes") {
      setLoader(true);
      try {
        console.log("Cancelling Booking...");
        await axios.patch(`${API}/bookings/${deleteModal.bookingId}`);
        getDataFromFirebase();
        setDeleteModal(null);

        setLoader(false);
      } catch (error) {
        setLoader(false);
        setDeleteModal(null);
      }
    } else {
      setDeleteModal(null);
    }
  };
  const buttons = [
    {
      title: "cross",

      render: (row) => {
        return (
          <Tooltip text={"Edit Booking"}>
            <img
              className='dashboard-table-row-image'
              src={TablePen}
              alt='Edit'
              onClick={() => {
                handleEditClick(row);
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      title: "icon",
      render: (item) => {
        return (
          <>
            <BookingMenuDropdown
              handleCancelBooking={handleDeleteHospitalModal}
              handleDownloadInvoice={handleDownloadInvoice}
              item={item}
            />
          </>
        );
      },
    },
  ];

  const handleClick = (ind) => {
    setIndex(ind);
  };

  const numberOfPages = Math.ceil(bookingsData.length / size);

  const getDataFromFirebase = async () => {
    try {
      const { data } = await axios.get(`${API}/bookings`);
      setTableData(data);
      setBookingsData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDataFromFirebase();
  }, []);

  //////////////////////// PATIENT FILTER POPUP CONTENT START ////////////////////////////

  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const [isClosingFilter, setIsClosingFilter] = useState(false);
  const [filterPatientName, setFilterPatientName] = useState("");
  const [filterPatientMedicalCondition, setFilterPatientMedicalCondition] =
    useState("");
  const [filterPatientPreferredHospital, setFilterPatientPreferredHospital] =
    useState("");
  const [filterPatientHospitalLocation, setFilterPatientHospitalLocation] =
    useState("");
  const [filterPatientVisaAssistance, setFilterPatientVisaAssistance] =
    useState("");
  const [
    filterPatientAirportTransfersAssistance,
    setFilterPatientAirportTransfersAssistance,
  ] = useState("");
  const [
    filterPatientAccommodationAssistance,
    setFilterPatientAccommodationAssistance,
  ] = useState("");
  const [
    filterPatientAccommodationLocation,
    setFilterPatientAccommodationLocation,
  ] = useState("");
  const [selectedPatientFiltersChips, setSelectedPatientFiltersChips] =
    useState({
      gender: [],
      patientCountry: [],
      medicalCondition: [],
      preferredHospital: [],
      hospitalLocation: [],
      accommodationLocation: [],
    });

  const togglePatientFilterChip = (label, category) => {
    if (category === "patientCountry") {
      setSelectedPatientFiltersChips({
        ...selectedPatientFiltersChips,
        patientCountry: label,
      });
    } else if (category === "medicalCondition") {
      setSelectedPatientFiltersChips({
        ...selectedPatientFiltersChips,
        medicalCondition: label,
      });
    } else if (category === "preferredHospital") {
      setSelectedPatientFiltersChips({
        ...selectedPatientFiltersChips,
        preferredHospital: label,
      });
    } else if (category === "hospitalLocation") {
      setSelectedPatientFiltersChips({
        ...selectedPatientFiltersChips,
        hospitalLocation: label,
      });
    } else if (category === "accommodationLocation") {
      setSelectedPatientFiltersChips({
        ...selectedPatientFiltersChips,
        accommodationLocation: label,
      });
    } else {
      const currentSelection = selectedPatientFiltersChips[category];

      if (currentSelection.includes(label)) {
        setSelectedPatientFiltersChips({
          ...selectedPatientFiltersChips,
          [category]: currentSelection.filter((chip) => chip !== label),
        });
      } else {
        setSelectedPatientFiltersChips({
          ...selectedPatientFiltersChips,
          [category]: [...currentSelection, label],
        });
      }
    }
  };

  const togglePopupFilter = (toClear) => {
    if (toClear === "clearFilters") {
      if (isVisibleFilter) {
        setIsClosingFilter(true);
        setTimeout(() => {
          setIsVisibleFilter(false);
          setIsClosingFilter(false);
          handlePatientFilterClear();
        }, 300);
      } else {
        setIsVisibleFilter(true);
      }
    } else {
      if (isVisibleFilter) {
        setIsClosingFilter(true);
        setTimeout(() => {
          setIsVisibleFilter(false);
          setIsClosingFilter(false);
        }, 300);
      } else {
        setIsVisibleFilter(true);
      }
    }
  };

  const handlePatientFilterChange = (label, category) => {
    if (category === "name") {
      setFilterPatientName(label);
    }
    // if (category === "medicalCondition") {
    //   setFilterPatientMedicalCondition(label);
    // }
    // if (category === "preferredHospital") {
    //   setFilterPatientPreferredHospital(label);
    // }
    // if (category === "hospitalLocation") {
    //   setFilterPatientHospitalLocation(label);
    // }
    if (category === "visaAssistance") {
      if (filterPatientVisaAssistance === label) {
        setFilterPatientVisaAssistance("");
      } else {
        setFilterPatientVisaAssistance(label);
      }
    }
    if (category === "airportTransfersAssistance") {
      if (filterPatientAirportTransfersAssistance === label) {
        setFilterPatientAirportTransfersAssistance("");
      } else {
        setFilterPatientAirportTransfersAssistance(label);
      }
    }
    if (category === "accommodationAssistance") {
      if (filterPatientAccommodationAssistance === label) {
        setFilterPatientAccommodationAssistance("");
      } else {
        setFilterPatientAccommodationAssistance(label);
      }
    }
    // if (category === "accommodationLocation") {
    //   setFilterPatientAccommodationLocation(label);
    // }
  };

  const handlePatientFilterClear = () => {
    setFilterPatientName("");
    // setFilterPatientMedicalCondition("");
    // setFilterPatientPreferredHospital("");
    // setFilterPatientHospitalLocation("");
    setFilterPatientVisaAssistance("");
    setFilterPatientAirportTransfersAssistance("");
    setFilterPatientAccommodationAssistance("");
    // setFilterPatientAccommodationLocation("");
    setSelectedPatientFiltersChips({
      gender: [],
      patientCountry: [],
      medicalCondition: [],
      preferredHospital: [],
      hospitalLocation: [],
      accommodationLocation: [],
    });
  };

  const handlePatientFilterSubmit = () => {
    console.log("Patient Filters:", {
      name: filterPatientName,
      medicalCondition: filterPatientMedicalCondition,
      preferredHospital: filterPatientPreferredHospital,
      hospitalLocation: filterPatientHospitalLocation,
      visaAssistance: filterPatientVisaAssistance,
      airportTransfersAssistance: filterPatientAirportTransfersAssistance,
      accommodationAssistance: filterPatientAccommodationAssistance,
      accommodationLocation: filterPatientAccommodationLocation,
      gender: selectedPatientFiltersChips.gender,
      patientCountry: selectedPatientFiltersChips.patientCountry,
    });
    togglePopupFilter("clearFilters");
  };

  //////////////////////// ADD BOOKING POPUP CONTENT START ////////////////////////////

  const [errorText, setErrorText] = useState("");
  const [displayError, setDisplayError] = useState(false);

  const [addPatientName, setAddPatientName] = useState("");
  const [addHospitalName, setAddHospitalName] = useState("");
  const [addTreatmentType, setAddTreatmentType] = useState("");
  const [addBookingDate, setAddBookingDate] = useState(Date.now());

  const [isVisibleAddBooking, setIsVisibleAddBooking] = useState(false);
  const [isClosingAddBooking, setIsClosingAddBooking] = useState(false);
  const [selectedFiltersAddBooking, setSelectedFiltersAddBooking] = useState({
    name: "",
    gender: "",
    contact: "",
    email: "",
    country: "",
    medicalIssue: "",
    dateOfBirth: "",
  });

  const toggleAddPatientChip = (label, category) => {
    const currentSelection = selectedFiltersAddBooking[category];
    if (currentSelection === "") {
      setSelectedFiltersAddBooking({
        ...selectedFiltersAddBooking,
        [category]: label,
      });
    } else {
      setSelectedFiltersAddBooking({
        ...selectedFiltersAddBooking,
        [category]: label,
      });
    }
  };

  const togglePopupAddBooking = (toClear) => {
    if (toClear === "clearFilters") {
      if (isVisibleAddBooking) {
        setIsClosingAddBooking(true);
        setTimeout(() => {
          setIsVisibleAddBooking(false);
          setIsClosingAddBooking(false);
          handleAddBookingClear();
        }, 300);
      } else {
        setIsVisibleAddBooking(true);
      }
    } else {
      if (isVisibleAddBooking) {
        setIsClosingAddBooking(true);
        setTimeout(() => {
          setIsVisibleAddBooking(false);
          setIsClosingAddBooking(false);
        }, 300);
      } else {
        setIsVisibleAddBooking(true);
      }
    }
  };

  const handleAddBookingClear = () => {
    setAddPatientName("");
    setAddHospitalName("");
    setAddTreatmentType("");
  };

  const handleSubmitBooking = () => {
    if (
      addPatientName === "" ||
      addHospitalName === "" ||
      addTreatmentType === ""
    ) {
      setErrorText("*Please fill all the fields");
      setDisplayError(true);
      setTimeout(() => {
        setDisplayError(false);
      }, 3000);
      return;
    }

    try {
      axios.post(`${API}/bookings/add-booking`, {
        data: {
          patientName: addPatientName,
          hospitalName: addHospitalName,
          treatmentType: addTreatmentType,
        },
      });
    } catch (error) {
      console.log(error);
    }

    console.log("Booking Details:", {
      patientName: addPatientName,
      hospitalName: addHospitalName,
      treatmentType: addTreatmentType,
    });
    togglePopupAddBooking("clearFilters");
  };

  return (
    <DashboardLayout sidebarItems={sidebarFacilitatorItems}>
      <div className='p-4'>
        <Breadcrumb
          breadcrumbs={[{ label: "Bookings", url: "/facilitator/bookings" }]}
          divider={"/"}
          styles={{
            paddingTop: "1rem",
          }}
        />

        <div className='button-div'>
          <div
            className='button-div'
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <div className='button-div-left-side'>
              <h1 className='appointment-header'>Bookings Management</h1>
              <FilterPopup
                isVisible={isVisibleAddBooking}
                isClosing={isClosingAddBooking}
                setIsClosing={setIsClosingAddBooking}
                setIsVisible={setIsVisibleAddBooking}
                togglePopup={togglePopupAddBooking}
                handleFilterClear={handleAddBookingClear}
                handleFilterSubmit={handleSubmitBooking}
                name='Add Booking'
                buttonStyle='add-button-style'
                displayError={displayError}
                errorText={errorText}
              >
                <AddBookingModal
                  selectedFilters={selectedFiltersAddBooking}
                  toggleChip={toggleAddPatientChip}
                  addPatientName={addPatientName}
                  setAddPatientName={setAddPatientName}
                  addHospitalName={addHospitalName}
                  setAddHospitalName={setAddHospitalName}
                  addTreatmentType={addTreatmentType}
                  setAddTreatmentType={setAddTreatmentType}
                  setAddBookingDate={setAddBookingDate}
                  addBookingDate={addBookingDate}
                />
              </FilterPopup>
            </div>

            <div className='button-div-right-side'>
              <img src={Search} alt='search' className='search-icon' />
              <input
                type='text'
                onChange={(event) => {
                  setSearchedInput(event.target.value);
                }}
                value={searchedInput}
                className='searched-input-field'
                placeholder='Search'
              />
              <FilterPopup
                isVisible={isVisibleFilter}
                isClosing={isClosingFilter}
                setIsClosing={setIsClosingFilter}
                setIsVisible={setIsVisibleFilter}
                togglePopup={togglePopupFilter}
                handleFilterClear={handlePatientFilterClear}
                handleFilterSubmit={handlePatientFilterSubmit}
                name='Filter'
                buttonStyle='filter-button-style'
                displayError={displayError}
                errorText={errorText}
              >
                <BookingFilterModel
                  selectedFilters={selectedPatientFiltersChips}
                  toggleChip={togglePatientFilterChip}
                  handlePatientFilterChange={handlePatientFilterChange}
                  filterPatientName={filterPatientName}
                  filterPatientVisaAssistance={filterPatientVisaAssistance}
                  filterPatientAirportTransfersAssistance={
                    filterPatientAirportTransfersAssistance
                  }
                  filterPatientAccommodationAssistance={
                    filterPatientAccommodationAssistance
                  }
                />
              </FilterPopup>
            </div>
          </div>
        </div>

        {editBookingModelOpen != null && (
          <EditBooking
            handleModalClose={() => {
              setEditBookingModelOpen(null);
            }}
            submitHandler={editAppointmentSubmitHandler}
          />
        )}

        {deleteModal && (
          <BookingConfirmationPopup
            handleClick={handleDeleteClick}
            data={deleteModal}
          />
        )}
        <div className='table-section-container'>
          <DashboardTable
            columns={BookingsTableConfig}
            rows={updatedRows}
            size={6}
            action={buttons}
            actionHeaderTitle='Actions'
          />{" "}
        </div>
        {numberOfPages > 1 && (
          <DashboardTablePagination
            numberOfPages={numberOfPages}
            index={index}
            handleClick={handleClick}
          />
        )}
      </div>
    </DashboardLayout>
  );
};

export default Bookings;
