import React from 'react'

const TextInputComponent = (props) => {
    return (
      <div className = "login-page-form-input-container">
        <label htmlFor={props.name} className="login-page-form-label">
          {props.label}
        </label>
        <input
          type="text"
          placeholder={props.placeholder}
          className={props.className}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
        />
      </div>
    );
  };
  
export default TextInputComponent