import React, { useState, useEffect } from "react";
import "./ComboBarLineGraphComponent.css";
import BarGraphComponent from "../../../components/GraphComponents/BarGraphComponent/BarGraphComponent";
import indianStatesData from "../../../assets/custom-data/data";

const ComboBarLineGraphComponent = (props) => {
  useEffect(() => {
    console.log(Object.keys(props.onboardingCallsValues)[1]);
  });

  function average(ctx) {
    const values = ctx.chart.data.datasets[0].data;
    console.log(indianStatesData.map((state) => state.totalHospitals));
    return values.reduce((a, b) => a + b, 0) / values.length;
  }

  const verticalBarOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
          drawTicks: false,
        },
        border: {
          color: "#757575",
        },
        ticks: {
          color: "#000000",
          padding: 10,
        },
      },
      y: {
        border: {
          dash: [5, 5],
          color: "transparent",
        },
        grid: {
          color: "#E8E8E8",
          drawTicks: false,
        },
        ticks: {
          color: "#000000",
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom",
        pointStyleWidth: 10,
        labels: {
          color: "#757575",
          usePointStyle: true,
        },
      },
      annotation: {
        annotations: [
          {
            drawTime: "beforeDatasetsDraw",
            type: "line",
            yMin: (ctx) => average(ctx),
            yMax: (ctx) => average(ctx),
            borderColor: "#EF5EA2",
            borderDash: [5, 5],
            borderWidth: 2,
            // label: {
            //   drawTime: "afterDatasetsDraw",
            //   display: true,
            //   content: "Average",
            //   position: "end",
            // }
          },
        ],
      },
    },
  };

  return (
    <div className="comboBarLine-chart-container">
      <div className="onboarding-calls-chart-container">
        <div className="onboarding-calls-title-select-container">
          <h1 className="onboarding-calls-title">
            {props.onboardingCallsValues.title}
          </h1>
          <select
            name={Object.keys(props.onboardingCallsValues)[1]}
            id={Object.keys(props.onboardingCallsValues)[1]}
          >
            {props.onboardingCallsValues.sortBy.map((option) => {
              return <option value={option}>{option}</option>;
            })}
          </select>
        </div>
        <div className="onboarding-calls-chart-graph">
          <BarGraphComponent
            chartData={props.onboardingCallsData}
            options={verticalBarOptions}
            chartWidth="70vw"
            chartHeight="37vh"
          />
        </div>
      </div>
    </div>
  );
};

export default ComboBarLineGraphComponent;
