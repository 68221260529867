// import Checkbox from "../components/InputTypes/CheckBox";
// import FileInput from "../components/InputTypes/FileInput";
// import ParagraphInput from "../components/InputTypes/ParagraphInput";
// import Radio from "../components/InputTypes/Radio";
// import SimpleInput from "../components/InputTypes/SimpleInput";
// import SingleSelectInput from "../components/InputTypes/SingleSelectInput";

// import MultipleSelect from "../components/InputTypes/MultipleSelect";
// import PhoneNumberInput from "../components/InputTypes/PhoneInput";

//Sample Form Configuration - Testing
import { indianCities } from "../assets/custom-data/indianCities";
import { worldCountries } from "../assets/custom-data/worldCountries";
import PatientOverview from "../components/PatientOverview/PatientOverview";

export const phoneValidation = (input) => {
  // VALIDATION COMMMENTED FOR NOW
  const value = input?.phone?.slice(input?.dialCode?.length);
  // const phoneno = /^\d{10}$/;
  // if (value?.match(phoneno)) {
  //   return true;
  // } else {
  //   return false;
  // }

  // const value = input?.phone?.slice(input?.dialCode?.length);
  // const phoneno =
  //   /((\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}/;
  // // const phoneno = /\\A[0-9]{10}\\z/;
  // if (value?.match(phoneno)) {
  //   return true;
  // } else {
  //   return false;
  // }
  if (value) {
    return true;
  } else {
    return false;
  }
};

const indianCitiesOptions = indianCities.map((item, ind) => {
  const obj = {
    label: item,
    id: ind,
  };
  return obj;
});
export const dummyFormConfig = [
  {
    type: "section",
    label: "Section 1",
  },
  {
    type: "email",
    label: "Email",
  },
];

export const agentTypesFormat = [
  //Tell us about yourself
  {
    section: "Tell us about yourself",
    fields: [
      [
        {
          title: "FirstName",
          label: "First Name",
          placeholder: "Enter First Name",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
        },
        {
          title: "LastName",
          label: "Last Name",
          placeholder: "Enter Last Name",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
        },
      ],

      [
        {
          title: "Contact_Number",
          label: "Contact Number",
          placeholder: "Enter Contact Number",
          intitalValue: { dialCode: "", phone: "" },
          type: "tele",
          required: true,
          validation: phoneValidation,
          onChange: () => { },
        },
      ],

      // [{
      //   title: "password",
      //   type: "password",
      //   label: "Password",
      //   intitalValue: "",
      //   placeholder: "Enter Password",
      //   required: true,
      //   validation: (input) => { return input?.length !== 0 },
      //   onChange: () => { },
      // }],

      // [{
      //   title: "Phone",
      //   type: "text",
      //   label: "Phone Number",
      //   placeholder: "enter 10 digit mobile number",
      //   intitalValue: "",
      //   required: true,
      // validation: (input) => {
      //   const phoneno = /^\d{10}$/;
      //   if (input?.match(phoneno)) {
      //     return true;
      //   }
      //   else {
      //     return false;
      //   }
      // },
      //   onChange: () => { },
      // }],

      [
        {
          type: "radio",
          title: "Nationality",
          intitalValue: "indian",
          radioFields: [
            {
              value: "indian",
              title: "Indian",
            },
            {
              value: "notIndian",
              title: "Non-Indian",
            },
          ],
          required: true,
          onChange: () => { },
          validation: (input) => {
            return true;
          },
        },
      ],

      [
        {
          type: "select",
          title: "Gender",
          multiple: false,
          intitalValue: "male",
          label: "Gender",
          options: [
            {
              value: "male",
              title: "Male",
            },
            {
              value: "female",
              title: "Female",
            },
            {
              value: "Not disclosed",
              title: "Prefer not to say",
            },
          ],
          onChange: () => { },
          validation: (input) => {
            return true;
          },
        },
      ],
    ],
  },

  // {
  //   section: "Test",
  //   fields: [
  //     [
  //       {
  //         title: "Test",
  //         // label: "Test",
  //         initialValue: "",
  //         render: function(formData, handleChange, handleTouch) {
  //           return <Table /> /// UseEffect dependency consists values of table in state
  //         }
  //       }
  //     ]
  //   ]
  // }
  // {
  //   section: "Doctor",
  //   fields: [
  //     [
  //       {
  //         title: "Doctor",
  //         // label: "Test",
  //         initialValue: "",
  //         render: function(formData, handleChange, handleTouch) {
  //           return <Table /> /// UseEffect dependency consists values of table in state
  //         }
  //       }
  //     ]
  //   ]
  // }
  // {
  //   section: "ADdd",
  //   fields: [
  //     [
  //       {
  //         title: "Test",
  //         // label: "Test",
  //         initialValue: "",
  //         render: function(formData, handleChange, handleTouch) {
  //           return <Table /> /// UseEffect dependency consists values of table in state
  //         }
  //       }
  //     ]
  //   ]
  // }

  //Documents
  {
    section: "Upload documents",
    fields: [
      [
        {
          title: "PAN CARD",
          label: "Pan Card",
          onChange: () => { },
          placeholder: "",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
        },
      ],

      [
        {
          title: "additionalInfo",
          label: "Additional Information",
          intitalValue: "",
          placeholder: "Enter Additional Information",
          type: "para",
          rows: 5,
          cols: 33,
          required: false,
          validation: (value) => {
            return value?.length !== 0;
          },
          onChange: () => { },
        },
      ],

      [
        {
          type: "checkbox",
          title: "Who you are?",
          intitalValue: [],
          checkboxFields: [
            {
              value: "Hospital",
              title: "Hospital",
            },
            {
              value: "Agent",
              title: "Agent",
            },
            {
              value: "Patient",
              title: "Patient",
            },
          ],
          required: true,
          onChange: () => { },
          validation: (value) => {
            return value.length !== 0;
          },
        },
      ],
      [
        {
          title: "multiple-select",
          label: "Select Multiple Options",
          onChange: () => { },
          placeholder: "",
          intitalValue: [],
          type: "multiple-select",
          required: true,
          options: [
            {
              value: "option1",
              label: "Option 1",
            },
            {
              value: "option2",
              label: "Option 2",
            },
            {
              value: "option3",
              label: "Option 3",
            },
            {
              value: "option4",
              label: "Option 4",
            },
          ],

          validation: function (value) {
            if (value === null || value === undefined) return false;
            if (value.length === 0) return false;
            return true;
          },
        },
      ],
    ],
  },
];

export const agentTypes = [
  {
    section: "Tell us about yourself",
    stepperHeader: "Basics",
    fields: [
      [
        {
          title: "Full_Name",
          label: "Full Name",
          placeholder: "Enter Full Name",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "please enter a non empty name",
        },
      ],
      [
        {
          title: "Contact_Number",
          label: "Contact Number",
          placeholder: "Enter Contact Number",
          intitalValue: { dialCode: "", phone: "" },
          type: "tele",
          required: true,
          validation: phoneValidation,
          onChange: () => { },
          errorELe:
            "please enter a 10 digit mobile number without country code",
        },
      ],
      [
        {
          type: "radio",
          title: "Nationality",
          intitalValue: "indian",
          radioFields: [
            {
              value: "indian",
              title: "Indian",
            },
            {
              value: "notIndian",
              title: "Non-Indian",
            },
          ],
          required: true,
          onChange: () => { },
          validation: (input) => {
            return true;
          },
        },
      ],
    ],
  },

  {
    section: "Upload Documents",
    stepperHeader: "Documents",
    fields: [
      [
        {
          title: "PAN_CARD",
          label: "Pan Card",
          onChange: () => { },
          placeholder: "",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a document",
        },
      ],

      [
        {
          title: "Cancelled_cheque",
          label: "Cancelled cheque",
          onChange: () => { },
          placeholder: "",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a document",
        },
      ],
      [
        {
          title: "Passport",
          label: "Passport",
          onChange: () => { },
          placeholder: "",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a document",
        },
      ],
    ],
  },
];

export const hospitalTypes = [
  {
    section: "Tell us About Yourself",
    stepperHeader: "Basics",
    fields: [
      [
        {
          title: "Name_Of_Hospital",
          label: "Name Of Hospital",
          placeholder: "St. Marks Hospital",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "please enter a non empty name",
        },
      ],
      [
        {
          title: "Contact_Number",
          label: "Contact Number",
          placeholder: "Enter Contact Number",
          intitalValue: { dialCode: "", phone: "" },
          type: "tele",
          required: true,
          validation: phoneValidation,
          onChange: () => { },
          errorELe:
            "please enter a 10 digit mobile number without country code",
        },
        {
          title: "Location",
          label: "Location",
          placeholder: "Location",
          type: "searchable-select",
          multiple: false,
          options: indianCitiesOptions,
          initialValue: {
            label: "Mumbai",
            id: 124,
          },
          errorELe: "please select at least one city",
          validation: (value) => {
            return value !== undefined && value !== "";
          },
        },
      ],
      [
        {
          type: "radio",
          title: "Nationality",
          intitalValue: "indian",
          radioFields: [
            {
              value: "indian",
              title: "Indian",
            },
            {
              value: "notIndian",
              title: "Non-Indian",
            },
          ],
          required: true,
          onChange: () => { },
          validation: (input) => {
            return true;
          },
        },
      ],
      [
        // {
        //   type: "radio",
        //   title: "Accreditation",
        //   intitalValue: "NABH",
        //   radioFields: [
        //     {
        //       value: "NABH",
        //       title: "NABH",
        //     },
        //     {
        //       value: "JCI",
        //       title: "JCI",
        //     },
        //   ],
        //   required: true,
        //   onChange: () => {},
        //   validation: (input) => {
        //     return true;
        //   },
        // },
        {
          type: "checkbox",
          title: "Accreditation",
          intitalValue: [],
          checkboxFields: [
            {
              value: "NABH",
              title: "NABH",
            },
            {
              value: "JCI",
              title: "JCI",
            },
            {
              value: "NABL",
              title: "NABL",
            },
          ],
          required: true,
          onChange: () => { },
          validation: (value) => {
            return value && value.length !== 0;
          },
        },
      ],
    ],
  },
  {
    section: "Upload License",
    stepperHeader: "License",
    fields: [
      [
        {
          title: "License",
          label: "License",
          onChange: () => { },
          placeholder: "View License",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a valid document",
        },
      ],
      [
        {
          title: "NABH",
          label: "Accreditation Certificate (NABH)",
          tag: "NABH",
          onChange: () => { },
          placeholder: "upload Certificate",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a valid document",
        },
        {
          title: "JCI",
          label: "Accreditation Certificate (JCI)",
          tag: "JCI",
          onChange: () => { },
          placeholder: "upload Certificate",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a valid document",
        },
        {
          title: "NABL",
          label: "Accreditation Certificate (NABL)",
          tag: "NABL",
          onChange: () => { },
          placeholder: "upload Certificate",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a valid document",
        },
      ],
    ],
  },
  {
    section: "Upload Documents",
    stepperHeader: "Documents",
    fields: [
      [
        {
          title: "GST_certificate",
          label: "GST certificate",
          onChange: () => { },
          placeholder: "upload GST Certificate",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a valid document",
        },
      ],
      [
        {
          title: "PAN_CARD",
          label: "Pan Card",
          onChange: () => { },
          placeholder: "upload Pan Card",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a valid document",
        },
      ],
    ],
  },
  {
    section: "Upload bank Details",
    stepperHeader: "Bank Details",
    fields: [
      [
        {
          title: "Name_Of_Bank",
          label: "Name Of Bank",
          placeholder: "State Bank Of India",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "please enter a valid bank name",
        },
      ],
      [
        {
          title: "Account_Number",
          label: "Account Number",
          placeholder: "12341234123",
          intitalValue: "",
          type: "number",
          required: true,
          validation: (string) => {
            if (string?.length === 0) return true;
            var res = string?.match(/^[0-9]{9,18}$/);
            return res !== null && string !== undefined;
          },
          onChange: () => { },
          errorELe: "please enter a valid  account number of 8 to 18 digits",
        },
      ],
      [
        {
          title: "IFSC_Code",
          label: "IFSC Code",
          placeholder: "SBIN0005943",
          intitalValue: "",
          type: "text",
          required: true,
          validation: async (ifsc_Code) => {
            // let checkIFSC = await axios.get(
            //   "https://api.attestr.com/api/1.0/public/finanx/ifsc",
            //   {
            //     ifsc: ifsc_Code,
            //   },
            //   {
            //     headers: {
            //       "Content-Type": "application/json",
            //       Authorization: `Bearer ${process.env.REACT_APP_IFSC_TOKEN}`,
            //     },
            //   }
            // );
            let regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
            if (ifsc_Code == null) {
              return false;
            }
            if (regex.test(ifsc_Code) == true) {
              return true;
            } else {
              return false;
            }
          },
          onChange: () => { },
          errorELe: "please enter a valid IFSC Code of 11 digit",
        },
      ],
    ],
  },
];

export const agentFacilitator = [
  {
    section: "Tell us about yourself",
    stepperHeader: "Basics",
    fields: [
      [
        {
          title: "Name_Of_Agency",
          label: "Name Of Agency",
          placeholder: "Health Today",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "please enter a valid non empty name ",
        },
        {
          title: "Representative",
          label: "Representative",
          placeholder: "Abhay Terra",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "please enter a valid non empty name ",
        },
      ],
      [
        {
          title: "Contact_Number",
          label: "Contact Number",
          placeholder: "Enter Contact Number",
          intitalValue: { dialCode: "", phone: "" },
          type: "tele",
          required: true,
          validation: phoneValidation,
          onChange: () => { },
          errorELe:
            "please enter a 10 digit mobile number without country code",
        },
      ],
      [
        {
          title: "Website",
          label: "Website(Optional)",
          placeholder: "www.healthtoday@gmail.com",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (string) => {
            if (string?.length === 0) return true;
            var res = string?.match(
              /^((https?):\/\/)?([w|W]{3}\.)+[a-zA-Z0-9\-\.]{3,}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/
            );
            return res !== null && string !== undefined;
          },
          onChange: () => { },
          errorELe:
            "please enter a valid website like www.abc.com or leave it empty",
        },
      ],
      [
        {
          title: "Address",
          label: "Address",
          placeholder: "#71,Whitefield,Oak field Extension,Bangalore-43 ",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "please enter a valid non empty address",
        },
      ],
    ],
  },
  {
    section: "Upload License",
    stepperHeader: "License",
    fields: [
      [
        {
          title: "Company_Registration_Number",
          label: "Company Registration Number",
          placeholder: "**** **** **** ****",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "please enter a document",
        },
      ],
      [
        {
          title: "Company_License",
          label: "Company License",
          onChange: () => { },
          placeholder: "",
          intitalValue: "",
          type: "file",
          required: false,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a document",
        },
      ],
    ],
  },
  {
    section: "Upload Documents",
    stepperHeader: "Documents",
    fields: [
      [
        {
          title: "Upload_GST_Certificate",
          label: "Upload GST Certificate",
          onChange: () => { },
          placeholder: "",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a document",
        },
      ],
      [
        {
          title: "Pan_Card",
          label: "Pan Card",
          onChange: () => { },
          placeholder: "",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a document",
        },
      ],
    ],
  },
  {
    section: "Upload bank Details",
    stepperHeader: "Bank Details",
    fields: [
      [
        {
          title: "Name_Of_Bank",
          label: "Name Of Bank",
          placeholder: "State Bank Of India",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "please enter a non empty name",
        },
      ],
      [
        {
          title: "Account_Number",
          label: "Account Number",
          placeholder: "12312312312",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (string) => {
            if (string?.length === 0) return true;
            var res = string?.match(/^[0-9]{9,18}$/);
            return res !== null && string !== undefined;
          },
          onChange: () => { },
          errorELe: "please enter a valid account number of 9 to 18 digits",
        },
      ],
      [
        {
          title: "IFSC_Code",
          label: "IFSC Code",
          placeholder: "SBIN0001234",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (ifsc_Code) => {
            let regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
            if (ifsc_Code == null) {
              return false;
            }
            if (regex.test(ifsc_Code) == true) {
              return true;
            } else {
              return false;
            }
          },
          onChange: () => { },
          errorELe: "please enter a valid IFSC code of 11 digits",
        },
      ],
    ],
  },
];

// hospital onbaord by agent
export const hospitalOnbaordByAgent = [
  {
    section: "Facility Overview",
    stepperHeader: "Facility Overview",
    fields: [
      [
        {
          type: "radio",
          title: "specialityTypes",
          // label: "Speciality Types",
          intitalValue: "Single",
          radioFields: [
            {
              value: "single",
              title: "Single",
            },
            {
              value: "multi-speciality",
              title: "Multi-Speciality",
            },
          ],
          required: true,
          onChange: () => { },
          validation: () => {
            return true;
          },
        },
      ],
      [
        {
          title: "treatmentOffers",
          label: "Treatment Offer",
          placeholder: "Select Treatment Offer",
          intitalValue: [],
          type: "multiple-select",
          options: [
            { value: "Surgery", label: "Surgery" },
            { value: "Operation", label: "Operation" },
            // Add more treatment options as needed
          ],
          required: true,
          validation: (input) => {
            return true;
          },
          onChange: () => { },
          errorELe: "Please select at least one treatment offer",
        },
      ],
      [
        {
          title: "numPatientRooms",
          label: "Number of Patient Rooms",
          placeholder: "Enter Number of Patient Rooms",
          intitalValue: "",
          type: "number",
          required: true,
          validation: (input) => {
            return true;
          },
          onChange: () => { },
          errorELe: "Please enter a valid number of patient rooms",
        },
        {
          title: "numOperationTheater",
          label: "Number of Operation Theaters",
          placeholder: "Enter Number of Operation Theaters",
          intitalValue: "",
          type: "number",
          required: true,
          validation: (input) => {
            return true;
          },
          onChange: () => { },
          errorELe: "Please enter a valid number of operation theaters",
        },
      ],
    ],
  },

  {
    section: "Doctors Information",
    stepperHeader: "Doctors Information",
    buttonName: "Add Doctor",
    fields: [
      [
        {
          title: "add-doctors",
          label: "Doctors",
          type: "table",
          initialValue: [], // Array to store doctors' information
          columns: [
            { title: "Name", field: "name" },
            { title: "Qualification", field: "Qualification" },
            { title: "Experience", field: "Experience" },
            // Add more columns as needed
          ],
          validation: () => true,
          onChange: () => { },
          errorELe: "Please add at least one doctor",
        },
      ],
    ],
  },

  {
    section: "Treatment",
    stepperHeader: "Treatment",
    fields: [
      [
        {
          title: "Specialization_Doctor",
          label: "Specialization Doctor",
          placeholder: "Select Specialization Doctor",
          intitalValue: [],
          type: "multiple-select",
          options: [
            { value: "cardiologist", label: "Cardiologist" },
            { value: "orthopedic", label: "Orthopedic" },
            // Add more specialization options as needed
          ],
          required: true,
          validation: () => {
            return true;
          },
          onChange: () => { },
          errorELe: "Please select at least one specialization doctor",
        },
      ],
    ],
  },

  {
    section: "Uploads",
    stepperHeader: "Uploads",
    fields: [
      [
        {
          title: "Uploads",
          label: "Uploads",
          placeholder: "Uploads",
          intitalValue: [],
          type: "Uploads",
          validation: () => {
            return true;
          },
          onChange: () => { },
          errorELe: "Please select at least one specialization doctor",
        },
      ],
    ],
  },

  {
    section: "Summary",
    stepperHeader: "Summary",
    fields: [
      [
        {
          title: "Summary",
          label: "Summary",
          placeholder: "Summary",
          intitalValue: [],
          type: "Summary",
          validation: () => {
            return true;
          },
          onChange: () => { },
          errorELe: "Please select at least one specialization doctor",
        },
      ],
    ],
  },
];

export const overviewOnbaordByAgent = [
  {
    section: "Facility Overview",
    stepperHeader: "Facility Overview",
    fields: [
      [
        {
          title: "specialityTypes",
          label: "Speciality Types",
          intitalValue: "",
          type: "text",
          required: true,
          onChange: () => { },
          validation: () => {
            return true;
          },
          errorELe: "Please select at least one speciality",
        },
      ],

      [
        {
          title: "numPatientRooms",
          label: "Number of Patient Rooms",
          placeholder: "Enter Number of Patient Rooms",

          type: "number",
          required: true,
          validation: () => {
            return true;
          },
          onChange: () => { },
          errorELe: "Please enter a valid number of patient rooms",
        },
      ],

      [
        {
          title: "numOperationTheaters",
          label: "Number of Operation Theaters",
          placeholder: "Enter Number of Operation Theaters",
          type: "number",

          required: true,
          validation: () => {
            return true;
          },
          onChange: () => { },
          errorELe: "Please enter a valid number of operation theaters",
        },
      ],
    ],
  },
];

//adding doctors
export const addTreatmentSection = [
  {
    section: "Add Treatment",
    stepperHeader: "Add Treatment",
    fields: [
      [
        {
          title: "name",
          label: "Name",
          placeholder: "Enter Your Name",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "Please enter a valid treatment name",
        },
      ],

      [
        {
          title: "email",
          label: "Email",
          placeholder: "Enter Email",
          initialValue: "",
          type: "email", // Use type "email" for email input
          required: true,
          validation: (input) => {
            // Validate email using a regular expression
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(input);
          },
          onChange: () => { },
          errorELe: "Please enter a valid email address",
        },
      ],
      [
        {
          title: "experience",
          label: "Experience",
          placeholder: "Enter Experience",
          intitalValue: "",
          type: "number", // Updated to accept numbers
          required: true,
          validation: (input) => {
            // Validate if the input is a number
            return !isNaN(input) && input > 0;
          },
          onChange: () => { },
          errorELe: "Please enter a valid experience as a number",
        },
      ],
      [
        {
          title: "qualification",
          label: "Qualification",
          placeholder: "Enter Qualification",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "Please enter a valid qualification",
        },
      ],
      [
        {
          title: "image",
          label: "image",
          onChange: () => { },
          placeholder: "",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a document",
        },
      ],
    ],
  },
];

export const addSpecialityPopup = [
  {
    section: "Add Speciality",
    stepperHeader: "Add Speciality",
    fields: [
      [
        {
          title: "Speciality Name",
          label: "Speciality Name",
          placeholder: "Enter Specility",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "Please enter a valid speciality name",
        },
      ],
      [
        {
          title: "Description",
          label: "Description",
          placeholder: "Enter Description",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "Please enter a valid speciality name",
        },
      ],

      [
        {
          title: "doctorList",
          label: "doctorList",
          onChange: () => { },
          placeholder: "",
          intitalValue: "",
          type: "searchable-select",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a name",
        },
      ],
    ],
  },
];

export const addPatientDetails = [
  {
    section: "",
    stepperHeader: "Patient Details",
    fields: [
      [
        {
          title: "Patient_Name",
          label: "Patient Name",
          placeholder: "Enter your name",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "Please enter a valid name",
        },
      ],
      [
        {
          title: "Email",
          label: "Email",
          placeholder: "Enter your email",
          intitalValue: "",
          type: "email",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "Please enter a valid email",
        },
      ],
      [
        {
          type: "radio",
          title: "Gender",
          // label: "Speciality Types",
          intitalValue: "",
          radioFields: [
            {
              value: "Male",
              title: "Male",
            },
            {
              value: "Female",
              title: "Female",
            },
          ],
          required: true,
          onChange: () => { },
          validation: () => {
            return true;
          },
        },
      ],
      [
        {
          title: "Contact_Number",
          label: "Contact Number",
          placeholder: "Enter contact number",
          intitalValue: { dialCode: "", phone: "" },
          type: "tele",
          required: true,
          validation: phoneValidation,
          onChange: () => { },
        },
      ],
      [
        {
          title: "Country",
          label: "Country",
          placeholder: "Enter your country",
          type: "searchable-select",
          multiple: false,
          options: worldCountries,
          initialValue: {
            label: "India",
            id: 124,
          },
          errorELe: "please select at least one country",
          validation: (value) => {
            return value !== undefined && value !== "";
          },
        },
      ],
      [
        {
          title: "Medical_Issue",
          label: "Medical Issue",
          placeholder: "Enter your medical issue",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "Please enter a valid name",
        },
      ],
      [
        {
          title: "Date_Of_Birth",
          label: "Date of birth",
          placeholder: "Enter your date of birth",
          intitalValue: "",
          type: "date",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "Please enter a valid date of birth",
        },
      ],
    ],
  },
  {
    section: "",
    stepperHeader: "Documents",
    fields: [
      [
        {
          title: "Photo",
          label: "Photo",
          onChange: () => { },
          placeholder: "",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "Please enter a document",
        },
      ],

      [
        {
          title: "Passport",
          label: "Passport",
          onChange: () => { },
          placeholder: "",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "Please enter a document",
        },
      ],
      [
        {
          title: "Visa",
          label: "Visa",
          onChange: () => { },
          placeholder: "",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "Please enter a document",
        },
      ],
      [
        {
          title: "Medical_History",
          label: "Medical History",
          onChange: () => { },
          placeholder: "",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "Please enter a document",
        },
      ],
    ],
  },
  {
    section: "",
    stepperHeader: "Overview",
    fields: [
      [
        {
          title: "Patient_Name",
          label: "Patient Name",
          placeholder: "Enter your name",
          intitalValue: "",
          type: "text",
          required: true,
          disabled: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "Please enter a valid name",
        },
      ],
      [
        {
          title: "Email",
          label: "Email",
          placeholder: "Enter your email",
          intitalValue: "",
          type: "email",
          required: true,
          disabled: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "Please enter a valid email",
        },
      ],
      [
        {
          type: "radio",
          title: "Gender",
          // label: "Speciality Types",
          intitalValue: "",
          disabled: true,
          radioFields: [
            {
              value: "Male",
              title: "Male",
            },
            {
              value: "Female",
              title: "Female",
            },
          ],
          required: true,
          onChange: () => { },
          validation: () => {
            return true;
          },
        },
      ],
      [
        {
          title: "Contact_Number",
          label: "Contact Number",
          placeholder: "Enter contact number",
          intitalValue: { dialCode: "", phone: "" },
          type: "tele",
          required: true,
          disabled: true,
          validation: phoneValidation,
          onChange: () => { },
        },
      ],
      [
        {
          title: "Country",
          label: "Country",
          placeholder: "Enter your country",
          type: "searchable-select",
          multiple: false,
          disabled: true,
          options: worldCountries,
          initialValue: {
            label: "India",
            id: 124,
          },
          errorELe: "please select at least one country",
          validation: (value) => {
            return value !== undefined && value !== "";
          },
        },
      ],
      [
        {
          title: "Medical_Issue",
          label: "Medical Issue",
          placeholder: "Enter your medical issue",
          intitalValue: "",
          type: "text",
          required: true,
          disabled: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "Please enter a valid name",
        },
      ],
      [
        {
          title: "Date_Of_Birth",
          label: "Date of birth",
          placeholder: "Enter your date of birth",
          intitalValue: "",
          type: "date",
          required: true,
          disabled: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "Please enter a valid date of birth",
        },
      ],
      [
        {
          title: "",
          // label: "Test",
          initialValue: "",
          render: function (formData, handleChange, handleTouch) {
            return <PatientOverview formData={formData} />; /// UseEffect dependency consists values of table in state
          },
          validation: (input) => {
            return true;
          },
        },
      ],
    ],
  },
];

export const editPatientDetails = [
  {
    section: "",
    stepperHeader: "Patient Details",
    fields: [
      [
        {
          title: "Patient_Name",
          label: "Patient Name",
          placeholder: "Enter your name",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "Please enter a valid name",
        },
      ],
      [
        {
          title: "Email",
          label: "Email",
          placeholder: "Enter your email",
          intitalValue: "",
          type: "email",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "Please enter a valid email",
        },
      ],
      [
        {
          type: "radio",
          title: "Gender",
          // label: "Speciality Types",
          intitalValue: "",
          radioFields: [
            {
              value: "Male",
              title: "Male",
            },
            {
              value: "Female",
              title: "Female",
            },
          ],
          required: true,
          onChange: () => { },
          validation: () => {
            return true;
          },
        },
      ],
      [
        {
          title: "Contact_Number",
          label: "Contact Number",
          placeholder: "Enter contact number",
          // intitalValue: { dialCode: "", phone: "" },
          type: "tele",
          required: true,
          validation: phoneValidation,
          onChange: () => { },
        },
      ],
      [
        {
          title: "Country",
          label: "Country",
          placeholder: "Enter your country",
          type: "searchable-select",
          multiple: false,
          options: worldCountries,
          initialValue: {
            label: "India",
            id: 124,
          },
          errorELe: "please select at least one country",
          validation: (value) => {
            return value !== undefined && value !== "";
          },
        },
      ],
      [
        {
          title: "Medical_Issue",
          label: "Medical Issue",
          placeholder: "Enter your medical issue",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "Please enter a valid name",
        },
      ],
      [
        {
          title: "Date_Of_Birth",
          label: "Date of birth",
          placeholder: "Enter your date of birth",
          intitalValue: "",
          type: "date",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "Please enter a valid date of birth",
        },
      ],
    ],
  },
  {
    section: "",
    stepperHeader: "Documents",
    fields: [
      [
        {
          title: "Photo",
          label: "Photo",
          onChange: () => { },
          placeholder: "",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "Please enter a document",
        },
      ],

      [
        {
          title: "Passport",
          label: "Passport",
          onChange: () => { },
          placeholder: "",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "Please enter a document",
        },
      ],
      [
        {
          title: "Visa",
          label: "Visa",
          onChange: () => { },
          placeholder: "",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "Please enter a document",
        },
      ],
      [
        {
          title: "Medical_History",
          label: "Medical History",
          onChange: () => { },
          placeholder: "",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "Please enter a document",
        },
      ],
    ],
  },
  {
    section: "",
    stepperHeader: "Overview",
    fields: [
      [
        {
          title: "Patient_Name",
          label: "Patient Name",
          placeholder: "Enter your name",
          intitalValue: "",
          type: "text",
          required: true,
          disabled: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "Please enter a valid name",
        },
      ],
      [
        {
          title: "Email",
          label: "Email",
          placeholder: "Enter your email",
          intitalValue: "",
          type: "email",
          required: true,
          disabled: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "Please enter a valid email",
        },
      ],
      [
        {
          type: "radio",
          title: "Gender",
          // label: "Speciality Types",
          intitalValue: "",
          disabled: true,
          radioFields: [
            {
              value: "Male",
              title: "Male",
            },
            {
              value: "Female",
              title: "Female",
            },
          ],
          required: true,
          onChange: () => { },
          validation: () => {
            return true;
          },
        },
      ],
      [
        {
          title: "Contact_Number",
          label: "Contact Number",
          placeholder: "Enter contact number",
          // intitalValue: { dialCode: "", phone: "" },
          type: "tele",
          required: true,
          validation: phoneValidation,
          onChange: () => { },
        },
      ],
      [
        {
          title: "Country",
          label: "Country",
          placeholder: "Enter your country",
          type: "searchable-select",
          multiple: false,
          disabled: true,
          options: worldCountries,
          initialValue: {
            label: "India",
            id: 124,
          },
          errorELe: "please select at least one country",
          validation: (value) => {
            return value !== undefined && value !== "";
          },
        },
      ],
      [
        {
          title: "Medical_Issue",
          label: "Medical Issue",
          placeholder: "Enter your medical issue",
          intitalValue: "",
          type: "text",
          required: true,
          disabled: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "Please enter a valid name",
        },
      ],
      [
        {
          title: "Date_Of_Birth",
          label: "Date of birth",
          placeholder: "Enter your date of birth",
          intitalValue: "",
          type: "date",
          required: true,
          disabled: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "Please enter a valid date of birth",
        },
      ],
      [
        {
          title: "",
          // label: "Test",
          initialValue: "",
          render: function (formData, handleChange, handleTouch) {
            return <PatientOverview formData={formData} />; /// UseEffect dependency consists values of table in state
          },
          validation: (input) => {
            return true;
          },
        },
      ],
    ],
  },
];

export const addAccounts = [
  {
    section: "Add Accounts",
    stepperHeader: "Add Accounts",
    fields: [
      [
        {
          title: "name",
          label: "Name",
          placeholder: "Enter Name",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "Please enter a valid name",
        },
      ],
      [
        {
          title: "email",
          label: "Email",
          placeholder: "Enter Email",
          initialValue: "",
          type: "email",
          required: true,
          validation: (input) => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(input);
          },
          onChange: () => { },
          errorELe: "Please enter a valid email address",
        },
      ],
    ],
  },
];

export const LandingPageContactUs = [
  {
    section: "Need assistance? It’s FREE",
    stepperHeader: "Contact Us",
    fields: [
      [
        {
          title: "name",
          label: "Name",
          placeholder: "Enter Name",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "Please enter a valid name",
        },
      ],
      [
        {
          title: "email",
          label: "Email",
          placeholder: "Enter Email",
          initialValue: "",
          type: "email",
          required: true,
          validation: (input) => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(input);
          },
          onChange: () => { },
          errorELe: "Please enter a valid email address",
        },
      ],
      [
        {
          title: "contactNumber",
          label: "Contact Number",
          placeholder: "Enter Contact Number",
          initialValue: "",
          type: "number",
          required: true,
          validation: (input) => {
            return input?.length !== 0 && input?.length === 10;
          },
        },
      ],
      [
        {
          title: "report",
          label: "Attach Report (Optional)",
          onChange: () => { },
          placeholder: "",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a valid document",
        },
      ],
      [
        {
          title: "query",
          label: "Tell us what you need",
          placeholder: "Enter your query",
          intitalValue: "",
          // type textarea
          type: "para",
          required: false,
          validation: (input) => {
            return input?.length !== 0;
          },
        },
      ],
    ],
  },
];

export const ContactSideBar = [
  {
    section: "",
    stepperHeader: "Contact Us",
    fields: [
      [
        {
          title: "name",
          label: "Name",
          placeholder: "Enter Name",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "Please enter a valid name",
        },
        {
          title: "email",
          label: "Email",
          placeholder: "Enter Email",
          initialValue: "",
          type: "email",
          required: true,
          validation: (input) => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(input);
          },
          onChange: () => { },
          errorELe: "Please enter a valid email address",
        },
      ],
      [
        {
          title: "contactNumber",
          label: "Contact Number",
          placeholder: "Enter Contact Number",
          initialValue: "",
          type: "number",
          required: true,
          validation: (input) => {
            return input?.length !== 0 && input?.length === 10;
          },
        },
      ],
      [
        {
          title: "report",
          label: "Attach Report (Optional)",
          onChange: () => { },
          placeholder: "",
          intitalValue: "",
          type: "file",
          required: false,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a valid document",
        },
      ],
      [
        {
          title: "query",
          label: "Tell us what you need",
          placeholder: "Enter your query",
          intitalValue: "",
          // type textarea
          type: "para",
          required: false,
          validation: (input) => {
            return input?.length !== 0;
          },
        },
      ],
    ],
  },
];

export const diagnosticCenter = [
  {
    section: "Tell us About Yourself",
    stepperHeader: "Basics",
    fields: [
      [
        {
          title: "nameOfDiagnostics",
          label: "Name Of Diagnostic Center",
          placeholder: "Mark Diagnostic Center",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => { },
          errorELe: "please enter a non empty name",
        },
      ],
      [
        {
          title: "contactNumber",
          label: "Contact Number",
          placeholder: "Enter Contact Number",
          intitalValue: { dialCode: "", phone: "" },
          type: "tele",
          required: true,
          validation: phoneValidation,
          onChange: () => { },
          errorELe:
            "please enter a 10 digit mobile number without country code",
        },
        {
          title: "Location",
          label: "Location",
          placeholder: "Location",
          type: "searchable-select",
          multiple: false,
          options: indianCitiesOptions,
          initialValue: {
            label: "Mumbai",
            id: 124,
          },
          errorELe: "please select at least one city",
          validation: (value) => {
            return value !== undefined && value !== "";
          },
        },
      ],
      [
        {
          type: "radio",
          title: "Nationality",
          intitalValue: "indian",
          radioFields: [
            {
              value: "indian",
              title: "Indian",
            },
            {
              value: "notIndian",
              title: "Non-Indian",
            },
          ],
          required: true,
          onChange: () => { },
          validation: (input) => {
            return true;
          },
        },
      ],
      [
        {
          type: "checkbox",
          title: "Accreditation",
          intitalValue: [],
          checkboxFields: [
            {
              value: "NABH",
              title: "NABH",
            },
            {
              value: "JCI",
              title: "JCI",
            },
          ],
          required: true,
          onChange: () => { },
          validation: (value) => {
            return value && value.length !== 0;
          },
        },
      ],
    ],
  },
  {
    section: "Upload License",
    stepperHeader: "License",
    fields: [
      [
        {
          title: "License",
          label: "License",
          onChange: () => { },
          placeholder: "View License",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a valid document",
        },
      ],
      [
        {
          title: "NABH",
          label: "Accreditation",
          onChange: () => { },
          placeholder: "upload Certificate",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a valid document",
        },
        {
          title: "JCI",
          label: "Accreditation Certificate (JCI)",
          tag: "JCI",
          onChange: () => { },
          placeholder: "upload Certificate",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a valid document",
        },
        {
          title: "NABL",
          label: "Accreditation Certificate (NABL)",
          tag: "NABL",
          onChange: () => { },
          placeholder: "upload Certificate",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a valid document",
        },
      ],
    ],
  },
  {
    section: "Upload Documents",
    stepperHeader: "Documents",
    fields: [
      [
        {
          title: "GST_certificate",
          label: "GST certificate",
          onChange: () => { },
          placeholder: "upload GST Certificate",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a valid document",
        },
      ],
      [
        {
          title: "PAN_CARD",
          label: "Pan Card",
          onChange: () => { },
          placeholder: "upload Pan Card",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a valid document",
        },
      ],
    ],
  },
];
