import React from 'react'
import HospitalsSlider from '../HospitalsSlider/HospitalsSlider';
import './PortfolioHospitals.css';
import { motion } from "framer-motion";
const PortfolioHospitals = ({dynamicHospitals}) => {
  return (
    <div className="portfolio-hospitals" id="about">
      <motion.h4
        className="portfolio-hospitals-heading"
        initial={{ y: "100%", opacity: 0 }}
        whileInView={{ y: "0%", opacity: 1 }}
      >
        Trusted by leading Hospitals Nationwide
      </motion.h4>
      <HospitalsSlider
        dynamicList={dynamicHospitals}
      />
    </div>
  );
}

export default PortfolioHospitals
