import React from "react";
import "./DashboardTableRowChip.css";

const disaplyTextWidth = (text) => {
  let canvas =
    disaplyTextWidth.canvas ||
    (disaplyTextWidth.canvas = document.createElement("canvas"));
  let context = canvas.getContext("2d");
  context.font = "15px Proxima Nova";
  const metrics = context.measureText(text);
  return metrics.width;
};

const DashboardTableRowChip = ({ chipsArray }) => {
  chipsArray?.sort((a, b) => {
    if (a?.value?.length < b?.value?.length) {
      return -1;
    } else {
      return 1;
    }
  });
  // console.log("+100 length is", disaplyTextWidth("+100"));
  let CurrentWidth = 250;
  let first = true;
  return (
    <div className="dashboard-table-row-chips">
      {chipsArray?.map((item, ind) => {
        const lengthOfText = Math.ceil(disaplyTextWidth(item.value)) + 30;
        // console.log(lengthOfText);
        if (lengthOfText >= CurrentWidth || CurrentWidth - lengthOfText < 61) {
          if (!first) {
            return null;
          }
          first = false;
          return (
            <p key={ind} className="dashboard-table-row-chip-component">
              +{chipsArray.length - ind}
            </p>
          );
        } else {
          CurrentWidth = CurrentWidth - lengthOfText;
          return (
            <p
              key={ind}
              className="dashboard-table-row-chip-component"
              id={`${ind}`}
            >
              {item.value}
            </p>
          );
        }
      })}
    </div>
  );
};

export default DashboardTableRowChip;
