import React, { useState, useEffect, useRef } from "react";
import SimpleInput from "../InputTypes/SimpleInput/SimpleInput";
import FileInput from "../InputTypes/FileInput/FileInput";
import { addTreatmentSection } from "../../constants/formconfig";
import "./EditModal.css";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { API } from "../../constants/api";
// import { editDoctorSection } from '../../constants/formconfig'; // Assuming you have the configuration for editDoctorSection fields

const EditModal = ({
  selectedDoctor,
  onUpdate,
  onClose,
  docid,
  onDoctorUpdated,
  open,
  handleOpen,
  handleClose,
  setLoader,
}) => {
  const [formData, setFormData] = useState({});
  const closeButtonRef = useRef(null);
  const searchParams = new URLSearchParams(window.location.search);
  const hospitalId = searchParams.get('q');

  useEffect(() => {
    // Update form data when selectedDoctor changes
    if (selectedDoctor) {
      const updatedFormData = {};
      addTreatmentSection[0].fields.forEach((field) => {
        updatedFormData[field[0].title] = {
          value: selectedDoctor[field[0].title] || "", // Use selectedDoctor data or an empty string if not available
          isTouched: false,
        };
      });
      setFormData(updatedFormData);
    }
  }, [selectedDoctor]); // Run this effect whenever selectedDoctor changes

  const handleChange = (field, newValue) => {
    setFormData((obj) => {
      let tempObj = { ...obj };
      const tempField = { ...tempObj[field], value: newValue };
      tempObj[field] = tempField;
      return tempObj;
    });
  };

  const handleTouch = (field) => {
    setFormData((obj) => {
      const tempObj = { ...obj };
      tempObj[field] = { ...tempObj[field], isTouched: true };
      return tempObj;
    });
  };

  const fieldsToValidate = Object.keys(formData).filter(key => key !== 'image');

  const isFormValid = fieldsToValidate.every(key => {
    const field = formData[key];
    return field.value !== '' || field.isTouched;
  });
  console.log(isFormValid);
  console.log('edit formData', formData);

  const handleSubmit = async (event) => {
    // event.preventDefault();

    // // Check if any required field is empty
    // const requiredFields = addTreatmentSection[0].fields.filter(
    //   (field) => field[0].required
    // );

    // const emptyFields = requiredFields.filter(
    //   (field) => !formData[field[0].title].value
    // );

    // if (emptyFields.length > 0) {
    //   // If any required field is empty, show an alert
    //   alert("Please fill out all required fields before submitting.");
    //   return;
    // }

    // // Handle form submission using formData
    // console.log("Form wala data :", formData);

    // const keys = Object.keys(formData);
    // const data = new FormData();
    // keys.forEach((key) => {
    //   if (key === "Contact_Number") {
    //     const number = formData[key].value.phone.slice(
    //       formData[key].value.dialCode.length
    //     );
    //     data.append(key, formData[key].value.dialCode + "_" + number);
    //   } else {
    //     data.append(key, formData[key].value);
    //   }
    // });
    // console.log("Data is : ", data);
    // const url = `${API}/agenthospital/updatesingledoctor/-Ngy86nBlGlvPH5eODNh/${selectedDoctor.id}`;

    // try {
    //   const result = await axios.post(url, formData);
    //   alert("Doctor Updated");
    //   // console.log("setDoc", result.data.doctor);
    //   closeButtonRef.current.click();
    // } catch (err) {
    //   console.log(err);
    // }

    event.preventDefault();

    // Check if any required field is empty
    // const requiredFields = addTreatmentSection[0].fields.filter(
    //   (field) => field[0].required
    // );

    // const emptyFields = requiredFields.filter(
    //   (field) => !formData[field[0].title].value
    // );

    // if (emptyFields.length > 0) {
    //   // If any required field is empty, show an alert

    //   alert("Please fill out all required fields before submitting.");
    //   return;
    // }

    // Extract specific fields from formData
    const { name, email, experience, qualification } = formData;

    // Prepare the data for the backend API request
    const requestData = {
      name: name.value,
      email: email.value,
      experience: experience.value,
      qualification: qualification.value,
    };
    setLoader(true)

    // Construct the API endpoint URL
    const url = `${API}/agenthospital/updatesingledoctor/${hospitalId}/${selectedDoctor.id}`;
    console.log(requestData);

    try {
      // Send a POST request with requestData to the backend
      const result = await axios.post(url, requestData);

      // Handle the API response here
      if (result.status === 200) {
        console.log("Doctor Updated successfully:", result.data.doctor);
        // alert("Doctor Updated successfully!");
        onDoctorUpdated(requestData);
      } else {
        console.error("Error updating doctor:", result.data.error);
        alert("Error updating doctor. Please try again later.");
      }

      // Close the modal
      // closeButtonRef.current.click();
      handleClose();
      setLoader(false)
    } catch (error) {
      // Handle network errors or any other errors that occurred during the request
      console.error("Request error:", error);
      alert("Failed to update doctor. Please check your network connection.");
    }
  };



  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    boxShadow: 24,
    p: 0,
  };

  return (
    <div className='edit-doctor-modal'>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <div className='modal-content'>
            {/* Modal Header */}
            <div className='modal-header'>
              <h5 className='modal-title'>Edit Doctor</h5>
              <button
                type='button'
                className='btn-close cross'
                onClick={handleClose}
              />
            </div>
            {/* Modal Body */}
            <div className='modal-body'>
              {/* Form Fields */}
              <form>
                {addTreatmentSection[0].fields.map((field, index) => {
                  // console.log("fetch", formData);
                  if (
                    field[0].type === "text" ||
                    field[0].type === "number" ||
                    field[0].type === "email"
                  ) {
                    return (
                      <SimpleInput
                        key={index}
                        input={field[0]}
                        state={formData[field[0].title]}
                        handleChange={handleChange}
                        handleTouch={handleTouch}
                      />
                    );
                  }
                  return null;
                })}
              </form>
            </div>
            {/* Modal Footer */}
            <div className='modal-footer border border-0 d-flex justify-content-center align-items-center'>
              {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" ref={closeButtonRef}>Close</button> */}
              <button
                type='button'
                className='btn my-btn'
                onClick={handleSubmit}
                disabled={!isFormValid}
              >
                Save changes
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default EditModal;

// <div className="modal fade" id="exampleModal2" tabIndex={-1} aria-labelledby="exampleModalLabel2" aria-hidden="true">
//   <div className="modal-dialog modal-dialog-centered">
//     <div className="modal-content">
//       {/* Modal Header */}
//       <div className="modal-header">
//         <h5 className="modal-title">Edit Doctor</h5>
//         <button type="button" className="btn-close cross" data-bs-dismiss="modal" />
//       </div>
//       {/* Modal Body */}
//       <div className="modal-body">
//         {/* Form Fields */}
//         <form>
//           {addTreatmentSection[0].fields.map((field, index) => {
//             // console.log("fetch", formData);
//             if (field[0].type === 'text' || field[0].type === 'number' || field[0].type === 'email') {
//               return (
//                 <SimpleInput
//                   key={index}
//                   input={field[0]}
//                   state={formData[field[0].title]}
//                   handleChange={handleChange}
//                   handleTouch={handleTouch}
//                 />
//               );

//             }
//             return null;
//           })}
//         </form>
//       </div>
//       {/* Modal Footer */}
//       <div className="modal-footer">
//         <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" ref={closeButtonRef} >
//           Close
//         </button>
//         <button type="button" className="btn btn-primary" onClick={handleSubmit}>
//           Save Changes
//         </button>
//       </div>
//     </div>
//   </div>
// </div >
