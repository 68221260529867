export const LandingPageFaqs = [
    {
        question: "How does BharatOne Care enhance medical tourism?",
        answer: "BharatOne Care is revolutionizing medical tourism by offering a comprehensive, user-friendly platform that serves as a one-stop solution for the entire medical tourism ecosystem. It simplifies the process from choosing healthcare providers to managing post-treatment care, ensuring affordability and high-quality healthcare. As a trusted companion, BharatOne Care provides continuous tracking and support throughout the medical journey, focusing on creating a streamlined experience and ensuring patients have a reliable, singular point of reference for all their medical tourism needs. Additionally, BharatOne Care employs robust data security measures, ensuring the confidentiality and protection of personal and medical information, and adheres to strict privacy laws.",
    },
    {
        question: "Does BharatOne Care offer assistance with travel, accommodation, language, visa, and dietary needs?",
        answer: "Absolutely. BharatOne Care offers a comprehensive service that includes booking travel and accommodations, multi-lingual support, visa application assistance, and managing travel documentation. We also cater to dietary preferences and handle all logistical aspects of your journey, ensuring a smooth and personalized medical tourism experience.",
    },
    {
        question: "Are medical costs transparent and are there options for financial assistance or payment plans available for treatments through BharatOne Care?",
        answer: "BharatOne Care ensures transparency in medical costs by providing a clear, upfront pricing analysis from multiple partner hospitals, allowing you to compare and make informed choices. The costs are clearly bifurcated, detailing all the services you opt for. Additionally, the platform offers financial assistance and flexible payment plans for treatments, available to patients who meet certain guidelines, thus making healthcare more accessible and easing financial burdens.",
    },
    {
        question: "Does BharatOne Care offer post-treatment care, wellness, and rehabilitation services, including rejuvenation centre services?",
        answer: "BharatOne Care provides an extensive range of post-treatment care and follow-up services, including remote consultations to support patient recovery, even after returning home. In addition to these services, the platform offers comprehensive wellness and rehabilitation programs, fully integrated into medical packages. These services focus on both physical recovery and overall well-being, and include access to rejuvenation center services, ensuring a holistic approach to health and recovery for every patient.",
    },
    {
        question: "What makes BharatOne Care different from other medical tourism platforms?",
        answer: "BharatOne Care's unique ecosystem, which integrates healthcare, travel, and wellness services, offers a comprehensive medical tourism experience. Key features include personalized online doctor consultations, transparent standardized pricing, and an efficient patient tracking system. It introduces industry-first health checkups for tourists upon arrival and provides wellness, rehabilitation, and rejuvenation services. The platform has an effective query resolution and post-treatment follow-up system, integrates with AYUSH, and offers technology solutions to hospitals and facilitators. Additionally, it provides financial assistance for international patients, enhancing the overall healthcare journey.",
    },
];

export const BusinessPageFaqs = [
    {
        question: "How does partnering with BharatOne Care benefit our hospital?",
        answer: "Partnering with BharatOne Care benefits your hospital in multiple ways. It enhances global visibility, attracting a diverse international patient base. The collaboration includes robust marketing support, showcasing your hospital's specialties and services through digital campaigns. BharatOne Care's extensive network boosts your hospital's exposure. Importantly, stringent data protection measures ensure patient privacy and data security, aligning with privacy laws and instilling trust in patients. This partnership can lead to increased patient inflow, boosting business growth.",
    },
    {
        question: "What kind of patient management tools does BharatOne Care offer?",
        answer: "The platform's sophisticated patient management tools encompass a real-time scheduling system, patient tracking, data analytics, dashboard, and query management. These integrated tools streamline patient handling, from appointment scheduling to treatment and follow-up. They not only enhance efficiency and the patient experience but also reduce response times for hospitals, making it easier to address patient queries promptly and effectively.",
    },
    {
        question: "What are the requirements for hospitals to join the BharatOne Care network?",
        answer: "Hospitals looking to join the BharatOne Care network must meet stringent quality criteria, demonstrate a commitment to international healthcare standards, and provide exceptional patient care. BharatOne Care evaluates potential partners based on these parameters to ensure the highest level of service. Additionally, the platform actively collaborates with hospitals to maintain and improve standards of care. It provides guidelines and support for international healthcare accreditation, patient safety protocols, and continuous quality improvement, ensuring that partner hospitals consistently meet and exceed global healthcare standards.",
    },
    {
        question: "How does BharatOne Care support hospitals in patient feedback management?",
        answer: "BharatOne Care not only supports hospitals in patient feedback management but also maintains an ongoing connection with hospitals for this purpose. The platform provides tools to gather and analyze patient feedback, enabling hospitals to understand patient needs better and continuously improve their services. This feedback loop is vital for enhancing patient satisfaction and care quality. BharatOne Care's commitment to maintaining a continuous connection with hospitals ensures that the feedback process remains dynamic and responsive to the evolving needs of both patients and healthcare providers.",
    },
    {
        question: "What kind of training and support does BharatOne Care provide to hospital staff?",
        answer: "BharatOne Care prioritizes comprehensive training for hospital staff, ensuring they can effectively utilize the platform's capabilities. This training covers patient management, data analytics, feedback handling, and administrative tasks. Beyond initial training, the platform provides continuous support and updates, allowing hospitals to stay current with the latest features and functionalities.. BharatOne Care stands as a long-term partner, dedicated to enhancing patient care and optimizing hospital operations for their international patients.",
    },
]