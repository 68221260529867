import React, { useState } from "react";
import "./FilterPopup.css";
import blackCross from "../../assets/images/black-cross.png";
import filterIcon from "../../assets/images/filter-icon.png";
import AppointmentFilterModel from "../AppointmentFilterModel/AppointmentFilterModel";
import FilterPopupAlertComponent from "./FilterPopupAlertComponent/FilterPopupAlertComponent";
const FilterPopup = (props) => {
  return (
    <div>
      <button
        className={props.buttonStyle}
        onClick={() => props.togglePopup("dontClearFilters")}
      >
        {props.name}
      </button>
      {props.isVisible && (
        <div>
          <div
            className="overlay"
            onClick={() => props.togglePopup("dontClearFilters")}
          ></div>
          <div
            className={`popup ${props.isClosing ? "closing" : ""}`}
            onAnimationEnd={() => {
              if (props.isClosing) {
                props.setIsVisible(false);
                props.setIsClosing(false);
              }
            }}
          >
            <div className="popup-content">
              <div className="popup-header">
                <h1 className="popup-title">{props.name}</h1>
                <img
                  className="popup-close-icon"
                  src={blackCross}
                  onClick={() => props.togglePopup("dontClearFilters")}
                  alt=""
                />
              </div>
              {props.children}
              <div className="popup-button-and-alert-container">
                {props.displayError && (
                  <FilterPopupAlertComponent errorText={props.errorText} />
                )}
                <div className="popup-button-container">
                  <button
                    className="no-bg-button"
                    onClick={props.handleFilterClear}
                  >
                    Clear All
                  </button>
                  <button
                    className="no-bg-button left-mg"
                    onClick={() => props.togglePopup("clearFilters")}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-button"
                    onClick={props.handleFilterSubmit}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterPopup;
