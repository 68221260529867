import React from "react";
import "./Stepper.css";

const Step = ({ label, isActive, onClick }) => {
  return (
    <div className="step me-3 me-sm-1" onClick={onClick}>
      <p
        className="label"
        style={{
          color: isActive ? "#2c416b" : "#c0c0c0",
        }}
      >
        {label}
      </p>
      <div
        className="indicator"
        style={{
          backgroundColor: isActive ? "#2c416b" : "#c0c0c0",
        }}
      ></div>
    </div>
  );
};

export default Step;
