import React, { useEffect, useState } from "react";
import LandingPageNavbar from "../../components/LandingPageNavbar/LandingPageNavbar";
import HospitalDetail from "../../components/HospitalDetail/HospitalDetail";
import SearchDoctors from "../../components/HospitalDetailPageComponents/SearchDoctors/SearchDoctors";
import HospitalDetailAbout from "./Sections/About/HospitalDetailAbout";
import HospitalDetailProcedures from "./Sections/Procedures/HospitalDetailProcedures";
import HospitalInfrastructure from "./Sections/Infrastructure/HospitalInfrastructure";
import Facilities from "./Sections/Facilities/Facilities";
import TopDoctors from "../PROCEDURE/Sections/TopDoctors/TopDoctors";
import VerifiedHotelsNearHospital from "./Sections/Hotels/VerifiedHotelsNearHospital";
import SimilarHospitals from "./Sections/SimilarHospitals/SimilarHospitals";
import { API } from "../../constants/api";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import StickyProgressBarSection from "../../components/StickyProgressBarSection/StickyProgressBarSection";
import { hospitalDetailsPageProgressBarOptions } from "../../constants";
import useContactUsSidebar from "../../Hooks/useContactUsSidebar";
import ContactUsSidebar from "../../components/ContactUsSidebar/ContactUsSidebar";
import UserEndContactComponent from "../../components/UserEndContactComponent/UserEndContactComponent";
import Footer from "../../components/Footer/Footer";
import PatientReviews from "../PROCEDURE/Sections/PatientReviews/PatientReviews";

const HospitalDetailTestPage = () => {
  const {
    contactUsSidebarToggle,
    openContactUsSidebarHandler,
    closeContactUsSidebarHandler,
  } = useContactUsSidebar();
  const [hospitalDetails, setHospitalDetails] = useState([]);
  const hospitalId = useSearchParams()[0].get("hospitalId");
  const fetchHospitalDetails = async (id) => {
    try {
      const url = API + "/hospital/getsinglehospital/" + id;
      const {
        data: { hospital },
      } = await axios.get(url);
      setHospitalDetails(hospital);
      console.log(hospital);
    } catch (err) {
      let msg;
      if (err?.response) msg = err.response.data.message;
      else msg = err.message;
      toast.error(msg);
    }
  };
  useEffect(() => {
    fetchHospitalDetails(hospitalId);
  }, [hospitalId]);

  return (
    <div>
      <LandingPageNavbar />
      <HospitalDetail
        hospitalName={hospitalDetails?.Name_Of_Hospital}
        hospitalImage={hospitalDetails?.Hospital_images?.[0]}
        hospitalLocation={hospitalDetails?.Location}
        hospitalAccreditations={hospitalDetails?.Accreditation?.split(",")}
        specialty={hospitalDetails?.facility_overview?.specialtyTypes}
        noOfPatientsAssisted={hospitalDetails?.No_of_Patients_Assited}
        noOfBeds={hospitalDetails?.NoOfBeds}
        noOfDoctors={hospitalDetails?.doctorsAvailable?.length}
        hospitalEstablishedIn={hospitalDetails?.EstablishedIn}
        bookAppointmentClickHandler={openContactUsSidebarHandler}
      />
      {/* <SearchDoctors /> */}
      <StickyProgressBarSection
        optionsList={hospitalDetailsPageProgressBarOptions}
      >
        <HospitalDetailAbout
          aboutHospital={hospitalDetails?.aboutHospital}
          hospitalImages={hospitalDetails?.Hospital_images}
          locationDetails={hospitalDetails?.locationDetails}
        />
        <HospitalDetailProcedures
          procedures={hospitalDetails?.facility_overview?.specialties}
          hospitalName={hospitalDetails?.Name_Of_Hospital}
          hospitalLoction={hospitalDetails?.Location}
        />
        <HospitalInfrastructure
          infrastructurePoints={hospitalDetails?.infrastructure}
        />
        <Facilities hospitalFacilities={hospitalDetails?.facilities} />
        <TopDoctors
          hospitalId={hospitalId}
          doctorsFor={"Treatment"}
          topDoctorsIds={hospitalDetails?.doctorsAvailable}
          // topDoctorsIds={["-NouDsLzlvhO40fgRXQZ"]}
          location={hospitalDetails?.Location}
        />
        <VerifiedHotelsNearHospital
          verifiedHotels={hospitalDetails?.verifiedHotels?.slice(0, 3)}
          nearByHospitalId={hospitalId}
        />
        <SimilarHospitals
          hospitalId={hospitalId}
          city={hospitalDetails?.locationDetails?.address?.city}
          country={hospitalDetails?.locationDetails?.address?.country}
        />
      </StickyProgressBarSection>
      <UserEndContactComponent
        openContactUsSidebarHandler={openContactUsSidebarHandler}
      />
      <Footer />
      {contactUsSidebarToggle && (
        <ContactUsSidebar closeHandler={closeContactUsSidebarHandler} />
      )}
    </div>
  );
};

export default HospitalDetailTestPage;
