import { createContext, useContext, useState } from "react";

export const LoginContext = createContext();

export const LoginContextProvider = ({ children }) => {
  const [currentComponent, setCurrentComponent] = useState("login");
  //   const [appointmentDate, setAppointmentDate] = useState("");
  const CurrentComponentHandler = (value) => {
    setCurrentComponent(value);
  };

  return (
    <LoginContext.Provider
      value={{
        currentComponent,
        CurrentComponentHandler,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export const useLoginContext = () => {
  return useContext(LoginContext);
};
