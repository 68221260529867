import { useEffect, useRef } from "react";
import React from "react";
import LoadingBar from "react-top-loading-bar";

const Loader = ({ setToZero }) => {
  const ref = useRef(null);
  useEffect(() => {
    ref.current.continuousStart();
  }, [ref]);
  return (
    <div>
      <LoadingBar color="var(--secondaryColor)" ref={ref} shadow={true} />
      {/* <button onClick={() => ref.current.continuousStart()}>
        Start Continuous Loading Bar
      </button> */}
      {/* <br /> */}
    </div>
  );
};

export default Loader;
