import React, { useState, useEffect } from "react";
import DoctorInfoModel from "../../components/DoctorInfoModel/DoctorInfoModel";
import "./DoctorInfoPage.css";
import img from "../../assets/images/doctor_experience_svg.svg";
import img2 from "../../assets/images/doctor_certifications_svg.svg";
import img3 from "../../assets/images/doctor_memberships_svg.svg";
import img4 from "../../assets/images/Trophy.svg";
import UserEndContactComponent from "../../components/UserEndContactComponent/UserEndContactComponent";
import Footer from "../../components/Footer/Footer";
import LandingPageNavbar from "../../components/LandingPageNavbar/LandingPageNavbar";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import axios from "axios";
import { API } from "../../constants/api";
import { useSearchParams } from "react-router-dom";
import useContactUsSidebar from "../../Hooks/useContactUsSidebar";
import ContactUsSidebar from "../../components/ContactUsSidebar/ContactUsSidebar";

const DoctorInfoPage = () => {
  const {
    contactUsSidebarToggle,
    openContactUsSidebarHandler,
    closeContactUsSidebarHandler,
  } = useContactUsSidebar();
  const [doctorData, setDoctorData] = useState(null);
  const [hospitalData, setHospitalData] = useState(null);
  const doctorId = useSearchParams()[0].get("doctor_id");
  const hospitalId = useSearchParams()[0].get("hospital_id");
  useEffect(() => {
    if (hospitalId) {
      getHospitalDataFromFirebase();
    }
    getDoctorDataFromFirebase();
  }, []);

  useEffect(() => {
    if (!hospitalId) {
      getHospitalDataFromDoctorData();
    }
  }, [doctorData]);

  const getHospitalDataFromFirebase = async () => {
    try {
      const url = `${API}/hospital/getsinglehospital/${hospitalId}`;
      const response = await axios.get(url);
      console.log(response.data.hospital);
      setHospitalData(response.data.hospital);
    } catch (error) {
      console.log("Error Fetching Hospital Details");
      console.log(error);
    }
  };

  const getDoctorDataFromFirebase = async () => {
    try {
      const url = `${API}/agenthospital/getsingledoctor/${doctorId}`;
      const response = await axios.get(url);
      console.log(response.data.doctor);
      setDoctorData(response.data.doctor);
    } catch (error) {
      console.log("Error Fetching Doctor Details");
      console.log(error);
    }
  };

  const getHospitalDataFromDoctorData = () => {
    if (doctorData) {
      setHospitalData({ Name_Of_Hospital: doctorData.hospitalName });
    }
  };

  return (
    <div>
      <LandingPageNavbar />
      <Breadcrumb
        breadcrumbs={[
          { label: "Home", url: "/" },
          { label: "Doctors", url: "/doctors" },
          // { label: "Hospitals", url: "/hospitals" },
          // {
          //   label: `${hospitalData?.Name_Of_Hospital
          //       ? hospitalData.Name_Of_Hospital
          //       : ""
          //     }`,
          //   url: "/doctors/info",
          // },
        ]}
        divider={"|"}
        styles={{
          marginLeft: "4.5rem",
          marginTop: "1rem",
          color: "black",
        }}
      />
      <div className="doctor-info-page">
        <DoctorInfoModel data={doctorData} data2={hospitalData} />
        <div className="doctor-detailed-info-container">
          <div className="doctor-detailed-info-section">
            <h4 className="doctor-detailed-info-section-heading">Profile</h4>
            <p className="doctor-detailed-info-section-text">
              {doctorData?.description ? doctorData.description : ""}
            </p>
          </div>
          <div className="doctor-detailed-info-section ">
            <div className="doctor-detailed-info-section-svg">
              <img
                src={img}
                className="doctor-detailed-info-section-svg-image"
                alt="experience_logo"
              />
              <h4 className="doctor-detailed-info-section-heading">
                Experiences
              </h4>
            </div>
            <div className="custom-list">
              <div className="custom-list-item">
                {doctorData?.specialization ? doctorData.specialization : ""}
              </div>
              <div className="custom-list-item">
                {doctorData?.special_interest
                  ? doctorData.special_interest
                  : ""}
              </div>
              <div className="custom-list-item">
                {doctorData?.list_of_treatments
                  ? doctorData.list_of_treatments
                  : ""}
              </div>

              {/* {doctorData?.experiences
                ? doctorData.experiences.map((experience) => (
                    <div className="custom-list-item">{experience.text}</div>
                  ))
                : ""} */}
            </div>
          </div>
          <div className="doctor-detailed-info-section ">
            <div className="doctor-detailed-info-section-svg">
              <img
                src={img2}
                className="doctor-detailed-info-section-svg-image"
                alt="experience_logo"
              />
              <h4 className="doctor-detailed-info-section-heading">
                Certifications
              </h4>
            </div>
            <div className="custom-list">
              <div className="custom-list-item">
                {doctorData?.qualification ? doctorData.qualification : ""}
              </div>
              {/* {doctorData?.certifications
                ? doctorData.certifications.map((certification) => (
                    <div className="custom-list-item">{certification.text}</div>
                  ))
                : ""} */}
            </div>
          </div>
          {doctorData?.memberships ? (
            <div className="doctor-detailed-info-section ">
              <div className="doctor-detailed-info-section-svg">
                <img
                  src={img3}
                  className="doctor-detailed-info-section-svg-image"
                  alt="experience_logo"
                />
                <h4 className="doctor-detailed-info-section-heading">
                  Memberships
                </h4>
              </div>
              <div className="custom-list">
                {doctorData.memberships.split(",").map((membership) => (
                  <div className="custom-list-item">{membership.text}</div>
                ))}
              </div>
            </div>
          ) : (
            ""
          )}
          {doctorData?.awards && (
            <div className="doctor-detailed-info-section ">
              <div className="doctor-detailed-info-section-svg">
                <img
                  src={img4}
                  className="doctor-detailed-info-section-svg-image"
                  alt="experience_logo"
                />
                <h4 className="doctor-detailed-info-section-heading">
                  Awards and Recognitions
                </h4>
              </div>
              <div className="custom-list">
                {doctorData.awards.split(",").map((ele) => (
                  <div className="custom-list-item">{ele}</div>
                ))}
              </div>
            </div>
          )}
        </div>
        <UserEndContactComponent
          openContactUsSidebarHandler={openContactUsSidebarHandler}
        />
        {contactUsSidebarToggle && (
          <ContactUsSidebar closeHandler={closeContactUsSidebarHandler} />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default DoctorInfoPage;
