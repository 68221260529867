import React from "react";
import Fab from "@mui/material/Fab";
import Box from "@mui/material/Box";
import "./ActionButton.css";

const ActionButton = ({ text, icon, task, styling }) => {
  return (
    <Fab variant="extended" color="primary" style={styling} onClick={task} className="b1c-action-button">
      {" "}
      {icon} {text}
    </Fab>
  );
};

export default ActionButton;
