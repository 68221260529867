import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "./HospitalsSlider.css";
import apollo from "../../assets/images/apollo.svg";

const HospitalsSlider = ({ dynamicList }) => {
  const [slideNumber, setSlideNumber] = useState(1);
  useEffect(() => {
    console.log("called");
    let tid = setTimeout(
      () =>
        setSlideNumber(slideNumber < dynamicList.length ? slideNumber + 1 : 1),
      6000
    );
    return () => {
      clearTimeout(tid);
    };
  }, [dynamicList, slideNumber]);
  return (
    <div className="portfolio-hospital-slider" id="portfolio-hospital-slider">
      {dynamicList &&
        dynamicList.map((list, i) => {
          return (
            <div
              key={i}
              className={`portfolio-hospital-slider-slide ${
                slideNumber === i + 1
                  ? "portfolio-hospital-slider-slide-type2"
                  : (slideNumber - 1 === 0
                      ? dynamicList.length
                      : slideNumber - 1) ===
                    i + 1
                  ? "portfolio-hospital-slider-slide-type3"
                  : "portfolio-hospital-slider-slide-type1"
              }`}
            >
              {list &&
                list.map((hospital, i) => {
                  return (
                    <motion.div
                      key={hospital?.Name_Of_Hospital}
                      initial={{
                        y: "100%",
                        opacity: "0",
                      }}
                      animate={{
                        y: "0%",
                        opacity: 1,
                        cursor: "pointer",
                      }}
                      transition={{
                        delay: `${(i + 2) / 10}`,
                      }}
                      whileHover={{
                        scale: 1.2,
                      }}
                      viewport={{ once: true }}
                    >
                      <img
                        src={hospital?.Hospital_Icon || apollo}
                        alt="hospitalImage"
                        className="portfolio-hospital-icon"
                      />
                      <h4 className="portfolio-hospital-name">
                        {hospital?.Name_Of_Hospital}
                      </h4>
                    </motion.div>
                  );
                })}
            </div>
          );
        })}
    </div>
  );
};

export default HospitalsSlider;
