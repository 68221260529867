import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "./BlogPopup.css";
const BlogPopup = ({
  action,
  textContent,
  highlightContent,
  openState,
  closeHandler,
}) => {
  return (
    <React.Fragment>
      <Dialog
        open={openState}
        onClose={closeHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Blog"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            fontSize={"15px"}
            color={"gray"}
          >
            Do you really want to{" "}
            <span style={{ fontWeight: "600" }}>Delete {} Blog</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button onClick={closeHandler} className="blog-popup-button">
            Cancel {openState}
          </button>
          <button onClick={action} className="blog-popup-delete-button">
            Delete
          </button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default BlogPopup;
