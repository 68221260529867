import React from "react";
import DualAnimatedRing from "../../../assets/svg/Dual_Animated_Ring.svg";
import { BOneAvatar } from "./BOneAvatar";

export const ChatLoader = (props) => {
  return (
    <div class="react-chatbot-kit-chat-bot-message-container">
      <BOneAvatar />
      <div>
        <img src={DualAnimatedRing} alt="loading" style={{ height: "40px" }} />
      </div>
    </div>
  );
};
