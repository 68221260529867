import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "./IndexPage.css";
import { auth, googleProvider } from "../../config/testFirebaseConfig";
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  signInWithEmailAndPassword,
  sendEmailVerification,
  onAuthStateChanged,
  fetchSignInMethodsForEmail,
  signInWithRedirect,
  signOut,
} from "firebase/auth";
import CommonComponent from "../../components/CommonComponent/CommonComponent";
import LoginPage from "./LoginPage/LoginPage";
import SignUpPage from "./SignUpPage/SignUpPage";
// import PinVerificationComponent from "../Login/PinVerificationPage/PinVerificationPage";
import PartnerSelectionComponent from "./PartnerSelectionPage/PartnerSelectionPage";
import EmailLinkVerificationPage from "./EmailLinkVerificationPage/EmailLinkVerificationPage";
import axios from "axios";
import { API } from "../../constants/api";
import { useLoginContext } from "../../context/LoginContext";
import AdminLogin from "../Admin/Login/AdminLogin";
import ForgotPasswordPage from "./ForgotPasswordPage/ForgotPasswordPage";
import CustomerSupportSignup from "./CustomerSupport";
import CustomerSupportLogin from "./CustomerSupport/Login";
import toast from "react-hot-toast";
export const IndexPage = (props) => {
  const { currentComponent, CurrentComponentHandler } = useLoginContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [verified, setVerified] = useState(false);
  const [pathName, setPathName] = useState("");
  const tempUser = {
    fullName: "",
    phoneNumber: "",
    email: "",
    password: "",
    isCustomer: false,
  };
  const [user, setUser] = useState(tempUser);
  const path = location.pathname;
  const match = path.match(/^\/([^/]+)/);
  const subPath = match ? match[1] : null;
  console.log(subPath);

  const [errorText, setErrorText] = useState("");
  const [displayError, setDisplayError] = useState(false);
  useEffect(() => {
    setPathName(location.pathname);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.emailVerified) {
        setVerified(true);
      }
    });
    return () => unsubscribe();
  }, [location.pathname]);
  useEffect(() => {
    console.log(auth);
    console.log(auth.currentUser);
  }, [auth.currentUser]);
  const handleCreateAccountChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const deleteCurrentUser = async () => {
    try {
      const user = auth.currentUser;
      console.log(user);
      if (user) {
        await user.delete();
        console.log("User deleted successfully");
        await axios.delete(`${API}/user/deleteuser/${user.uid}`);
        CurrentComponentHandler("login");
        setUser(tempUser);
        setResendTimer(60);
      } else {
        console.log("No user is currently signed in");
      }
    } catch (error) {
      console.error("Error deleting user", error.message);
    }
  };

  const deleteGoogleSignInUser = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        await user.delete();
        console.log("User deleted from authentication");
      } else {
        console.log("No user is currently signed in");
      }
    } catch (error) {
      console.error("Error deleting user", error.message);
    }
  };

  const handleCreateAccountSubmit = async (e, buttonType, text) => {
    console.log(buttonType, "sajkhdksjahdk hajkd hakjhd jk ahkjdhajk dhujk");
    e.preventDefault();
    if (buttonType === "simple" && text === "Create Account") {
      console.log("Create Simple Account running");
      if (!validateEmail(user.email) || !validatePassword(user.password)) {
        if (!validatePassword(user.password)) {
          setErrorText("Password must be atleast 6 characters");
        }
        if (!validateEmail(user.email)) {
          setErrorText("Please enter a valid email");
        }
        setDisplayError(true);
        setTimeout(() => {
          setDisplayError(false);
        }, 3000);
        console.log("Validation failed");
        return;
      }
      try {
        await createUserWithEmailAndPassword(auth, user.email, user.password)
          .then(async (userCredential) => {
            await axios.post(`${API}/user/register`, {
              uid: userCredential.user.uid,
              email: userCredential.user.email,
            });
            sendEmailVerification(auth.currentUser).then(() => {
              console.log(auth);
              console.log(auth.currentUser);

              const prevComponent =
                currentComponent === "emailLinkVerification"
                  ? "partnerSelection"
                  : "emailLinkVerification";
              CurrentComponentHandler(prevComponent);
            });

            console.log("userCredential", userCredential);
          })
          .catch((error) => {
            if (error.code === "auth/email-already-in-use") {
              setErrorText("Account already exists. Please log in.");
              setDisplayError(true);
              setTimeout(() => {
                setDisplayError(false);
              }, 3000);
            } else {
              console.error("Error creating user:", error.message);
            }
          });
      } catch (error) {
        console.error("Error creating user:", error.message);
      }
    } else if (buttonType === "simple" && text === "Login") {
      console.log("Login Simple Account running");
      if (!validateEmail(user.email) || !validatePassword(user.password)) {
        if (!validatePassword(user.password)) {
          setErrorText("Password must be atleast 6 characters");
        }
        if (!validateEmail(user.email)) {
          setErrorText("Please enter a valid email");
        }
        setDisplayError(true);
        setTimeout(() => {
          setDisplayError(false);
        }, 3000);
        console.log("Validation failed");
        return;
      }
      try {
        await signInWithEmailAndPassword(auth, user.email, user.password).then(
          (userCredential) => {
            const user = userCredential.user;
            if (user.emailVerified) {
              props.onUserEmailChange(user.email);
              navigate("/home");
            } else {
              CurrentComponentHandler("emailLinkVerification");
              sendEmailVerification(user);
            }
          }
        );
      } catch (error) {
        setErrorText("No account exists with the given email");
        setDisplayError(true);
        setTimeout(() => {
          setDisplayError(false);
        }, 3000);
      }
    } else if (buttonType === "customer-support" && text === "Login") {
      if (!validateEmail(user.email) || !validatePassword(user.password)) {
        if (!validatePassword(user.password)) {
          setErrorText("Password must be at least 6 characters");
        }
        if (!validateEmail(user.email)) {
          setErrorText("Please enter a valid email");
        }
        setDisplayError(true);
        setTimeout(() => {
          setDisplayError(false);
        }, 3000);
        return;
      }
      try {
        const response = await fetch(
          `${API}/customerSupport/support-request/login`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: user.email,
              password: user.password,
              isCustomer: true,
            }),
          }
        );
        if (response.ok) {
          const userData = await response.json();
          console.log(userData);

          // Assuming 'userData' contains the logged-in user's data
          if (userData && userData.user && userData.user.email) {
            // If the user data and email property exist, set the email
            props.onUserEmailChange(userData.user.email);
          } else {
            // Handle the case where user data or email property is missing
            throw new Error("User data or email is missing");
          }

          // Navigate to the home page
          navigate("/home");

          // Redirect to Google after successful login
          window.location.href = "https://www.google.com";

          // Display toast for successful login
          toast("Login successful", { type: "success" });
        } else {
          // Handle failed login
          const errorMessage = await response.text();
          console.error("Login failed:", errorMessage);
          setErrorText("Invalid email or password");
          setDisplayError(true);
          setTimeout(() => {
            setDisplayError(false);
          }, 3000);
        }
      } catch (error) {
        console.error("An error occurred while logging in:", error.message);
        setErrorText("An error occurred while logging in");
        setDisplayError(true);
        setTimeout(() => {
          setDisplayError(false);
        }, 3000);
      }
    } else if (buttonType === "customer-support" && text === "Create Account") {
      try {
        await axios.post(`${API}/customerSupport/support-request/register`, {
          email: user.email,
          fullName: user.fullName,
          phoneNumber: user.phoneNumber,
          password: user.password,
          isCustomer: true,
        });
        // Send email verification
        await sendEmailVerification(auth.currentUser);
        const prevComponent =
          currentComponent === "emailLinkVerification"
            ? "partnerSelection"
            : "emailLinkVerification";
        CurrentComponentHandler(prevComponent);
        console.log("Customer support user created successfully:");
      } catch (error) {
        if (error.code === "auth/email-already-in-use") {
          setErrorText("Account already exists. Please log in.");
        } else {
          setErrorText(`Error creating user: ${error.message}`);
        }
        setDisplayError(true);
        setTimeout(() => {
          setDisplayError(false);
        }, 3000);
        console.error("Error creating user:", error.message);
      }
    } else if (buttonType === "simple" && text === "Admin Login") {
      if (!validateEmail(user.email) || !validatePassword(user.password)) {
        if (!validatePassword(user.password)) {
          setErrorText("Password must be atleast 6 characters");
        }
        if (!validateEmail(user.email)) {
          setErrorText("Please enter a valid email");
        }
        setDisplayError(true);
        setTimeout(() => {
          setDisplayError(false);
        }, 3000);
        console.log("Validation failed");
        return;
      }
      try {
        await signInWithEmailAndPassword(auth, user.email, user.password).then(
          (userCredential) => {
            const user = userCredential.user;
            console.log("admin user is here ->", user);

            axios
              .get(`${API}/admin/verification/${userCredential.user.email}`)
              .then(({ data }) => {
                console.log(data);
                const { isAdmin } = data;
                if (isAdmin) {
                  alert("Admin");
                  props.onUserEmailChange(userCredential.user.email);
                  return navigate("/console/blogs");
                } else throw new Error("Only Admin can access.");
              })
              .catch((err) => {
                setErrorText(err?.message);
                signOut(auth);
              });
          }
        );
      } catch (error) {
        setErrorText(
          error.message === "Only admin can access."
            ? error.message
            : "No account exists with the given email"
        );
        setDisplayError(true);
        setTimeout(() => {
          setDisplayError(false);
        }, 3000);
      }
    } else if (buttonType === "google" && text === "Sign in with Google") {
      try {
        console.log("Login Google Account running");
        const googleUser = await signInWithRedirect(auth, googleProvider).then(
          async (userCredential) => {
            console.log(userCredential);
            try {
              const dbUser = await axios.get(
                `${API}/user/getsingleuser/${userCredential.user.uid}`
              );
              console.log(userCredential);
              console.log(dbUser.data);
              if (dbUser.data) {
                console.log("user exists");
                props.onUserEmailChange(userCredential.user.email);
                navigate("/home");
              }
            } catch (error) {
              await deleteGoogleSignInUser();
              // setCurrentComponent("login");
              CurrentComponentHandler("login");
              setErrorText("No account exists with the given google email");
              setDisplayError(true);
              setTimeout(() => {
                setDisplayError(false);
              }, 3000);
              console.log(auth);
              console.log("User doesn't exist");
            }
          }
        );
      } catch (error) {
        console.log(error);
      }
    } else if (buttonType === "google") {
      try {
        console.log("Create Google Account running");
        await signInWithPopup(auth, googleProvider).then(
          async (userCredential) => {
            // setCurrentComponent((prevComponent) =>
            //   prevComponent === "login" ? "partnerSelection" : "login"
            // );
            const prevComponent =
              currentComponent === "login" ? "partnerSelection" : "login";
            CurrentComponentHandler(prevComponent);
            await axios.post(`${API}/user/register`, {
              uid: userCredential.user.uid,
              email: userCredential.user.email,
            });
          }
        );
      } catch (error) {
        console.error("Error creating user:", error.message);
      }
    }
  };

  const [resendTimer, setResendTimer] = useState(60);

  const handleResendEmailVerification = () => {
    // window.open("https://mail.google.com/", "_blank");
    if (resendTimer === 0) {
      sendEmailVerification(auth.currentUser);
      setResendTimer(60);
    }
  };

  const openTermsAndConditions = (text) => {
    const result = text.replace(/\s+/g, "").toLowerCase();
    window.open(`/${result}`, "_blank");
  };

  const submitPartner = async (partner, ticked) => {
    if (partner !== "" && ticked) {
      if (partner === "Hospital Onboarding by agent") {
        navigate(`/agent/appointments/hospital-details`);
      } else {
        partner = partner.toLowerCase();
        const userID = auth.currentUser.uid;
        await axios.patch(`${API}/user/updateuser/${userID}`, {
          partnerType: partner,
        });
        navigate(`/onboarding/${partner}`);
      }
    }
  };

  return (
    <div className="field-wrapper">
      <CommonComponent>
        {pathName === "/signup" &&
        currentComponent !== "emailLinkVerification" &&
        currentComponent !== "partnerSelection" ? (
          <SignUpPage
            handleSubmit={handleCreateAccountSubmit}
            handleChange={handleCreateAccountChange}
            user={user}
            displayError={displayError}
            errorText={errorText}
          />
        ) : pathName === "/login" &&
          currentComponent !== "emailLinkVerification" &&
          currentComponent !== "partnerSelection" ? (
          <LoginPage
            handleSubmit={handleCreateAccountSubmit}
            handleChange={handleCreateAccountChange}
            user={user}
            displayError={displayError}
            errorText={errorText}
          />
        ) : pathName === "/customer-support/login" &&
          currentComponent !== "emailLinkVerification" &&
          currentComponent !== "partnerSelection" ? (
          <CustomerSupportLogin
            handleSubmit={handleCreateAccountSubmit}
            handleChange={handleCreateAccountChange}
            user={user}
            displayError={displayError}
            errorText={errorText}
          />
        ) : pathName === "/customer-support/signup" &&
          currentComponent !== "emailLinkVerification" &&
          currentComponent !== "partnerSelection" ? (
          <CustomerSupportSignup
            handleSubmit={handleCreateAccountSubmit}
            handleChange={handleCreateAccountChange}
            user={user}
            displayError={displayError}
            errorText={errorText}
          />
        ) : pathName === "/adminlogin" &&
          currentComponent !== "emailLinkVerification" &&
          currentComponent !== "partnerSelection" ? (
          <AdminLogin
            handleSubmit={handleCreateAccountSubmit}
            handleChange={handleCreateAccountChange}
            user={user}
            displayError={displayError}
            errorText={errorText}
          />
        ) : pathName === "/forgot-password" &&
          currentComponent !== "emailLinkVerification" &&
          currentComponent !== "partnerSelection" ? (
          <ForgotPasswordPage />
        ) : currentComponent === "emailLinkVerification" ? (
          <EmailLinkVerificationPage
            user={user}
            onClick={handleResendEmailVerification}
            resendTimer={resendTimer}
            setResendTimer={setResendTimer}
            deleteCurrentUser={deleteCurrentUser}
            // setCurrentComponent={setCurrentComponent}
          />
        ) : (
          <PartnerSelectionComponent
            submitPartner={submitPartner}
            openTermsAndConditions={openTermsAndConditions}
          />
        )}
      </CommonComponent>
    </div>
  );
};

export default IndexPage;
