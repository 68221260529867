import React, { useState } from "react";
import cross from "../../assets/images/black-cross.png";

const EditAppointments = ({ handleModalClose, submitHandler }) => {
  const [appointmentDate, setAppointmentDate] = useState({
    value: "",
    isTouched: false,
  });

  const today = new Date(); // get today's date
  const currentDate = new Date(today);
  currentDate.setDate(today.getDate() + 1);
  const year = `${currentDate.getFullYear()}`;
  let month = `${currentDate.getMonth() + 1}`;
  let date = "";
  if (currentDate.getMonth() + 1 < 10) {
    month = `0${currentDate.getMonth() + 1}`;
  }
  if (currentDate.getDate() < 10) {
    date = `0${currentDate.getDate()}`;
  }
  const minDate = `${year}-${month}-${date}`;

  const disabled = appointmentDate.value === "" ? true : false;

  //  console.log(appointmentDate.value);

  const handleDateChange = (title, value) => {
    setAppointmentDate({
      value: value,
      isTouched: true,
    });
  };

  const handleDateTouch = (title) => {
    setAppointmentDate((prev) => {
      return {
        value: prev.value,
        isTouched: true,
      };
    });
  };

  const onSubmit = () => {
    const date = appointmentDate.value;
    submitHandler(date);
  };
  return (
    <div className="modal-background-1">
      <div className="modal-section">
        <div className="modal-header modal-header-1">
          <h5
            className="modal-title  add-appointment-model-title"
            id="exampleModalLabel"
          >
            Rescheduele Appointments
          </h5>
          <img
            src={cross}
            alt="cross"
            className="red-cross-button"
            onClick={() => {
              handleModalClose();
            }}
          />
        </div>
        <div className="modal-body">
          <div className="multi-select-box  mb-3">
            <label htmlFor="date" className="form-label d-flex mb-1 ms-1">
              Appointment Date
            </label>
            <input
              type="date"
              name="date"
              placeholder="date"
              min={minDate}
              value={appointmentDate.value}
              onBlur={(event) => {
                handleDateTouch("date");
              }}
              onChange={(event) => {
                handleDateChange("date", event.target.value);
              }}
              className="form-control simple-field"
            ></input>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className={`my-btn-1 ${disabled === true ? "disabled" : ""}`}
            data-bs-dismiss="modal"
            disabled={disabled}
            onClick={onSubmit}
          >
            Rescheduele Appointment
          </button>
          <button
            type="button"
            className={`my-btn-1`}
            onClick={handleModalClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditAppointments;
