import React, { useEffect, useState } from "react";
import axios from "axios";
import DashboardLayout from "../DashboardSidebar/DashboardLayout";
import { sidebarAgentItems } from "../../constants/SidebarItems";
import { useUserAuth } from "../../context/UserAuthContext";
import { API } from "../../constants/api";
import getAccessToken from "../../utils/getAccessToken";
import FormGeneratorStore from "../../components/FormGenearatorAndStepper/FormGenearatorAndStepper";
import { format } from "date-fns";
import { agentProfile } from "../../constants/profileConfig";

const AgentProfile = () => {
  const { user } = useUserAuth();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getAccessToken();
        // console.log(token);
        const { data } = await axios.get(`${API}/agent/user/profile`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (data.item) {
          const id = Object.keys(data.item);
          // Access the corresponding value
          const userProfileData = data.item[id];

          console.log("USER DATA", userProfileData);

          // var pattern = /\+(\d+)(\d+)/;
          // var match = userProfileData.Contact_Number.match(pattern);

          // var dialCode = match[1];
          // var phone = match[2];

          setUserData({
            // Contact_Number: {
            //   value: { phone: userProfileData.Contact_Number || "" },
            //   isTouched: true,
            // },
            Full_Name: {
              value: userProfileData.Full_Name || "",
              isTouched: true,
            },
            Contact_Number: {
              value: {
                phone: userProfileData.Contact_Number || "",
              },
              isTouched: true,
            },
            Nationality: {
              value: userProfileData.Nationality || "",
              isTouched: true,
            },
            PAN_CARD: {
              value: userProfileData.PAN_CARD || "",
              isTouched: true,
            },
            Cancelled_cheque: {
              value: userProfileData.Cancelled_cheque || "",
              isTouched: true,
            },
            Passport: {
              value: userProfileData.Passport || "",
              isTouched: true,
            },
          });
        } else {
          console.error("Error");
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
    if (user && Object.keys(userData).length > 0) {
      setLoading(false);
    }
  }, [user]);

  return (
    <DashboardLayout sidebarItems={sidebarAgentItems}>
      <div
        className="edit-patient-details-container"
        style={{
          marginTop: "5rem",
        }}
      >
        <FormGeneratorStore
          agentTypes={agentProfile}
          // submitHandler={onEditSubmit}
          classnames="mx-auto mt-5"
          updatedFormData={userData}
        />
      </div>
    </DashboardLayout>
  );
};

export default AgentProfile;
