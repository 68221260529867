import { useEffect, useState } from "react";

const useImageSlider = (images) => {
  const [slide, setSlide] = useState(0);
  const [autoRightSlide, setAutoRightSlide] = useState(true);
  const [imagesSlideArr, setImagesSlideArr] = useState([]);
  useEffect(() => {
    let tempArr = [];
    let arr = [];
    if (images) {
      for (let i = 0; i < images.length; i++) {
        tempArr.push(images[i]);
        if ((i + 1) % 3 === 0) {
          arr.push(tempArr);
          tempArr = [];
        }
      }
      setImagesSlideArr(arr);
    }
  }, [images]);
  useEffect(() => {
    if (imagesSlideArr.length > 0) {
      let td = setTimeout(() => {
        if (imagesSlideArr.length === 1) {
          return clearTimeout(td);
        }
        if (autoRightSlide) {
          if (slide + 1 === imagesSlideArr.length - 1) setAutoRightSlide(false);
          setSlide(slide + 1);
        } else {
          if (slide - 1 === 0) setAutoRightSlide(true);
          setSlide(slide - 1);
        }
      }, 3000);
      return () => clearTimeout(td);
    }
  }, [slide, imagesSlideArr]);
  const nextSlideHandler = () => {
    if (autoRightSlide) {
      if (slide + 1 === imagesSlideArr.length - 1) {
        setAutoRightSlide(false);
      }
      setSlide(slide + 1);
    }
  };
  const previousSliderHandler = () => {
    if (slide - 1 === 0) {
      setAutoRightSlide(true);
    }
    setSlide(slide - 1);
  };
  return { slide, nextSlideHandler, previousSliderHandler, imagesSlideArr };
};
export default useImageSlider;
