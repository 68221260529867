import React from "react";
import { useState } from "react";
import "./AgentFacilitatorOnboarding.css";
import CommonComponent from "../../components/CommonComponent/CommonComponent";
import FormGeneratorStore from "../../components/FormGenearatorAndStepper/FormGenearatorAndStepper";
import { agentFacilitator } from "../../constants/formconfig";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import getAccessToken from "../../utils/getAccessToken";
import { API } from "../../constants/api";
import Loader from "../../components/Loader/Loader";
import { useUserAuth } from "../../context/UserAuthContext";
import { ref, uploadBytes } from "firebase/storage";
import { storage } from "../../config/testFirebaseConfig";
import { uploadAndReturnUrls } from "../../constants/UploadeImageUrl";
import { deleteAllImagesRef } from "../../constants/DeleteUrlsImages";

export const AgentFacilitatorOnboarding = (_props) => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const onSubmit = async (formData) => {
    console.log(formData);
    setLoader(true);
    const keys = Object.keys(formData);
    const imgData = [
      formData.Company_License.value,
      formData.Pan_Card.value,
      formData.Upload_GST_Certificate.value,
    ];
    const data = new FormData();
    keys.forEach((key) => {
      if (key === "Contact_Number") {
        const number = formData[key].value.phone.slice(
          formData[key].value.dialCode.length
        );
        data.append(key, `+${formData[key].value.dialCode}${number}`);
      } else if (
        key !== "Company_License" &&
        key !== "Pan_Card" &&
        key !== "Upload_GST_Certificate"
      ) {
        data.append(key, formData[key].value);
      }
      // } else {
      //   data.append(key, formData[key].value);
      // }
    });
    console.log("Data is : ", data);
    const url = `${API}/facilitator/register`;
    try {
      const token = getAccessToken();
      const result = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(result);

      const facilitatorImageRef = ref(
        storage,
        `facilitator/${result.data.facilitatorId}`
      );

      const structuredLinks = {
        Company_License: null,
        Pan_Card: null,
        Upload_GST_Certificate: null,
      };

      await Promise.all(
        imgData.map(async (image, index) => {
          const uploadedFacilitatorUrls = await uploadAndReturnUrls(
            [image],
            facilitatorImageRef
          );
          console.log(uploadedFacilitatorUrls);
          structuredLinks[Object.keys(structuredLinks)[index]] =
            uploadedFacilitatorUrls[0];
        })
      );

      console.log("Final structuredLinks:", structuredLinks);

      const response = await axios.post(
        `${API}/facilitator/upload/${result.data.facilitatorId}`,
        structuredLinks
      );

      await axios.patch(`${API}/user/updateuser/${user.uid}`, {
        isOnboarded: "true",
      });
      navigate("/facilitator/dashboard");
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };

  return (
    <div className="field-wrapper">
      <CommonComponent>
        {loader && <Loader />}
        <FormGeneratorStore
          agentTypes={agentFacilitator}
          submitHandler={onSubmit}
        />
      </CommonComponent>
    </div>
  );
};
