import React from "react";
import { Link, useLocation } from "react-router-dom";
import dashboardIcon from "../../../assets/DashboardAssets/dashboard.svg";
import hospitalmangement from "../../../assets/DashboardAssets/hospitalmanagement.svg";
import profile from "../../../assets/DashboardAssets/profile.svg";

import "./ConsoleSidebar.css";
const ConsoleSidebar = () => {
  const location = useLocation();
  const isActive = (path) => {
    console.log("Path : ", path);
    return location.pathname === path ? "active" : "";
  };
  return (
    <div className="console-sidebar-box position-sticky">
      <div className="console-sidebar-content">
        {/* <hr className='text-white  d-none d-sm-block' /> */}

        <ul className="nav nav-pills d-flex flex-lg-column flex-row mt-lg-4 justify-content-evenly">
          <li className="nav-item p-2">
            <Link
              to="/console/blogs"
              className={`nav-link text-white d-flex justify-content-start py-2 ${isActive(
                "/console/blogs"
              )}`}
            >
              {/* <FontAwesomeIcon icon={faHouse} className='fs-4' /> */}
              <img
                src={dashboardIcon}
                alt="Dashboard Icon"
                className="colored-svg"
              />
              <span className="fs-md-5 fs-6 ms-3 d-none d-lg-inline fw-bold text-start">
                Blogs
              </span>
            </Link>
          </li>

          <li className="nav-item p-2">
            <Link
              to="/customer-support/home"
              className={`nav-link text-white d-flex justify-content-start align-items-center  py-2 ${isActive(
                "/customer-support/home"
              )}`}
            >
              {/* <FontAwesomeIcon icon={faCalendar} className='fs-4 ' /> */}
              <img src={profile} alt="Dashboard Icon" className="colored-svg" />
              <span className="fs-md-5 fs-6 ms-lg-3 ms-2 d-none d-lg-inline fw-bold text-start">
                Customer Support
              </span>
            </Link>
          </li>
          <li className="nav-item p-2">
            <Link
              to="/console/portfolio-hospitals"
              className={`nav-link text-white d-flex justify-content-start align-items-center  py-2 ${isActive(
                "/console/portfolio-hospitals"
              )}`}
            >
              {/* <FontAwesomeIcon icon={faCalendar} className='fs-4 ' /> */}
              <img
                src={hospitalmangement}
                alt="Dashboard Icon"
                className="colored-svg"
              />
              <span className="fs-md-5 fs-6 ms-lg-3 ms-2 d-none d-lg-inline fw-bold text-start">
                Portfolio Hospitals
              </span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ConsoleSidebar;
