import React from "react";
// import samplePhoto from "../../../assets/Procedure/601d12eebb2b7.webp";

const ProcedureCard = ({
  hospital_uid,
  hospitalName,
  hospitalLocation,
  procedureAmount,
  procedureName,
  hospitalPhoto,
}) => {
  const goToProcedure = (name, hospital, location) => {
    window.location.href = `/procedure?name=${name}&hospital=${hospital}&location=${location}`;
  };
  const samplePhoto =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPAtqCPgS8ehGxhJ2_rXFPZN6TxI_myeIyZqS1M4NK2cPo6crfM3gdvfu86tj8FUfXd5g&usqp=CAU";
  return (
    <div
      className="recommended-hospital-card col-md-6 col-lg-4 col-sm-12"
      key={hospital_uid}
    >
      <div className="recommended-content">
        <div className="recommended-hospital-photo-box">
          <div className="recommended-hospital-photo">
            {hospitalPhoto ? (
              <img src={hospitalPhoto[0]} className="img-fluid" alt={`Slide`} />
            ) : (
              <img src={samplePhoto} className="img-fluid" alt="Fallback" />
            )}
          </div>
        </div>

        <div className="recommended-hospital-name-location mt-4 text-truncate">
          <h6 className="name mb-2 text-capitalize">{hospitalName}</h6>
          <h6 className="location text-capitalize">{hospitalLocation}</h6>
          <hr className="m-0" />
        </div>
        <div className="recommended-hospital-facilities-box mt-3 mb-4">
          <p className="mb-0 fw-light mb-1 overflow-auto">Procedure Fees</p>
          <div className="facilities">
            <p className="mb-2 fw-bold p-0 text-truncate">{procedureAmount}</p>
          </div>
        </div>
      </div>
      <div className="recommended-btn">
        <a
          href={`/procedure?name=${procedureName}&hospital=${hospitalName}&location=${hospitalLocation}`}
        >
          <button className="hospital-primary-button">View Details</button>{" "}
        </a>
      </div>
    </div>
  );
};

export default ProcedureCard;
