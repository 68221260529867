import React from 'react'
import './MiniLoading.css';
const MiniLoading = () => {
  return (
    <div className='mini-loading'>
    </div>
  )
}

export default MiniLoading
