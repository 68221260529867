import React, { useContext, useEffect } from "react";
import { BlogContext } from "../../../context/BlogContextApi";
import "./BlogPreview.css";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import { AiFillFacebook } from "react-icons/ai";
import { FaTwitter, FaPinterestP, FaLinkedinIn } from "react-icons/fa";
import toast from "react-hot-toast";
import { StickyLinkBar, TagChip } from "../../ReadBlog/ReadBlog";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ref } from "firebase/storage";
import { storage } from "../../../config/testFirebaseConfig";
import { API } from "../../../constants/api";
import { BloguploadAndReturnUrls } from "../../../constants/BlogUploadImageUrl";
const BlogPreview = () => {
  const { blog } = useContext(BlogContext);
  const navigate = useNavigate();
  const saveBlog = async (saveBlog) => {
    const url = `${API}/blog/update/${saveBlog.id}`;
    const {
      data: { message, blog },
    } = await axios.put(url, {
      title: saveBlog.title,
      description: saveBlog.description,
      relatedTags: JSON.stringify(saveBlog.relatedTags),
      htmlContent: saveBlog.htmlContent,
      textContent: saveBlog.textContent,
    });
    const blogImageRef = ref(storage, `blogs/${saveBlog.id}`);
    if (saveBlog.thumbnailFile) {
      const imageData = [saveBlog.thumbnailFile];
      const structuredLinks = {
        thumbnail: "",
      };
      await Promise.all(
        imageData.map(async (image, index) => {
          const uploadedBlogUrls = await BloguploadAndReturnUrls(
            [image],
            blogImageRef
          );

          console.log(uploadedBlogUrls);
          structuredLinks[Object.keys(structuredLinks)[index]] =
            uploadedBlogUrls[0];
        })
      );

      console.log("Final structuredLinks:", structuredLinks);
      const response = await axios.patch(
        `${API}/blog/upload/${saveBlog.id}`,
        structuredLinks
      );
    }

    return message;
  };
  const saveBlogPromise = () => {
    toast.promise(saveBlog(blog), {
      loading: "Saving Blog...",
      success: (msg) => {
        navigate("/console/blogs");
        return msg;
      },
      error: (err) => {
        if (err.response) {
          return err.response.data.message;
        }
        return err.message;
      },
    });
  };
  useEffect(() => {
    if (!blog) {
      return navigate("/console/blogs");
    }
    let readBlogPreviewBox = document.getElementById(
      "read-blog-preview-container"
    );
    if (readBlogPreviewBox) {
      readBlogPreviewBox.innerHTML = blog?.htmlContent;
    }
  }, []);
  return (
    <>
      <Navbar />
      <div className="blog-preview-container">
        <StickyLinkBar
          blogUrl={`${process.env.REACT_APP_FRONTEND_URL}/read/blog/${blog?.title}`}
        />
        <div className="save-blog-button-box">
          <button
            className="Add-Appointments-button save-blog-button"
            onClick={saveBlogPromise}
          >
            Save Blog
          </button>
        </div>
        <div className="blog-preview-container-box">
          <h1 className="blog-preview-title">{blog?.title}</h1>
          <p className="blog-preview-createdAt">
            BharatOne Care <span style={{ color: "black" }}>. </span>
            {blog?.createdAt}
          </p>
          <div className="blog-preview-thumbnail">
            <img src={blog?.thumbnail} alt="" />
          </div>
          <div
            className="read-blog-preview-container"
            id="read-blog-preview-container"
          ></div>
        </div>
        <hr />
        <div className="blog-preview-tags-and-links">
          <div className="blog-preview-tags">
            <h4>Tags:</h4>
            {blog.relatedTags &&
              blog.relatedTags.length > 0 &&
              blog.relatedTags.map((tag) => <TagChip key={tag} tag={tag} />)}
          </div>

          <div className="blog-preview-share-links">
            <div className="blog-preview-share">
              <p>SHARE ON</p>
              {/* <p></p> */}
              <div className="blog-preview-link-icons">
                <div>
                  <AiFillFacebook />
                  <FaLinkedinIn />
                  <FaPinterestP />
                  <FaTwitter />
                </div>
                {/* <div>
              </div> */}
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default BlogPreview;
