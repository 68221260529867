import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';




const SidebarPink = ({
  menuItems,
  handleToggleWidth,
  showText
}) => {

  const location = useLocation();

  const isActive = (path) => {
    // console.log('Path : ', path);
    return location.pathname === path ? "active" : "";
  };


  // console.log("show text:::", showText);


  return (
    <div className='sidebar-box position-sticky'>
      <div className='sidebar-content position-relative'>
        <div className={`sidebar-toggle position-absolute d-lg-flex d-none ${showText ? 'sidebar-toggle' : 'sidebar-toggle-collaps '}`} onClick={() => {
          handleToggleWidth();

        }}>
          <ChevronRightIcon />
        </div>

        <ul className='nav nav-pills d-flex flex-lg-column flex-row mt-lg-2 justify-content-evenly overflow-hidden'>

          {menuItems.map((item, index) => {
            const shouldDisplay = item.display === 'lg' ? 'd-none d-lg-inline' : '';
            return (
              <li key={index} className={`nav-item p-lg-2  py-lg-3 p-2  ${shouldDisplay}`}>
                <Link
                  to={item.link}
                  className={`nav-link text-white d-flex ${showText ? 'justify-content-start' : 'justify-content-center'}  py-2 ${isActive(item.link)}`}
                >
                  <img src={item.icon} alt={item.altText} className='colored-svg' />
                  <span className={` fs-md-5 fs-6 ms-3 ${showText ? 'd-lg-inline d-none' : 'd-none'}  fw-bold text-start`} >
                    {item.label}
                  </span>
                </Link>
              </li>
            );
          })}
        </ul>


      </div>
    </div>
  );
};

export default SidebarPink;
