import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight, UploadFile } from "@mui/icons-material";
import "./HospitalImages.css";
import greenTick from "../../../src/assets/images/green-tick.png";

const HospitalImages = ({
  images,
  handleFileChange,
  moveToPrev,
  imageSubmit,
  handleLogoHospital,
  logoFile,
}) => {
  // const [images, setImages] = useState([]);
  console.log(images);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const photoSelect = images.length === 0;
  const [defaultImage, setDefaultImage] = useState(null);

  // const handleFileChange = (e) => {
  //   const fileList = e.target.files;
  //   const fileArray = Array.from(fileList)
  //     .slice(0, 5)
  //     .map((file) => URL.createObjectURL(file));
  //   setImages(fileArray);

  //   // Array.from(fileList).forEach((file) => {
  //   //   const storageRef = storage.ref(`images/${file.name}`);
  //   //   storageRef.put(file).then((snapshot) => {
  //   //     console.log("Uploaded a file!", snapshot);
  //   //   });
  //   // });
  // };

  const [touchPosition, setTouchPosition] = useState(null);

  useEffect(() => {
    // const fetchImages = async () => {
    //   const storageRef = storage.ref("images");
    //   const imagesList = await storageRef.listAll();
    //   const urls = await Promise.all(
    //     imagesList.items.map((imageRef) => imageRef.getDownloadURL())
    //   );
    //   setImages(urls);
    // };
    // fetchImages();
  }, []);

  const backHandle = (event) => {
    moveToPrev(event);
  };

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      handleNext();
    }

    if (diff < -5) {
      handlePrev();
    }

    setTouchPosition(null);
  };

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  // const nextHandle = (event) => {
  //   moveToNext(event);

  // }

  // const backHandle = (event) => {
  //   moveToPrev(event);

  // }
  console.log("Iam Image", photoSelect);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        paddingTop: "2rem",
      }}
      className="treatment-container"
    >
      <div className="d-block mb-3">
        <label className="form-label d-flex mb-1">
          Upload hospital and facilities images
        </label>
        <button className="btn btn-uploade border d-flex align-items-center mb-4">
          {photoSelect ? (
            <UploadFile sx={{ marginRight: "0rem", marginLeft: "1rem" }} />
          ) : (
            <img src={greenTick} alt="" />
          )}

          {/* <label className='form-label upload-text align-items-center'>
            Upload Multiple hospital Images
          </label> */}
          {photoSelect ? (
            <label className="form-label upload-text align-items-center">
              Upload hospital Images
            </label>
          ) : (
            <label className="form-label upload-text">
              Selected Images: {images.length}
            </label>
          )}

          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleFileChange}
            title="Upload hospital Images"
            name="Upload hospital Images"
            className="from-control file-input"
            defaultValue={defaultImage ? defaultImage.name : ""}
          />
        </button>

        {false && (
          <p
            data-testid="errorElement"
            className="validate-text text-danger mb-0 ms-1"
          >
            Error
          </p>
        )}

        <label className="form-label d-flex mb-1">Upload hospital logo</label>
        <button className="btn btn-uploade border d-flex align-items-center mb-4">
          <img src={greenTick} alt="" />

          {logoFile ? (
            <label className="form-label upload-text align-items-center">
              Logo uploaded : {logoFile.name}
            </label>
          ) : (
            <label className="form-label upload-text">
              Default logo uploaded
            </label>
          )}

          <input
            type="file"
            accept="image/*"
            onChange={handleLogoHospital}
            title="Upload hospital logo"
            name="Upload hospital logo"
            className="from-control file-input"
          />
        </button>
      </div>

      {/* {images.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <img
            src={images[currentImageIndex]}
            alt={`uploaded`}
            style={{ width: "400px", height: "300px", objectFit: "cover" }}
          />
          {images.length > 1 && (
            <>
              <ChevronLeft
                onClick={handlePrev}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "10px",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                fontSize='small'
                color='primary'
              />

              <ChevronRight
                sx={{
                  position: "absolute",
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={handleNext}
                fontSize='small'
                color='primary'
              />
            </>
          )}
        </div>
      )} */}

      {/* <button
        disabled={images.length === 0}
        className={`btn btn-next ms-3 ${images.length === 0 ? "disabled" : ""}`}
      >
        {images.length === 0
          ? "0 Images Selected"
          : `Upload ${images.length} images`}
      </button> */}
      <div className="treatment-button-container btn-action d-flex justify-content-end btn-container">
        <button
          // onClick={props.moveToPrev}
          className={`btn btn-back ms-3`}
          onClick={backHandle}
          // disabled={props.disabled}
        >
          Back
        </button>
        <button
          // onClick={props.isLast ? props.onSubmit : props.moveToNext}
          // disabled={props.disabled}
          className={`btn btn-next ms-3`}
          // onClick={nextHandle}
          // disabled={section.length === 0}
          onClick={imageSubmit}
          disabled={images.length === 0}
        >
          Save and Preview
        </button>
      </div>
    </div>
  );
};

export default HospitalImages;
