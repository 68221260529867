import React from "react";
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import "./BOneChatbot.css";
import config from "./ChatbotConfig/config";
import ActionProvider from "./ChatbotConfig/actionProvider";
import MessageParser from "./ChatbotConfig/messageParser";
import closeIcon from "../../assets/images/cross-icon-final.svg";
export const BOneChatbot = (props) => {
  return (
    <div className="b1c-chatbot-container">
      {props.closeIcon && (
        <div style={{ position: "relative" }}>
          <img
            src={closeIcon}
            className="b1c-chatbot-close-icon"
            onClick={props.onClickCloseIcon}
            alt="closeicon"
          />
        </div>
      )}
      <Chatbot
        config={config}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
      />
    </div>
  );
};
