import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "../../../../constants/api";
import getAccessToken from "../../../../utils/getAccessToken";
import axios from "axios";
import Loader from "../../../Loader/Loader";
import FormGeneratorStore from "../../../FormGenearatorAndStepper/FormGenearatorAndStepper";
import { hospitalTypes } from "../../../../constants/formconfig";
import Breadcrumb from "../../../Breadcrumb/Breadcrumb";

export const HospitalOnboarding = (_props) => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const onSubmit = async (formData) => {
    setLoader(true);
    const keys = Object.keys(formData);
    const data = new FormData();
    keys.forEach((key) => {
      if (key === "Contact_Number") {
        const number = formData[key].value.phone.slice(
          formData[key].value.dialCode.length
        );
        data.append(key, `+${formData[key].value.dialCode}${number}`);
      } else if (key === "Location") {
        const value = formData[key].value.label;
        data.append(key, value);
      } else {
        data.append(key, formData[key].value);
      }
    });

    console.log("Data is : ", data);

    const url = `${API}/hospital/register`;
    try {
      const token = getAccessToken();
      const result = await axios.post(url, data, {
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(result);
      navigate("/agent/hospital-management");
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };

  return (
    <div className="field-wrapper">
      {loader && <Loader />}
      <Breadcrumb
        breadcrumbs={[
          { label: "Hospital Management", url: "/agent/hospital-management" },
          {
            label: "Add Hospital",
            url: "/agent/hospital-management/add-hospital",
          },
        ]}
        divider={"/"}
        styles={{
          paddingBottom: "2rem",
          // paddingTop: "1rem",
        }}
      />
      <FormGeneratorStore
        classnames={"mx-auto"}
        agentTypes={hospitalTypes}
        submitHandler={onSubmit}
      />
    </div>
  );
};
