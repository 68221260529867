import React, { useState, useEffect, useCallback } from "react";
import FormGenerator from "../FormGenerator/FormGenerator";
import "./DashboardFormGenerator.css";
import Stepper from "../Stepper/Stepper";
import TreatmentComponent from "../TreatmentComponent/TreatmentComponent";
import axios from "axios";
import HospitalImages from "../HospitalImages/HospitalImages";
import TableComponent from "../TableComponent/TableComponent";
import { useNavigate } from "react-router-dom";
import OverviewComponent from "../OverviewComponent/OverviewComponent";
import { API } from "../../constants/api";
import hospitalLogo from "../../../src/assets/images/hospitalLogo.svg";
import { useContext } from "react";
import { AppointmentContext } from "../../context/AppointmentContext";

import { ref, uploadBytes } from "firebase/storage";
import { storage } from "../../config/testFirebaseConfig";
import { uploadAndReturnUrls } from "../../constants/UploadeImageUrl";

const DashboardFormGenerator = ({
  agentTypes,
  submitHandler,
  facilityData,
  setLoader,
  hospitalData,
}) => {
  const [formData, setFormData] = useState({});
  const [arrayIndex, setArrayIndex] = useState(0);
  const [disabled, setDisabled] = useState({});
  const [tableData, setTableData] = useState([]);
  const [specialities, setSpecialities] = useState({});
  const { appointmentId, appointmentDate } = useContext(AppointmentContext);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // console.log(specialities);

  console.log(
    "disabled state:::::::::",
    disabled,
    ":::::::::",
    disabled?.[arrayIndex]
  );

  //abhishek and ayush

  const [section, setSection] = useState([]);

  // Prathmesh
  const [images, setImages] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [logoFile, setLogoFile] = useState(null);

  useEffect(() => {
    if (hospitalData) {
      setImages(hospitalData.Hospital_images);
      setImageFiles((el) => [...el, ...hospitalData.Hospital_images]);
    }
  }, [hospitalData]);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      specialities === null ||
      specialities === undefined ||
      specialities == {}
    )
      return;
    const keys = Object.keys(specialities);
    const tempSection = keys.map((speciality) => {
      const doctorObj = specialities[speciality].doctors;
      const doctorKeys = Object.keys(doctorObj);
      const tempSelectedDoctors = [];
      doctorKeys.forEach((id) => {
        if (id === "description") return;
        const tempObj = {
          id: id,
          email: doctorObj[id].email,
          experience: doctorObj[id].experience,
          image: doctorObj[id].image,
          name: doctorObj[id].name,
          qualification: doctorObj[id].qualification,
        };
        tempSelectedDoctors.push(tempObj);
      });
      const tempSectionObj = {
        speciality: speciality,
        description: specialities[speciality].doctors.description,
        selectedDoctors: tempSelectedDoctors,
      };
      return tempSectionObj;
    });
    setSelectedArray(() => {
      return keys.map((value) => ({
        value: value,
        label: value,
      }));
    });
    setSection(tempSection);
    setFormData((prev) => {
      return {
        ...prev,
        treatmentOffers: {
          value: keys,
          isTouched: false,
        },
      };
    });
  }, [specialities]);

  // console.log('Images :::::::::', imageFiles)

  //multiselect
  const [optionData, setOptionData] = useState([]);
  const [selectedArray, setSelectedArray] = useState([]);
  const [toRun, setToRun] = useState(false);

  //formData initilization if fcaility found
  const searchParams = new URLSearchParams(window.location.search);
  const hospitalId = searchParams.get("q");

  //facility loade
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API}/agenthospital/getfacility/${hospitalId}`
        );

        if (response) {
          const { data } = response;

          // Update the state with the retrieved data
          setFormData({
            specialityTypes: {
              value: data.facilityOverview.specialityTypes || "",
              isTouched: true,
            },

            numPatientRooms: {
              value: data.facilityOverview.numPatientRooms || "",
              isTouched: true,
            },
            numOperationTheater: {
              value: data.facilityOverview.numOperationTheaters || "",
              isTouched: true,
            },
            // Add other form fields as needed
          });

          setSpecialities(data.facilityOverview.specialties);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Fetch data when the component mounts
    fetchData();
  }, [hospitalId]);

  const handleFileChange = (e) => {
    const fileList = e.target.files;
    // console.log("FileList", fileList);
    setImageFiles((el) => [...el, ...fileList]);
    const fileArray = Array.from(fileList)
      .slice(0, 5)
      .map((file) => URL.createObjectURL(file));
    setImages((el) => [...el, fileArray]);
  };

  //logoHospital
  const handleLogoHospital = (e) => {
    const file = e.target.files[0];
    setLogoFile(file);
  };

  //image submit
  const imageSubmit = async (e) => {
    e.preventDefault();

    //================================ backend code =============================>

    // const photoData = new FormData();
    // imageFiles.slice(0, 8).forEach((ele) => {
    //   photoData.append("file", ele);
    // });

    // //for logo file
    // const logoData = new FormData();
    // if (logoFile) {
    //   logoData.append("logo", logoFile);
    // } else {
    //   // If no photo is selected, add default SVG file to FormData
    //   const svgContent = await fetch(hospitalLogo).then((response) =>
    //     response.text()
    //   );
    //   const blob = new Blob([svgContent], { type: "image/svg+xml" });
    //   logoData.append("logo", blob, "svgImage.svg");

    //   // logoData.append('image', hospitalLogo)
    //   // logoData.append('file', blob, 'defaultImage.svg');
    //   console.log("LogoData::::::::::::::", logoData);
    // }
    // try {
    //   // Call the deleteSingleDcotortController API endpoint
    //   const response = await axios.post(
    //     `${API}/agenthospital/upload/${hospitalId}`,
    //     photoData
    //   );
    //   const responseLogo = await axios.post(
    //     `${API}/agenthospital/hospitallogo/${hospitalId}`,
    //     logoData
    //   );

    //   console.log("Response:", responseLogo.data);
    //   setHospitalLogo(responseLogo.data.imgUrl);

    //   if (response && responseLogo) {
    //     console.log(
    //       "Hospital and Logo photo successfully:",
    //       response.data.existingDoctor
    //     );
    //     // alert("Doctor photo successfully!");
    //     moveToNext(e);
    //     // navigate('/home')
    //   } else {
    //     console.error("Error uploding photo of hospital:", response.data.error);
    //     alert("Failed to uploade doctor photo . Please try again later.");
    //   }
    // }
    // catch (error) {
    //   // Handle network errors or any other errors that occurred during the request
    //   console.error("Request error:", error);
    //   alert(
    //     "Failed to uploade hospital photo. Please check your network connection."
    //   );
    // }

    //================================ backend code end =============================>

    setLoader(true);

    try {
      const hospitalImageRef = ref(storage, `onboard_hospital/${hospitalId}`);
      const hospitalLogoRef = ref(
        storage,
        `onboard_hospital/${hospitalId}/Hospital_Logo`
      );

      let uploadedHospitalUrls = await uploadAndReturnUrls(
        imageFiles,
        hospitalImageRef,
        8,
        "hospital"
      );

      let uploadedLogoUrls;
      if (logoFile) {
        // const logoRef = ref(hospitalImageRef, 'logo.svg');
        uploadedLogoUrls = await uploadAndReturnUrls(
          [logoFile],
          hospitalLogoRef
        );
      } else {
        const svgContent = await fetch(hospitalLogo).then((response) =>
          response.text()
        );
        const blob = new Blob([svgContent], { type: "image/svg+xml" });
        // const defaultLogoRef = ref(hospitalImageRef, 'defaultImage.svg');
        uploadedLogoUrls = await uploadAndReturnUrls([blob], hospitalLogoRef);
      }

      console.log(
        "Files uploaded to Firebase Storage successfully",
        uploadedHospitalUrls,
        uploadedLogoUrls
      );

      const response = await axios.post(
        `${API}/agenthospital/upload/${hospitalId}`,
        uploadedHospitalUrls
      );
      const responseLogo = await axios.post(
        `${API}/agenthospital/hospitallogo/${hospitalId}`,
        uploadedLogoUrls
      );

      if (response && responseLogo) {
        setLoader(false);
        moveToNext(e);
      }

      // alert('Files uploaded to Firebase Storage successfully')
    } catch (error) {
      console.error("Error uploading files to Firebase:", error);
      alert("Error Files uploaded to Firebase Storage");
      // Handle the error
    }
  };

  // console.log('ImagwFiles::::', imageFiles);

  useEffect(() => {
    setSection((prev) => {
      const newArr = [];
      selectedArray.forEach((item) => {
        const ind = prev.findIndex((ele) => ele.speciality === item.value);
        if (ind == -1) {
          const payload = {
            selectedDoctors: [],
            description: item.label,
            speciality: item.value,
          };
          newArr.push(payload);
        } else {
          newArr.push(prev[ind]);
        }
      });
      return newArr;
    });
  }, [selectedArray]);

  const finishHome = async (event) => {
    event.preventDefault();
    setLoader(true);
    if (appointmentId !== "") {
      // console.log(appointmentId, appointmentDate);
      const speciality = section.map((ele) => {
        return ele.speciality;
      });
      const payload = {
        status: "Completed",
        statusDate: appointmentDate,
        totalStaff: tableData.length,
        speciality: speciality,
        hospitalLogo: hospitalLogo,
      };
      try {
        await axios.patch(
          `${API}/appointment/updateappointmentstatus/${appointmentId}`,
          payload
        );
        // navigate("/agent/dashboard");

        // setEditAppointmentsModelOpen(null);
      } catch (error) {
        console.log(error);
      }
    }
    setLoader(false);
    navigate("/agent/dashboard");
  };

  const optionArray = tableData.map((ele) => {
    return { label: ele?.name, id: ele?.id };
  });

  const handleSubmit = (value) => {
    const tempSelectedArray = [];
    if (
      selectedArray.findIndex(
        (ele1) => ele1.speciality === value.speciality
      ) === -1
    ) {
      setSelectedArray((ele) => {
        return [...ele, { value: value.speciality, label: value.speciality }];
      });
    }
    setSection((ele) => {
      if (
        ele.findIndex((ele1) => ele1.speciality === value.speciality) === -1
      ) {
        return [...ele, value];
      } else {
        const index = ele.findIndex(
          (ele1) => ele1.speciality === value.speciality
        );
        const newDoctorArray = [
          ...value.selectedDoctors,
          ...ele[index].selectedDoctors,
        ];
        const jsonObject = newDoctorArray.map(JSON.stringify);
        const uniqueSet = new Set(jsonObject);
        const uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        const payload = {
          speciality: value.speciality,
          description: value.description,
          selectedDoctors: uniqueArray,
        };

        const temp = ele.map((item, ind) => {
          if (ind === index) {
            tempSelectedArray.push({
              value: payload.speciality,
              label: payload.speciality,
            });

            return payload;
          }
          tempSelectedArray.push({
            value: item.speciality,
            label: item.speciality,
          });
          return item;
        });
        setSelectedArray(tempSelectedArray);
        return temp;
      }
    });
  };

  const specilityHandleChange = (speciality, description, input) => {
    setSection((prev) => {
      const filteredDoctor = tableData.filter(
        (ele) => input.findIndex((ele1) => ele.id === ele1.id) !== -1
      );
      const index = prev.findIndex((ele) => ele.speciality === speciality);

      const payload = {
        speciality: speciality,
        description: description,
        selectedDoctors: filteredDoctor,
      };
      // const filteredSection = prev.filter(
      //   (ele) => ele.speciality !== speciality
      // );

      return prev.map((item, ind) => {
        if (ind === index) {
          return payload;
        }
        return item;
      });
    });
  };

  // console.log(section);

  const setDisabledArray = useCallback(() => {
    const tempObj = {};
    agentTypes.forEach((ele, ind) => {
      if (ind === 1) {
        tempObj[ind] = false;
      } else {
        tempObj[ind] = true;
      }
    });
    setDisabled(tempObj);
  }, [agentTypes]);

  const setEmptyFormData = useCallback(() => {
    let tempFormData = {};
    agentTypes.forEach((ele) => {
      ele.fields.forEach((temp) =>
        temp.forEach((item) => {
          tempFormData[item.title] = {
            value: item.intitalValue,
            isTouched: false,
          };
        })
      );
    });
    setFormData(tempFormData);
  }, [agentTypes]);

  //new isFormValid
  const isFormValid = useCallback(
    (tempObj, tempInd = arrayIndex) => {
      let flag = true;
      agentTypes[tempInd].fields.forEach((ele) => {
        ele.forEach((item) => {
          const temp = item.validation(tempObj?.[item.title]?.value);
          if (temp === undefined || temp === false) flag = false;
        });
      });
      return flag;
    },
    [arrayIndex, agentTypes]
  );

  const moveToNext = (event) => {
    event.preventDefault();

    const flag = isFormValid(formData);
    // console.log("flag of movetonext", flag);
    // console.log("iam index", arrayIndex);
    if (arrayIndex === 0) {
      specialitySubmit();
      // setSection((prev) => {
      //   const newArr = [...prev];
      //   const newArray = selectedArray.map((item) => {
      //     const payload = {
      //       selectedDoctors: [],
      //       description: item.label,
      //       speciality: item.value,
      //     };
      //     return payload;
      //   });
      //   return [...prev, ...newArray];
      // });
    }

    setArrayIndex((ele) => {
      if (flag) {
        if (ele < agentTypes.length - 1) {
          return ele + 1;
        } else {
          return ele;
        }
      } else {
        return ele;
      }
    });
  };

  const moveToPrev = (event) => {
    event.preventDefault();
    setArrayIndex((ele) => {
      if (ele > 0) {
        return ele - 1;
      } else {
        return ele;
      }
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const flag = isFormValid(formData);
    if (flag) {
      submitHandler(formData, specialities);
      setArrayIndex(0);
      reset();
    }
  };

  const specialitySubmit = () => {
    // console.log("HEllo Speciality");
    const flag = isFormValid(formData);
    if (flag) {
      submitHandler(formData, specialities);
    }
  };

  useEffect(() => {
    setEmptyFormData();
    setDisabledArray();
  }, [setEmptyFormData, setDisabledArray]);

  const handleChange = (field, newValue) => {
    setFormData((obj) => {
      let tempObj = { ...obj };
      const tempField = { ...tempObj[field], value: newValue };
      tempObj[field] = tempField;
      return tempObj;
    });
    const tempObj = Object.create(formData);
    const tempField = { ...tempObj[field], value: newValue };
    tempObj[field] = tempField;

    // const isValid = isFormValid(tempObj);
    // console.log('isValid::', isValid);
    // if (disabled[agentTypes[arrayIndex.section]] !== !isValid) {
    //   setDisabled((obj) => {
    //     const tempObj = Object.create(obj);
    //     tempObj[arrayIndex] = !isValid;
    //     return tempObj;
    //   });
    // }
  };

  //button disable logic
  useEffect(() => {
    const isFormFilled = (formData) => {
      const requiredFields = [
        "specialityTypes",
        "numPatientRooms",
        "numOperationTheater",
        "treatmentOffers",
      ];

      for (const field in formData) {
        if (requiredFields.includes(field)) {
          const { value } = formData[field];
          if (
            value === null ||
            value === undefined ||
            (Array.isArray(value) && value.length === 0) ||
            value === ""
          ) {
            return true; // If any required field is empty, return true
          }
        }
      }
      return false; // All required fields have values, return false
    };

    let valueDiasable = isFormFilled(formData);
    setIsButtonDisabled(valueDiasable);
    console.log("calllllleddddddddddd", isButtonDisabled);
  }, [formData]);

  console.log("form ka data::::::", formData);

  const handleTouch = (field) => {
    setFormData((obj) => {
      const tempObj = { ...obj };
      tempObj[field] = { ...tempObj[field], isTouched: true };
      return tempObj;
    });
  };

  const reset = () => {
    // console.log(formData);
    setEmptyFormData();
    setDisabledArray();
  };

  const stepperClickHandler = (value) => {
    // const tempFormData = { ...formData };
    // agentTypes[arrayIndex].fields.forEach((ele) => {
    //   ele.forEach((item) => {
    //     if (item.type === "table") {
    //       // For table fields, set a default valid value
    //       tempFormData[item.title] = { value: [], isTouched: true };
    //     }
    //   });
    // });

    // if (!isFormValid(formData)) return;
    // const ind = agentTypes.findIndex((val) => {
    //   return val.stepperHeader === value;
    // });
    // if (ind < arrayIndex) setArrayIndex(ind);
    // else {
    //   let flag = true;
    //   for (let i = arrayIndex; i < ind; i++) {
    //     if (!isFormValid(formData, i)) {
    //       flag = false;
    //     }
    //   }
    //   if (flag) setArrayIndex(ind);
    // }
    return;
  };

  const temp = agentTypes.map((ele) => ele.stepperHeader);
  const stepper = [...new Set(temp)];

  return (
    <div className="field-box field-main ">
      <Stepper
        steps={stepper}
        section={agentTypes?.[arrayIndex]?.stepperHeader}
        stepperClickHandler={stepperClickHandler}
      />
      <form>
        {agentTypes.map((ele, ind) => {
          if (arrayIndex === ind) {
            if (
              ele.section !== "Treatment" &&
              ele.section !== "Uploads" &&
              ele.section !== "Doctors Information" &&
              ele.section !== "Summary"
            ) {
              return (
                <FormGenerator
                  key={arrayIndex}
                  array={agentTypes[arrayIndex]}
                  handleChange={handleChange}
                  formData={formData}
                  setTableData={setTableData}
                  tableData={tableData}
                  handleTouch={handleTouch}
                  reset={reset}
                  arrayIndex={arrayIndex}
                  moveToPrev={moveToPrev}
                  moveToNext={moveToNext}
                  onSubmit={onSubmit}
                  optionData={optionData}
                  setOptionData={setOptionData}
                  selectedArray={selectedArray}
                  setSelectedArray={setSelectedArray}
                  setToRun={setToRun}
                  toRun={toRun}
                  // disabled={disabled?.[arrayIndex]}
                  disabled={isButtonDisabled}
                  isLast={agentTypes.length - 1 === arrayIndex}
                />
              );
            }

            if (ele.section === "Treatment") {
              return (
                <TreatmentComponent
                  section={section}
                  handleChange={specilityHandleChange}
                  handleSubmit={handleSubmit}
                  doctors={tableData}
                  // tableData={tableData}
                  optionArray={optionArray}
                  moveToPrev={moveToPrev}
                  moveToNext={moveToNext}
                  setLoader={setLoader}
                />
              );
            } else if (ele.section === "Uploads") {
              return (
                <HospitalImages
                  images={images}
                  moveToPrev={moveToPrev}
                  moveToNext={moveToNext}
                  handleFileChange={handleFileChange}
                  imageSubmit={imageSubmit}
                  handleLogoHospital={handleLogoHospital}
                  logoFile={logoFile}
                />
              );
            } else if (ele.section === "Doctors Information") {
              return (
                <TableComponent
                  setTableData={setTableData}
                  tableData={tableData}
                  moveToPrev={moveToPrev}
                  moveToNext={moveToNext}
                  setLoader={setLoader}
                />
              );
            } else if (ele.section === "Summary") {
              return (
                <OverviewComponent
                  facilityData={facilityData}
                  tableData={tableData}
                  section={section}
                  images={images}
                  moveToPrev={moveToPrev}
                  finishHome={finishHome}
                />
              );
            }
          } else {
            return null;
          }
        })}
      </form>
      {/* <p>{JSON.stringify(formData)}</p> */}
    </div>
  );
};

export default DashboardFormGenerator;
