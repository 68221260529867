import { format } from "date-fns";

const chips = [
  { label: "Booked", color: "#A9A9A9" }, // dark gray
  { label: "Cancelled", color: "#FF6961" }, // red
  { label: "Payment Initiated", color: "#0000FF" }, // blue
  { label: "Payment Processing", color: "#FFFF00" }, // yellow
  { label: "Payment Completed", color: "#008000" }, // green
];

const getBorderColor = (label) => {
  const chip = chips.find((chip) => chip.label === label);
  return chip ? chip.color : "cyan";
};

export const BookingsTableConfig = [
  {
    columnName: "Hospital Name",
    value: "hospitalName",
    style: { width: "200px", marginLeft: "20px", marginRight: "max(10px,2vw)" },
    render: (data) => {
      return (
        <div
          style={{
            width: "200px",
            marginLeft: "20px",
            marginRight: "max(10px,2vw)",
          }}
        >
          {data.hospitalName}
        </div>
      );
    },
  },
  { columnName: "Patient Name", value: "patientName" },
  {
    columnName: "Booking Date",
    value: "date",
    style: { width: "100px", marginLeft: "20px", marginRight: "max(10px,2vw)" },
    render: (data) => {
      return (
        <div
          style={{
            width: "100px",
            marginLeft: "20px",
            marginRight: "max(10px,2vw)",
          }}
        >
          {format(new Date(data.date), "dd-MM-yyyy hh:mm a")}
        </div>
      );
    },
  },
  {
    columnName: "Status",
    value: "status",
    style: { width: "150px", marginLeft: "20px", marginRight: "max(10px,2vw)" },
    render: (data) => {
      const borderColor = getBorderColor(data.status);
      return (
        <div
          style={{
            width: "150px",
            marginLeft: "20px",
            marginRight: "max(10px,2vw)",
          }}
        >
          <p
            style={{
              border: `2px solid ${borderColor}`,
              borderRadius: "100px",
              padding: "2px 10px",
              fontSize: "12px",
              fontWeight: "bold",
              textAlign: "left",
              width: "fit-content",
            }}
          >
            {data.status}
          </p>
        </div>
      );
    },
  },
  {
    columnName: "Treatment Type",
    value: "treatmentType",
    style: { width: "200px", marginLeft: "20px", marginRight: "max(10px,2vw)" },
    render: (data) => {
      return (
        <div
          style={{
            width: "200px",
            marginLeft: "20px",
            marginRight: "max(10px,2vw)",
          }}
        >
          {data.treatmentType}
        </div>
      );
    },
  },
  { columnName: "Total Amount", value: "totalAmount" },
];
