import React from "react";
import "./AppointmentFilterModel.css";
import Chip from "@mui/material/Chip";
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import { FormControl } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FilterMultiSelect from "../InputTypes/FilterMultiSelect/FilterMultiSelect";
import { indianCities } from "../../assets/custom-data/indianCities";

const AppointmentFilterModel = (props) => {
  return (
    <div className="popup-body">
      <div className="hospital-status filter-section">
        <p>Hospital's status</p>
        <div className="status-chip-container">
          <Chip
            sx={{
              borderColor: props.selectedFilters.status.includes("Completed")
                ? "transparent"
                : "#787785",
              color: props.selectedFilters.status.includes("Completed")
                ? "white"
                : "black",
              backgroundColor: props.selectedFilters.status.includes(
                "Completed"
              )
                ? "var(--primaryColor)"
                : "transparent",
              "&:hover": {
                backgroundColor: "var(--primaryColor)",
                color: "black",
              },
            }}
            label="Completed"
            variant="outlined"
            onClick={() => props.toggleChip("Completed", "status")}
          />
          <Chip
            sx={{
              borderColor: props.selectedFilters.status.includes("Scheduled")
                ? "transparent"
                : "#787785",
              color: props.selectedFilters.status.includes("Scheduled")
                ? "white"
                : "black",
              backgroundColor: props.selectedFilters.status.includes(
                "Scheduled"
              )
                ? "var(--primaryColor)"
                : "transparent",
              "&:hover": {
                backgroundColor: "var(--primaryColor)",
                color: "black",
              },
            }}
            label="Scheduled"
            variant="outlined"
            onClick={() => props.toggleChip("Scheduled", "status")}
          />
        </div>
      </div>
      <div className="speciality-type filter-section">
        <p>Speciality type</p>
        <div className="status-chip-container">
          <Chip
            sx={{
              borderColor: props.selectedFilters.speciality.includes("Super")
                ? "transparent"
                : "#787785",
              color: props.selectedFilters.speciality.includes("Super")
                ? "white"
                : "black",
              backgroundColor: props.selectedFilters.speciality.includes(
                "Super"
              )
                ? "var(--primaryColor)"
                : "transparent",
              "&:hover": {
                backgroundColor: "var(--primaryColor)",
                color: "black",
              },
            }}
            label="Super"
            variant="outlined"
            onClick={() => props.toggleChip("Super", "speciality")}
          />
          <Chip
            sx={{
              borderColor: props.selectedFilters.speciality.includes("Multi")
                ? "transparent"
                : "#787785",
              color: props.selectedFilters.speciality.includes("Multi")
                ? "white"
                : "black",
              backgroundColor: props.selectedFilters.speciality.includes(
                "Multi"
              )
                ? "var(--primaryColor)"
                : "transparent",
              "&:hover": {
                backgroundColor: "var(--primaryColor)",
                color: "black",
              },
            }}
            label="Multi"
            variant="outlined"
            onClick={() => props.toggleChip("Multi", "speciality")}
          />
          <Chip
            sx={{
              borderColor: props.selectedFilters.speciality.includes("Single")
                ? "transparent"
                : "#787785",
              color: props.selectedFilters.speciality.includes("Single")
                ? "white"
                : "black",
              backgroundColor: props.selectedFilters.speciality.includes(
                "Single"
              )
                ? "var(--primaryColor)"
                : "transparent",
              "&:hover": {
                backgroundColor: "var(--primaryColor)",
                color: "black",
              },
            }}
            label="Single"
            variant="outlined"
            onClick={() => props.toggleChip("Single", "speciality")}
          />
        </div>
      </div>
      <div className="total-staff filter-section">
        <p>Total staff</p>
        <div className="total-staff-checkbox-container">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.checkboxes.lessThan500}
                  onChange={() => props.handleCheckboxChange("lessThan500")}
                  sx={{
                    color: "#787785",
                    [`&.${checkboxClasses.checked}`]: {
                      color: "var(--primaryColor)",
                    },
                  }}
                  size="small"
                  onClick={() => props.toggleChip("0-500", "totalStaff")}
                />
              }
              label="less than 500"
              sx={{ color: "black" }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.checkboxes.between500And1000}
                  onChange={() =>
                    props.handleCheckboxChange("between500And1000")
                  }
                  sx={{
                    color: "#787785",
                    [`&.${checkboxClasses.checked}`]: {
                      color: "var(--primaryColor)",
                    },
                  }}
                  size="small"
                  onClick={() => props.toggleChip("500-1000", "totalStaff")}
                />
              }
              label="500-1000"
              sx={{ color: "black" }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.checkboxes.between1000And2000}
                  onChange={() =>
                    props.handleCheckboxChange("between1000And2000")
                  }
                  sx={{
                    color: "#787785",
                    [`&.${checkboxClasses.checked}`]: {
                      color: "var(--primaryColor)",
                    },
                  }}
                  size="small"
                  onClick={() => props.toggleChip("1000-2000", "totalStaff")}
                />
              }
              label="1000-2000"
              sx={{ color: "black" }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.checkboxes.above2000}
                  onChange={() => props.handleCheckboxChange("above2000")}
                  sx={{
                    color: "#787785",
                    [`&.${checkboxClasses.checked}`]: {
                      color: "var(--primaryColor)",
                    },
                  }}
                  size="small"
                  onClick={() => props.toggleChip("2000-max", "totalStaff")}
                />
              }
              label="above 2000"
              sx={{
                color: "black",
              }}
            />
          </FormGroup>
        </div>
      </div>
      <div className="location filter-section">
        <p>Location</p>
        <FilterMultiSelect
          onSelect={props.toggleChip}
          value = {props.selectedFilters.location}
          selectedFilters={props.selectedFilters}
          options = {indianCities}
          category = "location"
        />
      </div>
    </div>
  );
};

export default AppointmentFilterModel;
