import React, { useEffect } from "react";
// import PinInputComponent from "../../../components/PinInputComponent/PinInputComponent";
import CreateAccountButton from "../../../components/CreateAccountButton/CreateAccountButton";
import img from "../../../assets/images/email-box.png";
import "./EmailLinkVerificationPage.css";
import { auth } from "../../../config/testFirebaseConfig";
import { useLoginContext } from "../../../context/LoginContext";

const EmailLinkVerificationPage = (props) => {
  const { currentComponent, CurrentComponentHandler } = useLoginContext();
  useEffect(() => {
    const checkAuthInterval = setInterval(() => {
      const user = auth.currentUser;

      if (user) {
        user.reload().then(() => {
          const isEmailVerified = user.emailVerified;
          if (isEmailVerified) {
            // props.setCurrentComponent("partnerSelection");
            CurrentComponentHandler("partnerSelection");
          }
        });
      }
    }, 1000);

    return () => clearInterval(checkAuthInterval);
  }, []);
  useEffect(() => {
    let intervalId;

    if (props.resendTimer > 0) {
      intervalId = setInterval(() => {
        props.setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [props.resendTimer]);

  return (
    <div className="verification-page-form">
      <img src={img} alt="email-box" className="verification-page-form-img" />
      <h2 className="verification-page-form-header">Check Your Inbox!</h2>
      <div className="verification-page-form-content">
        <div className="verification-page-form-content-description">
          We've sent you and easy link to
          <span className="verification-page-form-content-description-bold">
            {props.user.email}
          </span>
        </div>
        <p className="verification-page-form-content-description margin-bottom">
          Please click the link to confirm your address
        </p>
        <CreateAccountButton
          handleSubmit={props.onClick}
          // text="Open Gmail"
          text={
            props.resendTimer === 0
              ? "Resend Email"
              : `Resend Email in ${props.resendTimer}s`
          }
          buttonType="google"
          icon="HiOutlineMail"
        />
        <p className="verification-page-form-content-unreceived-description margin-top">
          Can't see the email? Please check the spam folder
        </p>
        <p className="verification-page-form-content-unreceived-description">
          Wrong Email?
          <span
            className="verification-page-form-content-unreceived-description resend-bold-underline margin-left"
            onClick={props.deleteCurrentUser}
          >
            Please re-enter your address
          </span>
        </p>
      </div>
    </div>
  );
};

export default EmailLinkVerificationPage;
