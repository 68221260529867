import React from "react";
import "./SecondaryButton.css";
const SecondaryButton = ({ text, fontSize, buttonType, openContact }) => {
  return (
    <div>
      <button
        type={buttonType && buttonType}
        className="portfolio-secondary-button"
        style={{ fontSize: fontSize && fontSize }}
        onClick={openContact}
      >
        {text}
      </button>
    </div>
  );
};

export default SecondaryButton;
