import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer.jsx";
import ContactUsSidebar from "../../components/ContactUsSidebar/ContactUsSidebar.jsx";
import LandingPageNavbar from "../../components/LandingPageNavbar/LandingPageNavbar.jsx";
import { LandingPagetranslation } from "../../constants/Translation.js";

const ErrorPage = () => {
  const [contactUsSidebarToggle, setContactUsSidebarToggle] = useState(false);
  const [content, setContent] = useState({});
  const [language, setLanguage] = useState("English");

  useEffect(() => {
    if (language === "English") {
      setContent(LandingPagetranslation.en);
    } else if (language === "Hindi") {
      setContent(LandingPagetranslation.hi);
    } else if (language === "Arabic") {
      setContent(LandingPagetranslation.ar);
    }
  }, [language]);

  const openContactUsSidebarHandler = () => {
    setContactUsSidebarToggle(true);
  };
  const closeContactUsSidebarHandler = () => {
    document.getElementById("portfolio-contact-us-sidebar").style.transform =
      "translateX(100%)";
    setTimeout(() => setContactUsSidebarToggle(false), 500);
  };
  return (
    <div>
      <LandingPageNavbar
        openContact={openContactUsSidebarHandler}
        setLanguage={setLanguage}
        language={language}
      />
      <div
        style={{
          minHeight: "70vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "2rem",
          textAlign: "center",
        }}
      >
        <h2
          style={{
            textAlign: "center",
            fontWeight: "700",
            color: "#ef5ea2",
            fontSize: "6rem",
          }}
        >
          404
        </h2>
        <h2
          style={{
            textAlign: "center",
            color: "#2f2f2f",
            fontWeight: "600",
            fontSize: "3rem",
          }}
        >
          PAGE NOT FOUND
        </h2>
        <p style={{ textAlign: "center", fontSize: "1.5rem" }}>
          Sorry, the page you're looking for doesn't exist.
        </p>
      </div>
      <Footer language={language} />
      {contactUsSidebarToggle && (
        <ContactUsSidebar closeHandler={closeContactUsSidebarHandler} />
      )}
    </div>
  );
};

export default ErrorPage;
