import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SectionHeading from "../../../../components/ProcedurePageComponents/SectionHeading/SectionHeading";
import RecommendedHospitalCard from "../../../../components/ProcedurePageComponents/RecommendedHospitalCard/RecommendedHospitalCard";
import "./SimilarHospitals.css";
import { API } from "../../../../constants/api";
import axios from "axios";
import toast from "react-hot-toast";
const SimilarHospitals = ({ hospitalId, city, country }) => {
  const [similarHospitals, setSimilarHospitals] = useState([]);
  const getSimilarHospitals = async () => {
    try {
      const url = `${API}/hospital/similar/hospitals?city=${city}&hospitalId=${hospitalId}`;
      const {
        data: { hospitals },
      } = await axios.get(url);
      setSimilarHospitals(hospitals.slice(0, 3));
    } catch (err) {
      let msg;
      if (err?.response) msg = err.response.data.message;
      else msg = err.message;
      toast.error(msg);
    }
  };
  useEffect(() => {
    if (city) {
      getSimilarHospitals();
    }
  }, [city, hospitalId, country]);
  return (
    <div
      className="similar-hospitals-container progress-section"
      id="hospital-details-similar-hospitals"
    >
      <div className="heading-with-view-more-button">
        <SectionHeading text={"View Similar Hospitals"} textAlign={"left"} />
        <Link
          to={`/similar/hospitals?city=${city}&hospitalId=${hospitalId}`}
          className="view-more-button"
        >
          View more
        </Link>
      </div>
      <p className="similar-hospitals-location">
        {city} , {country}
      </p>
      <div className="similar-hospitals">
        {similarHospitals &&
          similarHospitals.map((hospital) => {
            return (
              <RecommendedHospitalCard
                hospitalName={hospital.Name_Of_Hospital}
                hospitalLocation={hospital.Location}
                specialilty={hospital.facility_overview.specialityTypes}
                facilities={hospital.facility_overview.specialties}
                hospital_uid={hospital.uid}
                hospitalPhoto={
                  hospital.Hospital_images &&
                  hospital.Hospital_images.length > 0
                    ? hospital.Hospital_images
                    : null
                }
              />
            );
          })}
      </div>
    </div>
  );
};

export default SimilarHospitals;
