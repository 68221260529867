import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import DashboardLayout from "../../pages/DashboardSidebar/DashboardLayout";
import { sidebarFacilitatorItems } from "../../constants/SidebarItems";
import { API } from "../../constants/api";
import Breadcrumb from "../Breadcrumb/Breadcrumb";

const ViewBooking = () => {
  const { id } = useParams();
  const [booking, setBooking] = useState(null);

  useEffect(() => {
    const fetchBooking = async () => {
      try {
        const { data } = await axios.get(`${API}/bookings/${id}`);
        setBooking(data);
      } catch (error) {
        console.log("Error fetching booking");
      }
    };

    fetchBooking();
  }, [id]);

  return (
    <DashboardLayout sidebarItems={sidebarFacilitatorItems}>
      <Breadcrumb
        breadcrumbs={[
          { label: "Bookings", url: "/facilitator/bookings" },
          {
            label: "View Booking",
            url: "/facilitator/bookings/view-booking",
            disabled: true,
          },
        ]}
        divider={"/"}
        styles={{
          paddingTop: "1rem",
        }}
      />
      {booking && (
        <div className='edit-patient-details-container'>
          <div className='edit-patient-details-detail-container'>
            <div className='hospital-box '>
              <div className='row m-0 mx-2 justify-content-center position-relative'>
                <div className='col d-flex justify-content-center align-items-center text-info-area me-md-5 m-0'>
                  <div className='row m-0 p-0'>
                    {Object.values(booking).map((item, index) => (
                      <div
                        className='col-md-auto m-1 d-flex flex-column align-self-center'
                        key={index}
                      >
                        <div className='bold-text d-flex justify-content-center justify-content-md-start'>
                          <p>{item}</p>
                        </div>
                        {/* <div className='mute-text d-flex justify-content-center justify-content-md-start'>
                          <p className='text-muted mb-1'>{item.description}</p>
                        </div> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};

export default ViewBooking;
