import React, { useEffect, useState } from "react";
import LandingPageNavbar from "../../components/LandingPageNavbar/LandingPageNavbar";
import axios from "axios";
import { API } from "../../constants/api";
import DoctorCard from "../../components/ProcedurePageComponents/DoctorCard/DoctorCard";
import Lottie from "lottie-react";
import coming_soon_lottie from "../../assets/lottie/coming-soon.json";
import Footer from "../../components/Footer/Footer";
const AllDoctors = () => {
  const [alldocotors, setAllDoctors] = useState([]);
  const queryParams = new URLSearchParams(window.location.search);
  const hospitalId = queryParams.get("hospitalId");
  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        let url = `${API}/agenthospital/getalldoctor`;
        if (hospitalId) {
          url = `${url}/${hospitalId}`;
        }
        const response = await axios.get(url);
        if (response.data.doctors && response.data.doctors.length > 0) {
          let doctorsData = response.data.doctors;
          doctorsData = doctorsData.filter((ele) => ele.type !== "test");
          setAllDoctors(doctorsData);
        } else {
          console.log("No  doctors found");
        }
      } catch (error) {
        console.error("Error fetching  doctors:", error);
      }
    };

    fetchDoctors();
  }, []);

  const chunkArray = (array, size) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  };

  let chunkedDoctors = chunkArray(alldocotors, 4);

  const initialDisplayCount = 2; // Number of hospitals to display initially
  const [displayCount, setDisplayCount] = useState(initialDisplayCount);
  chunkedDoctors = chunkedDoctors ? chunkedDoctors.slice(0, displayCount) : [];

  const handleLoadMore = () => {
    setDisplayCount(displayCount + 2);
  };

  return (
    <div className="alldocotos-container">
      <LandingPageNavbar />
      <div className="container-fluid">
        {chunkedDoctors?.map((chunk, index) => (
          <div key={index} className="row mb-3 ">
            {chunk.map((doctor) => (
              <div className="col-lg-3 col-6 d-flex justify-content-evenly">
                <DoctorCard
                  key={doctor?.id}
                  doctorId={doctor?.id}
                  hospitalId={doctor?.hospitalId}
                  doctorName={doctor.name || "Dr. Not Specified"}
                  expertIn={
                    doctor.specialization || "Specialization Not Provided"
                  }
                  education={doctor.qualification || "Education Not Specified"}
                  yearsOfExperience={doctor.experience}
                  doctorPhoto={
                    doctor.imageLinks && doctor.imageLinks.length > 0
                      ? doctor.imageLinks[0]
                      : null
                  }
                />
              </div>
            ))}
          </div>
        ))}
        <div className="button-loade-more d-flex justify-content-center">
          {alldocotors.length >= 8 ? (
            <button
              className="btn btn-lg btn-secondary mb-4"
              onClick={handleLoadMore}
            >
              Load More
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AllDoctors;
