import React from "react";
import "./TreatmentCard.css";
import TreatmentMultiSelect from "../InputTypes/TreatmentMultiSelect/TreatmentMultiSelect";

const TreatmentCard = (props) => {
  return (
    <div className="treatment-card-container">
      <div className="treatment-card-header margin-top">
        <div className="treatment-card-specializations">Specialization:</div>
        <div className="treatment-card-title">{props.item.speciality}</div>
      </div>
      <div className="treatment-card-header margin-bottom">
        <div className="treatment-card-specializations margin-top">
          Doctors:
        </div>
        <TreatmentMultiSelect
          options={props.options}
          item={props.item}
          handleChange={props.handleChange}
        />
      </div>
    </div>
  );
};

export default TreatmentCard;
