// export const agentTableConfig = [
//   {
//     columnName: "Patient Name",
//     value: "patientName",
//   },
//   {
//     columnName: "Hospital Name",
//     value: "hospitalName",
//   },
//   //   {
//   //     columnName: "Assigned To",
//   //     render: (item) => {
//   //       return (
//   //         <div style={{ width: "125px", marginRight: "max(3vw,30px)" }}>
//   //           <p
//   //             className="dashboard-table-row-chip-component"
//   //             style={{ width: "fit-content" }}
//   //           >
//   //             {item.agentName}
//   //           </p>
//   //         </div>
//   //       );
//   //     },
//   //   },
//   {
//     columnName: "Treatment Type",
//     value: "treatmentType",
//   },
//   {
//     columnName: "date Of Arrival",
//     type: "date",
//     value: "arrivalDate",
//   },
//   {
//     columnName: "status",
//     render: (item) => {
//       let colour = "rgb(152, 152, 7)";
//       if (item.status === "arrived") colour = "pink";
//       if (item.status === "Payment Recieved") colour = "green";
//       return (
//         <div
//           className="dashboard-table-row-description"
//           style={{ color: colour }}
//         >
//           {item.status}
//         </div>
//       );
//     },
//   },
// ];
// export const dummyagentTableData = [
//   {
//     patientName: "amit",
//     hospitalName: "Appolo Indraprasth hospital",
//     treatmentType: "cardiology",
//     arrivalDate: "2020-11-12",
//     status: "arrived",
//     agentName: "akash",
//   },
//   {
//     patientName: "akash",
//     hospitalName: "Mendatta hospital",
//     treatmentType: "cardiology",
//     arrivalDate: "2020-11-12",
//     status: "Payment Recieved",
//     agentName: "atul",
//   },
//   {
//     patientName: "mahesh",
//     hospitalName: "Appolo hospital",
//     treatmentType: "cardiology",
//     arrivalDate: "2020-11-12",
//     status: "booked",
//     agentName: "shreyansh",
//   },
// ];
import DashboardTableRowChip from "../components/DashboardTableRowChip/DashboardTableRowChip";
import DashboardTableNameColumn from "../components/DashboardTableNameColumn/DashboardTableNameColumn";
import Tooltip from "../components/Tooltip/Tooltip";

export const agentTableConfig = [
  {
    columnName: "Patient Name",
    value: "patientName",
  },
  {
    columnName: "Hospital Name",
    style: { width: "210px", marginLeft: "20px", marginRight: "max(10px,3vw)" },
    render: (data) => {
      return (
        <DashboardTableNameColumn
          title={data.hospitalName}
          subtitle={data.address}
          toShow={false}
        />
      );
    },
  },

  {
    columnName: "Treatment Type",
    style: { width: "250px" },
    render: (data) => {
      const treatment = data.treatments;

      return <DashboardTableRowChip chipsArray={treatment} />;
    },
  },
  {
    columnName: "Date Of Arrival",
    type: "date",
    value: "arrivalDate",
  },
  {
    columnName: "Status",
    render: (item) => {
      let colour = "rgb(152, 152, 7)";
      if (item.status === "arrived") colour = "#0000FF";
      if (item.status === "Payment Recieved") colour = "green";
      return (
        <Tooltip text={item.status}>
          <div
            className="dashboard-table-row-description"
            style={{ color: colour }}
          >
            {item.status}
          </div>
        </Tooltip>
      );
    },
  },
];

export const dummyagentTableData = [
  {
    patientName: "amit",
    hospitalName: "Appolo Indraprasth hospital a long long name",
    address: "mumbai",
    treatments: [
      {
        id: 1,
        value: "cardiology",
      },
      {
        id: 2,
        value: "surgery",
      },
      {
        id: 3,
        value: "Chemotherapy",
      },
      {
        value: "Blood transfusion",
        id: 4,
      },
    ],
    arrivalDate: "2020-11-12",
    status: "arrived",
    address: "mumbai",
    agentName: "akash",
  },
  {
    patientName: "akashasdbjjjjhasdddddddddddddddd",
    hospitalName: "Mendatta hospital",
    treatments: [
      {
        id: 1,
        value: "cardiology",
      },
      {
        id: 2,
        value: "surgery",
      },
      {
        id: 3,
        value: "Chemotherapy",
      },
      {
        value: "Blood transfusion",
        id: 4,
      },
    ],
    arrivalDate: "2020-11-12",
    status: "Payment Recieved",
    address: "mumbai",
    agentName: "atulasddddddddddddddddddddd",
  },
  {
    patientName: "mahesh",
    hospitalName: "Appolo hospital",
    address: "mumbai",
    treatments: [
      {
        id: 1,
        value: "cardiology",
      },
      {
        id: 2,
        value: "surgery",
      },
      {
        id: 3,
        value: "Chemotherapy",
      },
      {
        value: "Blood transfusion",
        id: 4,
      },
    ],
    arrivalDate: "2020-11-12",
    status: "booked",
    agentName: "shreyansh",
  },
];
