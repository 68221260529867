import React, { useEffect, useState } from "react";
import LandingPageNavbar from "../../components/LandingPageNavbar/LandingPageNavbar";
import axios from "axios";
import { API } from "../../constants/api";
import ProcedureCard from "../../components/ProcedureCard/ProcedureCard";
import MiniLoading from "../../components/ProcedurePageComponents/Loading/MiniLoading";
import Lottie from "lottie-react";
import coming_soon_lottie from "../../assets/lottie/coming-soon.json";
import Footer from "../../components/Footer/Footer";
import { Carousel } from "primereact/carousel";
const Procedures = () => {
  const [allProcedures, setAllProcedures] = useState([]);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setLoader(true);
    const fetchProcedures = async () => {
      try {
        const response = await axios.get(`${API}/procedures/getAllProcedures`);
        if (response.data.procedures && response.data.procedures.length > 0) {
          setAllProcedures(response.data.procedures);
        } else {
          console.log("No procedures found");
        }
        setLoader(false);
      } catch (error) {
        console.error("Error fetching procedures:", error);
      }
    };
    fetchProcedures();
  }, []);

  console.log("All procedures data  ::::::", allProcedures);
  return (
    <div>
      <LandingPageNavbar />
      {loader ? (
        <div className="container text-center" style={{ padding: "100px" }}>
          <MiniLoading />
        </div>
      ) : (
        <>
          {allProcedures.map((procedure, index) => (
            <div className="container">
              <div key={procedure.id}>
                <h1>{procedure.name}</h1>
                <div className="row mb-3" key={index}>
                  <Carousel
                    value={procedure.hospitalsData}
                    itemTemplate={(hospital) => (
                      <ProcedureCard
                        key={hospital.id}
                        hospitalName={hospital.Name_Of_Hospital}
                        hospitalLocation={hospital.Location}
                        procedureAmount={hospital.amount}
                        procedureName={procedure.name}
                        hospital_uid={hospital.uid}
                        hospitalPhoto={
                          hospital.Hospital_images &&
                          hospital.Hospital_images.length > 0
                            ? hospital.Hospital_images
                            : null
                        }
                      />
                    )}
                    numVisible={3}
                    numScroll={2}
                    autoplayInterval={3000}
                    circular={true}
                    responsiveOptions={[
                      {
                        breakpoint: "1400px",
                        numVisible: 3,
                        numScroll: 2,
                      },
                      {
                        breakpoint: "1199px",
                        numVisible: 3,
                        numScroll: 1,
                      },
                      {
                        breakpoint: "767px",
                        numVisible: 2,
                        numScroll: 1,
                      },
                      {
                        breakpoint: "575px",
                        numVisible: 1,
                        numScroll: 1,
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          ))}
        </>
      )}
      <Footer />
    </div>
  );
};

export default Procedures;
