import React from "react";
import SectionHeading from "../../../../components/ProcedurePageComponents/SectionHeading/SectionHeading";
import "./HospitalInfrastructure.css";
const HospitalInfrastructure = ({ infrastructurePoints }) => {
  return (
    <div
      className="hospital-infrastructure-container progress-section"
      id="hospital-details-infrastructure"
    >
      <SectionHeading
        text={"Infrastructure"}
        textAlign={"left"}
        textColor={"var(--primaryColor)"}
      />
      <ol>
        {infrastructurePoints &&
          infrastructurePoints.length > 0 &&
          infrastructurePoints.map((v) => {
            return <li key={v}>{v}</li>;
          })}
      </ol>
    </div>
  );
};

export default HospitalInfrastructure;
