import React, { useState } from "react";
import "./FacilitatorSettingsComponent.css";
import { auth, database } from "../../config/testFirebaseConfig";
import { sendPasswordResetEmail } from "firebase/auth";
import toast from "react-hot-toast";

const FacilitatorSettingsComponent = () => {
  const [email, setEmail] = useState("");

  const handleResetPassword = async () => {
    sendPasswordResetEmail(database, email)
      .then((data) => {
        // alert("Check your mail")
        toast.success("Check your mail for reset link");
      })
      .catch((error) => {
        console.log(error);
        if (error.code === "auth/invalid-email") {
          toast.error("Invalid email address");
        } else if (error.code === "auth/missing-email") {
          toast.error("Please provide an email");
        } else if (error.code === "auth/user-not-found") {
          toast.error("User not found.");
        } else {
          // Generic error message
          toast.error("An error occurred. Please try again later.");
        }
      });
  };

  return (
    <div className="facilitator-settings-container">
      <div className="facilitator-settings-header-and-info">
        <h3 className="facilitator-settings-header">Change Password:</h3>
        <p className="facilitator-settings-info">
          (Enter your email to receive a reset link on your email)
        </p>
      </div>
      <div className="facilitator-settings-form">
        {/* <label className="facilitator-settings-label">Email</label> */}
        <input
          className="facilitator-settings-email-input"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
        />
        <button
          className="facilitator-settings-submit-button"
          onClick={handleResetPassword}
        >
          Reset Password
        </button>
      </div>
    </div>
  );
};

export default FacilitatorSettingsComponent;
