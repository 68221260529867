import React from "react";
import "./HospitalDetailProcedures.css";
import SectionHeading from "../../../../components/ProcedurePageComponents/SectionHeading/SectionHeading";
import { Link } from "react-router-dom";
import { FaHeartbeat } from "react-icons/fa";

const ProdceduresListItem = ({
  procedureName,
  procedureIcon,
  noOfDoctors = 0,
}) => {
  return (
    <div className="procedures-list-item">
      <div className="procedures-list-item-image-box">
        {/* <img src={procedureIcon} alt="" /> */}
        <FaHeartbeat />
      </div>
      <h6>
        {procedureName} {`(${noOfDoctors})`}
      </h6>
    </div>
  );
};
const HospitalDetailProcedures = ({
  procedures,
  hospitalLoction,
  hospitalName,
}) => {
  return (
    <div
      className="hospital-detail-procedures progress-section"
      id="hospital-details-procedures"
    >
      <SectionHeading
        text={"Procedures"}
        textAlign={"left"}
        textColor={"var(--primaryColor)"}
      />
      <div className="hospital-detail-procedure-list">
        {procedures && procedures.length > 0 ? (
          procedures.map((v) => {
            return (
              <Link
                style={{ color: "black" }}
                to={`/procedure?name=${v?.name}&hospital=${hospitalName}&location=${hospitalLoction}`}
              >
                <ProdceduresListItem
                  procedureName={v?.name}
                  // procedureIcon={"SAd"}
                  noOfDoctors={v?.doctor_ids?.length}
                />
              </Link>
            );
          })
        ) : (
          <div>No procedures added</div>
        )}
      </div>
    </div>
  );
};

export default HospitalDetailProcedures;
