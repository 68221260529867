import React, { Fragment, useEffect, useState } from "react";
import Home from "../Home/Home";
import Navbar from "../../components/Navbar/Navbar";
import "./Portfolio.css";
import {
  hospitalArray,
  simpleSteps,
  smallScreenHospitalArray,
} from "../../constants";
import hospitalServiceIcon from "../../assets/images/serviceSvgs/OBJECTS.svg";
import conveyIdeasIcon from "../../assets/images/serviceSvgs/Group 6243.svg";
import About from "../About/About";
import Features from "../Features/Features";
import Services from "../Services/Services";
import { motion } from "framer-motion";
import Footer from "../../components/Footer/Footer";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import Blogs from "../Blogs/Blogs";
import FAQs from "../FAQs/FAQs";
import PortfolioHospitals from "../../components/PortfolioHospitals/PortfolioHospitals";
import getPortfolioHospitals from "../../utils/getPortfolioHospitals";
import ContactUsSidebar from "../../components/ContactUsSidebar/ContactUsSidebar";
import Heading from "../../components/Heading/Heading";
import ServiceBox from "../../components/ServiceBox/ServiceBox";
const Portfolio = () => {
  let hospitalList =
    window.innerWidth <= 600 ? smallScreenHospitalArray : hospitalArray;
  // const [dynamicHospitals, setDynamicHospitals] = useState([]);
  const [contactUsSidebarToggle, setContactUsSidebarToggle] = useState(false);
  const openContactUsSidebarHandler = () => {
    setContactUsSidebarToggle(true);
  };
  const closeContactUsSidebarHandler = () => {
    document.getElementById("portfolio-contact-us-sidebar").style.transform =
      "translateX(100%)";
    setTimeout(() => setContactUsSidebarToggle(false), 500);
  };
  // useEffect(() => {
  //   getPortfolioHospitals(setDynamicHospitals);
  // }, []);
  const services = [
    {
      category: "For Hospitals",
      icon: hospitalServiceIcon,
      services: [
        {
          title: "Efficient Workflow",
          description:
            "Our plateform facilitates smooth communication and collaboration  between medical tourism facilitators and hospitals,optimizing patient's journey.",
        },
        {
          title: "Digital Documentation",
          description:
            "Streamlining paper work and documentation, we ensure that the necessary information is easily accessible for all stackeholders.",
        },
        {
          title: "Appointment Management",
          description:
            "Simplified appointment scheduling to avoid any delay or confusion.",
        },
      ],
      actionName: "Join Our Healthcare Network",
    },
    {
      category: "For Partners",
      icon: conveyIdeasIcon,
      services: [
        {
          title: "Comprehensive Partner",
          description:
            "User-friendly registration process,making it easy to create an account and access our services.",
        },
        {
          title: "Transparent Communication",
          description:
            "Real-time updates and transparent communication channels help medical tourism facilitators stay informed about their patient's healthcare journey.",
        },
        {
          title: "Collaborative Workflow",
          description:
            "The streamlined workflow optimizes the patenit's overall experience and ensures that the necessary information is accessible to all relevant stakeholders.",
        },
      ],
      actionName: "Partner with Us",
    },
  ];
  return (
    <Fragment>
      <Navbar />
      <Home
        openContact={openContactUsSidebarHandler}
        className={"portfolio-home"}
        header={`Empower Medical Journeys in <br />
                <span style="color:var(--primaryColor)">India</span>
                with Bharat One Care`}
        buttonName={"Become a partner today"}
        buttonData={""}
        data={`25K+ International patients trusted us as their treatment
                <br className="break-line" /> planner in just a year’s time.`}
      />
      {/* <PortfolioHospitals dynamicHospitals={dynamicHospitals} /> */}
      {/* <About /> */}
      <Features />
      {/* <Services openContact={openContactUsSidebarHandler} /> */}
      <motion.section
        id="services"
        initial={{ y: "30%", opacity: 0 }}
        whileInView={{ y: "0%", opacity: 1 }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
        style={{ padding: "40px 0" }}
      >
        <Heading title={"SERVICES"} content={"Here’s what we offer"} />
        <div className="services">
          <div>
            <div>
              {" "}
              {services.map((service) => {
                return (
                  <ServiceBox
                    category={service.category}
                    icon={service.icon}
                    services={service.services}
                    actionName={service.actionName}
                    openContact={openContactUsSidebarHandler}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </motion.section>
      <div className="portofolio-healthcare-easy-steps-box">
        <div>
          <h2>Healthcare , but simple & easy</h2>
          <p>Finding the right hospital is easy with just few simple steps</p>
        </div>
        <div className="all-steps">
          {simpleSteps &&
            simpleSteps.map((step, index) => {
              return (
                <div
                  className={`step-box ${index === simpleSteps.length - 1
                      ? ""
                      : "step-box-right-border"
                    } `}
                  key={step?.stepName}
                >
                  <div>
                    <motion.img
                      src={step?.stepIcon}
                      alt=""
                      initial={{ scale: 0, origin: "bottom" }}
                      whileInView={{ scale: 1 }}
                      transition={{ duration: 0.5 }}
                    />
                    <h4>{step?.stepName}</h4>
                  </div>
                  <p>{step?.stepDescription}</p>
                </div>
              );
            })}
        </div>
      </div>
      <FAQs openContact={openContactUsSidebarHandler} />
      <Blogs />
      <div className="portfolio-consultation-call-box">
        <motion.h1
          initial={{ scale: 0.2, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          Take a Step towards better Lifestyle Today!
        </motion.h1>
        <SecondaryButton
          text={"Book a Free Consultation call"}
          openContact={openContactUsSidebarHandler}
        />
      </div>
      <Footer />
      {contactUsSidebarToggle && (
        <ContactUsSidebar closeHandler={closeContactUsSidebarHandler} />
      )}
    </Fragment>
  );
};

export default Portfolio;
