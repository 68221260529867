import React from 'react'
import './NextButtonComponent.css'

const NextButtonComponent = (props) => {

  return (
    <button className = {props.partner && props.ticked ? "next-button-active" : "next-button"} onClick = {() => props.onClick(props.partner, props.ticked)}>
      {props.text}
    </button>
  )
}

export default NextButtonComponent