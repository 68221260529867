import React, { useState } from "react";
import TextInputComponent from "../../../components/TextInputComponent/TextInputComponent";
import PasswordInputComponent from "../../../components/PasswordInputComponent/PasswordInputComponent";
import AlertComponent from "../../../components/AlertComponent/AlertComponent";
import CreateAccountButton from "../../../components/CreateAccountButton/CreateAccountButton";
import { Link } from "react-router-dom";

function CustomerSupportSignup(props) {
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };
  return (
    <div className="login-page-form">
      <h2>Create your Account</h2>
      <div className="login-page-form-content">
        <form>
          <TextInputComponent
            label="Full Name"
            placeholder="John Sondoe"
            className="login-page-form-input-text"
            name="fullName" // Ensure name attribute matches the key in props.user
            value={props.user.fullName}
            onChange={props.handleChange}
          />

          <TextInputComponent
            label="Email"
            placeholder="johnsondoe@gmail.com"
            className="login-page-form-input-text"
            name="email"
            value={props.user.email}
            onChange={props.handleChange}
          />
          <TextInputComponent
            label="Phone"
            placeholder="0123456789"
            className="login-page-form-input-text"
            name="phoneNumber" // Ensure name attribute matches the key in props.user
            value={props.user.phoneNumber}
            onChange={props.handleChange}
          />
          <PasswordInputComponent
            label="Password"
            placeholder="******"
            className="login-page-form-input-password"
            name="password"
            value={props.user.password}
            onChange={props.handleChange}
            passwordShown={passwordShown}
            togglePasswordVisibility={togglePasswordVisibility}
          />
          <div>
            {/* {props.displayError && (
              <AlertComponent errorText={props.errorText} />
            )} */}
            <div style={{ marginTop: "24px" }}>
              <CreateAccountButton
                handleSubmit={props.handleSubmit}
                text="Create Account"
                buttonType="customer-support"
              />
            </div>
          </div>
          <div className="login-page-form-account-login">
            Already have an account?
            <Link
              to="/customer-support/login"
              className="login-page-form-account-login-link"
            >
              Login
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CustomerSupportSignup;
