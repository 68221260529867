import React, { useEffect, useState } from "react";
import "./StickyReadProgressBar.css";
const ProgressBarOption = ({ optionName, linkToSection }) => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    const sectionTop = section.offsetTop - 120;
    window.scrollTo({ top: sectionTop, behavior: "smooth" });
  };
  return (
    <div className="progress-bar-option">
      <a
        href="#scroll"
        onClick={() => {
          scrollToSection(linkToSection.slice(1, linkToSection.length + 1));
        }}
      >
        {optionName}
      </a>
      <span className="section-progress-bar"></span>
    </div>
  );
};

const StickyReadProgressBar = ({ optionsList }) => {
  const updateProgress = () => {
    const sectionsList = document.querySelectorAll(".progress-section");
    const progressBarList = document.querySelectorAll(".section-progress-bar");
    for (let i = 0; i < sectionsList.length; i++) {
      sectionProgress(sectionsList[i], progressBarList[i]);
    }
  };
  const sectionProgress = (targetSection, targetProgressBar) => {
    if (!targetSection || !targetProgressBar) return;
    const distanceFromTop = targetSection.offsetTop;
    const targetSectionHeight = targetSection.offsetHeight;
    const scrollY = window.scrollY;
    const difference = scrollY - distanceFromTop;
    let progressPercent = difference / targetSectionHeight;
    if (progressPercent > 100) return;
    const screenHeight = window.innerHeight;
    let increment = 0;
    if (targetSectionHeight < screenHeight) {
      increment = 100;
    } else {
      increment = screenHeight / targetSectionHeight;
      increment = increment * 100;
    }
    // console.log({ difference: scrollY - distanceFromTop, progressPercent: progressPercent * 100 + 100, targetSection, targetSectionHeight, distanceFromTop, scrollY });
    progressPercent = progressPercent * 100;
    targetProgressBar.style.setProperty(
      "--progress",
      progressPercent + increment + "%"
    );
  };
  window.addEventListener("scroll", updateProgress);
  return (
    <div className="sticky-read-progress-bar">
      {optionsList &&
        optionsList.map((e, i) => (
          <ProgressBarOption
            key={i}
            optionName={e?.optionName}
            linkToSection={e?.linkToSection}
          />
        ))}
    </div>
  );
};

export default StickyReadProgressBar;
