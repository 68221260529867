import React, { useState } from "react";
import TextInputComponent from "../../../components/TextInputComponent/TextInputComponent";
import PasswordInputComponent from "../../../components/PasswordInputComponent/PasswordInputComponent";
import { Link } from "react-router-dom";
import AlertComponent from "../../../components/AlertComponent/AlertComponent";
import CreateAccountButton from "../../../components/CreateAccountButton/CreateAccountButton";
const LoginPage = (props) => {
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <div className="login-page-form">
      <h2>Login to your Account</h2>
      <div className="login-page-form-content">
        <form>
          <TextInputComponent
            label="Email"
            placeholder="johnsondoe@gmail.com"
            className="login-page-form-input-text"
            name="email"
            value={props.user.email}
            onChange={props.handleChange}
          />
          <PasswordInputComponent
            label="Password"
            placeholder="******"
            className="login-page-form-input-password"
            name="password"
            value={props.user.password}
            onChange={props.handleChange}
            passwordShown={passwordShown}
            togglePasswordVisibility={togglePasswordVisibility}
          />
          <Link
            to="/forgot-password"
            className="login-page-form-forgot-password"
          >
            Forgot Password?
          </Link>
          <div>
            {props.displayError && (
              <AlertComponent errorText={props.errorText} />
            )}
            <CreateAccountButton
              handleSubmit={props.handleSubmit}
              text="Login"
              buttonType="customer-support"
            />
          </div>
          <div className="login-page-form-account-login">
            Don't have an account?
            <Link
              to="/customer-support/signup"
              className="login-page-form-account-login-link"
            >
              Create Account
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
