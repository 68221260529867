import React from "react";
import "./HospitalDetail.css";
import { accreditationLogos } from "../../constants.js";
// import WhiteButton from "../Buttons/WhiteButton";
// import PinkButton from "../Buttons/PinkButton";
import Experiencesvg from "../../assets/NewPortfolioAssets/Experience.svg";
import assisted from "../../assets/NewPortfolioAssets/assisted.svg";
import Establish from "../../assets/NewPortfolioAssets/Establish.svg";
import beds from "../../assets/NewPortfolioAssets/beds.svg";
import hospitalPlaceholder from "../../assets/images/hospital-placeholder.png";
import CountUp from "react-countup";
const HospitalDetail = ({
  hospitalName,
  hospitalLocation,
  hospitalAccreditations = ["ISO", "JCI"],
  hospitalImage,
  specialty,
  bookAppointmentClickHandler,
  hospitalEstablishedIn,
  noOfBeds,
  noOfPatientsAssisted,
  noOfDoctors,
}) => {
  const scrollToHandler = (sectionId) => {
    let section = document.getElementById(sectionId);
    window.scrollTo({ top: section.offsetTop - 100, behavior: "smooth" });
  };
  return (
    <div className="single-hospital-detail">
      <div className="single-hospital-detail-box-1">
        <div>
          <h1 className="hospital-name">{hospitalName}</h1>
          <h6 className="hospital-location">{hospitalLocation}</h6>
          <div className="hospital-speciality-accreditations">
            <p className="hospital-speciality-type">{specialty} - speciality</p>
            <div className="hospital-accreditations-icons">
              {hospitalAccreditations &&
                hospitalAccreditations.map((v) => {
                  return <img src={accreditationLogos[v]} alt="" />;
                })}
            </div>
          </div>
        </div>
        <div className="single-hospital-detail-buttons">
          {/* <PinkButton
            text={"Book Appointment"}
            onClick={bookAppointmentClickHandler}
          />

          <WhiteButton
            text={"Need Help"}
            onClick={() => scrollToHandler("need-help")}
          /> */}
        </div>
      </div>
      <div className="single-hospital-detail-box-2">
        <div className="single-hospital-detail-hospital-image">
          {hospitalImage ? (
            <img src={hospitalImage} alt="" />
          ) : (
            <img src={hospitalPlaceholder} alt="" />
          )}
        </div>
        <div className="hospital-google-map-location">
          <img
            src={
              "https://www.google.com/maps/d/thumbnail?mid=1oqDNxjZGIysC7-0YhB780pJ_Nn0&hl=en_US"
            }
            alt=""
          />
        </div>
      </div>
      <div className="px-4">
        <div className="col hospital-features-box d-flex justify-content-md-between flex-md-row flex-column py-3 px-5 ">
          <div className="experience-box mb-md-0 mb-4">
            <img src={Experiencesvg} alt="" srcset="" />
            <p className="mb-0 mt-3">Experienced doctors : {noOfDoctors}</p>
          </div>

          <div className="establish-box mb-md-0 mb-4">
            <img src={Establish} alt="" srcset="" />
            <p className="mb-0 mt-3">
              Established in : {hospitalEstablishedIn}
            </p>
          </div>

          <div className="beds-box mb-md-0 mb-4">
            <img src={beds} alt="" srcset="" />
            <p className="mb-0 mt-3">{noOfBeds} : beds</p>
          </div>

          <div className="assisted-box mb-md-0 mb-4">
            <img src={assisted} alt="" srcset="" />
            <p className="mb-0 mt-3">
              {noOfPatientsAssisted}+ patients assisted
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HospitalDetail;
