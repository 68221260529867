import React from "react";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
const PatientDeletionPopup = ({ handleClick, data = null }) => {
  return (
    <div className='modal-background-1'>
      <div
        className='modal-section'
        style={{
          padding: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h3
            className='modal-title add-appointment-model-title'
            style={{ color: "#404040" }}
          >
            Delete Patient
          </h3>
          <CancelRoundedIcon
            sx={{
              color: "#404040",
              cursor: "pointer",
            }}
            onClick={() => handleClick("No")}
          />
        </div>

        <div className='modal-body'>
          <h5 style={{ color: "#000", textAlign: "center" }}>
            Are you sure you want to delete this patient information?
            {data && ` for ${data.patientName} at ${data.hospitalName}`}?
          </h5>
        </div>
        <div className='modal-footer'>
          <button
            type='button'
            className={`my-btn-1`}
            onClick={() => handleClick("Yes")}
          >
            Yes
          </button>
          <button
            type='button'
            className={`my-btn-1`}
            style={{
              backgroundColor: "transparent",
              color: "#ef5ea2",
              border: "1px solid #ef5ea2",
            }}
            onClick={() => handleClick("No")}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default PatientDeletionPopup;
