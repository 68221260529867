import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../context/UserAuthContext";
import { useNavigate } from "react-router-dom";
import { API } from "../../constants/api";
import { useLoginContext } from "../../context/LoginContext";
import axios from "axios";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../config/testFirebaseConfig";
import Loader from "../../components/Loader/Loader";

export const HomePage = (props) => {
  const { logOut } = useUserAuth();
  const [user, setUser] = useState({});
  onAuthStateChanged(auth, (currentuser) => {
    setUser(currentuser);
  });
  const { currentComponent, CurrentComponentHandler } = useLoginContext();
  const navigate = useNavigate();
  const getUserFromFirebase = async () => {
    console.log(user.uid);
    const userInfo = await axios.get(`${API}/user/getsingleuser/${user.uid}`);
    const userData = userInfo.data.user;
    console.log(user);
    console.log(user.emailVerified);
    if (user.emailVerified === false) {
      console.log("I am running");
      CurrentComponentHandler("emailLinkVerification");
      navigate("/login");
    } else if (userData.partnerType === "none") {
      console.log("I am running partner selection");
      CurrentComponentHandler("partnerSelection");
      navigate("/login");
    } else {
      if (userData.isOnboarded === "false") {
        navigate(`/onboarding/${userData.partnerType}`);
      } else {
        navigate(`/${userData.partnerType}/dashboard`);
        // console.log(userData);
      }
    }
  };
  useEffect(() => {
    if (user && user.uid) getUserFromFirebase();
  }, [user]);

  // const handleLogout = async () => {
  //   try {
  //     await logOut();
  //     navigate("/login");
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };
  return (
    <div>
      <Loader />
      {/* <p>Hello {user.email}</p>
      <button onClick={handleLogout}>Logout</button> */}
    </div>
  );
};
