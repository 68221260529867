import React, { useState } from "react";
import "./ReviewSlider.css";
import ReviewCard from "../ReviewCard/ReviewCard";
import { ReviewsTranslation } from "../../constants/Translation";

function ReviewSlider({ reviews }) {
  // const [reviews, setReviews] = useState(ReviewsTranslation.en);

  const btnpressprev = () => {
    let width = document.querySelector(".review-card").clientWidth;
    document.querySelector(".slider-container").scrollLeft =
      document.querySelector(".slider-container").scrollLeft - width;
    console.log(width);
  };

  const btnpressnext = () => {
    let width = document.querySelector(".review-card").clientWidth;
    document.querySelector(".slider-container").scrollLeft =
      document.querySelector(".slider-container").scrollLeft + width;
    console.log(width);
  };

  return (
    <div className="slider-carousel">
      <button className="pre-btn" onClick={btnpressprev}>
        <p></p>
      </button>
      <button className="next-btn" onClick={btnpressnext}>
        <p></p>
      </button>
      <div className="slider-container">
        {reviews.map((review) => {
          return (
            <ReviewCard
              description={
                review.description ? review.description : review.review
              }
              name={review.name}
              designation={review.designation}
            />
          );
        })}
      </div>
    </div>
  );
}

export default ReviewSlider;
