import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "./AlertPopup.css";
const AlertPopup = ({
  action,
  alertPopupContent,
  open,
  handleClose,
  hospitalName,
  deleteAll,
}) => {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Portfolio Hospital"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            fontSize={"15px"}
            color={"gray"}
          >
            Do you really want to{" "}
            <span style={{ fontWeight: "600" }}>
              delete {deleteAll ? "all" : hospitalName && hospitalName}
            </span>
            {deleteAll ? " hospitals" : " hospital"}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className="alert-popup-button">
            Cancel
          </button>
          <button
            onClick={action}
            className="alert-popup-button alert-popup-delete-button"
          >
            Delete
          </button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AlertPopup;
