import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import "./LandingPage.css";
import LandingPageNavbar from "../../components/LandingPageNavbar/LandingPageNavbar";
import Home from "../Home/Home";
import FormGenerator from "../../components/FormGenerator/FormGenerator";
import { LandingPageContactUs } from "../../constants/formconfig";
import { motion } from "framer-motion";
import Footer from "../../components/Footer/Footer";
import ReviewSlider from "../../components/ReviewSlider/ReviewSlider";
import ContactUsSidebar from "../../components/ContactUsSidebar/ContactUsSidebar";
import customer_portfolio_img_0 from "../../assets/images/customer_portfolio_0.svg";
import customer_portfolio_img_1 from "../../assets/images/customer_portfolio_1.svg";
import customer_portfolio_img_2 from "../../assets/images/customer_portfolio_2.svg";
import customer_portfolio_img_3 from "../../assets/images/customer_portfolio_3.svg";
import { simpleSteps } from "../../constants";
import aboutImage from "../../assets/images/bharat-cares.svg";
import ProcedureCost from "../../components/ProcedureCost/ProcedureCost";
import LandingPageAccordian from "../../components/LandingPageAccordian/LandingPageAccordian";
import UserEndContactComponent from "../../components/UserEndContactComponent/UserEndContactComponent";
import { uploadAndReturnUrls } from "../../constants/UploadeImageUrl";
import { ref } from "firebase/storage";
import { storage } from "../../config/testFirebaseConfig";
import { API } from "../../constants/api";
import axios from "axios";
import PortfolioHospitals from "../../components/PortfolioHospitals/PortfolioHospitals";
import getPortfolioHospitals from "../../utils/getPortfolioHospitals";
import { ReviewsTranslation } from "../../constants/Translation";
import { BOneChatbotPublic } from "../../components/BOneChatbot/BOneChatbotPublic";

const LandingPage = () => {
  const [contactUsSidebarToggle, setContactUsSidebarToggle] = useState(false);
  const [language, setLanguage] = useState("English");
  const [contactUsTranslation, setContactUsTranslation] =
    useState(LandingPageContactUs);
  const { i18n, t } = useTranslation(["translation"]);

  useEffect(() => {
    if (localStorage.getItem("i18nextLng")?.length > 2) {
      i18next.changeLanguage("English");
    }
  }, []);
  const handleLanguageChange = (e) => {
    i18next.changeLanguage(e.target.value);
  };

  useEffect(() => {
    setContactUsTranslation((prev) => [
      { ...prev[0], section: t(LandingPageContactUs[0].section) },
    ]);
    const newData = LandingPageContactUs[0].fields.map((ele) => {
      return ele.map((item) => {
        return {
          ...item,
          label: t(item.label),
          placeholder: t(item.placeholder),
        };
      });
    });
    setContactUsTranslation((prev) => [{ ...prev[0], fields: newData }]);
  }, [language, t]);

  const sectionRef = useRef(null);

  const scrollToSection = () => {
    const sectionTop = sectionRef.current.offsetTop - 80;
    window.scrollTo({ top: sectionTop, behavior: "smooth" });
  };

  const form = {
    name: {
      value: "",
      isTouched: false,
    },
    contactNumber: {
      value: "",
      isTouched: false,
    },
    email: {
      value: "",
      isTouched: false,
    },
    report: {
      value: "",
      isTouched: false,
    },
    query: {
      value: "",
      isTouched: false,
    },
  };
  const [formData, setFormData] = useState(form);
  const [dynamicHospitals, setDynamicHospitals] = useState([]);
  useEffect(() => {
    getPortfolioHospitals(setDynamicHospitals);
  }, []);
  const openContactUsSidebarHandler = () => {
    setContactUsSidebarToggle(true);
  };
  const closeContactUsSidebarHandler = () => {
    document.getElementById("portfolio-contact-us-sidebar").style.transform =
      "translateX(100%)";
    setTimeout(() => setContactUsSidebarToggle(false), 500);
  };
  const handleChange = (field, newValue) => {
    setFormData((obj) => {
      let tempObj = { ...obj };
      const tempField = { ...tempObj[field], value: newValue };
      tempObj[field] = tempField;
      return tempObj;
    });
    const tempObj = Object.create(formData);
    const tempField = { ...tempObj[field], value: newValue };
    tempObj[field] = tempField;
    console.log(formData);
  };
  const handleTouch = (field) => {
    setFormData((obj) => {
      const tempObj = { ...obj };
      tempObj[field] = { ...tempObj[field], isTouched: true };
      return tempObj;
    });
  };
  const handleClick = async (event) => {
    event.preventDefault();
    const nonFileData = {
      name: formData.name.value,
      contactNumber: formData.contactNumber.value,
      email: formData.email.value,
      query: formData.query.value,
    };
    try {
      const url = `${API}/admin/contact`;
      const result = await axios.post(url, nonFileData);
      console.log(result);
      const fileData = [formData.report.value];
      const structuredLinks = {
        report: "",
      };
      const contactRef = ref(storage, `contact/${result.data.contactId}`);
      // const fileUrl = await uploadAndReturnUrls(formData.report, contactRef);
      // nonFileData.report = fileUrl;
      await Promise.all(
        fileData.map(async (file, index) => {
          const uploadedFileUrls = await uploadAndReturnUrls(
            [file],
            contactRef
          );

          console.log(uploadedFileUrls);
          structuredLinks[Object.keys(structuredLinks)[index]] =
            uploadedFileUrls[0];
        })
      );
      // console.log(nonFileData);
      const response = await axios.patch(
        `${API}/admin/upload/${result.data.contactId}`,
        structuredLinks
      );
      console.log(response);
      setFormData(form);
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <>
      <LandingPageNavbar
        setLanguage={setLanguage}
        handleLanguageChange={handleLanguageChange}
        scrollToSection={scrollToSection}
        language={language}
      />
      <Home
        openContact={openContactUsSidebarHandler}
        className={"landingpage-home"}
        header={t("landingHeader")}
        data={t("landingData")}
        buttonName={t("landingButtonName")}
        buttonData={t("landingButtonData")}
        landingPage
      />
      {/* <div className="data-section">
        <div className="data-container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="ml-2 mt-4">
                <img
                  src={data_1}
                  className="icon-image mb-2"
                  alt="landingPage"
                />
                <p className="landingPage-data">
                  <CountUp
                    start={0}
                    end={400}
                    duration={5}
                    style={{
                      width: "60px",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                    suffix="+"
                  />{" "}
                  {t("countUp1")}
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="ml-2 mt-4">
                <img
                  src={data_2}
                  className="icon-image mb-2"
                  alt="landingPage"
                />
                <p className="landingPage-data">
                  <CountUp
                    start={0}
                    end={7000}
                    duration={5}
                    style={{
                      width: "60px",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                    suffix="+"
                  />{" "}
                  {t("countUp2")}
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="ml-2 mt-4">
                <img
                  src={data_3}
                  className="icon-image mb-2"
                  alt="landingPage"
                />
                <p className="landingPage-data">
                  {t("countUp3Part1")}{" "}
                  <CountUp
                    start={0}
                    end={20}
                    duration={5}
                    style={{
                      width: "60px",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                    suffix="+"
                  />{" "}
                  {t("countUp3Part2")}
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="ml-2 mt-4">
                <img
                  src={data_4}
                  className="icon-image mb-2"
                  alt="landingPage"
                />
                <p className="landingPage-data">
                  <CountUp
                    start={0}
                    end={25000}
                    duration={5}
                    style={{
                      width: "60px",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                    suffix="+"
                  />{" "}
                  {t("countUp4")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <PortfolioHospitals dynamicHospitals={dynamicHospitals} />
      <div className="main-container">
        <ProcedureCost language={language} />
      </div>
      <div>
        <motion.section
          initial={{ y: "50%", opacity: 0 }}
          whileInView={{ y: "0%", opacity: 1 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="portfolio-about-section"
        >
          <img src={aboutImage} alt="" />
          <div className="content">
            <h1>About Bharat One Care</h1>
            <p>
              At Bharat One Care, we are dedicated to helping you find the best
              medical solutions in India. Our mission is to make your healthcare
              journey seamless, from finding the right hospital to providing
              comprehensive support throughout your stay.
            </p>
            <p>
              We specialize in connecting patients from across the globe to
              India's world-class medical treatment facilities, renowned for
              their expertise and cutting-edge infrastructure.
            </p>
          </div>
        </motion.section>
      </div>
      <div className="portofolio-healthcare-easy-steps-box">
        <div>
          <h2>Healthcare , but simple & easy</h2>
          <p>Finding the right hospital is easy with just few simple steps</p>
        </div>
        <div className="all-steps">
          {simpleSteps &&
            simpleSteps.map((step, index) => {
              return (
                <div
                  className={`step-box ${
                    index === simpleSteps.length - 1
                      ? ""
                      : "step-box-right-border"
                  } `}
                  key={step?.stepName}
                >
                  <div>
                    <motion.img
                      src={step?.stepIcon}
                      alt=""
                      initial={{ scale: 0, origin: "bottom" }}
                      whileInView={{ scale: 1 }}
                      transition={{ duration: 0.5 }}
                    />
                    <h4>{step?.stepName}</h4>
                  </div>
                  <p>{step?.stepDescription}</p>
                </div>
              );
            })}
        </div>
      </div>
      <div>
        <motion.h2
          style={{
            fontWeight: "700",
            color: "var(--primaryColor)",
            textAlign: "center",
            marginTop: "3%",
          }}
        >
          {t("chooseUsHeader")}
        </motion.h2>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 info-sec">
              <div className="info-section">
                <div className="info-section__heading">
                  <h2>{t("section1Header")}</h2>
                </div>
                <div className="info-section__content">
                  <p>{t("section1Data")}</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 img-sec">
              <div className="image-section">
                <img
                  src={customer_portfolio_img_0}
                  className="info-image"
                  alt="whychoose"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 img-sec">
              <div className="image-section">
                <img
                  src={customer_portfolio_img_1}
                  className="info-image"
                  alt="whychoose"
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12 info-sec">
              <div className="info-section">
                <div className="info-section__heading">
                  <h2>{t("section2Header")}</h2>
                </div>
                <div className="info-section__content">
                  <p>{t("section2Data")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 info-sec">
              <div className="info-section">
                <div className="info-section__heading">
                  <h2>{t("section3Header")}</h2>
                </div>
                <div className="info-section__content">
                  <p>{t("section3Data")}</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 img-sec">
              <div className="image-section">
                <img
                  src={customer_portfolio_img_2}
                  className="info-image"
                  alt="whychoose"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 img-sec">
              <div className="image-section">
                <img
                  src={customer_portfolio_img_3}
                  className="info-image"
                  alt="whychoose"
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12 info-sec">
              <div className="info-section">
                <div className="info-section__heading">
                  <h2>{t("section4Header")}</h2>
                </div>
                <div className="info-section__content">
                  <p>{t("section4Data")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-container">
        <div
          className="contact-us"
          style={{
            backgroundColor: "white",
            padding: "30px",
            borderRadius: "10px",
          }}
        >
          <FormGenerator
            array={contactUsTranslation[0]}
            handleChange={handleChange}
            handleTouch={handleTouch}
            formData={formData}
            landingPage
            language={language}
            handleClick={handleClick}
          />
        </div>
      </div>
      <div className="main-container" ref={sectionRef}>
        <div>
          <ReviewSlider language={language} reviews={ReviewsTranslation.en} />
        </div>
        <div className="container" id="contact">
          <div className="faq-container">
            <motion.h2
              style={{
                textAlign: "center",
                marginTop: "3%",
              }}
            >
              {t("faqHeader")}
            </motion.h2>
            <LandingPageAccordian language={language} />
          </div>
        </div>
      </div>

      <UserEndContactComponent
        openContactUsSidebarHandler={openContactUsSidebarHandler}
      />
      <Footer language={language} />
      {contactUsSidebarToggle && (
        <ContactUsSidebar closeHandler={closeContactUsSidebarHandler} />
      )}
    </>
  );
};

export default LandingPage;
