import React from 'react'
import './FeatureBox.css';
const FeatureBox = ({title,description,icon}) => {
  return (
    <div className='portfolio-feature-box'>
      <div className='feature-icon-name' >    
      <img src={icon} alt="" />  
      <h3>{title}</h3>
      </div>
     <p className='feature-description'>{description}</p>
    </div>
  )
}

export default FeatureBox;
