import React, { useEffect, useState } from "react";
import "./AddRemovePortfolioHospital.css";
import { Autocomplete, Chip, TextField } from "@mui/material";
import axios from "axios";
import consoleHospitalList from "../../../utils/getConsoleHospitalList";
import getPortfolioHospitals, {
  dividePortfolioHospitalList,
} from "../../../utils/getPortfolioHospitals";
import toast from "react-hot-toast";
import PortfolioHospitals from "../../../components/PortfolioHospitals/PortfolioHospitals";
import AlertPopup from "../../../components/AlertPopup/AlertPopup";
import { API } from "../../../constants/api";
const AddRemovePortfolioHospital = () => {
  const [hospitalList, setHospitalList] = useState([]);
  const [portfolioHospitals, setPortfolioHospitals] = useState([]);
  const [hospitalsPreview, setHospitalsPreview] = useState([]);
  const [savedListLength, setSavedListLength] = useState(0);
  const [open, setOpen] = useState(false);
  const [deleteAll, setDeleteAll] = useState(false);
  const [hospitalToDelete, setHospitalToDelete] = useState("");
  const alertPopupToggleHandler = () => {
    setOpen(!open);
  };
  const closeHandler = () => {
    setOpen(false);
    if (deleteAll) {
      setTimeout(() => {
        setDeleteAll(false);
      }, 500);
    }
  };
  const fetchAllPortfolioHospitals = async () => {
    try {
      const url = `${API}/portfolio/hospital/all`;
      const {
        data: { portfolioHospitals, success },
      } = await axios.get(url);
      setPortfolioHospitals(portfolioHospitals);
      setSavedListLength(portfolioHospitals.length);
      console.log(portfolioHospitals.length);
      setHospitalsPreview(dividePortfolioHospitalList(portfolioHospitals));
    } catch (err) {
      toast.error(err.message);
    }
  };
  const deleteAllPortfolioHospitals = async () => {
    const url = `${API}/portfolio/hospital/all/delete`;
    setOpen(false);
    const {
      data: { success, message },
    } = await axios.delete(url);
    if (success) {
      setPortfolioHospitals([]);
      setHospitalsPreview([]);
      setSavedListLength(0);
      fetchAllPortfolioHospitals();
      return message;
    }
  };
  const autoCompleteChangeHandler = (event, data) => {
    console.log({ data });
    if (!data.length && savedListLength) {
      setDeleteAll(true);
      alertPopupToggleHandler();
    } else {
      setPortfolioHospitals(data);
      setHospitalsPreview(dividePortfolioHospitalList(data));
    }
  };
  const savePorfolioHospitals = async () => {
    let listToSave = portfolioHospitals.slice(
      savedListLength,
      portfolioHospitals.length
    );
    console.log({ listToSave });
    if (!listToSave.length) {
      throw new Error("You haven't selected any new hospital.");
    }

    const url = `${API}/portfolio/hospital/add`;
    const {
      data: { success, message },
    } = await axios.post(url, { portfolioHospitalList: listToSave });
    if (success) {
      await fetchAllPortfolioHospitals();
    }
  };
  const saveHandler = () => {
    toast.promise(savePorfolioHospitals(), {
      success: (data) => {
        return "Added to Portfolio.";
      },
      error: (err) => err.message,
      loading: "Adding to portfolio...",
    });
  };
  const deleteAction = () => {
    toast.promise(deletePortfolioHospital(hospitalToDelete), {
      success: "Hospital deleted from protfolio.",
      error: "Something went wrong",
      loading: "Deleting hospital...",
    });
    setOpen(false);
  };
  const deleteAllAction = () => {
    toast.promise(deleteAllPortfolioHospitals(), {
      success: (message) => {
        setDeleteAll(false);
        return message;
      },
      error: (err) => err.message,
      loading: "Deleting all hospitals...",
    });
  };
  const deletePortfolioHospital = async (value) => {
    console.log({ value });
    const { id } = value;
    const url = `${API}/portfolio/hospital/delete/${id}`;
    const {
      data: { success },
    } = await axios.delete(url);
    if (success) {
      const filteredData = portfolioHospitals.filter((v) => v.id !== id);
      setPortfolioHospitals(filteredData);
      setSavedListLength(filteredData.length);
      fetchAllPortfolioHospitals();
    }
  };
  const deleteHandler = (value) => {
    console.log({ value });
    const { id, Name_Of_Hospital } = value;
    if (!id) {
      let filteredList = portfolioHospitals.filter(
        (v) => v.Name_Of_Hospital !== Name_Of_Hospital
      );
      setPortfolioHospitals(filteredList);
      setHospitalsPreview(dividePortfolioHospitalList(filteredList));
      return;
    }
    setHospitalToDelete(value);
    alertPopupToggleHandler();
  };

  useEffect(() => {
    consoleHospitalList(setHospitalList);
    fetchAllPortfolioHospitals();
  }, []);
  console.log({
    portfolioHospitals: portfolioHospitals.length,
    portfolioHospitals,
  });
  return (
    <>
      {" "}
      <div className="portoflio-hospitals-admin-console">
        <h4 className="portfolio-hospitals-admin-console-heading">
          Portfolio Hospitals Console
        </h4>
        <div>
          <div className="add-remove-hospital-autocomplete">
            <Autocomplete
              multiple
              id="tags-outlined"
              options={hospitalList}
              getOptionLabel={(option) => option?.Name_Of_Hospital}
              value={portfolioHospitals}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  key={params.id}
                  style={{ color: "red" }}
                  variant="standard"
                  label="Tags"
                  placeholder="Related"
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option?.id}
                    variant="filled"
                    label={option?.Name_Of_Hospital}
                    {...getTagProps({ index })}
                    onDelete={() => deleteHandler(option)}
                  />
                ))
              }
              onChange={autoCompleteChangeHandler}
            />
          </div>
          <div className="portfolio-hospital-admin-console-preview">
            <h5>Preview</h5>
            <PortfolioHospitals dynamicHospitals={hospitalsPreview} />
          </div>{" "}
          <div className="portfolio-hospital-admin-console-save-button-box">
            <button
              onClick={saveHandler}
              className="portfolio-hospital-admin-console-save-button"
            >
              Add to Portfolio
            </button>
          </div>
        </div>
      </div>
      <AlertPopup
        open={open}
        handleClose={closeHandler}
        action={deleteAll ? deleteAllAction : deleteAction}
        deleteAll={deleteAll}
        hospitalName={hospitalToDelete?.Name_Of_Hospital}
      />
    </>
  );
};

export default AddRemovePortfolioHospital;
