import React from "react";
import "./DoctorCard.css";
import { ProcedurePagePrimaryButton } from "../../../pages/PROCEDURE/ProcedurePage/ProcedurePage";
import { useNavigate } from "react-router-dom";
import { doctorPlaceholderURL } from "../../../constants/api";
const DoctorCard = ({
  doctorName,
  expertIn,
  education,
  yearsOfExperience,
  doctorPhoto,
  hospitalId,
  doctorId,
}) => {
  const navigate = useNavigate();
  const onClickViewDetails = () => {
    let url = `/doctor/info?doctor_id=${doctorId}`;
    if (hospitalId) {
      url += `&hospital_id=${hospitalId}`;
    }
    navigate(url);
  };

  return (
    <div className="procedure-doctor-card">
      <div className="procedure-doctor-photo-box">
        <div className="procedure-doctor-photo">
          <img
            src={doctorPhoto ? doctorPhoto : doctorPlaceholderURL}
            alt=""
            className="doctor-card-img-fluid"
          />
        </div>
        {yearsOfExperience && (
          <div className="experience-tag mx-3">
            {yearsOfExperience} yrs experience
          </div>
        )}
      </div>

      <div className="procedure-doctor-name-experience">
        <h6 className="name">{doctorName}</h6>
        <h6>{expertIn}</h6>
        <hr className="m-0" />
      </div>

      <div className="procedure-doctor-education">
        <p>Education</p>

        <h6>{education}</h6>
        <hr className="m-0" />
      </div>

      <ProcedurePagePrimaryButton
        text={"Find out More"}
        action={() => onClickViewDetails()}
      />
    </div>
  );
};
export default DoctorCard;
