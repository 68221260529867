import React from "react";
import SectionHeading from "../../../../components/ProcedurePageComponents/SectionHeading/SectionHeading";
import "./HospitalDetailAbout.css";
import { FaLocationDot } from "react-icons/fa6";
import { MdOutlineAirplanemodeActive } from "react-icons/md";
import { FaTrainSubway } from "react-icons/fa6";
import { BsFillTaxiFrontFill } from "react-icons/bs";
import { hospitalNearbyResourcesLogos } from "../../../../constants";
import HospitalAboutImageSlider from "../../../../components/HospitalAboutImageSlider/HospitalAboutImageSlider";
import ImageSlider from "../../../../components/ImageSlider/ImageSlider";
const HospitalDetailAbout = ({
  aboutHospital,
  hospitalImages,
  locationDetails,
}) => {
  const distanceUnitConverter = (distance) => {
    if (distance < 1000) {
      return { value: distance, unit: "m" };
    } else return { value: Math.round(distance / 1000), unit: "Km" };
  };
  console.log({ hospitalImages });
  return (
    <div
      className="hospital-detail-about progress-section"
      id="hospital-details-about"
    >
      <SectionHeading
        text={"About"}
        textAlign={"left"}
        textColor={"var(--primaryColor)"}
      />
      <p className="hospital-detail-about-desc">
        {aboutHospital ||
          "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aliquam illo esse nulla, excepturi, perferendis illum odio impedit ea enim aspernatur recusandae at saepe veniam eos quae? Reprehenderit itaque numquam perspiciatis. Porro ad amet et, quod dolores assumenda, adipisci fuga incidunt aut eos, vel placeat esse doloribus? Unde, error! Rem error sit sequi aperiam cum neque architecto molestias, eos accusamus modi atque aspernatur dolorum nam nostrum tempora, ex omnis velit animi eaque veniam harum aliquam earum. Nemo ab dolorem ratione amet iste, repellendus nobis nam dolor adipisci, ipsam temporibus repellat commodi necessitatibus quo, corporis vero earum. Ducimus maiores iure provident repudiandae. Ex tenetur ad deleniti. Deserunt iste, vitae nulla maxime provident sapiente libero ut ea veritatis facilis maiores hic assumenda laborum adipisci voluptas asperiores voluptatibus similique facere incidunt esse. Voluptatem commodi et quam magni architecto perferendis deserunt fugit laborum inventore nisi praesentium aliquam, doloribus sunt, quaerat minus numquam maiores eaque mollitia, eius at consectetur similique unde vitae! Vel quaerat iure commodi quasi repudiandae dolor optio recusandae reiciendis eos? Architecto nemo ipsum iure est deserunt itaque iusto sit culpa nihil. Tempore, quod ullam fuga totam sit inventore soluta veniam dolore voluptas accusamus voluptatem veritatis saepe nostrum voluptatibus ea aliquid, dolores commodi temporibus."}
      </p>
      {/* <HospitalAboutImageSlider hospitalImages={hospitalImages} /> */}
      <div className="hospital-related-images-box">
        <h4 className="hospital-detail-about-sub-section-heading">
          Images related to Hospital
        </h4>
        {/* <div className="hospital-related-images">
          {hospitalImages &&
            hospitalImages.map((v, i) => {
              return (
                <div className="hospital-image-box">
                  <img src={v} alt="Hospital Image" />
                </div>
              );
            })}
        </div> */}
        <ImageSlider images={hospitalImages} />
      </div>
      <div className="hospital-location-details-box">
        <h4 className="hospital-detail-about-sub-section-heading">Location</h4>
        <div className="hospital-location-details">
          <div className="location-and-location-icon">
            <FaLocationDot />
            <div>
              <p>
                {locationDetails?.address?.recipient},
                {locationDetails?.address?.locality},
                {locationDetails?.address?.street}
                <br />
                {locationDetails?.address?.city},
                {locationDetails?.address?.postalCode}
                <br />
                {locationDetails?.address?.country}
              </p>
            </div>
          </div>
          <div className="hospital-location-transport-and-nearby-resources">
            <div className="transport-resources">
              {locationDetails?.transportResources?.Airport && (
                <div className="transport-resource-details">
                  <MdOutlineAirplanemodeActive />
                  <div className="transport-resource-distance-duration">
                    <h4>Airport</h4>
                    <p>
                      <b>Distance</b> : 45 km
                    </p>
                    <p>
                      <b>Duration</b> : 41 minutes
                    </p>
                  </div>
                </div>
              )}
              {locationDetails?.transportResources?.Taxi && (
                <div className="transport-resource-details">
                  <BsFillTaxiFrontFill />
                  <div className="transport-resource-distance-duration">
                    <h4>Taxi</h4>
                    <p>
                      <b>Availability</b> :{" "}
                      {(locationDetails?.transportResources?.Taxi
                        ?.availability &&
                        locationDetails?.transportResources?.Taxi
                          ?.description) ||
                        "Not available"}
                    </p>
                  </div>
                </div>
              )}
              {locationDetails?.transportResources?.Metro_Station && (
                <div className="transport-resource-details">
                  <FaTrainSubway />
                  <div className="transport-resource-distance-duration">
                    <h4>Metro Station</h4>
                    <p>
                      <b>Distance</b> :{" "}
                      {
                        locationDetails?.transportResources?.Metro_Station
                          ?.distance
                      }
                    </p>
                    <p>
                      <b>Duration</b> :{" "}
                      {
                        locationDetails?.transportResources?.Metro_Station
                          ?.distance
                      }
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className="nearby-resources">
              {locationDetails?.nearbyResources?.map((v) => {
                return (
                  <div
                    className="nearby-resource-details"
                    key={v?.resourceName}
                  >
                    {hospitalNearbyResourcesLogos[v?.resourceName]}
                    <div className="nearby-resource-availability">
                      <h4>{v?.resourceName}</h4>
                      <p>
                        <b>Availablity</b> :{" "}
                        {(v?.availability && v?.description) || "Not available"}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HospitalDetailAbout;
