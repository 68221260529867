import React, { useEffect, useState } from "react";
import axios from "axios";
import DashboardLayout from "../DashboardSidebar/DashboardLayout";
import {
  sidebarAgencyItems,
  sidebarFacilitatorItems,
} from "../../constants/SidebarItems";
import { useUserAuth } from "../../context/UserAuthContext";
import { API } from "../../constants/api";
import getAccessToken from "../../utils/getAccessToken";

const PatientProfile = () => {
  const { user } = useUserAuth();
  const [userData, setUserData] = useState(null);

  console.log(userData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getAccessToken();
        // console.log(token);
        const { data } = await axios.get(`${API}/patients/user/profile`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (data.item) {
          const id = Object.keys(data.item);
          // Access the corresponding value
          const userProfileData = data.item[id];
          console.log(userProfileData);
          setUserData(userProfileData);
        } else {
          console.error("Error");
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <DashboardLayout sidebarItems={sidebarAgencyItems}>
      {user && (
        <div>
          <h1>{user.displayName}</h1>
          <h3>{user.email}</h3>
        </div>
      )}
      {userData && (
        <div>
          <p>Name of Agency: {userData.Name_Of_Agency}</p>
          <div>
            <p>Pan Card:</p>
            <img src={userData.Pan_Card} alt='' width={"100px"} />
          </div>
          <div>
            <p>GST Certificate:</p>
            <img src={userData.Upload_GST_Certificate} alt='' width={"100px"} />
          </div>
          <div>
            <p>Company License:</p>
            <img src={userData.Company_License} alt='' width={"100px"} />
          </div>

          <div>
            <p>Name of Bank: {userData.Name_Of_Bank}</p>
            <p>Account Number: {userData.Account_Number}</p>
            <p>IFSC Code: {userData.IFSC_Code}</p>
          </div>

          <div>
            <p>Address: {userData.Address}</p>
            <p>Contact Number: {userData.Contact_Number}</p>
            <p>
              Company Registration Number:{" "}
              {userData.Company_Registration_Number}
            </p>
          </div>

          <p>Representative: {userData.Representative}</p>
          <p>Website: {userData.Website}</p>
        </div>
      )}
    </DashboardLayout>
  );
};

export default PatientProfile;
