import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import { AppointmentContextProvider } from "./context/AppointmentContext";
import { LoginContextProvider } from "./context/LoginContext";
import { WidthProvider } from "./context/WidthContext";
import { PrimeReactProvider } from "primereact/api";
import BlogContextApi from "./context/BlogContextApi";
import ProcedureContextApi from "./context/ProcedureContextApi";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <PrimeReactProvider>
    <LoginContextProvider>
      <AppointmentContextProvider>
        <UserAuthContextProvider>
          <WidthProvider>
            <BlogContextApi>
              <ProcedureContextApi>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </ProcedureContextApi>
            </BlogContextApi>
          </WidthProvider>
        </UserAuthContextProvider>
      </AppointmentContextProvider>
    </LoginContextProvider>
  </PrimeReactProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
