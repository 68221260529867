import React from "react";
import Heading from "../../components/Heading/Heading";
import "./Features.css";
import { featureList } from "../../constants.js";
import FeatureSlider from "../../components/FeaturesSlider/FeaturesSlider";
import { motion } from "framer-motion";

const Features = () => {
  return (
    <motion.section
      id="features"
      className="portfolio-features-section"
      initial={{ y: "50%", opacity: 0 }}
      whileInView={{ y: "0%", opacity: 1 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}
    >
      <Heading title={"FEATURES"} content={"So Why Choose Bharat One Care?"} />
      <div className="features">
        {<FeatureSlider featureList={featureList} />}
      </div>
    </motion.section>
  );
};
export default Features;
