import React, { createContext, useState } from "react";
export const ProcedureContext = createContext();
const ProcedureContextApi = ({ children }) => {

  const [procedureAndHospitalData, setProcedureAndHospitalData] = useState({});
  return (
    <ProcedureContext.Provider value={{ procedureAndHospitalData,setProcedureAndHospitalData }}>
      {children}
    </ProcedureContext.Provider>
  );
};

export default ProcedureContextApi;
