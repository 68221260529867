import React from "react";
import blog2 from "../../assets/images/blogs/blog2.svg";
import "./BlogCard.css";
import { Link, useNavigate } from "react-router-dom";
const BlogCard = ({
  title,
  blogImageUrl,
  id,
  description,
}) => {
  const navigateTo = useNavigate();
  const getLimitedDescription = () => {
    if (description) {
      if (description.length <= 150) return description;
      else return description.slice(0, 150) + "...";
    }
    return "Description not provided";
  };
  const limitedDescription = getLimitedDescription();
  return (
    <div
      className="blog-card"
      id={`blog-card-${id}`}
      onClick={() => navigateTo(`/read/blog/${title}`)}
    >
      <div className="blog-details">
        <div className="blog-card-image-box">
          <img
            src={blogImageUrl ? blogImageUrl : blog2}
            alt=""
            className="blog-card-image"
          />
        </div>
        <div className="blog-card-text-details">
          {" "}
          <h1>{title}</h1>
          <div
            className="blog-card-description"
            id={`blog-card-description-${id}`}
          >
            <p>
              {limitedDescription
                ? limitedDescription
                : "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sint pariatur neque debitis, soluta ad modi explicabo deleniti quae assumenda exercitationem!"}
            </p>
          </div>
        </div>
      </div>

      <div className="bog-card-button-box">
        <Link to={`/read/blog/${title}`}>
          <button className="blog-card-read-button">Read Article</button>
        </Link>
      </div>
    </div>
  );
};

export default BlogCard;
