import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { API } from "../../constants/api";
import axios from "axios";
import "./HotelsNearHospital.css";
import HotelCard from "../../components/HotelCard/HotelCard";
import SectionHeading from "../../components/ProcedurePageComponents/SectionHeading/SectionHeading";
import LandingPageNavbar from "../../components/LandingPageNavbar/LandingPageNavbar";
import Footer from "../../components/Footer/Footer";
const HotelsNearHospital = () => {
  const hospitalId = useSearchParams()[0].get("hospitalId");
  const [hotels, setHotels] = useState([]);
  const getHotelsNearByHospitals = async () => {
    const url = `${API}/hospital/verified/hotels?hospitalId=${hospitalId}`;
    const {
      data: { verifiedHotels },
    } = await axios.get(url);
    console.log({ verifiedHotels });
    setHotels(verifiedHotels);
  };
  useEffect(() => {
    getHotelsNearByHospitals();
  }, []);
  return (
    <div className="hotels-near-hospital-page">
      <LandingPageNavbar />
      <SectionHeading text={"Verified Hotels near Hospital"} />
      <div className="hotels-near-hospital">
        {hotels &&
          hotels.map((hotel) => {
            return (
              <HotelCard
                key={hotel?.id}
                hotelName={hotel?.hotelName}
                hotelImage={hotel?.images?.[0]}
                facilities={hotel?.facilities}
                startingPricePerNight={hotel?.minimumPricePerNightUSD}
                starStatus={hotel?.starStatus}
              />
            );
          })}
      </div>
      <Footer />
    </div>
  );
};

export default HotelsNearHospital;
