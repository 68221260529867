import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";

const BarGraphComponent = ({ chartData, options, chartWidth, chartHeight }) => {

  return (
    <div style={{ width: chartWidth, height: chartHeight }}>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default BarGraphComponent;
