import React from "react";
import HospitalOnboardByAgent from "../../../../pages/HospitalOnboardByAgent/HospitalOnboardByAgent";
import Breadcrumb from "../../../Breadcrumb/Breadcrumb";
import DashboardLayout from "../../../../pages/DashboardSidebar/DashboardLayout";
import { sidebarAgentItems } from "../../../../constants/SidebarItems";

const AddHospitalDetails = () => {
  return (
    <div>

      {/* <div
        style={{
          padding: "0px",
          marginBottom: "30px",
          marginTop: "30px",
          width: "100%",
          overflow: "auto",
          marginRight: "auto",
          marginLeft: "auto",
        }}
      > */}
      <HospitalOnboardByAgent />
      {/* </div> */}


    </div>
  );
};

export default AddHospitalDetails;
