import React, { useEffect, useState } from "react";
import "./TopDoctors.css";
import SectionHeading from "../../../../components/ProcedurePageComponents/SectionHeading/SectionHeading";
import DoctorCard from "../../../../components/ProcedurePageComponents/DoctorCard/DoctorCard";
import axios from "axios";
import MiniLoading from "../../../../components/ProcedurePageComponents/Loading/MiniLoading";
import { API } from "../../../../constants/api";
import { Link } from "react-router-dom";
const TopDoctors = ({ topDoctorsIds, hospitalId, location, doctorsFor }) => {
  const [topDoctors, setTopDoctors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewMore, setViewMore] = useState(false);
  const getSingleDoctor = async (id) => {
    // const url = `${process.env.REACT_APP_BACKEND_URL}/agenthospital/getsingledoctor/${hospitalId}/${id}`;
    const url = `${API}/agenthospital/getsingledoctor/${id}`;
    const { data } = await axios.get(url);
    return { ...data.doctor, id };
  };
  const getAllDoctors = async () => {
    setLoading(true);
    try {
      let promises = [],
        docIds = topDoctorsIds;
      if (docIds.length > 4) {
        docIds = docIds.slice(0, 4);
        setViewMore(true);
      }
      for (let id of docIds) {
        promises.push(getSingleDoctor(id));
      }
      let results = await Promise.all([...promises]);
      setTopDoctors(results);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (topDoctorsIds) getAllDoctors();
  }, [topDoctorsIds]);

  return (
    <div
      className="procedure-page-top-doctors-section progress-section"
      id="top-doctors"
    >
      <SectionHeading
        text={`Top Doctors for ${doctorsFor} `}
        subText={location}
      />
      <Link
        to={`/doctors?hospitalId=${hospitalId}`}
        className="view-more-button"
        style={{ display: "flex", justifyContent: "end", marginTop: "-40px" }}
      >
        View more
      </Link>

      {loading ? (
        <div style={{ padding: "20px" }}>
          <MiniLoading />
        </div>
      ) : (
        <div className="top-doctors">
          {topDoctors &&
            topDoctors.map((v) => {
              return (
                <DoctorCard
                  key={v?.name}
                  education={v?.qualification}
                  doctorName={v?.name}
                  doctorPhoto={v?.imageLinks?.[0]}
                  yearsOfExperience={v?.experience}
                  expertIn={doctorsFor}
                  doctorId={v?.id}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};

export default TopDoctors;
