import React, { useEffect, useState } from "react";
import Topbar from "./Topbar";
import SidebarPink from "./Sidebarpink";
import "./Sidebarpink.css";
import { useWidthContext } from "../../context/WidthContext";

// const sidebarItems = [
//   {
//     link: "/agent/dashboard",
//     icon: dashboardIcon,
//     altText: "Dashboard Icon",
//     label: "Dashboard",
//     display: "all", // 'all' means it's always displayed
//   },
//   {
//     link: "/agent/hospital-management",
//     icon: hospitalmangement,
//     altText: "Hospital Management Icon",
//     label: "Hospital Management",
//     display: "all",
//   },
//   {
//     link: "/agent/appointments",
//     icon: appointments,
//     altText: "Appointments Icon",
//     label: "Appointments",
//     display: "all",
//   },
//   {
//     link: "/agent/settings",
//     icon: settings,
//     altText: "Settings Icon",
//     label: "Settings",
//     display: "lg", // 'lg' means displayed only on large screens
//   },
//   {
//     link: "/agent/help-support",
//     icon: support,
//     altText: "Help & Support Icon",
//     label: "Help & Support",
//     display: "lg",
//   },
//   {
//     link: "/agent/my-profile",
//     icon: profile,
//     altText: "My Profile Icon",
//     label: "My Profile",
//     display: "all",
//   },
// ];

const DashboardLayout = ({ children, sidebarItems }) => {
  // const [sidebarWidth, setSidebarWidth] = useState("18%");
  // const [rightPageWidth, setRightPageWidth] = useState("82%");

  // const handleToggleWidth = () => {
  //   const newWidth = sidebarWidth === "18%" ? "6%" : "18%";
  //   setSidebarWidth(newWidth);
  //   setRightPageWidth(sidebarWidth === "18%" ? "94%" : "82%");
  // };

  const { isWide, toggleWidth } = useWidthContext();

  const sidebarWidth = isWide ? "18%" : "6%";
  const rightPageWidth = isWide ? "82%" : "94%";
  console.log(isWide);

  return (
    <div className="container-fluid">
      <div className="row p-0 sticky-top">
        <div className="left-side-box col p-0  ">
          <Topbar />
        </div>
      </div>
      <div className="row p-0 border flex-lg-row flex-column-reverse  ">
        <div
          style={{ width: sidebarWidth }}
          className={`sidebar-main-pink  border border-top-md-0  min-vh-sm-100 d-flex justify-content-center justify-content-lg-start align-items-lg-start align-items-end p-0 transition-width`}
        >
          <SidebarPink
            menuItems={sidebarItems}
            handleToggleWidth={toggleWidth}
            showText={isWide}
          />
        </div>
        <div
          style={{ width: rightPageWidth }}
          className={`sidebar-right-page transition-width`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
