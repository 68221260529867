import React, { useEffect, useState } from 'react'
import './HospitalDataComponent.css'
import ViewModal from '../ViewModal/ViewModal';
import { format } from 'date-fns';

const HospitalDataComponent = ({ infoDetails, infoLogo }) => {

  const [firstTwoLetters, setFirstTwoLetters] = useState("");

  // useEffect(() => {
  //   if (infoDetails) {
  //     // Get the first two letters from hospitalData.Name_Of_Hospital
  //     const name = hospitalData.Name_Of_Hospital;
  //     const firstTwoLetters = name
  //       .split(" ")
  //       .map((word) => word.charAt(0).toUpperCase())
  //       .join("")
  //       .slice(0, 2);
  //     setFirstTwoLetters(firstTwoLetters);


  //     const date = new Date(hospitalData.onBoardedOn);
  //     const formatDate = format(date, "dd MMM ''yy");
  //     setFormatedDate(formatDate)

  //   }
  // }, [hospitalData]);


  useEffect(() => {
    if (infoDetails.length > 0) {
      // Get the first two letters from hospitalData.Name_Of_Hospital
      const name = infoDetails.find(detail => detail.title === 'Name').value;
      const firstTwoLetters = name
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase())
        .join("")
        .slice(0, 2);
      setFirstTwoLetters(firstTwoLetters);
    }
  }, [infoDetails]);


  // console.log('Props in hospital data::::', infoLogo);



  return (
    <div className='hospital-box '>
      <div className="row m-0 mx-md-2 m-0 justify-content-center position-relative">

        <div className="col-auto p-2 m-2 d-flex align-items-center">
          <div className="first-circle d-flex justify-content-center align-items-center ">
            <div className="second-circle d-flex justify-content-center align-items-center">
              <div className={`text-content ${infoLogo ? 'm-0' : 'mt-2'}`}>
                {(infoLogo !== undefined) ?
                  (
                    <img
                      src={infoLogo}
                      alt={firstTwoLetters}
                      style={{ width: 50, height: 50 }}
                      className='rounded-circle img-fluid'
                    />
                  ) : (
                    <h4>{firstTwoLetters}</h4>
                  )}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="col d-flex justify-content-center align-items-center text-info-area me-md-5 m-0 ">
          <div className="row m-0 p-0 ">

            <div className="col-md-auto hospital-name  m-1 d-flex flex-column align-self-center">
              <div className="bold-text d-flex justify-content-center justify-content-md-start">
                <p>{hospitalData.Name_Of_Hospital}
                </p>
              </div>
              <div className="mute-text d-flex justify-content-center justify-content-md-start">
                <p className='text-muted mb-1'>Hospital Name</p>
              </div>
            </div>

            <div className="col-md-auto location-name  m-1 d-flex flex-column align-self-center ">
              <div className="bold-text d-flex justify-content-center justify-content-md-start">
                <p>{hospitalData.Location}
                </p>
              </div>
              <div className="mute-text d-flex  justify-content-center justify-content-md-start">
                <p className='text-muted mb-1'>Location</p>
              </div>
            </div>

            <div className="col-md-auto contact-number  m-1 d-flex flex-column align-self-center ">
              <div className="bold-text d-flex justify-content-center justify-content-md-start">
                <p>+91 {hospitalData.Contact_Number}
                </p>
              </div>
              <div className="mute-text d-flex  justify-content-center justify-content-md-start ">
                <p className='text-muted mb-1'>Contact number</p>
              </div>
            </div>


            <div className="col-md-auto contact-number  m-1 d-flex flex-column align-self-center ">
              <div className="bold-text d-flex justify-content-center justify-content-md-start">
                <p>{hospitalData.agentEmail}
                </p>
              </div>
              <div className="mute-text d-flex  justify-content-center justify-content-md-start">
                <p className='text-muted mb-1'>Email Id</p>
              </div>
            </div>

            <div className="col-md-auto contact-number  m-1 d-md-flex d-none flex-column align-self-center">
              <div className="bold-text d-flex justify-content-center justify-content-md-start">
                <p>{formatedDate}
                </p>
              </div>
              <div className="mute-text d-flex ">
                <p className='text-muted mb-1'>Created on</p>
              </div>
            </div>

          </div>
        </div> */}


        <div className="col d-flex justify-content-center align-items-center text-info-area me-md-5 m-0 px-1">
          <div className="row m-0 p-0">
            {infoDetails.map((item, index) => (
              <div className="col-md-auto m-1 d-flex flex-column align-self-center" key={index}>
                <div className="bold-text d-flex justify-content-center justify-content-md-start">
                  <p>{item.value}</p>
                </div>
                <div className="mute-text d-flex justify-content-center justify-content-md-start">
                  <p className="text-muted mb-1">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="position-absolute view-link d-md-flex d-none justify-content-end align-items-end p-0">
          <div className="modal-main">
            <ViewModal />
          </div>
        </div>

      </div>



    </div >
  )
}

export default HospitalDataComponent