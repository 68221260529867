import React from "react";
import "./Settings.css";
import DashboardLayout from "../DashboardSidebar/DashboardLayout";
import { sidebarFacilitatorItems } from "../../constants/SidebarItems";
import FacilitatorSettingsComponent from "../../components/FacilitatorSettingsComponent/FacilitatorSettingsComponent";

const Settings = (props) => {
  return (
    <DashboardLayout sidebarItems={props.sidebarItems}>
      <div className="settings-container">
        <h1 className="settings-header">Settings</h1>
        <FacilitatorSettingsComponent />
      </div>
    </DashboardLayout>
  );
};

export default Settings;
