import React, { useState, useEffect } from "react";
// import { faqsData } from "../../constants/Translation";
import { LandingPageFaqs, BusinessPageFaqs } from "../../constants/faq";
import "./LandingPageAccordian.css";

const LandingPageAccordian = ({ language, business, landing }) => {
  const [faqData, setFaqData] = useState(LandingPageFaqs);

  useEffect(() => {
    // if (language === "English") {
    //   setFaqData(faqsData.en);
    // } else if (language === "Hindi") {
    //   setFaqData(faqsData.hi);
    // } else if (language === "Arabic") {
    //   setFaqData(faqsData.ar);
    // } else {
    //   setFaqData(faqs);
    // }
    if (business) {
      setFaqData(BusinessPageFaqs);
    }
  }, [business, language]);
  return (
    <div className="accordion" id="accordionExample" style={{ width: "100%" }}>
      {faqData.map((faq, index) => (
        <div key={index} className="accordion-item">
          <h2 className="accordion-header" id={`heading${index}`}>
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse${index}`}
              aria-expanded="false"
              aria-controls={`collapse${index}`}
            >
              {faq.question}
            </button>
          </h2>
          <div
            id={`collapse${index}`}
            className="accordion-collapse collapse"
            aria-labelledby={`heading${index}`}
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">{faq.answer}</div>
          </div>
          {/* <div className="accordion-question"></div>
          <div className="accordion-icon">&#9654;</div> */}
          {/* {index === activeIndex && (
            <div className="accordion-content">
              <p>{faq.answer}</p>
            </div>
          )} */}
        </div>
      ))}
    </div>
  );
};

export default LandingPageAccordian;
