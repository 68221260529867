import * as React from "react";
import AccordionGroup from "@mui/joy/AccordionGroup";
import Accordion from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionSummary from "@mui/joy/AccordionSummary";
export default function AccordionControlled({ accordions }) {
  const [index, setIndex] = React.useState(0);

  const accordionStyles = {
    summary: {
      fontSize: "20px",
      color: "var(--primaryColor)",
      padding: "5px",
      fontWeight: "bolder",
    },
    details: {
      color: "var(--secondaryColor)",
    },
  };

  return (
    <AccordionGroup sx={{ maxWidth: "100%" }}>
      {accordions &&
        accordions.map((v, i) => {
          return (
            <Accordion
              key={v?.summary}
              expanded={index === i}
              onChange={(event, expanded) => {
                setIndex(expanded ? i : null);
              }}
            >
              <AccordionSummary>
                {/* {v?.summary} */}
                <h4 style={accordionStyles.summary}>{v?.summary}</h4>
              </AccordionSummary>
              <AccordionDetails style={accordionStyles.details}>
                {v?.details}
              </AccordionDetails>
            </Accordion>
          );
        })}
    </AccordionGroup>
  );
}
