import React, { useState, useEffect } from "react";
import "./EditPatientDetails.css";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "../DashboardSidebar/DashboardLayout";
import HospitalDataComponent from "../../components/HospitalDataComponent/HospitalDataComponent";
import FormGeneratorStore from "../../components/FormGenearatorAndStepper/FormGenearatorAndStepper";
import {
  addPatientDetails,
  editPatientDetails,
} from "../../constants/formconfig";
import { sidebarFacilitatorItems } from "../../constants/SidebarItems";
import axios from "axios";
import { useParams } from "react-router-dom";
import { API } from "../../constants/api";
import { format } from "date-fns";
import getAccessToken from "../../utils/getAccessToken";
import { ref, uploadBytes } from "firebase/storage";
import { storage } from "../../config/testFirebaseConfig";
import { uploadAndReturnUrls } from "../../constants/UploadeImageUrl";
import Loader from "../../components/Loader/Loader";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

const EditPatientDetails = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const patientId = queryParams.get("q");

  const [loader, setLoader] = useState(false);

  const [patientDetails, setPatientDetails] = useState(null);
  const [transformedPatientData, setTransformedPatientData] = useState(null);
  const [updatedPatientData, setUpdatedPatientData] = useState({});

  const patientData = {};

  useEffect(() => {
    if (pathname !== "/facilitator/patient-management/add-patient-details") {
      getDataFromFirebase();
    }
  }, []);

  const onAddSubmit = async (formData) => {
    setLoader(true);
    const keys = Object.keys(formData);
    const imgData = [
      formData.Medical_History.value,
      formData.Passport.value,
      formData.Photo.value,
      formData.Visa.value,
    ];
    console.log("Contact Number::::", formData.Contact_Number);
    const data = new FormData();
    keys.forEach((key) => {
      if (key === "Contact_Number") {
        const number = formData[key].value.phone.slice(
          formData[key].value.dialCode.length
        );
        data.append(key, `+${formData[key].value.dialCode}${number}`);
      } else if (key === "Country") {
        const value = formData[key].value;
        data.append(key, value);
      } else if (
        key !== "Medical_History" &&
        key !== "Passport" &&
        key !== "Photo" &&
        key !== "Visa"
      ) {
        data.append(key, formData[key].value);
      }
    });

    const url = `${API}/patients/register`;

    try {
      const token = getAccessToken();
      const result = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const patientImageRef = ref(storage, `patients/${result.data.patientId}`);

      const structuredLinks = {
        Medical_History: null,
        Passport: null,
        Photo: null,
        Visa: null,
      };

      await Promise.all(
        imgData.map(async (image, index) => {
          const uploadedPatientUrls = await uploadAndReturnUrls(
            [image],
            patientImageRef
          );
          structuredLinks[Object.keys(structuredLinks)[index]] =
            uploadedPatientUrls[0];
        })
      );

      const response = await axios.post(
        `${API}/patients/uploadfiles/${result.data.patientId}`,
        structuredLinks
      );

      navigate("/facilitator/patient-management");
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };

  const onEditSubmit = async (formData) => {
    setLoader(true);
    const keys = Object.keys(formData);
    const imgData = [
      formData.Medical_History.value,
      formData.Passport.value,
      formData.Photo.value,
      formData.Visa.value,
    ];
    const data = new FormData();
    keys.forEach((key) => {
      if (key === "Contact_Number") {
        const number = formData[key].value.phone.slice(
          formData[key].value.dialCode.length
        );
        data.append(key, `+${formData[key].value.dialCode}${number}`);
      } else if (key === "Country") {
        const value = formData[key].value;
        data.append(key, value);
      } else if (
        key !== "Medical_History" &&
        key !== "Passport" &&
        key !== "Photo" &&
        key !== "Visa"
      ) {
        data.append(key, formData[key].value);
      }
    });

    const url = `${API}/patients/edit-patient/${patientId}`;

    try {
      const token = getAccessToken();
      const result = await axios.put(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const patientImageRef = ref(storage, `patients/${result.data.patientId}`);

      const structuredLinks = {
        Medical_History: null,
        Passport: null,
        Photo: null,
        Visa: null,
      };

      await Promise.all(
        imgData.map(async (image, index) => {
          const uploadedPatientUrls = await uploadAndReturnUrls(
            [image],
            patientImageRef
          );
          structuredLinks[Object.keys(structuredLinks)[index]] =
            uploadedPatientUrls[0];
        })
      );

      const response = await axios.post(
        `${API}/patients/uploadfiles/${result.data.patientId}`,
        structuredLinks
      );

      navigate("/facilitator/patient-management");
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchData = async (patientId) => {
      try {
        // const queryParams = new URLSearchParams(search);
        // const patientId = queryParams.get("q");
        const response = await axios.get(
          `${API}/patients/getsinglepatient/${patientId}`
        );
        const { data } = response;

        const originalDate = data.Date_Of_Birth;

        const dateObject = new Date(originalDate);

        const year = dateObject.getFullYear();
        const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
        const day = dateObject.getDate().toString().padStart(2, "0");

        const formattedDate = `${year}-${month}-${day}`;

        const newPhone = data.Contact_Number;

        console.log("newPhone", newPhone);

        // Update the state with the retrieved data
        setUpdatedPatientData({
          Contact_Number: {
            // value: data.Contact_Number || "",
            value: { phone: newPhone || "" },
            isTouched: true,
          },

          Patient_Name: {
            value: data.Patient_Name || "",
            isTouched: true,
          },
          Date_Of_Birth: {
            value: formattedDate || "",
            isTouched: true,
          },
          Medical_Issue: {
            value: data.Medical_Issue || "",
            isTouched: true,
          },
          Email: {
            value: data.Email || "",
            isTouched: true,
          },
          Country: {
            value: data.Country || "",
            isTouched: true,
          },
          Gender: {
            value: data.Gender || "",
            isTouched: true,
          },
          Visa: {
            value: "",
            isTouched: false,
          },
          Passport: {
            value: "",
            isTouched: false,
          },
          Photo: {
            value: "",
            isTouched: false,
          },
          Medical_History: {
            value: "",
            isTouched: false,
          },
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (pathname === "/facilitator/patient-management/edit-patient-details") {
      const queryParams = new URLSearchParams(search);
      const patientId = queryParams.get("q");
      if (patientId !== "undefined") {
        fetchData(patientId);
      }
    }
  }, []);

  console.log("updatedFormData:::::", updatedPatientData);

  const transformData = (data) => {
    return [
      {
        title: "Name",
        value: data.Patient_Name,
        description: "Patient Name",
      },
      {
        title: "Contact Number",
        value: `${data.Contact_Number.replace("_", "")}`,
        description: "Contact number",
      },
      {
        title: "Country",
        value: data.Country,
        description: "Country",
      },
      {
        title: "Email Id",
        value: data.Email,
        description: "Email Id",
      },
      {
        title: "Scheduled on",
        value: format(new Date(data.scheduledOn), "dd MMM ''yy"),
        description: "Scheduled on",
      },
    ];
  };

  const getDataFromFirebase = async () => {
    try {
      // const {q} = useParams();
      const queryParams = new URLSearchParams(search);
      const patientId = queryParams.get("q");
      const patientData = await axios.get(
        `${API}/patients/getsinglepatient/${patientId}`
      );
      setPatientDetails(patientData.data);
      const transformedData = transformData(patientData.data);
      setTransformedPatientData(transformedData);
    } catch (err) {
      console.log(err);
    }
  };

  if (pathname === "/facilitator/patient-management/add-patient-details") {
    return (
      <DashboardLayout sidebarItems={sidebarFacilitatorItems}>
        {loader && <Loader />}
        <Breadcrumb
          breadcrumbs={[
            {
              label: "Patient Management",
              url: "/facilitator/patient-management",
            },
            {
              label: "Add Patient Details",
              url: "/facilitator/patient-management/add-patient-details",
              disabled: true,
            },
          ]}
          divider={"/"}
          styles={{
            paddingBottom: "2rem",
            // paddingTop: "1rem",
          }}
        />
        <FormGeneratorStore
          agentTypes={addPatientDetails}
          submitHandler={onAddSubmit}
          classnames="mx-auto mt-5"
        />
      </DashboardLayout>
    );
  } else {
    return (
      <DashboardLayout sidebarItems={sidebarFacilitatorItems}>
        {loader && <Loader />}
        <Breadcrumb
          breadcrumbs={[
            {
              label: "Patient Management",
              url: "/facilitator/patient-management",
            },
            {
              label: "Edit Patient Details",
              url: `/facilitator/patient-management/edit-patient-details?`,
              disabled: true,
            },
          ]}
          divider={"/"}
          styles={{
            paddingBottom: "2rem",
          }}
        />
        <div className="edit-patient-details-container">
          <div className="edit-patient-details-detail-container">
            {transformedPatientData && (
              <HospitalDataComponent infoDetails={transformedPatientData} />
            )}
          </div>
          <FormGeneratorStore
            agentTypes={editPatientDetails}
            submitHandler={onEditSubmit}
            classnames="mx-auto mt-5"
            updatedFormData={updatedPatientData}
          />
        </div>
      </DashboardLayout>
    );
  }
};

export default EditPatientDetails;
