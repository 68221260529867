import React, { useState, useEffect, useCallback } from "react";
import FormGenerator from "../FormGenerator/FormGenerator";
import "./FormGenearatorAndStepper.css";
import Stepper from "../Stepper/Stepper";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { API } from "../../constants/api";
const FormGeneratorStore = ({
  agentTypes,
  submitHandler,
  classnames,
  updatedFormData,
}) => {
  // const [disabled,setDisabled]=useState(true);
  console.log({ agentTypes });
  const [formData, setFormData] = useState({});
  const [arrayIndex, setArrayIndex] = useState(0);
  const [disabled, setDisabled] = useState({});

  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  console.log(formData);

  const setDisabledArray = useCallback(() => {
    const tempObj = {};
    agentTypes.forEach((ele, ind) => {
      // console.log(ele.stepperHeader);
      // if (ele.section === "") {
      if (ele.stepperHeader === "Summary") {
        tempObj[ind] = false;
      } else {
        tempObj[ind] = true;
      }
    });
    setDisabled(tempObj);
  }, [agentTypes]);

  const setEmptyFormData = useCallback(() => {
    let tempFormData = {};
    agentTypes.forEach((ele) => {
      ele.fields.forEach((temp) =>
        temp.forEach((item) => {
          tempFormData[item.title] = {
            value: item.intitalValue,
            isTouched: false,
          };
        })
      );
    });
    setFormData(tempFormData);
  }, [agentTypes]);

  const isFormValid = useCallback(
    (tempObj, tempInd = arrayIndex) => {
      let flag = true;
      agentTypes[tempInd].fields.forEach((ele) => {
        ele.forEach((item) => {
          const temp = item.validation(tempObj?.[item.title]?.value);
          if (temp === undefined || temp === false || flag === false)
            flag = false;
        });
      });
      return flag;
    },
    [arrayIndex, agentTypes]
  );

  const moveToNext = (event) => {
    // If FormData.Accreditation is available then the accreditation which is not in the array will set to touch:true
    if (formData.Accreditation && formData.Accreditation.value.length > 0) {
      // for (let i = 0; i < formData.Accreditation.value.length; i++) {
      if (!formData.Accreditation.value.includes("NABH")) {
        formData["NABH"].isTouched = true;
        formData["NABH"].value = "No File";
      }
      if (!formData.Accreditation.value.includes("JCI")) {
        formData["JCI"].isTouched = true;
        formData["JCI"].value = "No File";
      }
      if (!formData.Accreditation.value.includes("NABL")) {
        formData["NABL"].isTouched = true;
        formData["NABL"].value = "No File";
      }
      // }
    }
    console.log(formData);
    event.preventDefault();

    const flag = isFormValid(formData);
    setArrayIndex((ele) => {
      if (flag) {
        if (ele < agentTypes.length - 1) {
          return ele + 1;
        } else {
          return ele;
        }
      } else {
        return ele;
      }
    });
  };

  const moveToPrev = (event) => {
    console.log(formData);
    if (formData.Accreditation && formData.Accreditation.value.length > 0) {
      if (!formData.Accreditation.value.includes("NABH")) {
        formData["NABH"].isTouched = false;
        formData["NABH"].value = "";
      }
      if (!formData.Accreditation.value.includes("JCI")) {
        formData["JCI"].isTouched = false;
        formData["JCI"].value = "";
      }
      if (!formData.Accreditation.value.includes("NABL")) {
        formData["NABL"].isTouched = false;
        formData["NABL"].value = "";
      }
    }
    event.preventDefault();
    // const flag = isFormValid(formData);
    setArrayIndex((ele) => {
      if (ele > 0) {
        return ele - 1;
      } else {
        if (pathname.includes("agent/hospital-management/add-hospital")) {
          return navigate("/agent/hospital-management");
        }
        return ele;
      }
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const flag = isFormValid(formData);
    if (flag) {
      submitHandler(formData);
      // setArrayIndex(0);
      // reset();
    }
  };

  useEffect(() => {
    setEmptyFormData();
    setDisabledArray();
  }, [setEmptyFormData, setDisabledArray]);

  const handleChange = (field, newValue) => {
    setFormData((obj) => {
      let tempObj = { ...obj };
      const tempField = { ...tempObj[field], value: newValue };
      tempObj[field] = tempField;
      return tempObj;
    });
    const tempObj = Object.create(formData);
    const tempField = { ...tempObj[field], value: newValue };
    tempObj[field] = tempField;

    const isValid = isFormValid(tempObj);
    if (disabled[agentTypes[arrayIndex.section]] !== !isValid) {
      setDisabled((obj) => {
        const tempObj = Object.create(obj);
        tempObj[arrayIndex] = !isValid;
        return tempObj;
      });
    }
  };

  const handleTouch = (field) => {
    setFormData((obj) => {
      const tempObj = { ...obj };
      tempObj[field] = { ...tempObj[field], isTouched: true };
      return tempObj;
    });
  };

  const reset = () => {
    setEmptyFormData();
    setDisabledArray();
  };

  const stepperClickHandler = (value) => {
    // if (!isFormValid(formData)) return;
    const ind = agentTypes.findIndex((val) => {
      return val.stepperHeader === value;
    });
    if (ind < arrayIndex) setArrayIndex(ind);
    else {
      let flag = true;
      for (let i = arrayIndex; i < ind; i++) {
        if (!isFormValid(formData, i)) {
          flag = false;
        }
      }
      if (flag) setArrayIndex(ind);
    }
    return;
  };

  useEffect(() => {
    if (updatedFormData) {
      setFormData(updatedFormData);
    }
  }, [updatedFormData]);

  useEffect(() => {
    const isValid = isFormValid(formData);
    setDisabled((obj) => {
      const tempObj = Object.create(obj);
      tempObj[arrayIndex] = !isValid;
      return tempObj;
    });
  }, [formData]);

  const temp = agentTypes.map((ele) => ele.stepperHeader);
  const stepper = [...new Set(temp)];

  return (
    // <div className='container-fluid field-main border border-danger'>
    // <div className='row '>
    <div
      className={`col-sm-8 col-10   field-box field-main  mt-sm-0 mt-5 ${classnames}`}
    >
      <Stepper
        steps={stepper}
        section={agentTypes?.[arrayIndex]?.stepperHeader}
        stepperClickHandler={stepperClickHandler}
      />
      <form>
        {agentTypes.map((ele, ind) => {
          if (arrayIndex === ind) {
            return (
              <FormGenerator
                key={arrayIndex}
                array={agentTypes[arrayIndex]}
                handleChange={handleChange}
                formData={formData}
                handleTouch={handleTouch}
                reset={reset}
                arrayIndex={arrayIndex}
                moveToPrev={moveToPrev}
                moveToNext={moveToNext}
                onSubmit={onSubmit}
                disabled={disabled?.[arrayIndex]}
                isLast={agentTypes.length - 1 === arrayIndex}
              />
            );
          } else {
            return null;
          }
        })}
      </form>
      {/* <p>{JSON.stringify(formData)}</p> */}
    </div>
    // </div>
    // </div>
  );
};

export default FormGeneratorStore;
