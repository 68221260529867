import React from "react";
import "./ChipComponent.css";
import cross from "../../assets/images/red-cross.png";
import { Avatar } from "@mui/material";
const ChipComponent = ({ doctor, clickHandler }) => {
  return (
    <div className="outsid-box">
      <Avatar src={doctor.image[0]} alt="doctor-image" />
      <div className="doctor-information">
        <p className="doctor-name">{doctor.name}</p>
        <div className="doctor-bottom-info">
          <p className="doctor-experience">{`${doctor.experience} years experienced`}</p>
          <p className="doctor-qualification">{`|${doctor.qualification}`}</p>
        </div>
      </div>
      <img
        src={cross}
        alt="cross"
        className="red-cross"
        onClick={() => {
          clickHandler(doctor.id);
        }}
      />
    </div>
  );
};

export default ChipComponent;
