import React, { useState, useEffect, useContext } from "react";
import "./AdminBlogCard.css";
import { Link, useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import BlogPopup from "../BlogPopup/BlogPopup";
import axios from "axios";
import toast from "react-hot-toast";
import { BlogContext } from "../../context/BlogContextApi";
import { API } from "../../constants/api";

const AdminBlogCard = ({ title, id, description }) => {
  const { getBlogs } = useContext(BlogContext);
  const [blogPopup, setBlogPopup] = useState(false);
  const navigateTo = useNavigate();
  function openBlogPopup() {
    setBlogPopup(true);
  }
  function closeBlogPopup() {
    setBlogPopup(false);
  }
  const getLimitedDescription = () => {
    if (description) {
      if (description.length <= 150) return description;
      else return description.slice(0, 150) + "...";
    }
    return "Description not provided";
  };
  const limitedDescription = getLimitedDescription();
  const deleteBlog = async () => {
    const url = `${API}/blog/delete/${id}`;
    const {
      data: { success, message },
    } = await axios.delete(url);
    getBlogs();
    return message;
  };
  const deleteBlogHandler = () => {
    toast.promise(deleteBlog(), {
      loading: "Deleting Blog...",
      success: (message) => {
        closeBlogPopup();
        return message;
      },
      error: (err) => {
        if (err.response) {
          return err.response.data.message;
        }
        return err.message;
      },
    });
  };

  return (
    <div className="admin-blog-card" id={`admin-blog-card-${id}`}>
      <div
        className="admin-blog-card-details"
        onClick={() => navigateTo(`/read/blog/${title}`)}
      >
        <div className="admin-blog-card-text-details">
          <h3>{title}</h3>
          <div
            className="admin-blog-card-description"
            id={`admin-blog-card-description-${id}`}
          >
            <p>
              {limitedDescription
                ? limitedDescription
                : "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sint pariatur neque debitis, soluta ad modi explicabo deleniti quae assumenda exercitationem!"}
            </p>
          </div>
        </div>
      </div>

      <div className="admin-blog-card-button-box">
        <Link to={`/console/edit/blog/${title}`}>
          <FaEdit className="admin-blog-card-button" />
        </Link>
        <MdDelete className="admin-blog-card-button" onClick={openBlogPopup} />
      </div>
      <BlogPopup
        openState={blogPopup}
        closeHandler={closeBlogPopup}
        action={deleteBlogHandler}
      />
    </div>
  );
};

export default AdminBlogCard;
