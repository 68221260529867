import { useState } from "react"

const useContactUsSidebar = () => {
    const [contactUsSidebarToggle, setContactUsSidebarToggle] = useState(false);
    const openContactUsSidebarHandler = () => {
        setContactUsSidebarToggle(true);
    };
    const closeContactUsSidebarHandler = () => {
        document.getElementById("portfolio-contact-us-sidebar").style.transform =
            "translateX(100%)";
        setTimeout(() => setContactUsSidebarToggle(false), 500);
    };
    return { contactUsSidebarToggle, openContactUsSidebarHandler, closeContactUsSidebarHandler };
}
export default useContactUsSidebar;