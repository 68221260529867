import React, { useEffect, useState } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import "./App.css";
import { TestPage } from "./pages/TestPage/TestPage";
import IndexPage from "./pages/Login/IndexPage";
import LandingPage from "./pages/LandingPage/LandingPage";
import { HomePage } from "./pages/HomePage/HomePage";
import TermsAndConditionsPage from "./pages/TermsAndConditionsPage/TermsAndConditionsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage/PrivacyPolicyPage";
import { AgentFacilitatorOnboarding } from "./pages/AgentFacilitatorOnboarding/AgentFacilitatorOnboarding";
import { AgentOnboarding } from "./pages/AgentOnboarding/AgentOnboarding";
import { HospitalOnboarding } from "./pages/HospitalOnboarding/HospitalOnboarding";
import DiagnosticOnboarding from "./pages/DiagnosticOnboarding/DiagnosticOnboarding";
import HospitalOnboardByAgent from "./pages/HospitalOnboardByAgent/HospitalOnboardByAgent";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import HospitalImages from "./components/HospitalImages/HospitalImages";
import Portfolio from "./pages/Portfolio/Portfolio";
import CreateBlog from "./pages/Admin/CreateBlog/CreateBlog";
import ContactUs from "./pages/ContactUs/ContactUs";
import { Toaster } from "react-hot-toast";
import AllBlogs from "./pages/Blogs/AllBlogs";
import Dashboard from "./pages/Dashboard/Dashboard";
import WelcomeStatus from "./components/WelcomeStatus/WelcomeStatus";
import SupervisorDashboard from "./pages/SupervisorDashboard/SupervisorDashboard";
// import AddHospital from "./components/AddHospital/AddHospital";
import AgencyStatus from "./components/AgencyStatus/AgencyStatus";
import HospitalManagement from "./components/HospitalManagement/HospitalManagement";
import DashboardAddHospitalPage from "./components/HospitalManagement/components/HospitalOnboarding/DashboardAddHospitalPage";
import ReadBlog from "./pages/ReadBlog/ReadBlog";
import Console from "./pages/Admin/Console/Console";
import AddRemovePortfolioHospital from "./pages/Admin/PortfolioHospital/AddRemovePortfolioHospital";
import AdminBlogs from "./pages/Admin/Blogs/AdminBlogs";
import EditBlog from "./pages/Admin/EditBlog/EditBlog";
import PatientManagement from "./pages/PatientManagement/PatientManagement";
import AddHospitalDetails from "./components/HospitalManagement/components/AddHospitalDetails/AddHospitalDetails";
import EditPatientDetails from "./pages/EditPatientDetails/EditPatientDetails";
import HospitalStatus from "./components/HospitalStatus/HospitalStatus";
import PatientManagementTable from "./components/PatientManagementTable/PatientManagementTable";
import DiagnosticManagementTable from "./components/DiagnosticManagement/DiagnosticManagementTable";
import BookingsTable from "./components/BookingsTable/BookingsTable";
import Bookings from "./components/Bookings/Bookings";
import HospitalBookings from "./components/HospitalBookings/HospitalBookings";
import ViewBooking from "./components/ViewBooking/ViewBooking";
// import TestPage2 from "./pages/TestPage/TestPage2";
import HelpAndSupport from "./pages/HelpAndSupport/HelpAndSupport";
import Settings from "./pages/Settings/Settings";
import Accounts from "./pages/Admin/Accounts/Accounts";
// import Hospitals from "./pages/Hospitals/Hospitals";
// import Doctors from "./pages/Doctors/Doctors";

import Procedures from "./pages/Procedures/Procedures";
import BlogPreview from "./pages/Admin/BlogPreview/BlogPreview";
import {
  sidebarAgencyItems,
  sidebarHospitalItems,
  sidebarAgentItems,
  sidebarFacilitatorItems,
  sidebarDiagnosticItems,
} from "./constants/SidebarItems";
import TestPage3 from "./pages/TestPage/TestPage3";
import FacilitatorProfile from "./pages/Profile/FacilitatorProfile";
import PatientProfile from "./pages/Profile/PatientProfile";
import HospitalProfile from "./pages/Profile/HospitalProfile";
import AgentProfile from "./pages/Profile/AgentProfile";
// import TestPage4 from "./pages/TestPage/TestPage4";
import DoctorInfoPage from "./pages/DoctorInfoPage/DoctorInfoPage";
import HospitalPreviewPanel from "./pages/TestPage/TestPage3";
import ProcedurePage from "./pages/PROCEDURE/ProcedurePage/ProcedurePage";
// import HospitalInfoPage from "./pages/HospitalInfoPage/HospitalInfoPage";
import AllHospitals from "./pages/AllHospitals/AllHospitals";
import AllDoctors from "./pages/AllDoctors/AllDoctors";
import ErrorPage from "./pages/404/404";
import LogoUploadTest from "./pages/TestPage/LogoUploadTest";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./config/testFirebaseConfig";
import HospitalDetailTestPage from "./pages/HospitalDetailTestPage/HospitalDetailTestPage";
import HotelsNearHospital from "./pages/HotelsNearHospital/HotelsNearHospital";
import SimilarHospitalsPage from "./pages/SimilarHospitalsPage/SimilarHospitalsPage";
import ImageSliderTest from "./pages/TestPage/ImageSliderTest";
import DiagnosticDashboard from "./pages/DiagnosticDahsboard/DiagnosticDashboard";
import CustomerDashboard from "./pages/CustomerSupportDashboard/CustomerDashboard";
import DiagnosticProfile from "./pages/Profile/DiagnosticProfile";

function App() {
  const [userEmail, setUserEmail] = useState("");
  const handleUserEmailChange = (email) => {
    setUserEmail(email);
  };

  const { role } = useParams();
  console.log(role, "asdhkjahdkhakjdhk");

  return (
    <div className="App">
      <Routes>
        {/* Webpage Routes */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/image/slider" element={<ImageSliderTest />} />
        <Route path="/hospitals" element={<AllHospitals />} />
        <Route path="/doctors" element={<AllDoctors />} />
        <Route path="/doctor/info" element={<DoctorInfoPage />} />
        <Route path="/procedures" element={<Procedures />} />
        <Route path="/procedure" element={<ProcedurePage />} />
        <Route path="/business" element={<Portfolio />} />
        <Route path="/blogs" element={<AllBlogs />} />
        <Route path="/read/blog/:title" element={<ReadBlog />} />
        <Route path="/verified/hotels" element={<HotelsNearHospital />} />
        <Route path="/similar/hospitals" element={<SimilarHospitalsPage />} />
        {/* Console Routes */}
        <Route
          path="/console/portfolio-hospitals"
          element={
            <Console>
              <AddRemovePortfolioHospital />
            </Console>
          }
        />
        <Route
          path="/console/blogs"
          element={
            <Console>
              <AdminBlogs />
            </Console>
          }
        />
        <Route
          path="/console/create/blog"
          element={
            <Console>
              <CreateBlog />
            </Console>
          }
        />
        <Route
          path="/console/edit/blog/:title"
          element={
            <Console>
              <EditBlog />
            </Console>
          }
        />
        <Route path="/preview/blog/:title" element={<BlogPreview />} />
        <Route
          path="/customer-support/home"
          element={
            <Console>
              <CustomerDashboard />
            </Console>
          }
        />
        <Route
          path="/console/accounts"
          element={
            <ProtectedRoute>
              <Console>
                <Accounts />
              </Console>
            </ProtectedRoute>
          }
        />
        <Route
          path="/adminlogin"
          element={<IndexPage onUserEmailChange={handleUserEmailChange} />}
        />
        {/* Onboarding Routes */}
        <Route
          path="/onboarding/facilitator"
          element={
            <ProtectedRoute>
              <AgentFacilitatorOnboarding />
            </ProtectedRoute>
          }
        />
        <Route
          path="/onboarding/hospital"
          element={
            <ProtectedRoute>
              <HospitalOnboarding />
            </ProtectedRoute>
          }
        />
        <Route
          path="/onboarding/agent"
          element={
            <ProtectedRoute>
              <AgentOnboarding />
            </ProtectedRoute>
          }
        />
        <Route
          path="/onboarding/diagnostic"
          element={
            <ProtectedRoute>
              <DiagnosticOnboarding />
            </ProtectedRoute>
          }
        />

        {/**APP ROUTES */}
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/signup"
          element={<IndexPage onUserEmailChange={handleUserEmailChange} />}
        />
        <Route
          path="/login"
          element={<IndexPage onUserEmailChange={handleUserEmailChange} />}
        />
        <Route
          path="/customer-support/login"
          element={<IndexPage onUserEmailChange={handleUserEmailChange} />}
        />
        <Route
          path="/customer-support/signup"
          element={<IndexPage onUserEmailChange={handleUserEmailChange} />}
        />

        <Route
          path="/forgot-password"
          element={<IndexPage onUserEmailChange={handleUserEmailChange} />}
        />
        <Route path="/termsofuse" element={<TermsAndConditionsPage />} />
        <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />

        {/**AGENT ROUTES */}
        <Route
          path="/agent/appointments"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agent/appointments/hospital-details"
          element={
            <ProtectedRoute>
              <HospitalOnboardByAgent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agent/dashboard"
          element={
            <ProtectedRoute>
              <WelcomeStatus />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agent/hospital-management"
          element={
            <ProtectedRoute>
              <HospitalManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agent/hospital-management/add-hospital"
          element={
            <ProtectedRoute>
              <DashboardAddHospitalPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agent/hospital-management/add-hospital-details"
          element={
            <ProtectedRoute>
              <AddHospitalDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agent/help-and-support"
          element={
            <ProtectedRoute>
              <HelpAndSupport sidebarItems={sidebarAgentItems} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agent/settings"
          element={
            <ProtectedRoute>
              <Settings sidebarItems={sidebarAgentItems} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agent/my-profile"
          element={
            <ProtectedRoute>
              <AgentProfile />
            </ProtectedRoute>
          }
        />

        {/**Diagnostic ROUTES */}
        <Route
          path="/diagnostic/dashboard"
          element={
            <ProtectedRoute>
              <DiagnosticDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/diagnostic/patient-management"
          element={
            <ProtectedRoute>
              <DiagnosticManagementTable />
            </ProtectedRoute>
          }
        />

        <Route
          path="/diagnostic/help-and-support"
          element={
            <ProtectedRoute>
              <HelpAndSupport sidebarItems={sidebarDiagnosticItems} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/diagnostic/settings"
          element={
            <ProtectedRoute>
              <Settings sidebarItems={sidebarDiagnosticItems} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/diagnostic/my-profile"
          element={
            <ProtectedRoute>
              <DiagnosticProfile />
            </ProtectedRoute>
          }
        />
        {/**FACILITATOR ROUTES */}
        <Route
          path="/facilitator/dashboard"
          element={
            <ProtectedRoute>
              <SupervisorDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/facilitator/patient-management"
          element={
            <ProtectedRoute>
              <PatientManagementTable />
            </ProtectedRoute>
          }
        />
        <Route
          path="/facilitator/patient-management/add-patient-details"
          element={
            <ProtectedRoute>
              <EditPatientDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/facilitator/patient-management/edit-patient-details"
          element={
            <ProtectedRoute>
              <EditPatientDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/facilitator/bookings"
          element={
            <ProtectedRoute>
              <Bookings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/facilitator/bookings/edit-booking"
          element={
            <ProtectedRoute>
              <EditPatientDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/facilitator/bookings/:id"
          element={
            <ProtectedRoute>
              <ViewBooking />
            </ProtectedRoute>
          }
        />

        <Route
          path="/facilitator/help-and-support"
          element={
            <ProtectedRoute>
              <HelpAndSupport sidebarItems={sidebarFacilitatorItems} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/facilitator/settings"
          element={
            <ProtectedRoute>
              <Settings sidebarItems={sidebarFacilitatorItems} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/facilitator/my-profile"
          element={
            <ProtectedRoute>
              <FacilitatorProfile />
            </ProtectedRoute>
          }
        />

        {/**HOSPITAL ROUTES */}
        <Route
          path="/hospital/dashboard"
          element={
            <ProtectedRoute>
              <HospitalStatus />
            </ProtectedRoute>
          }
        />
        <Route
          path="/hospital/patient-management"
          element={
            <ProtectedRoute>
              <PatientManagementTable />
            </ProtectedRoute>
          }
        />
        <Route
          path="/hospital/bookings"
          element={
            <ProtectedRoute>
              <HospitalBookings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/hospital/help-and-support"
          element={
            <ProtectedRoute>
              <HelpAndSupport sidebarItems={sidebarHospitalItems} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/hospital/settings"
          element={
            <ProtectedRoute>
              <Settings sidebarItems={sidebarHospitalItems} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/hospital/my-profile"
          element={
            <ProtectedRoute>
              <HospitalProfile />
            </ProtectedRoute>
          }
        />

        <Route
          path="/patient/my-profile"
          element={
            <ProtectedRoute>
              <PatientProfile />
            </ProtectedRoute>
          }
        />

        {/**TESTING */}
        <Route
          path="/portfolio/doctor/info"
          element={
            <ProtectedRoute>
              <DoctorInfoPage />
            </ProtectedRoute>
          }
        />
        <Route path="/hospital/info" element={<HospitalDetailTestPage />} />
        <Route path="/logo/upload/testpage" element={<LogoUploadTest />} />
        <Route
          path="/testpage3"
          element={<HelpAndSupport sidebarItems={sidebarDiagnosticItems} />}
        />
        <Route
          path="/HospitalPreviewPanel"
          element={<HospitalPreviewPanel hospitalId={`-NjY0pM2zQj1pJPk--Tu`} />}
        />
        <Route
          path="/agency/help-and-support"
          element={
            <ProtectedRoute>
              <HelpAndSupport sidebarItems={sidebarAgencyItems} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agency/help-and-support"
          element={
            <ProtectedRoute>
              <HelpAndSupport sidebarItems={sidebarAgencyItems} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agency/dashboard"
          element={
            <ProtectedRoute>
              <AgencyStatus />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agency/patient-management"
          element={
            <ProtectedRoute>
              <PatientManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agency/settings"
          element={
            <ProtectedRoute>
              <Settings sidebarItems={sidebarAgencyItems} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agency/settings"
          element={
            <ProtectedRoute>
              <Settings sidebarItems={sidebarAgencyItems} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/hospital-images"
          element={
            <ProtectedRoute>
              <HospitalImages />
            </ProtectedRoute>
          }
        />

        {/* Do not add any route below this 404 route */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Toaster position="bottom-center" reverseOrder={false} />
    </div>
  );
}

export default App;
