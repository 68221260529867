import { indianCities } from "../assets/custom-data/indianCities";
import { worldCountries } from "../assets/custom-data/worldCountries";
import { phoneValidation } from "./formconfig";

const indianCitiesOptions = indianCities.map((item, ind) => {
  const obj = {
    label: item,
    id: ind,
  };
  return obj;
});

export const facilitatorProfile = [
  {
    section: "Tell us about yourself",
    stepperHeader: "Basics",
    fields: [
      [
        {
          title: "Name_Of_Agency",
          label: "Name Of Agency",
          placeholder: "Health Today",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => {},
          errorELe: "please enter a valid non empty name ",
        },
        {
          title: "Representative",
          label: "Representative",
          placeholder: "Abhay Terra",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => {},
          errorELe: "please enter a valid non empty name ",
        },
      ],
      [
        {
          title: "Contact_Number",
          label: "Contact Number",
          placeholder: "Enter Contact Number",
          intitalValue: { dialCode: "", phone: "" },
          type: "tele",
          required: true,
          validation: phoneValidation,
          onChange: () => {},
          errorELe:
            "please enter a 10 digit mobile number without country code",
        },
      ],
      [
        {
          title: "Website",
          label: "Website(Optional)",
          placeholder: "www.healthtoday@gmail.com",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (string) => {
            if (string?.length === 0) return true;
            var res = string?.match(
              /^((https?):\/\/)?([w|W]{3}\.)+[a-zA-Z0-9\-\.]{3,}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/
            );
            return res !== null && string !== undefined;
          },
          onChange: () => {},
          errorELe:
            "please enter a valid website like www.abc.com or leave it empty",
        },
      ],
      [
        {
          title: "Address",
          label: "Address",
          placeholder: "#71,Whitefield,Oak field Extension,Bangalore-43 ",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => {},
          errorELe: "please enter a valid non empty address",
        },
      ],
    ],
  },
  {
    section: "Upload License",
    stepperHeader: "License",
    fields: [
      [
        {
          title: "Company_Registration_Number",
          label: "Company Registration Number",
          placeholder: "**** **** **** ****",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => {},
          errorELe: "please enter a document",
        },
      ],
      [
        {
          title: "Company_License",
          label: "Company License",
          onChange: () => {},
          placeholder: "",
          intitalValue: "",
          type: "file",
          required: false,
          validation: (value) => {
            return true;
          },
          errorELe: "please enter a document",
        },
      ],
      [
        {
          title: "Uploaded_Company_License",
          label: "Uploaded Company License",
          type: "img",
          disabled: true,
          validation: (input) => {
            return true;
          },
          render: function (formData, handleChange, handleTouch) {
            return (
              <img
                src={formData.Company_License.value}
                alt="Uploaded Company License"
                style={{
                  height: "100px",
                  width: "auto",
                  objectFit: "contain",
                }}
              />
            );
          },

          errorELe: "please enter a document",
        },
      ],
    ],
  },
  {
    section: "Upload Documents",
    stepperHeader: "Documents",
    fields: [
      [
        {
          title: "Upload_GST_Certificate",
          label: "Upload GST Certificate",
          onChange: () => {},
          placeholder: "",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a document",
        },
      ],
      [
        {
          title: "Upload_GST_Certificate",
          label: "Upload GST Certificate",
          onChange: () => {},
          placeholder: "",
          intitalValue: "",
          type: "file",

          validation: (input) => {
            return true;
          },
          render: function (formData, handleChange, handleTouch) {
            return (
              <img
                src={formData.Upload_GST_Certificate.value}
                alt="Upload GST Certificate"
                style={{
                  height: "100px",
                  width: "auto",
                  objectFit: "contain",
                }}
              />
            );
          },

          errorELe: "please enter a document",
        },
      ],
      [
        {
          title: "Pan_Card",
          label: "Pan Card",
          onChange: () => {},
          placeholder: "",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a document",
        },
      ],
      [
        {
          title: "Pan_Card",
          label: "Pan Card",
          onChange: () => {},
          placeholder: "",
          intitalValue: "",
          type: "file",

          validation: (input) => {
            return true;
          },
          render: function (formData, handleChange, handleTouch) {
            return (
              <img
                src={formData.Pan_Card.value}
                alt="Pan Card"
                style={{
                  height: "100px",
                  width: "auto",
                  objectFit: "contain",
                }}
              />
            );
          },

          errorELe: "please enter a document",
        },
      ],
    ],
  },
  {
    section: "Upload bank Details",
    stepperHeader: "Bank Details",
    fields: [
      [
        {
          title: "Name_Of_Bank",
          label: "Name Of Bank",
          placeholder: "State Bank Of India",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => {},
          errorELe: "please enter a non empty name",
        },
      ],
      [
        {
          title: "Account_Number",
          label: "Account Number",
          placeholder: "12312312312",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (string) => {
            if (string?.length === 0) return true;
            var res = string?.match(/^[0-9]{9,18}$/);
            return res !== null && string !== undefined;
          },
          onChange: () => {},
          errorELe: "please enter a valid account number of 9 to 18 digits",
        },
      ],
      [
        {
          title: "IFSC_Code",
          label: "IFSC Code",
          placeholder: "SBIN0001234",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (ifsc_Code) => {
            let regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
            if (ifsc_Code == null) {
              return false;
            }
            if (regex.test(ifsc_Code) == true) {
              return true;
            } else {
              return false;
            }
          },
          onChange: () => {},
          errorELe: "please enter a valid IFSC code of 11 digits",
        },
      ],
    ],
  },
];

export const hospitalProfile = [
  {
    section: "Tell us About Yourself",
    stepperHeader: "Basics",
    fields: [
      [
        {
          title: "Name_Of_Hospital",
          label: "Name Of Hospital",
          placeholder: "St. Marks Hospital",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => {},
          errorELe: "please enter a non empty name",
        },
      ],
      [
        {
          title: "Contact_Number",
          label: "Contact Number",
          placeholder: "Enter Contact Number",
          intitalValue: { dialCode: "", phone: "" },
          type: "tele",
          required: true,
          validation: phoneValidation,
          onChange: () => {},
          errorELe:
            "please enter a 10 digit mobile number without country code",
        },
        {
          title: "Location",
          label: "Location",
          placeholder: "Location",
          type: "searchable-select",
          multiple: false,
          options: indianCitiesOptions,
          initialValue: {
            label: "Mumbai",
            id: 124,
          },
          errorELe: "please select at least one city",
          validation: (value) => {
            return value !== undefined && value !== "";
          },
        },
      ],
      [
        {
          type: "radio",
          title: "Nationality",
          intitalValue: "indian",
          radioFields: [
            {
              value: "indian",
              title: "Indian",
            },
            {
              value: "notIndian",
              title: "Non-Indian",
            },
          ],
          required: true,
          onChange: () => {},
          validation: (input) => {
            return true;
          },
        },
      ],
      [
        // {
        //   type: "radio",
        //   title: "Accreditation",
        //   intitalValue: "NABH",
        //   radioFields: [
        //     {
        //       value: "NABH",
        //       title: "NABH",
        //     },
        //     {
        //       value: "JCI",
        //       title: "JCI",
        //     },
        //   ],
        //   required: true,
        //   onChange: () => {},
        //   validation: (input) => {
        //     return true;
        //   },
        // },
        {
          type: "checkbox",
          title: "Accreditation",
          intitalValue: [],
          checkboxFields: [
            {
              value: "NABH",
              title: "NABH",
            },
            {
              value: "JCI",
              title: "JCI",
            },
            {
              value: "NABL",
              title: "NABL",
            },
          ],
          required: true,
          onChange: () => {},
          validation: (value) => {
            return value && value.length !== 0;
          },
        },
      ],
    ],
  },
  {
    section: "Upload License",
    stepperHeader: "License",
    fields: [
      [
        {
          title: "License",
          label: "License",
          onChange: () => {},
          placeholder: "View License",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a valid document",
        },
      ],
      [
        {
          title: "License",
          label: "License",
          onChange: () => {},
          placeholder: "View License",
          intitalValue: "",
          type: "img",
          validation: (input) => {
            return true;
          },
          render: function (formData, handleChange, handleTouch) {
            return (
              <embed
                src={formData.License.value}
                alt="License"
                style={{
                  height: "100px",
                  width: "auto",
                  objectFit: "contain",
                }}
              />
            );
          },
          errorELe: "please enter a valid document",
        },
      ],
      [
        {
          title: "NABH",
          label: "Accreditation Certificate (NABH)",
          tag: "NABH",
          onChange: () => {},
          placeholder: "upload Certificate",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a valid document",
        },
        {
          title: "JCI",
          label: "Accreditation Certificate (JCI)",
          tag: "JCI",
          onChange: () => {},
          placeholder: "upload Certificate",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a valid document",
        },
        {
          title: "NABL",
          label: "Accreditation Certificate (NABL)",
          tag: "NABL",
          onChange: () => {},
          placeholder: "upload Certificate",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a valid document",
        },
      ],
      [
        // {
        //   title: "Accreditation_Certificate",
        //   label: "Accreditation Certificate",
        //   onChange: () => {},
        //   placeholder: "upload Certificate",
        //   intitalValue: "",
        //   type: "img",
        //   validation: (input) => {
        //     return true;
        //   },
        //   render: function (formData, handleChange, handleTouch) {
        //     console.log("FORM DATA", formData);
        //     return (
        //       <img
        //         src={formData.Accreditation_Certificate.value}
        //         alt="Accreditation Certificate"
        //         style={{
        //           height: "100px",
        //           width: "auto",
        //           objectFit: "contain",
        //         }}
        //       />
        //     );
        //   },
        //   errorELe: "please enter a valid document",
        // },
        {
          title: "NABH",
          label: "Accreditation Certificate (NABH)",
          tag: "NABH",
          onChange: () => {},
          placeholder: "upload Certificate",
          intitalValue: "",
          type: "img",
          required: false,
          validation: (value) => {
            return true;
          },
          render: function (formData, handleChange, handleTouch) {
            return (
              formData.NABH.value && (
                <embed
                  src={formData.NABH.value}
                  alt="Accreditation Certificate (NABH)"
                  style={{
                    height: "100px",
                    width: "auto",
                    objectFit: "contain",
                  }}
                />
              )
            );
          },
        },
        {
          title: "JCI",
          label: "Accreditation Certificate (JCI)",
          tag: "JCI",
          onChange: () => {},
          placeholder: "upload Certificate",
          intitalValue: "",
          type: "img",
          required: false,
          validation: (value) => {
            return true;
          },
          render: function (formData, handleChange, handleTouch) {
            return (
              formData.JCI.value && (
                <embed
                  src={formData.JCI.value}
                  alt="Accreditation Certificate (JCI)"
                  style={{
                    height: "100px",
                    width: "auto",
                    objectFit: "contain",
                  }}
                />
              )
            );
          },
        },
        {
          title: "NABL",
          label: "Accreditation Certificate (NABL)",
          tag: "NABL",
          onChange: () => {},
          placeholder: "upload Certificate",
          intitalValue: "",
          type: "img",
          required: false,
          validation: (value) => {
            return true;
          },
          render: function (formData, handleChange, handleTouch) {
            console.log("FORM DATA", formData);
            return (
              formData.NABL.value && (
                <img
                  src={formData.NABL.value}
                  alt="Accreditation Certificate (NABL)"
                  style={{
                    height: "100px",
                    width: "auto",
                    objectFit: "contain",
                  }}
                />
              )
            );
          },
        },
      ],
    ],
  },
  {
    section: "Upload Documents",
    stepperHeader: "Documents",
    fields: [
      [
        {
          title: "GST_Certificate",
          label: "GST certificate",
          onChange: () => {},
          placeholder: "upload GST Certificate",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a valid document",
        },
      ],
      [
        {
          title: "GST_Certificate",
          label: "GST certificate",
          onChange: () => {},
          placeholder: "upload GST Certificate",
          intitalValue: "",
          type: "img",
          validation: (input) => {
            return true;
          },
          render: function (formData, handleChange, handleTouch) {
            return (
              <embed
                src={formData.GST_Certificate.value}
                alt="GST Certificate"
                style={{
                  height: "100px",
                  width: "auto",
                  objectFit: "contain",
                }}
              />
            );
          },
          errorELe: "please enter a valid document",
        },
      ],
      [
        {
          title: "PAN_CARD",
          label: "Pan Card",
          onChange: () => {},
          placeholder: "upload Pan Card",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a valid document",
        },
      ],
      [
        {
          title: "PAN_CARD",
          label: "Pan Card",
          onChange: () => {},
          placeholder: "upload Pan Card",
          intitalValue: "",
          type: "img",
          validation: (input) => {
            return true;
          },
          render: function (formData, handleChange, handleTouch) {
            return (
              <embed
                src={formData.PAN_CARD.value}
                alt="Pan Card"
                style={{
                  height: "100px",
                  width: "auto",
                  objectFit: "contain",
                }}
              />
            );
          },
          errorELe: "please enter a valid document",
        },
      ],
    ],
  },
  {
    section: "Upload bank Details",
    stepperHeader: "Bank Details",
    fields: [
      [
        {
          title: "Name_Of_Bank",
          label: "Name Of Bank",
          placeholder: "State Bank Of India",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => {},
          errorELe: "please enter a valid bank name",
        },
      ],
      [
        {
          title: "Account_Number",
          label: "Account Number",
          placeholder: "12341234123",
          intitalValue: "",
          type: "number",
          required: true,
          validation: (string) => {
            if (string?.length === 0) return true;
            var res = string?.match(/^[0-9]{9,18}$/);
            return res !== null && string !== undefined;
          },
          onChange: () => {},
          errorELe: "please enter a valid  account number of 8 to 18 digits",
        },
      ],
      [
        {
          title: "IFSC_Code",
          label: "IFSC Code",
          placeholder: "SBIN0005943",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (ifsc_Code) => {
            let regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
            if (ifsc_Code == null) {
              return false;
            }
            if (regex.test(ifsc_Code) == true) {
              return true;
            } else {
              return false;
            }
          },
          onChange: () => {},
          errorELe: "please enter a valid IFSC Code of 11 digit",
        },
      ],
    ],
  },
];

export const agentProfile = [
  {
    section: "Tell us about yourself",
    stepperHeader: "Basics",
    fields: [
      [
        {
          title: "Full_Name",
          label: "Full Name",
          placeholder: "Enter Full Name",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => {},
          errorELe: "please enter a non empty name",
        },
      ],
      [
        {
          title: "Contact_Number",
          label: "Contact Number",
          placeholder: "Enter Contact Number",
          intitalValue: { dialCode: "", phone: "" },
          type: "tele",
          required: true,
          validation: phoneValidation,
          onChange: () => {},
          errorELe:
            "please enter a 10 digit mobile number without country code",
        },
      ],
      [
        {
          type: "radio",
          title: "Nationality",
          intitalValue: "indian",
          radioFields: [
            {
              value: "indian",
              title: "Indian",
            },
            {
              value: "notIndian",
              title: "Non-Indian",
            },
          ],
          required: true,
          onChange: () => {},
          validation: (input) => {
            return true;
          },
        },
      ],
    ],
  },

  {
    section: "Upload Documents",
    stepperHeader: "Documents",
    fields: [
      [
        {
          title: "PAN_CARD",
          label: "Pan Card",
          onChange: () => {},
          placeholder: "",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a document",
        },
      ],
      [
        {
          title: "PAN_CARD",
          label: "Pan Card",
          onChange: () => {},
          placeholder: "",
          intitalValue: "",
          type: "img",
          validation: (input) => {
            return true;
          },
          render: function (formData, handleChange, handleTouch) {
            return (
              <img
                src={formData.PAN_CARD.value}
                alt="Pan Card"
                style={{
                  height: "100px",
                  width: "auto",
                  objectFit: "contain",
                }}
              />
            );
          },
          errorELe: "please enter a document",
        },
      ],

      [
        {
          title: "Cancelled_cheque",
          label: "Cancelled cheque",
          onChange: () => {},
          placeholder: "",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a document",
        },
      ],
      [
        {
          title: "Cancelled_cheque",
          label: "Cancelled cheque",
          onChange: () => {},
          placeholder: "",
          intitalValue: "",
          type: "img",
          validation: (input) => {
            return true;
          },
          render: function (formData, handleChange, handleTouch) {
            return (
              <img
                src={formData.Cancelled_cheque.value}
                alt="Cancelled cheque"
                style={{
                  height: "100px",
                  width: "auto",
                  objectFit: "contain",
                }}
              />
            );
          },
          errorELe: "please enter a document",
        },
      ],
      [
        {
          title: "Passport",
          label: "Passport",
          onChange: () => {},
          placeholder: "",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a document",
        },
      ],
      [
        {
          title: "Passport",
          label: "Passport",
          onChange: () => {},
          placeholder: "",
          intitalValue: "",
          type: "img",
          validation: (input) => {
            return true;
          },
          render: function (formData, handleChange, handleTouch) {
            return (
              <img
                src={formData.Passport.value}
                alt="Passport"
                style={{
                  height: "100px",
                  width: "auto",
                  objectFit: "contain",
                }}
              />
            );
          },
          errorELe: "please enter a document",
        },
      ],
    ],
  },
];

export const diagnosticProfile = [
  {
    section: "Tell us About Yourself",
    stepperHeader: "Basics",
    fields: [
      [
        {
          title: "Name_Of_diagnostic_center",
          label: "Name Of Diagnostic Center",
          placeholder: "Marks Diagnostic Center",
          intitalValue: "",
          type: "text",
          required: true,
          validation: (input) => {
            return input?.length !== 0;
          },
          onChange: () => {},
          errorELe: "please enter a non empty name",
        },
      ],
      [
        {
          title: "Contact_Number",
          label: "Contact Number",
          placeholder: "Enter Contact Number",
          intitalValue: { dialCode: "", phone: "" },
          type: "tele",
          required: true,
          validation: phoneValidation,
          onChange: () => {},
          errorELe:
            "please enter a 10 digit mobile number without country code",
        },
      ],
      [
        {
          type: "radio",
          title: "Nationality",
          intitalValue: "indian",
          radioFields: [
            {
              value: "indian",
              title: "Indian",
            },
            {
              value: "notIndian",
              title: "Non-Indian",
            },
          ],
          required: true,
          onChange: () => {},
          validation: (input) => {
            return true;
          },
        },
      ],
    ],
  },
  {
    section: "Upload License",
    stepperHeader: "License",
    fields: [
      [
        {
          title: "License",
          label: "License",
          onChange: () => {},
          placeholder: "View License",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a valid document",
        },
      ],
      [
        {
          title: "License",
          label: "License",
          onChange: () => {},
          placeholder: "View License",
          intitalValue: "",
          type: "img",
          validation: (input) => {
            return true;
          },
          render: function (formData, handleChange, handleTouch) {
            return (
              <embed
                src={formData.License.value}
                alt="License"
                style={{
                  height: "100px",
                  width: "auto",
                  objectFit: "contain",
                }}
              />
            );
          },
          errorELe: "please enter a valid document",
        },
      ],
      [
        {
          title: "Accreditation",
          label: "Accreditation",
          tag: "Diagnostics",
          onChange: () => {},
          placeholder: "upload Certificate",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a valid document",
        },
      ],
    ],
  },
  {
    section: "Upload Documents",
    stepperHeader: "Documents",
    fields: [
      [
        {
          title: "GST_Certificate",
          label: "GST certificate",
          onChange: () => {},
          placeholder: "upload GST Certificate",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a valid document",
        },
      ],
      [
        {
          title: "GST_Certificate",
          label: "GST certificate",
          onChange: () => {},
          placeholder: "upload GST Certificate",
          intitalValue: "",
          type: "img",
          validation: (input) => {
            return true;
          },
          render: function (formData, handleChange, handleTouch) {
            return (
              <embed
                src={formData.GST_Certificate.value}
                alt="GST Certificate"
                style={{
                  height: "100px",
                  width: "auto",
                  objectFit: "contain",
                }}
              />
            );
          },
          errorELe: "please enter a valid document",
        },
      ],
      [
        {
          title: "PAN_CARD",
          label: "Pan Card",
          onChange: () => {},
          placeholder: "upload Pan Card",
          intitalValue: "",
          type: "file",
          required: true,
          validation: (value) => {
            if (value === null || value === undefined) return false;
            if (value === "") return false;
            return true;
          },
          errorELe: "please enter a valid document",
        },
      ],
      [
        {
          title: "PAN_CARD",
          label: "Pan Card",
          onChange: () => {},
          placeholder: "upload Pan Card",
          intitalValue: "",
          type: "img",
          validation: (input) => {
            return true;
          },
          render: function (formData, handleChange, handleTouch) {
            return (
              <embed
                src={formData.PAN_CARD.value}
                alt="Pan Card"
                style={{
                  height: "100px",
                  width: "auto",
                  objectFit: "contain",
                }}
              />
            );
          },
          errorELe: "please enter a valid document",
        },
      ],
    ],
  },
];
