import React from "react";
import Loader from "../../components/Loader/Loader";
import { useState } from "react";
// import "./diagnosticOnboarding.css";
import CommonComponent from "./../../components/CommonComponent/CommonComponent";
import FormGeneratorStore from "../../components/FormGenearatorAndStepper/FormGenearatorAndStepper";
import { diagnosticCenter } from "../../constants/formconfig";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import getAccessToken from "../../utils/getAccessToken";
import { API } from "../../constants/api";
import { useUserAuth } from "../../context/UserAuthContext";
import { ref } from "firebase/storage";
import { storage } from "../../config/testFirebaseConfig";
import { uploadAndReturnUrls } from "../../constants/UploadeImageUrl";
import { bookingStatus } from "../../constants";

const DiagnosticOnboarding = (_props) => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const onSubmit = async (formData) => {
    setLoader(true);
    const keys = Object.keys(formData);
    const imgData = [
      formData?.NABH?.value,
      formData?.JCI?.value,
      formData?.NABL?.value,
      formData?.GST_certificate?.value,
      formData?.License?.value,
      formData?.PAN_CARD?.value,
    ];
    const data = new FormData();
    keys.forEach((key) => {
      if (key === "contactNumber") {
        const number = formData[key].value.phone.slice(
          formData[key].value.dialCode.length
        );
        data.append(key, `+${formData[key].value.dialCode}-${number}`);
      } else if (key === "Location") {
        const value = formData[key].value.label;
        data.append(key, value);
      } else if (
        key !== "GST_certificate" &&
        key !== "NABH" &&
        key !== "JCI" &&
        key !== "NABL" &&
        key !== "License" &&
        key !== "PAN_CARD"
      ) {
        data.append(key, formData[key].value);
      } else {
        //continue
      }
    });
    data.append("status", bookingStatus[0].statusName);
    const url = `${API}/diagnostics/register`;

    try {
      const token = getAccessToken();
      const result = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const diagId = result.data.diagnosticId;
      const imageRef = ref(storage, `diagnostics/${diagId}`);

      const structuredLinks = {
        // Accreditation_Certificate: null,
        NABH: null,
        JCI: null,
        NABL: null,
        GST_certificate: null,
        License: null,
        PAN_CARD: null,
      };

      await Promise.all(
        imgData.map(async (image, index) => {
          if (image === undefined || image === null || image === "No File") {
            return;
          }
          const uploadedImgUrls = await uploadAndReturnUrls([image], imageRef);

          structuredLinks[Object.keys(structuredLinks)[index]] =
            uploadedImgUrls[0];
        })
      );
      await axios.put(`${API}/diagnostic/update/${diagId}`, {
        diagnosticsImages: structuredLinks,
      });
      await axios.patch(`${API}/user/updateuser/${user.uid}`, {
        isOnboarded: "true",
      });
      navigate("/diagnostic/dashboard");

      await axios.patch(`${API}/user/updateuser/${user.uid}`, {
        isOnboarded: "true",
      });
      navigate("/diagnostic/dashboard");
    } catch (err) {
      setLoader(false);
    }
  };

  return (
    <div className="field-wrapper">
      <CommonComponent>
        {loader && <Loader />}
        <FormGeneratorStore
          agentTypes={diagnosticCenter}
          submitHandler={onSubmit}
        />
      </CommonComponent>
    </div>
  );
};

export default DiagnosticOnboarding;
