import React from "react";
import StickyReadProgressBar from "../StickyReadProgressBar/StickyReadProgressBar";

const StickyProgressBarSection = ({
  children,
  optionsList,
}) => {
  console.log(optionsList, children);
  return (
    <div
    // onClick={() => {
    //   scrollToSection(children.linkToSection);
    // }}
    >
      <StickyReadProgressBar
        optionsList={optionsList}
      />
      {children}
    </div>
  );
};

export default StickyProgressBarSection;
