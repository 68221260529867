import React from "react";
import "./DashboardTableStatusColumn.css";
import statusComplete from "../../assets/images/Status-Completed-Tick.svg";
import statusScheduled from "../../assets/images/Status-Scheduled-Tick.svg";
import statusCancelled from "../../assets/images/cross-icon-final.svg";
import statusInProgress from "../../assets/images/in-progress-icon-final.svg";
export const numberMonthMapping = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

const DashboardTableStatusColumn = ({ status, statusDate }) => {
  let StatusIcon = status === "Completed" ? statusComplete : statusScheduled;
  if (status === "Cancelled") StatusIcon = statusCancelled;
  if (status === "In Progress") StatusIcon = statusInProgress;
  const day = statusDate.slice(8, 10);
  const month = numberMonthMapping[+statusDate.slice(5, 7)];
  const year = statusDate.slice(0, 4);
  return (
    <div className="status-container">
      <img src={StatusIcon} className="status-image" />
      <div className="status-text-container">
        <p className="status-date">{`${day} ${month} ${year}`}</p>
        <p className="status-text">{status}</p>
      </div>
    </div>
  );
};

export default DashboardTableStatusColumn;
