import React, { useEffect, useState } from 'react'
import './TestPage3.css';
import Experiencesvg from '../../assets/NewPortfolioAssets/Experience.svg';
import assisted from '../../assets/NewPortfolioAssets/assisted.svg';
import Establish from '../../assets/NewPortfolioAssets/Establish.svg';
import beds from '../../assets/NewPortfolioAssets/beds.svg';
import badget1 from '../../assets/NewPortfolioAssets/badget1.png';
import badget2 from '../../assets/NewPortfolioAssets/badget2.png';
import badget3 from '../../assets/NewPortfolioAssets/badget3.png';
import axios from 'axios';
import { API } from '../../constants/api';

const HospitalPreviewPanel = ({ hospitalId }) => {

  const [hospitalData, setHospitalData] = useState(null)
  // const hospitalId = '-NjY0pM2zQj1pJPk--Tu'//in props {hospitalId } or in params



  const chunkedImages = [];

  //fetching the hospital
  useEffect(() => {
    const fetchHospitalData = async () => {
      try {
        // console.warn('called');
        const response = await axios.get(
          `${API}/hospital/getsinglehospital/${hospitalId}`
        );

        setHospitalData(response.data.hospital);


      } catch (error) {
        console.error("Error fetching hospital data:", error);
      }


    };


    fetchHospitalData();


  }, [hospitalId]);

  // Function to chunk the images into groups of 3
  const chunkImages = (images) => {
    const chunkedImages = [];
    for (let i = 0; i < images.length; i += 3) {
      chunkedImages.push(images.slice(i, i + 3));
    }
    return chunkedImages;
  };

  console.log('iam hospital:::', hospitalData);

  return (
    <div className="container border">

      <div className="row mb-4">

        <div className="col hopsital-data-box mb-md-0 mb-3">
          <p className='hospital-name'>{hospitalData?.Name_Of_Hospital}</p>
          <p className='location-name'>{hospitalData?.Location}, {hospitalData?.Nationality}</p>
          <div className="badget-box d-flex py-2">
            <p className='multi-speacilaity-text border py-2 px-4 m-0'>
              {hospitalData?.facility_overview?.specialityTypes}
            </p>
            <div className="badget-items me-2  my-auto ms-3 ">
              <img src={badget1} alt="b1" srcset="" className='mx-1' />
              <img src={badget2} alt="b2" srcset="" className='mx-1' />
              <img src={badget3} alt="b3" srcset="" className='mx-1' />

            </div>

          </div>
        </div>

        <div className="col hopsital-button-box d-flex justify-content-md-center justify-content-between align-items-center">
          <button type="button" class="btn book-appointment-button btn-lg me-2 px-md-5 px-3">Book Appointment</button>
          <button type="button" class="btn btn-outline-secondary need-help-btn btn-lg px-md-5 px-3">Need Help</button>
        </div>

      </div>

      <div className="row mb-4">
        <div className="col image-box">

          <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner" >

              <div className="carousel-inner">
                {chunkImages(hospitalData?.Hospital_images || []).map((chunk, index) => (
                  <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                    <div className="card-wrapper">
                      {chunk.map((image, idx) => (
                        <div key={idx} className="card">
                          <img src={image} className="card-img-top" alt={`Slide ${index} Image ${idx}`} />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>

              {/* <div className={`carousel-item active`}>

                <div className="card-wrapper" >

                  <div class="card">
                    <img src="https://source.unsplash.com/user/erondu/200x200" className="card-img-top" alt="..." />
                  </div>

                  <div class="card">
                    <img src="https://source.unsplash.com/user/erondu/200x200" className="card-img-top" alt="..." />
                  </div>

                  <div class="card">
                    <img src="https://source.unsplash.com/user/erondu/200x200" className="card-img-top" alt="..." />
                  </div>

                </div>

              </div> */}

              {/* <div className={`carousel-item`}>

                <div className="card-wrapper" >

                  <div class="card">
                    <img src="https://source.unsplash.com/user/erondu/200x200" className="card-img-top" alt="..." />
                  </div>

                  <div class="card">
                    <img src="https://source.unsplash.com/user/erondu/200x200" className="card-img-top" alt="..." />
                  </div>

                  <div class="card">
                    <img src="https://source.unsplash.com/user/erondu/200x200" className="card-img-top" alt="..." />
                  </div>

                </div>

              </div>

              <div className={`carousel-item `}>

                <div className="card-wrapper" >

                  <div class="card">
                    <img src="https://source.unsplash.com/user/erondu/200x200" className="card-img-top" alt="..." />
                  </div>

                  <div class="card">
                    <img src="https://source.unsplash.com/user/erondu/200x200" className="card-img-top" alt="..." />
                  </div>

                  <div class="card">
                    <img src="https://source.unsplash.com/user/erondu/200x200" className="card-img-top" alt="..." />
                  </div>

                </div>

              </div> */}

            </div>
          </div>

        </div >
      </div >

      <div className="row">
        <div className="col hospital-features-box d-flex justify-content-md-between flex-md-row flex-column py-3 px-5 ">

          <div className="experience-box mb-md-0 mb-4">
            <img src={Experiencesvg} alt="" srcset="" />
            <p className='mb-0 mt-3'>200 Experienced doctors</p>
          </div>

          <div className="establish-box mb-md-0 mb-4">
            <img src={Establish} alt="" srcset="" />
            <p className='mb-0 mt-3'>Established in: 1996</p>
          </div>

          <div className="beds-box mb-md-0 mb-4">
            <img src={beds} alt="" srcset="" />
            <p className='mb-0 mt-3'>100 beds</p>
          </div>

          <div className="assisted-box mb-md-0 mb-4">
            <img src={assisted} alt="" srcset="" />
            <p className='mb-0 mt-3'>25000+ patients assisted</p>
          </div>

        </div>
      </div>
    </div >
  )
}

export default HospitalPreviewPanel