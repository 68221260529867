import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { AddAppointmentsColumnConfigMain } from "../../constants/AddAppointmentsTableConfig";
import "./WelcomeStatus.css";
import StatsCard from "../StatsCard/StatsCard";
import buildingPink from "../../assets/DashboardAssets/buildingPink.svg";
import rightTick from "../../assets/DashboardAssets/rightTick.svg";
import crossTick from "../../assets/DashboardAssets/crossTick.svg";
import calender from "../../assets/DashboardAssets/calender.svg";
import diffGreen from "../../assets/DashboardAssets/diffGreen.svg";
import DashboardTable from "../DashboardTable/DashboardTable";
import DashboardTablePagination from "../DashboardTablePagination/DashboardTablePagination";
import { Link } from "react-router-dom";
import StatusCrad from "../StatusCard/StatusCrad";
import { API } from "../../constants/api";
import {
  agentTableConfig,
  dummyagentTableData,
} from "../../constants/AgentTableConfig";
import ComboBarLineGraphComponent from "../GraphComponents/ComboBarLineGraphComponent/ComboBarLineGraphComponent";
import indianStatesData from "../../assets/custom-data/data";
import LeaderTableComponent from "../LeaderTableComponent/LeaderTableComponent";
import { sidebarAgencyItems, sidebarAgentItems } from "../../constants/SidebarItems";
import DashboardLayout from "../../pages/DashboardSidebar/DashboardLayout";
import { useUserAuth } from "../../context/UserAuthContext";


const AgencyStatus = () => {
  const tableData = dummyagentTableData;

  const [index, setIndex] = useState(0);
  const size = 4;
  const updatedRows = tableData.slice(size * index + 0, size * index + size);
  const { user, logOut } = useUserAuth();
  const handleClick = (ind) => {
    setIndex(ind);
  };
  const numberOfPages = Math.ceil(tableData.length / size);
  const title = "All hospital";
  const number = 50;
  const description = "vs last month";

  //=============== Graph dummy data start =========
  const [onboardingCallsData, setOnboardingCallsData] = useState({
    // labels: indianStatesData.map((state) => state.state),
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri"],
    datasets: [
      {
        label: "Call Volume",
        data: indianStatesData.map((state) => state.totalHospitals),
        backgroundColor: ["#2D416B"],
        barPercentage: 0.5,
        // categoryPercentage: 0.6,
        order: 2,
      },
      {
        label: "Converted",
        // data: indianStatesData.map((state) => state.totalHospitals),
        data: [90, 105, 100, 95, 100],
        type: "line",
        borderColor: "#0CB71D",
        borderWidth: 2,
        backgroundColor: "#12F428",
        pointStyle: "circle",
        pointRadius: 5,
        pointHoverRadius: 10,
        order: 0,
      },
      {
        label: "Failed",
        // data: indianStatesData.map((state) => state.totalHospitals),
        data: [25, 15, 40, 20, 35],
        type: "line",
        borderColor: "#F55C05",
        borderWidth: 2,
        backgroundColor: "#FF853F",
        pointStyle: "circle",
        pointRadius: 5,
        pointHoverRadius: 10,
        order: 1,
      },
    ],
  });

  const onboardingCallsValues = {
    title: "Onboarding Calls",
    sortBy: ["Daily", "Weekly", "Monthly", "Yearly"],
  };

  // === graph data end ===

  const arr = [1, 2];

  return (
    <DashboardLayout sidebarItems={sidebarAgencyItems}>
      <div className="row m-0 left-text-box px-4 mt-4">
        <div className="col welcome-box  d-flex flex-column">
          <div className="welcome-text d-flex ">
            <h1 className='fw-bold mb-0 text-start'>Welcome back, {user?.displayName}</h1>
          </div>
          <div className="muted-text d-flex ">
            <p className="text-muted mb-2 text-start">
              Track and manage your hospital on boarding and oppointment
            </p>
          </div>
        </div>
      </div>

      <div className="row m-0 status-card px-md-5 px-2 mt-2 p-3">
        <div className="col-6 m-0 p-0">
          <div className="row m-0 p-0">
            {arr.map((item) => {
              return (
                <StatusCrad
                  status={rightTick}
                  icon={buildingPink}
                  number={number}
                  title={title}
                  difference={diffGreen}
                  description={description}
                />
              );
            })}

          </div>

        </div>
        <div className="col-6 m-0 p-0">
          <div className="row m-0 p-0">
            {arr.map((item) => {
              return (
                <StatusCrad
                  status={rightTick}
                  icon={buildingPink}
                  number={number}
                  title={title}
                  difference={diffGreen}
                  description={description}
                />
              );
            })}

          </div>

        </div>

      </div>

      <div className="row m-0 suggestion-box px-md-5 px-2 mt-2">
        <div className="col-12 suggestion-content border">
          <div className="row m-0">
            <div className="suggest-text-up d-flex  p-2">
              <h2 className="text-start mb-0">Suggestion</h2>
            </div>
          </div>
          <div className="row m-0">
            <div className="col suggest-text-bottom d-flex px-2 mb-2  align-items-center">
              <span
                className="d-inline-block text-truncate text-start m-0 "
                style={{ maxWidth: "85%" }}
              >
                8 hospitals recently stopped being active, contact the hospitals
                to retain them. In this article, find the script to Lorem ipsum
                dolor sit amet consectetur adipisicing elit. Ipsam harum
                voluptatem omnis repudiandae ex vitae iusto vel placeat facere
                minima exercitationem velit nemo aspernatur est nobis, quaerat,
                voluptatum esse sapiente voluptas ullam enim? Nemo.
              </span>
              <div className="link-box d-flex ms-sm-4">
                <p className="m-0 fs-lg-5 fs-6">
                  <Link to className="link-to-article">
                    View this article →
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row m-0 my-5 table-booking-box px-md-5 px-2">
        <div
          className="col p-0"
          style={{
            border: "1px solid gray",
            borderRadius: "12px",
          }}
        >
          <div className="row mx-0 upcoming-main-box px-md-5 px-2 "
            style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} >
            <div className="col-4 upcoming-box  d-flex mx-3">
              <div style={{ marginBottom: "20px" }}>
                <p
                  className="text-start mb-0"
                  style={{
                    fontWeight: "550",
                    fontSize: "25px",
                  }}
                >
                  Upcoming Bookings
                </p>
              </div>
              <div className="link-box d-flex ms-sm-4 mt-2 ">
                <p className="m-0 fs-8">
                  <Link to className="link-to-article">
                    View all →
                  </Link>
                </p>
              </div>
            </div>
            <div className="col d-flex filter-select align-items-center justify-content-end">
              <select name="sortBy" id="sortBy">
                <option value="Daily">Daily</option>
                <option value="Weekly">Weekly</option>
                <option value="Monthly">Monthly</option>
                <option value="Yearly">Yearly</option>
              </select>
            </div>
          </div>

          <div className="row m-0  mb-0 px-4">
            <div className="col-12 p-0 table-section-container d-flex justify-content-start">
              <DashboardTable
                columns={agentTableConfig}
                rows={updatedRows}
                size={size}
              />{" "}
            </div>
            {numberOfPages > 1 && (
              <DashboardTablePagination
                numberOfPages={numberOfPages}
                index={index}
                handleClick={handleClick}
              />
            )}
          </div>
        </div>
      </div>

      <div className="agency-chart-table-container">
        <ComboBarLineGraphComponent
          onboardingCallsValues={onboardingCallsValues}
          onboardingCallsData={onboardingCallsData}
        />
        <div className="col-md-5 col-12 me-md-3 leader-table-box p-2">
          <div className="leadertable-wrapper border border-dark px-4">
            <div className="leader-text d-flex mb-3 mt-4 ">
              <h2 className="mb-0 text-start">Leaderboard</h2>
            </div>
            <LeaderTableComponent />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AgencyStatus;
