import { createContext, useState } from "react";

export const AppointmentContext = createContext();

export const AppointmentContextProvider = ({ children }) => {
  const [appointmentId, setAppointmentId] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");
  const appointmentIdHandler = (value) => {
    setAppointmentId(value);
  };
  const appointmentDateHandler = (value) => {
    setAppointmentDate(value);
  };

  return (
    <AppointmentContext.Provider
      value={{
        appointmentId,
        appointmentIdHandler,
        appointmentDateHandler,
        appointmentDate,
      }}
    >
      {children}
    </AppointmentContext.Provider>
  );
};
