import { sidebarAgentItems } from "../../../../constants/SidebarItems";
import DashboardLayout from "../../../../pages/DashboardSidebar/DashboardLayout";
import { HospitalOnboarding } from "./HospitalOnboarding";

const DashboardAddHospitalPage = () => {
  return (
    <DashboardLayout sidebarItems={sidebarAgentItems}>
      <div
        style={{
          padding: "0px",
          marginBottom: "30px",
          marginTop: "30px",
          width: "100%",
          overflow: "auto",
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        <HospitalOnboarding />
      </div>
    </DashboardLayout>
  );
};

export default DashboardAddHospitalPage;
