import React from "react";

const PrivacyPolicyComponent = () => {
  return (
    <div className="login-page-form">
      <h2>Privacy Policy</h2>
      <div className="verification-page-form-content">
          <p className="verification-page-form-content-description margin-top">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iusto
            dolorum reiciendis velit in sed, vero a quae officia nisi doloremque
            veniam ex cumque perspiciatis nesciunt dicta nihil sunt! Nesciunt
            beatae consequuntur pariatur, deserunt alias earum quibusdam veniam,
            eum quos at perspiciatis eaque, provident corrupti animi! Debitis
            laborum quae ex quisquam consequuntur commodi animi officiis totam
            in, delectus autem aspernatur dignissimos porro. Harum, magni. Quos
            laudantium rem porro dolorum consequuntur temporibus magnam nobis,
            deleniti veritatis nisi? Nihil perspiciatis autem, aspernatur
            inventore dignissimos incidunt exercitationem cupiditate ex repellat
            eum non! Unde veniam ad magni, quibusdam dolores vitae ipsa, minima
            nam velit dolore sint rerum! Sit voluptatum reprehenderit expedita
            inventore eveniet dolore eos fugit dignissimos. Ratione provident
            fugiat nesciunt expedita accusamus vero blanditiis dicta aspernatur,
            excepturi, laboriosam
          </p>
      </div>
    </div>
  );
};

export default PrivacyPolicyComponent;
