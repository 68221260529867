import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  deleteObject,
} from "firebase/storage";

export const uploadAndReturnUrls = async (
  files,
  storageRef,
  maxFiles = 8,
  role = "other"
) => {
  try {
    // console.log('in uploader :::', files);
    const uploadedUrls = [];
    const filesToUpload = files.slice(0, maxFiles);
    const allowedDoctype = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "application/pdf",
    ];

    const existingFiles = await listAll(storageRef);
    console.log("exists files :::", existingFiles.items.length);

    // if (role === 'other' && existingFiles.items.length > 0) {
    //   console.log('iam in delete ');
    //   const deletePromises = existingFiles.items.map(async (item) => {
    //     await deleteObject(item);
    //   });
    //   await Promise.all(deletePromises);
    // }

    for (const file of filesToUpload) {
      console.log("in uploader :::", files);
      console.log(file.type);
      if (!allowedDoctype.includes(file.type)) {
        console.log("File type not allowed");
        // return { error: "File type not allowed" };
        // continue;
      }
      const fileRef = ref(storageRef, file.name || "default.jpg");
      await uploadBytes(fileRef, file);
      const downloadUrl = await getDownloadURL(fileRef);
      uploadedUrls.push(downloadUrl);
    }
    console.log(
      "Files uploaded to Firebase Storage successfully",
      uploadedUrls
    );
    return uploadedUrls;
  } catch (error) {
    console.error("Error uploading files:", error.message);
    return [];
  }
};
