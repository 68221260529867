import axios from "axios";
import React from "react";
import { useState } from "react";
import { createContext } from "react";
import { API } from "../constants/api";
export const BlogContext = createContext();
const BlogContextApi = ({ children }) => {
  const [blog, setBlog] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const getBlogs = async () => {
    try {
      const url = `${API}/blog/all`;
      const { data } = await axios.get(url);
      if (data.success) {
        setBlogs(data.blogs);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <BlogContext.Provider
      value={{
        blog,
        setBlog,
        setBlogs,
        blogs,
        getBlogs,
      }}
    >
      {children}
    </BlogContext.Provider>
  );
};

export default BlogContextApi;
