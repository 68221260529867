import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import React, { useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import "./CreateBlog.css";
import "../EditorStyle/EditorStyle.css";
import toast from "react-hot-toast";
import ImageUploaderButton from "../../../components/ImageUploaderButton/ImageUploaderButton";
import { blogRelatedTags } from "../../../constants";
import axios from "axios";
import { storage } from "../../../config/testFirebaseConfig";
import { ref } from "firebase/storage";
import { BloguploadAndReturnUrls } from "../../../constants/BlogUploadImageUrl";
import { useNavigate } from "react-router-dom";
import { API } from "../../../constants/api";

const CreateBlog = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [thumbnailPreview, setThumbnailPreview] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [relatedTags, setRelatedTags] = useState([]);
  const navigate = useNavigate();
  const editorStateChangeHandler = (state) => {
    setEditorState(state);
  };
  const createBlog = async () => {
    if (!relatedTags.length) {
      throw new Error("Please add atleast one tag.");
    }
    let data = convertToRaw(editorState.getCurrentContent());
    let textContent = data.blocks.map((block) => block.text + "\n").join("");
    let htmlContent = draftToHtml(data);
    htmlContent = htmlContent.replace(
      /font-family:\s*[^;]*/gi,
      "font-family: Proxima Nova"
    );

    let newBlog = {
      title,
      description,
      textContent,
      htmlContent,
      relatedTags: JSON.stringify(relatedTags),
    };
    const imgData = [thumbnail];
    console.log({ imgData });
    const url = `${API}/blog/create`;
    const {
      data: { blogId, message },
    } = await axios.post(url, newBlog);
    console.log(blogId);
    const blogImageRef = ref(storage, `blogs/${blogId}`);
    const structuredLinks = {
      thumbnail: "",
    };
    await Promise.all(
      imgData.map(async (image, index) => {
        const uploadedBlogUrls = await BloguploadAndReturnUrls(
          [image],
          blogImageRef
        );

        console.log(uploadedBlogUrls);
        structuredLinks[Object.keys(structuredLinks)[index]] =
          uploadedBlogUrls[0];
      })
    );

    console.log("Final structuredLinks:", structuredLinks);
    const response = await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/blog/upload/${blogId}`,
      structuredLinks
    );
    console.log(response);
    navigate("/console/blogs");
    return message;
  };
  const createBlogHandler = (e) => {
    e.preventDefault();
    toast.promise(createBlog(), {
      loading: "Adding Blog...",
      success: (msg) => {
        return msg;
      },
      error: (err) => {
        if (err.response) {
          return err.response.data.message;
        }
        return err.message;
      },
    });
  };
  const thumbnailPreviewHandler = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setThumbnailPreview("");
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setThumbnailPreview(reader.result);
      setThumbnail(file);
    };
  };
  const autoCompleteChangeHandler = (e, data) => {
    console.log(data);
    setRelatedTags(data);
  };
  return (
    <div className="create-blog-page">
      <h4 className="portfolio-hospitals-admin-console-heading">Add Blog</h4>
      <div className="create-blog-inputs-and-editor">
        {" "}
        <div className="create-blog-page-blog-details">
          <form onSubmit={createBlogHandler}>
            <div className="create-blog-input-box">
              <p>Title</p>
              <input
                type="text"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>
            <div className="create-blog-input-box">
              <p>Meta Description</p>
              <input
                type="text"
                name="title"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </div>
            <div className="create-blog-input-box">
              <p>Meta Tags</p>
              <Autocomplete
                multiple
                id="tags-outlined"
                options={blogRelatedTags}
                getOptionLabel={(option) => option}
                defaultValue={[]}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Tags"
                    placeholder="Related"
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={index}
                      variant="filled"
                      label={option}
                      {...getTagProps({ index })}
                      style={{ margin: "4px" }}
                    />
                  ))
                }
                onChange={autoCompleteChangeHandler}
                style={{ width: "100%", marginTop: "16px" }}
              />
            </div>
            <ImageUploaderButton
              text={"CHOOSE THUMBNAIL"}
              onChange={thumbnailPreviewHandler}
              isRequired={true}
            />
            {thumbnailPreview && (
              <div className="blog-thumbnail-preview-box">
                <img src={thumbnailPreview && thumbnailPreview} alt={""} />{" "}
              </div>
            )}
            <div className="create-blog-button-box">
              <button type="submit" className="create-blog-button">
                Add
              </button>
            </div>
          </form>
        </div>
        <div>
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClass"
            editorClassName="editorClass"
            onEditorStateChange={editorStateChangeHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateBlog;
