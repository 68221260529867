import React, { useState } from "react";
import "./AdminLogin.css";
import CreateAccountButton from "../../../components/CreateAccountButton/CreateAccountButton";
import TextInputComponent from "../../../components/TextInputComponent/TextInputComponent";
import PasswordInputComponent from "../../../components/PasswordInputComponent/PasswordInputComponent";
import AlertComponent from "../../../components/AlertComponent/AlertComponent";
const AdminLogin = (props) => {
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <div className="login-page-form">
      <h2>Admin Login</h2>
      <div className="login-page-form-content">
        <form>
          <TextInputComponent
            label="Email"
            placeholder="johnsondoe@gmail.com"
            className="login-page-form-input-text"
            name="email"
            value={props.user.email}
            onChange={props.handleChange}
          />
          <PasswordInputComponent
            label="Password"
            placeholder="******"
            className="login-page-form-input-password"
            name="password"
            value={props.user.password}
            onChange={props.handleChange}
            passwordShown={passwordShown}
            togglePasswordVisibility={togglePasswordVisibility}
          />
          <div>
            {props.displayError && (
              <AlertComponent errorText={props.errorText} />
            )}
            <CreateAccountButton
              handleSubmit={props.handleSubmit}
              text="Admin Login"
              buttonType="simple"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
