import React from "react";
import Loader from "../../components/Loader/Loader";
import { useState } from "react";
import "./HospitalOnboarding.css";
import CommonComponent from "./../../components/CommonComponent/CommonComponent";
import FormGeneratorStore from "../../components/FormGenearatorAndStepper/FormGenearatorAndStepper";
import { hospitalTypes } from "../../constants/formconfig";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import getAccessToken from "../../utils/getAccessToken";
import { API } from "../../constants/api";
import { useUserAuth } from "../../context/UserAuthContext";
import { ref, uploadBytes } from "firebase/storage";
import { storage } from "../../config/testFirebaseConfig";
import { uploadAndReturnUrls } from "../../constants/UploadeImageUrl";
import { deleteAllImagesRef } from "../../constants/DeleteUrlsImages";

export const HospitalOnboarding = (_props) => {
  console.log({ onboarding: _props });
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const onSubmit = async (formData) => {
    console.log(formData);
    setLoader(true);
    const keys = Object.keys(formData);
    console.log(keys);
    const imgData = [
      // formData.Accreditation_Certificate.value,
      formData.NABH.value,
      formData.JCI.value,
      formData.NABL.value,
      formData.GST_certificate.value,
      formData.License.value,
      formData.PAN_CARD.value,
    ];
    console.log(imgData);
    const data = new FormData();
    keys.forEach((key) => {
      if (key === "Contact_Number") {
        const number = formData[key].value.phone.slice(
          formData[key].value.dialCode.length
        );
        data.append(key, `+${formData[key].value.dialCode}${number}`);
      } else if (key === "Location") {
        const value = formData[key].value.label;
        data.append(key, value);
      } else if (
        key !== "GST_certificate" &&
        // key !== "Accreditation_Certificate" &&
        key !== "NABH" &&
        key !== "JCI" &&
        key !== "NABL" &&
        key !== "License" &&
        key !== "PAN_CARD"
      ) {
        console.log("Running else if");
        data.append(key, formData[key].value);
      }
      // } else {
      //   data.append(key, formData[key].value);
      // }
    });

    const url = `${API}/hospital/register`;

    try {
      const token = getAccessToken();
      const result = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(result);

      const hospitalImageRef = ref(
        storage,
        `hospitals/${result.data.hospitalId}`
      );

      const structuredLinks = {
        // Accreditation_Certificate: null,
        NABH: null,
        JCI: null,
        NABL: null,
        GST_certificate: null,
        License: null,
        PAN_CARD: null,
      };

      await Promise.all(
        imgData.map(async (image, index) => {
          if (image === undefined || image === null || image === "No File") {
            return;
          }
          const uploadedHospitalUrls = await uploadAndReturnUrls(
            [image],
            hospitalImageRef
          );
          console.log(uploadedHospitalUrls);
          structuredLinks[Object.keys(structuredLinks)[index]] =
            uploadedHospitalUrls[0];
        })
      );

      console.log("Final structuredLinks:", structuredLinks);

      const response = await axios.post(
        `${API}/hospital/uploadfiles/${result.data.hospitalId}`,
        structuredLinks
      );

      await axios.patch(`${API}/user/updateuser/${user.uid}`, {
        isOnboarded: "true",
      });
      navigate("/hospital/dashboard");
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };

  return (
    <div className="field-wrapper">
      <CommonComponent>
        {loader && <Loader />}
        <FormGeneratorStore
          agentTypes={hospitalTypes}
          submitHandler={onSubmit}
        />
      </CommonComponent>
    </div>
  );
};
