// import { Chip } from "@mui/material";
import DashboardTableNameColumn from "../components/DashboardTableNameColumn/DashboardTableNameColumn";

export const AddAccountsTableConfig = [
  {
    columnName: "Name",
    style: { width: "210px", marginLeft: "20px", marginRight: "max(10px,3vw)" },
    render: (data) => {
      return <DashboardTableNameColumn title={data.name} subtitle={data.id} />;
    },
  },
  {
    columnName: "Email",
    value: "email",
  },
  {
    columnName: "Validity",
    value: "validity",
  },
  {
    columnName: "Expiry Status",
    value: "expiry_status",
    // render: (data) => {
    //   return <Chip label={data} variant="outlined" />;
    // },
  },
];

export const AddAccountsDummyData = [
  {
    name: "Dhiraj",
    email: "dhiraj@gmail.com",
    validity: "2022-09-19",
  },
  {
    name: "Ayush",
    email: "ayush@gmail.com",
    validity: "2022-09-19",
  },
  {
    name: "Gaurav",
    email: "gaurav@gmail.com",
    validity: "2022-09-19",
  },
];
