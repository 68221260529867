import React, { createContext, useContext, useState } from 'react';

const WidthContext = createContext();

export const useWidthContext = () => {
  return useContext(WidthContext);
};

export const WidthProvider = ({ children }) => {
  const [isWide, setIsWide] = useState(true);

  const toggleWidth = () => {
    setIsWide((prevIsWide) => !prevIsWide);
  };

  return (
    <WidthContext.Provider value={{ isWide, toggleWidth }}>
      {children}
    </WidthContext.Provider>
  );
};