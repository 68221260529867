import React, { useEffect, useState } from "react";
import DashboardLayout from "../DashboardSidebar/DashboardLayout";
import {
  sidebarDiagnosticItems,
  sidebarFacilitatorItems,
} from "../../constants/SidebarItems";
import FormGeneratorStore from "../../components/FormGenearatorAndStepper/FormGenearatorAndStepper";
import Loader from "../../components/Loader/Loader";
import { diagnosticCenter } from "../../constants/formconfig";
import { useUserAuth } from "../../context/UserAuthContext";
import { useNavigate } from "react-router-dom";
import { API } from "../../constants/api";
import getAccessToken from "../../utils/getAccessToken";
import axios from "axios";
import { ref } from "firebase/storage";
import { storage } from "../../config/testFirebaseConfig";
import { uploadAndReturnUrls } from "../../constants/UploadeImageUrl";
import toast from "react-hot-toast";

const DiagnosticProfile = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [userData, setUserData] = useState();
  const [diagnosticId, setDiagnosticId] = useState();
  const [diagnosticData, setDiagnosticData] = useState();
  const fetchData = async () => {
    try {
      const token = getAccessToken();
      const { data } = await axios.get(`${API}/diagnostics/user/${user.uid}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.diagnostics) {
        const id = data.diagnostics.id;
        setDiagnosticId(id);
        const userProfileData = data.diagnostics;
        setDiagnosticData(userProfileData);
        let match = String(userProfileData.contactNumber).match(
          /^\+(\d+)-(\d+)$/
        );
        let dialCode = match[1] || "";
        let phone = match[2] || "";
        let newUserData = {
          nameOfDiagnostics: {
            value: userProfileData["nameOfDiagnostics"] || "",
            isTouched: true,
          },
          contactNumber: {
            value: { phone: `+${dialCode}${phone}` } || "",

            isTouched: true,
          },
          Location: {
            value: userProfileData["Location"] || "",
            isTouched: true,
          },
          Nationality: {
            value: userProfileData["Nationality"] || "",
            isTouched: true,
          },
          Accreditation: {
            value: [userProfileData["Accreditation"]] || [""],
            isTouched: true,
          },
          License: {
            value: userProfileData.diagnosticsImages?.["License"] || "",
            isTouched: true,
          },
          NABH: {
            value: userProfileData.diagnosticsImages?.["NABH"] || "",
            isTouched: true,
          },
          JCI: {
            value: userProfileData.diagnosticsImages?.["JCI"] || "",
            isTouched: true,
          },
          NABL: {
            value: userProfileData.diagnosticsImages?.["NABL"] || "",
            isTouched: true,
          },
          GST_certificate: {
            value: userProfileData.diagnosticsImages?.["GST_certificate"] || "",
            isTouched: true,
          },
          PAN_CARD: {
            value: userProfileData.diagnosticsImages?.["PAN_CARD"] || "",
            isTouched: true,
          },
        };
        setUserData(newUserData);
        setLoader(false);
      } else {
        console.error("Error");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchData().catch();
  }, [user]);

  const onSubmit = async (formData) => {
    setLoader(true);
    const keys = Object.keys(formData);
    const imgData = [
      formData?.NABH?.value,
      formData?.JCI?.value,
      formData?.NABL?.value,
      formData?.GST_certificate?.value,
      formData?.License?.value,
      formData?.PAN_CARD?.value,
    ];
    const data = new FormData();
    keys.forEach((key) => {
      if (key === "contactNumber") {
        const number = formData[key].value.phone.slice(
          formData[key].value.dialCode.length
        );
        data.append(key, `+${formData[key].value.dialCode}-${number}`);
      } else if (key === "Location") {
        const value = formData[key].value.label;
        data.append(key, value);
      } else if (
        key !== "GST_certificate" &&
        key !== "NABH" &&
        key !== "JCI" &&
        key !== "NABL" &&
        key !== "License" &&
        key !== "PAN_CARD"
      ) {
        data.append(key, formData[key].value);
      } else {
        //continue
      }
    });

    const url = `${API}/diagnostics/update/${diagnosticId}`;

    try {
      const token = getAccessToken();
      const result = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const diagId = result.data.diagnosticId;
      const imageRef = ref(storage, `diagnostics/${diagId}`);

      const structuredLinks = {
        // Accreditation_Certificate: null,
        NABH: null,
        JCI: null,
        NABL: null,
        GST_certificate: null,
        License: null,
        PAN_CARD: null,
      };

      await Promise.all(
        imgData.map(async (image, index) => {
          if (image === undefined || image === null || image === "No File") {
            return;
          }
          const uploadedImgUrls = await uploadAndReturnUrls([image], imageRef);

          structuredLinks[Object.keys(structuredLinks)[index]] =
            uploadedImgUrls[0];
        })
      );
      await axios.put(`${API}/diagnostic/update/${diagId}`, {
        diagnosticsImages: structuredLinks,
      });
      toast.success("Profile updated");
    } catch (err) {
      setLoader(false);
    }
  };
  return (
    <DashboardLayout sidebarItems={sidebarDiagnosticItems}>
      {loader && <Loader />}
      <div className="edit-patient-details-container">
        <FormGeneratorStore
          agentTypes={diagnosticCenter}
          submitHandler={onSubmit}
          updatedFormData={userData}
        />
      </div>
    </DashboardLayout>
  );
};

export default DiagnosticProfile;
