import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";
import { API } from "../../constants/api";
import DashboardTable from "../DashboardTable/DashboardTable";
import DashboardTablePagination from "../DashboardTablePagination/DashboardTablePagination";
import { BookingsTableConfig } from "../../constants/BookingsTableConfig";

const BookingsTable = () => {
  const [index, setIndex] = useState(0);
  const [bookingsData, setBookingsData] = useState([]);

  const size = 5;
  const updatedRows = bookingsData.slice(size * index + 0, size * index + size);

  const buttons = [
    {
      title: "edit",
      render: (item) => {
        return (
          <div className='rescheduele-appointment-button' onClick={() => {}}>
            <EditIcon
              sx={{
                color: "#475466",
                cursor: "pointer",
                mx: 1,
              }}
              fontSize='medium'
            />

            <p className='rescheduele-appointment'>Edit Patient Information</p>
          </div>
        );
      },
    },
    {
      title: "cancel",
      render: (item) => {
        return (
          <div className='delete-appointment-button' onClick={() => {}}>
            <CancelIcon
              sx={{
                color: "#475466",
                cursor: "pointer",
                mx: 1,
              }}
              fontSize='medium'
            />

            <p className='rescheduele-appointment'>Delete Patient</p>
          </div>
        );
      },
    },
    {
      title: "download",
      render: (item) => {
        return (
          <div className='delete-appointment-button' onClick={() => {}}>
            <DownloadIcon
              sx={{
                color: "#475466",
                cursor: "pointer",
                mx: 1,
              }}
              fontSize='medium'
            />

            <p className='rescheduele-appointment'>Delete Patient</p>
          </div>
        );
      },
    },
  ];

  const handleClick = (ind) => {
    setIndex(ind);
  };

  const numberOfPages = Math.ceil(bookingsData.length / size);

  const getDataFromFirebase = async () => {
    try {
      const { data } = await axios.get(`${API}/bookings`);

      setBookingsData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDataFromFirebase();
  }, []);

  return (
    <div className='table-section-container'>
      <DashboardTable
        columns={BookingsTableConfig}
        rows={updatedRows}
        size={6}
        action={buttons}
        actionHeaderTitle='Actions'
      />
      {numberOfPages > 1 && (
        <DashboardTablePagination
          numberOfPages={numberOfPages}
          index={index}
          handleClick={handleClick}
        />
      )}
    </div>
  );
};

export default BookingsTable;
