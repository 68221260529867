/*
 {
    columnName: "Hospital Name",
    style: { marginLeft: "20px" },
    render: (data) => {
      return (
        <DashboardTableNameColumn
          title={data.name}
          subtitle={data.id}
          imgSrc={data.icon}
        />
      );
    },
  }, */
import DashboardTableRowChip from "../components/DashboardTableRowChip/DashboardTableRowChip";
import DashboardTableStatusColumn, {
  numberMonthMapping,
} from "../components/DashboardTableStatusComponent/DashboardTableStatusColumn";
import DashboardTableNameColumn from "../components/DashboardTableNameColumn/DashboardTableNameColumn";
import statusComplete from "../assets/images/Status-Completed-Tick.svg";
import statusScheduled from "../assets/images/Status-Scheduled-Tick.svg";

export const AddAppointmentsColumnConfig = [
  {
    columnName: "Hospital Name",
    style: { width: "210px", marginLeft: "20px", marginRight: "max(10px,3vw)" },
    render: (data) => {
      return (
        <DashboardTableNameColumn
          title={data.name}
          subtitle={data.id}
          hospitalLogo={data.hospitalLogo}
        />
      );
    },
  },
  {
    columnName: "Contact",
    value: "contact",
  },
  {
    columnName: "Location",
    value: "location",
  },
  {
    columnName: "Speciality",
    style: { width: "250px" },
    render: (data) => {
      const speciality =
        data?.speciality?.map((item, index) => {
          return { value: item, index: index };
        }) || [];

      return <DashboardTableRowChip chipsArray={speciality} />;
    },
  },
  {
    columnName: "Added On",
    render: (data) => {
      const date = data.addedOn.slice(0, 10);
      const day = date.slice(8, 10);
      const month = numberMonthMapping[+date.slice(5, 7)];
      const year = date.slice(0, 4);
      return (
        <p className="dashboard-table-row-description">{`${day} ${month} ${year}`}</p>
      );
    },
  },
  {
    columnName: "Total Staff",
    value: "totalStaff",
  },
  {
    columnName: "Status",
    style: { width: "200px" },
    render: (data) => {
      return (
        <DashboardTableStatusColumn
          status={data.status}
          statusDate={data.statusDate}
        />
      );
    },
  },
];
export const AddAppointmentsColumnConfigMain = [
  // {
  //   columnName: "Hospital Name",
  //   style: { width: "210px", marginLeft: "20px", marginRight: "max(10px,3vw)" },
  //   render: (data) => {
  //     return <DashboardTableNameColumn title={data.name} subtitle={data.id} />;
  //   },
  // },
  {
    columnName: "Hospital Name",
    style: { width: "210px", marginLeft: "20px", marginRight: "max(10px,3vw)" },
    render: (data) => {
      return (
        <DashboardTableNameColumn
          title={data.name}
          subtitle={data.id}
          hospitalLogo={data.hospitalLogo}
        />
      );
    },
  },
  {
    columnName: "Contact",
    value: "contact",
  },
  {
    columnName: "Speciality",
    style: { width: "250px" },
    render: (data) => {
      const speciality =
        data?.speciality?.map((item, index) => {
          return { value: item, index: index };
        }) || [];

      return <DashboardTableRowChip chipsArray={speciality} />;
    },
  },
  {
    columnName: "Added On",
    render: (data) => {
      const date = data.addedOn.slice(0, 10);
      const day = date.slice(8, 10);
      const month = numberMonthMapping[+date.slice(5, 7)];
      const year = date.slice(0, 4);
      return (
        <p className="dashboard-table-row-description">{`${day} ${month} ${year}`}</p>
      );
    },
  },
  {
    columnName: "Total Staff",
    value: "totalStaff",
  },
  {
    columnName: "Status",
    style: { width: "200px" },
    render: (data) => {
      return (
        <DashboardTableStatusColumn
          status={data.status}
          statusDate={data.statusDate}
        />
      );
    },
  },
];
export const AddAppointmentsDummyData = [
  {
    name: "Appolo Indraprasth Hospital",
    id: "B1C0018",
    speciality: "Multi",
    contact: "91 1231231231",
    addedOn: "2023-10-31T01:30:00.000-05:00",
    totalStaff: "100",
    status: "Completed",
    statusDate: "2022-09-19",
  },
  {
    name: "Mendata Hospital",
    id: "B1C0028",
    speciality: "Single",
    contact: "91 1321321321",
    addedOn: "2023-12-31T01:30:00.000-05:00",
    totalStaff: "102",
    status: "Scheduled",
    statusDate: "2022-09-19",
  },
  {
    name: "Appolo Indraprasth Hospital",
    id: "B1C0018",
    speciality: "Multi",
    contact: "91 1231231231",
    addedOn: "2023-11-31T01:30:00.000-05:00",
    totalStaff: "100",
    status: "Completed",
    statusDate: "2022-09-19",
  },
  {
    name: "Appolo Indraprasth Hospital",
    id: "B1C0018",
    speciality: "Multi",
    contact: "91 1231231231",
    addedOn: "2023-10-31T01:30:00.000-05:00",
    totalStaff: "100",
    status: "Completed",
    statusDate: "2022-09-19",
  },
  {
    name: "Appolo Indraprasth Hospital",
    id: "B1C0018",
    speciality: "Multi",
    contact: "91 1231231231",
    addedOn: "2023-10-31T01:30:00.000-05:00",
    totalStaff: "100",
    status: "Completed",
    statusDate: "2022-09-19",
  },
];
