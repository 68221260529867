import React, { useEffect } from "react";
import "./Home.css";
import Button from "../../components/Buttons/Button";
import CountUp from "react-countup";
import hero1 from "../../assets/lottie/hero-lottie-v10.json";
import hero from "../../assets/lottie/hero-lottie.json";
import Lottie from "lottie-react";
import { motion } from "framer-motion";
const Home = ({
  openContact,
  className,
  header,
  data,
  buttonName,
  buttonData,
  landingPage,
}) => {
  useEffect(() => {
    if (header) {
      document.querySelector(".header").innerHTML = header;
    }
    if (data) {
      document.querySelector(".data").innerHTML = data;
    }
    if (buttonData) {
      document.querySelector(".buttonData").innerHTML = buttonData;
    }
  }, [buttonData, data, header]);
  return (
    <section className={className}>
      <div className="portfolio-home-container">
        <>
          <div className="box1">
            <motion.h1
              initial={{ x: "-100%", opacity: 0 }}
              whileInView={{ x: "0%", opacity: 1 }}
              className="header"
            ></motion.h1>
            <p className="data"></p>
            <div className="portfolio-ideal-treatment-button-box">
              <Button
                text={buttonName}
                openContact={openContact}
              // landingPage={landingPage ? true : false}
              />
              {buttonData && (
                <small>
                  <CountUp
                    start={0}
                    end={5000}
                    duration={5}
                    style={{
                      width: "60px",
                      fontSize: "15px",
                      fontWeight: "bold",
                      paddingRight: "5px",
                    }}
                    suffix="+"
                  />
                  <span className="buttonData"></span>
                </small>
              )}
            </div>
          </div>
          <div className="box2">
            {landingPage ? (
              <Lottie animationData={hero1} className="hero-animation" />
            ) : (
              <Lottie animationData={hero} alt="" className="hero-animation" />
            )}
          </div>
        </>
      </div>
    </section>
  );
};

export default Home;
