import React, { useEffect, useState } from "react";
import "./PatientManagementTable.css";
import DashboardLayout from "../../pages/DashboardSidebar/DashboardLayout";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API } from "../../constants/api";
import DashboardTable from "../DashboardTable/DashboardTable";
import DashboardTablePagination from "../DashboardTablePagination/DashboardTablePagination";
import TablePen from "../../assets/images/table-pen-button.svg";
import Loader from "../Loader/Loader";
import { DeleteForeverOutlined } from "@mui/icons-material";
import FilterPopup from "../FilterPopup/FilterPopup";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Search from "../../assets/images/search-lg.svg";
import { patientManagementTableConfig } from "../../constants/PatientManagementTableConfig";
import PatientFilterModel from "../PatientFilterModel/PatientFilterModel";
import { sidebarFacilitatorItems } from "../../constants/SidebarItems";
import PatientDeletionPopup from "../PatientDeletionPopup/PatientDeletionPopup";

const PatientManagementTable = () => {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [loader, setLoader] = useState(false);
  const [patientsData, setPatientsData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(null);
  const [searchedInput, setSearchedInput] = useState("");
  // Menu Dropdown
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleMenuItemClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const size = 5;
  const updatedRows = patientsData.slice(size * index + 0, size * index + size);

  const handlePatientDelete = (item) => {
    setDeleteModal(item);
  };

  const handleDeleteClick = async (item) => {
    if (item === "Yes") {
      setLoader(true);
      try {
        await axios.delete(`${API}/patients/delete-patient/${deleteModal.id}`);
        getDataFromFirebase();
        setDeleteModal(null);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        setDeleteModal(null);
      }
    } else {
      setDeleteModal(null);
    }
  };

  const buttons = [
    {
      title: "cross",
      render: (item) => {
        return (
          <div
            className="rescheduele-appointment-button"
            onClick={() => {
              console.log(item);
              navigate(
                `/facilitator/patient-management/edit-patient-details?q=${item.id}`
              );
            }}
          >
            <img src={TablePen} alt="Edit icon" />
            <p className="rescheduele-appointment">Edit Patient Information</p>
          </div>
        );
      },
    },
    {
      title: "cross",
      render: (item) => {
        return (
          <div
            className="delete-appointment-button"
            onClick={() => {
              handlePatientDelete(item);
              handleClose();
            }}
          >
            <DeleteForeverOutlined
              sx={{
                color: "#475466",
                cursor: "pointer",
                mx: 3,
              }}
              fontSize="medium"
            />

            <p className="rescheduele-appointment">Delete Patient</p>
          </div>
        );
      },
    },
  ];

  const handleClick = (ind) => {
    setIndex(ind);
  };

  const numberOfPages = Math.ceil(patientsData.length / size);

  const getDataFromFirebase = async () => {
    try {
      const { data } = await axios.get(`${API}/patients`);
      console.log("Patients Data from Firebase", data);
      setPatientsData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDataFromFirebase();
  }, []);

  console.log("Patients Data from Firebase", patientsData);

  //////////////////////// PATIENT FILTER POPUP CONTENT START ////////////////////////////

  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const [isClosingFilter, setIsClosingFilter] = useState(false);
  const [filterPatientName, setFilterPatientName] = useState("");
  const [filterPatientMedicalCondition, setFilterPatientMedicalCondition] =
    useState("");
  const [filterPatientPreferredHospital, setFilterPatientPreferredHospital] =
    useState("");
  const [filterPatientHospitalLocation, setFilterPatientHospitalLocation] =
    useState("");
  const [filterPatientVisaAssistance, setFilterPatientVisaAssistance] =
    useState("");
  const [
    filterPatientAirportTransfersAssistance,
    setFilterPatientAirportTransfersAssistance,
  ] = useState("");
  const [
    filterPatientAccommodationAssistance,
    setFilterPatientAccommodationAssistance,
  ] = useState("");
  const [
    filterPatientAccommodationLocation,
    setFilterPatientAccommodationLocation,
  ] = useState("");
  const [selectedPatientFiltersChips, setSelectedPatientFiltersChips] =
    useState({
      gender: [],
      patientCountry: [],
    });

  const togglePatientFilterChip = (label, category) => {
    if (category === "location") {
      setSelectedPatientFiltersChips({
        ...selectedPatientFiltersChips,
        patientCountry: label,
      });
    } else {
      const currentSelection = selectedPatientFiltersChips[category];

      if (currentSelection.includes(label)) {
        setSelectedPatientFiltersChips({
          ...selectedPatientFiltersChips,
          [category]: currentSelection.filter((chip) => chip !== label),
        });
      } else {
        setSelectedPatientFiltersChips({
          ...selectedPatientFiltersChips,
          [category]: [...currentSelection, label],
        });
      }
    }
  };

  const togglePopupFilter = (toClear) => {
    if (toClear === "clearFilters") {
      if (isVisibleFilter) {
        setIsClosingFilter(true);
        setTimeout(() => {
          setIsVisibleFilter(false);
          setIsClosingFilter(false);
          handlePatientFilterClear();
        }, 300);
      } else {
        setIsVisibleFilter(true);
      }
    } else {
      if (isVisibleFilter) {
        setIsClosingFilter(true);
        setTimeout(() => {
          setIsVisibleFilter(false);
          setIsClosingFilter(false);
        }, 300);
      } else {
        setIsVisibleFilter(true);
      }
    }
  };

  const handlePatientFilterChange = (label, category) => {
    if (category === "name") {
      setFilterPatientName(label);
    }
    if (category === "medicalCondition") {
      setFilterPatientMedicalCondition(label);
    }
    if (category === "preferredHospital") {
      setFilterPatientPreferredHospital(label);
    }
    if (category === "hospitalLocation") {
      setFilterPatientHospitalLocation(label);
    }
    if (category === "visaAssistance") {
      if (filterPatientVisaAssistance === label) {
        setFilterPatientVisaAssistance("");
      } else {
        setFilterPatientVisaAssistance(label);
      }
    }
    if (category === "airportTransfersAssistance") {
      if (filterPatientAirportTransfersAssistance === label) {
        setFilterPatientAirportTransfersAssistance("");
      } else {
        setFilterPatientAirportTransfersAssistance(label);
      }
    }
    if (category === "accommodationAssistance") {
      if (filterPatientAccommodationAssistance === label) {
        setFilterPatientAccommodationAssistance("");
      } else {
        setFilterPatientAccommodationAssistance(label);
      }
    }
    if (category === "accommodationLocation") {
      setFilterPatientAccommodationLocation(label);
    }
  };

  const handlePatientFilterClear = () => {
    setFilterPatientName("");
    setFilterPatientMedicalCondition("");
    setFilterPatientPreferredHospital("");
    setFilterPatientHospitalLocation("");
    setFilterPatientVisaAssistance("");
    setFilterPatientAirportTransfersAssistance("");
    setFilterPatientAccommodationAssistance("");
    setFilterPatientAccommodationLocation("");
    setSelectedPatientFiltersChips({
      gender: [],
      patientCountry: [],
    });
  };

  const handlePatientFilterSubmit = () => {
    console.log("Patient Filters:", {
      name: filterPatientName,
      medicalCondition: filterPatientMedicalCondition,
      preferredHospital: filterPatientPreferredHospital,
      hospitalLocation: filterPatientHospitalLocation,
      visaAssistance: filterPatientVisaAssistance,
      airportTransfersAssistance: filterPatientAirportTransfersAssistance,
      accommodationAssistance: filterPatientAccommodationAssistance,
      accommodationLocation: filterPatientAccommodationLocation,
      gender: selectedPatientFiltersChips.gender,
      patientCountry: selectedPatientFiltersChips.patientCountry,
    });
    togglePopupFilter("clearFilters");
  };

  //////////////////////// ADD PATIENT POPUP CONTENT START ////////////////////////////

  const [errorText, setErrorText] = useState("");
  const [displayError, setDisplayError] = useState(false);

  const [addPatientName, setAddPatientName] = useState("");
  const [addPatientGender, setAddPatientGender] = useState("");
  const [addPatientContact, setAddPatientContact] = useState("");
  const [addPatientEmail, setAddPatientEmail] = useState("");
  const [addPatientCountry, setAddPatientCountry] = useState("");
  const [addPatientMedicalIssue, setAddPatientMedicalIssue] = useState("");
  const [addPatientDateOfBirth, setAddPatientDateOfBirth] = useState("");

  const [isVisibleAddPatient, setIsVisibleAddPatient] = useState(false);
  const [isClosingAddPatient, setIsClosingAddPatient] = useState(false);
  const [selectedFiltersAddPatient, setSelectedFiltersAddPatient] = useState({
    name: "",
    gender: "",
    contact: "",
    email: "",
    country: "",
    medicalIssue: "",
    dateOfBirth: "",
  });

  const toggleAddPatientChip = (label, category) => {
    const currentSelection = selectedFiltersAddPatient[category];
    if (currentSelection === "") {
      setSelectedFiltersAddPatient({
        ...selectedFiltersAddPatient,
        [category]: label,
      });
    } else {
      setSelectedFiltersAddPatient({
        ...selectedFiltersAddPatient,
        [category]: label,
      });
    }
  };

  const togglePopupAddPatient = (toClear) => {
    if (toClear === "clearFilters") {
      if (isVisibleAddPatient) {
        setIsClosingAddPatient(true);
        setTimeout(() => {
          setIsVisibleAddPatient(false);
          setIsClosingAddPatient(false);
          handleAddPatientClear();
        }, 300);
      } else {
        setIsVisibleAddPatient(true);
      }
    } else {
      if (isVisibleAddPatient) {
        setIsClosingAddPatient(true);
        setTimeout(() => {
          setIsVisibleAddPatient(false);
          setIsClosingAddPatient(false);
        }, 300);
      } else {
        setIsVisibleAddPatient(true);
      }
    }
  };

  const handleAddPatientClear = () => {
    setAddPatientName("");
    setAddPatientGender("");
    setAddPatientContact("");
    setAddPatientEmail("");
    setAddPatientCountry("");
    setAddPatientMedicalIssue("");
    setAddPatientDateOfBirth("");
  };

  const handleAddPatientSubmit = () => {
    if (
      addPatientName === "" ||
      addPatientGender === "" ||
      addPatientContact === "" ||
      addPatientEmail === "" ||
      addPatientCountry === "" ||
      addPatientMedicalIssue === "" ||
      addPatientDateOfBirth === ""
    ) {
      setErrorText("*Please fill all the fields");
      setDisplayError(true);
      setTimeout(() => {
        setDisplayError(false);
      }, 3000);
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const birthDateRegex =
      /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

    if (!emailRegex.test(addPatientEmail)) {
      setErrorText("*Please enter a valid email address");
      setDisplayError(true);
      setTimeout(() => {
        setDisplayError(false);
      }, 3000);
      return;
    }

    if (!birthDateRegex.test(addPatientDateOfBirth)) {
      setErrorText("*Please enter a valid date of birth");
      setDisplayError(true);
      setTimeout(() => {
        setDisplayError(false);
      }, 3000);
      return;
    }

    console.log("Patient Details:", {
      name: addPatientName,
      gender: addPatientGender,
      contact: addPatientContact,
      email: addPatientEmail,
      country: addPatientCountry,
      medicalIssue: addPatientMedicalIssue,
      dateOfBirth: addPatientDateOfBirth,
    });
    togglePopupAddPatient("clearFilters");
  };

  return (
    <DashboardLayout sidebarItems={sidebarFacilitatorItems}>
      {loader && <Loader />}
      <Breadcrumb
        breadcrumbs={[
          { label: "Patient Management", url: "/agent/patient-management" },
        ]}
        divider={"/"}
        styles={{
          paddingTop: "1rem",
        }}
      />

      <div>
        <div className="button-div">
          <div
            className="button-div"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <div className="button-div-left-side">
              <h1 className="appointment-header">Patient Management</h1>
              <button
                className="add-button-style"
                onClick={() =>
                  navigate(
                    "/facilitator/patient-management/add-patient-details"
                  )
                }
              >
                Add Patient
              </button>
              {/* <FilterPopup
                isVisible={isVisibleAddPatient}
                isClosing={isClosingAddPatient}
                setIsClosing={setIsClosingAddPatient}
                setIsVisible={setIsVisibleAddPatient}
                togglePopup={togglePopupAddPatient}
                handleFilterClear={handleAddPatientClear}
                handleFilterSubmit={handleAddPatientSubmit}
                name="Add Patient"
                buttonStyle="add-button-style"
                displayError={displayError}
                errorText={errorText}
              >
                <AddPatientModel
                  selectedFilters={selectedFiltersAddPatient}
                  toggleChip={toggleAddPatientChip}
                  addPatientName={addPatientName}
                  setAddPatientName={setAddPatientName}
                  addPatientGender={addPatientGender}
                  setAddPatientGender={setAddPatientGender}
                  addPatientContact={addPatientContact}
                  setAddPatientContact={setAddPatientContact}
                  addPatientEmail={addPatientEmail}
                  setAddPatientEmail={setAddPatientEmail}
                  addPatientCountry={addPatientCountry}
                  setAddPatientCountry={setAddPatientCountry}
                  addPatientMedicalIssue={addPatientMedicalIssue}
                  setAddPatientMedicalIssue={setAddPatientMedicalIssue}
                  addPatientDateOfBirth={addPatientDateOfBirth}
                  setAddPatientDateOfBirth={setAddPatientDateOfBirth}
                />
              </FilterPopup> */}
            </div>

            <div className="button-div-right-side">
              <img src={Search} alt="search" className="table-search-icon" />
              <input
                type="text"
                onChange={(event) => {
                  setSearchedInput(event.target.value);
                }}
                value={searchedInput}
                className="searched-input-field"
                placeholder="Search"
              />
              <FilterPopup
                isVisible={isVisibleFilter}
                isClosing={isClosingFilter}
                setIsClosing={setIsClosingFilter}
                setIsVisible={setIsVisibleFilter}
                togglePopup={togglePopupFilter}
                handleFilterClear={handlePatientFilterClear}
                handleFilterSubmit={handlePatientFilterSubmit}
                name="Filter"
                buttonStyle="filter-button-style"
                displayError={displayError}
                errorText={errorText}
              >
                <PatientFilterModel
                  selectedFilters={selectedPatientFiltersChips}
                  toggleChip={togglePatientFilterChip}
                  handlePatientFilterChange={handlePatientFilterChange}
                  filterPatientName={filterPatientName}
                  filterPatientMedicalCondition={filterPatientMedicalCondition}
                  filterPatientPreferredHospital={
                    filterPatientPreferredHospital
                  }
                  filterPatientHospitalLocation={filterPatientHospitalLocation}
                  filterPatientVisaAssistance={filterPatientVisaAssistance}
                  filterPatientAirportTransfersAssistance={
                    filterPatientAirportTransfersAssistance
                  }
                  filterPatientAccommodationAssistance={
                    filterPatientAccommodationAssistance
                  }
                  filterPatientAccommodationLocation={
                    filterPatientAccommodationLocation
                  }
                />
              </FilterPopup>
            </div>
          </div>
        </div>

        {deleteModal && (
          <PatientDeletionPopup
            handleClick={handleDeleteClick}
            data={deleteModal}
          />
        )}

        <div className="table-section-container">
          <DashboardTable
            columns={patientManagementTableConfig}
            rows={updatedRows}
            size={6}
            action={buttons}
            actionHeaderTitle="Actions"
          />{" "}
        </div>
        {numberOfPages > 1 && (
          <DashboardTablePagination
            numberOfPages={numberOfPages}
            index={index}
            handleClick={handleClick}
          />
        )}
      </div>
    </DashboardLayout>
  );
};

export default PatientManagementTable;
