import React, { useEffect, useState } from "react";
import axios from "axios";
import DashboardLayout from "../DashboardSidebar/DashboardLayout";
import { sidebarHospitalItems } from "../../constants/SidebarItems";
import { useUserAuth } from "../../context/UserAuthContext";
import { API } from "../../constants/api";
import getAccessToken from "../../utils/getAccessToken";
import FormGeneratorStore from "../../components/FormGenearatorAndStepper/FormGenearatorAndStepper";
import { format } from "date-fns";
import { hospitalProfile } from "../../constants/profileConfig";

const HospitalProfile = () => {
  const { user } = useUserAuth();
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getAccessToken();
        // console.log(token);
        const { data } = await axios.get(`${API}/hospital/user/profile`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        console.log("PROFILE DATA", data);

        if (data.item) {
          // Access the corresponding value
          const userProfileData = data.item;

          console.log("USER DATA", userProfileData);

          // var pattern = /\+(\d+)(\d+)/;
          // var match = userProfileData.Contact_Number.match(pattern);

          // var dialCode = match[1];
          // var phone = match[2];

          setUserData({
            Contact_Number: {
              value: { phone: userProfileData.Contact_Number || "" },
              isTouched: true,
            },
            Name_Of_Hospital: {
              value: userProfileData.Name_Of_Hospital || "",
              isTouched: true,
            },
            Nationality: {
              value: userProfileData.Nationality || "",
              isTouched: true,
            },
            Accreditation: {
              value: userProfileData.Accreditation.split(",") || "",
              isTouched: true,
            },
            Location: {
              value: userProfileData.Location || "",
              isTouched: true,
            },
            // Accreditation_Certificate: {
            //   value: userProfileData.Accreditation_Certificate || "",
            //   isTouched: true,
            // },
            NABH: {
              value: userProfileData.NABH ? userProfileData.NABH : null,
              isTouched: true,
            },
            JCI: {
              value: userProfileData.JCI ? userProfileData.JCI : null,
              isTouched: true,
            },
            NABL: {
              value: userProfileData.NABL ? userProfileData.NABL : null,
              isTouched: true,
            },
            License: {
              value: userProfileData.License || "",
              isTouched: true,
            },
            GST_Certificate: {
              value: userProfileData.GST_certificate || "",
              isTouched: true,
            },
            PAN_CARD: {
              value: userProfileData.PAN_CARD || "",
              isTouched: true,
            },

            IFSC_Code: {
              value: userProfileData.IFSC_Code || "",
              isTouched: true,
            },
            Account_Number: {
              value: userProfileData.Account_Number || "",
              isTouched: true,
            },
            Name_Of_Bank: {
              value: userProfileData.Name_Of_Bank || "",
              isTouched: false,
            },
          });
        } else {
          console.error("Error");
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, [user]);

  return (
    <DashboardLayout sidebarItems={sidebarHospitalItems}>
      <div
        className="edit-patient-details-container"
        style={{
          marginTop: "5rem",
        }}
      >
        <FormGeneratorStore
          agentTypes={hospitalProfile}
          // submitHandler={onEditSubmit}
          classnames="mx-auto mt-5"
          updatedFormData={userData}
        />
      </div>
    </DashboardLayout>
  );
};

export default HospitalProfile;
