import React from "react";
import "./UserEndContactComponent.css";
import img from "../../assets/images/contact_phone_svg.svg";
import img2 from "../../assets/images/contact_email_svg.svg";

const UserEndContactComponent = ({ openContactUsSidebarHandler }) => {
  return (
    <div className="UserEndContactComponent-container" id="need-help">
      <h1 className="UserEndContactComponent-heading">
        We are a team of healthcare experts
      </h1>
      <p className="UserEndContactComponent-subheading">
        Get FREE Opinion, Quote, Medical Visa Invitation, and assistance at
        every step of your treatment.
      </p>
      <div className="UserEndContactComponent-content-container">
        <button
          className="UserEndContactComponent-contact-button"
          onClick={openContactUsSidebarHandler}
        >
          Get in Touch
        </button>
        <p className="UserEndContactComponent-content-text">or</p>
        <img
          src={img}
          alt="phone"
          className="UserEndContactComponent-content-image"
        />
        <a
          className="UserEndContactComponent-content-text"
          href="tel:+91-9076557455"
        >
          +91 9076557455
        </a>
        <p className="UserEndContactComponent-content-text">OR</p>
        <img
          src={img2}
          alt="phone"
          className="UserEndContactComponent-content-image"
        />
        <a
          className="UserEndContactComponent-content-text"
          href="mailto:info@bharatonecare.com"
        >
          info@bharatonecare.com
        </a>
      </div>
    </div>
  );
};

export default UserEndContactComponent;
