import React, { useState, useEffect } from "react";
import cross from "../../assets/images/cross-icon-black.svg";
import SimpleInput from "../InputTypes/SimpleInput/SimpleInput";
import { addAccounts } from "../../constants/formconfig";
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import { auth } from "../../config/testFirebaseConfig";
import toast from "react-hot-toast";
import "./EditAccounts.css";

function EditAccounts({
  handleModalClose,
  handleSubmit,
  setAddedOpen,
  editData,
}) {
  const disabled = false;
  const today = new Date();
  const currentDate = new Date(today);
  currentDate.setDate(today.getDate() + 1);
  const year = `${currentDate.getFullYear()}`;
  let month = `${currentDate.getMonth() + 1}`;
  let date = "";
  if (currentDate.getMonth() + 1 < 10) {
    month = `0${currentDate.getMonth() + 1}`;
  }
  if (currentDate.getDate() < 10) {
    date = `0${currentDate.getDate()}`;
  }
  const minDate = `${year}-${month}-${date}`;
  const [validityDate, setValidityDate] = useState({
    value: "",
    isTouched: false,
  });
  const [formData, setFormData] = useState({});
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setFormData((obj) => {
      let tempObj = { ...obj };
      for (let key in editData) {
        tempObj[key] = { value: editData[key], isTouched: false };
      }
      return tempObj;
    });
    setChecked(editData.validity === "Never Expires");
    setValidityDate({
      value: editData.validity,
      isTouched: false,
    });
  }, [editData]);

  const handleDateChange = (title, value) => {
    setValidityDate({
      value: value,
      isTouched: true,
    });
  };

  const handleDateTouch = (title) => {
    setValidityDate((prev) => {
      return {
        value: prev.value,
        isTouched: true,
      };
    });
  };

  const handleChange = (field, newValue) => {
    // const updateChanges = { ...formData, [field]: newValue };
    // setFormData(updateChanges);
    setFormData((obj) => {
      let tempObj = { ...obj };
      const tempField = { ...tempObj[field], value: newValue };
      tempObj[field] = tempField;
      return tempObj;
    });
  };

  const handleTouch = (field) => {
    setFormData((obj) => {
      const tempObj = { ...obj };
      tempObj[field] = { ...tempObj[field], isTouched: true };
      return tempObj;
    });
  };

  const onSubmit = async () => {
    const payload = {};
    for (let key in formData) {
      payload[key] = formData[key].value;
    }
    payload["validity"] = validityDate.value;
    payload["password"] = "password";
    if (editData.email !== payload.email) {
      const exist = await fetchSignInMethodsForEmail(auth, payload.email);
      if (exist.length === 0) {
        await createUserWithEmailAndPassword(
          auth,
          payload.email,
          payload.password
        ).then(async () => {
          await sendPasswordResetEmail(auth, payload.email);
          handleSubmit(payload);
          setAddedOpen(true);
          handleModalClose();
        });
      } else {
        toast.error("Email already exists");
        setAddedOpen(true);
        handleModalClose();
      }
    } else {
      handleSubmit(payload);
      setAddedOpen(true);
      handleModalClose();
    }
  };

  return (
    <div className="modal-background-1">
      <div className="modal-section">
        <div className="modal-header modal-header-1">
          <h5
            className="modal-title  add-account-model-title"
            id="exampleModalLabel"
          >
            Edit Account
          </h5>
          <img
            src={cross}
            alt="cross"
            className="red-cross-button"
            onClick={() => {
              handleModalClose();
            }}
          />
        </div>
        <div className="modal-body">
          {addAccounts[0].fields.map((field, index) => {
            return (
              <div className="multi-select-box  mb-3">
                <SimpleInput
                  key={index}
                  input={field[0]}
                  state={formData[field[0].title]}
                  handleChange={handleChange}
                  handleTouch={handleTouch}
                />
              </div>
            );
          })}
          <div className="multi-select-box  mb-3">
            {checked ? (
              <input
                type="checkbox"
                name="vailidity"
                placeholder="vailidity"
                value="Never Expires"
                className="ms-1"
                onBlur={(event) => {
                  handleDateTouch("vailidity");
                }}
                onChange={(event) => {
                  handleDateChange("vailidity", event.target.value);
                  setChecked(!checked);
                }}
                checked
              ></input>
            ) : (
              <input
                type="checkbox"
                name="vailidity"
                placeholder="vailidity"
                value="Never Expires"
                className="ms-1"
                onBlur={(event) => {
                  handleDateTouch("vailidity");
                }}
                onChange={(event) => {
                  handleDateChange("vailidity", event.target.value);
                  setChecked(!checked);
                }}
              ></input>
            )}
            <label htmlFor="date" className="form-label ps-2 mb-1 ms-1">
              Never Expires
            </label>
          </div>
          {!checked && (
            <div className="multi-select-box  mb-3">
              <label htmlFor="date" className="form-label d-flex mb-1 ms-1">
                Validity Date
              </label>
              <input
                type="date"
                name="vailidity"
                placeholder="vailidity"
                min={minDate}
                value={validityDate.value}
                onBlur={(event) => {
                  handleDateTouch("vailidity");
                }}
                onChange={(event) => {
                  handleDateChange("vailidity", event.target.value);
                }}
                className="form-control simple-field"
              ></input>
            </div>
          )}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className={`my-btn-1 ${disabled === true ? "disabled" : ""}`}
            onClick={onSubmit}
          >
            Edit Account
          </button>
          <button
            type="button"
            className={`my-btn-1`}
            onClick={handleModalClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditAccounts;
