import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { AddAppointmentsColumnConfigMain } from "../../constants/AddAppointmentsTableConfig";
import "./HospitalStatus.css";
import DashboardLayout from "../../pages/DashboardSidebar/DashboardLayout";
import buildingPink from "../../assets/DashboardAssets/buildingPink.svg";
import rightTick from "../../assets/DashboardAssets/rightTick.svg";
import diffGreen from "../../assets/DashboardAssets/diffGreen.svg";
import DashboardTable from "../DashboardTable/DashboardTable";
import DashboardTablePagination from "../DashboardTablePagination/DashboardTablePagination";
import { Link } from "react-router-dom";
import StatusCrad from "../StatusCard/StatusCrad";
import { API } from "../../constants/api";
import Loader from "../Loader/Loader";
import BookingsTable from "../BookingsTable/BookingsTable";
import { sidebarHospitalItems } from "../../constants/SidebarItems";
import { useUserAuth } from "../../context/UserAuthContext";

const HospitalStatus = () => {
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [index, setIndex] = useState(0);
  const size = 4;
  const updatedRows = tableData.slice(size * index + 0, size * index + size);
  const { user, logOut } = useUserAuth();
  const handleClick = (ind) => {
    setIndex(ind);
  };
  const numberOfPages = Math.ceil(tableData.length / size);

  const getDataFromFirebase = async () => {
    setLoader(true);
    try {
      const response = await axios.get(
        `${API}/appointment/getallappointments/-NhLGB5XwncQ6Keg88vT`
      );
      const data = await response.data;
      console.log(data.appointments);
      const keys = Object.keys(data.appointments);
      const tempTableData = keys.map((key) => {
        const payload = data.appointments[key];
        payload.uid = key;
        return payload;
      });
      console.log(tempTableData);
      setLoader(false);
      // tempTableData.sort((a, b) => {
      //   return Number(new Date(a.statusDate)) - Number(new Date(b.statusDate));
      // });
      // setTableData((ele) => [...tempTableData]);
      const sortedTableData = statusDataSort(tempTableData);
      console.log(tempTableData);
      setTableData((ele) => [...sortedTableData]);
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };

  const statusDataSort = (tableData) => {
    const scheduledArray = tableData.filter(
      (ele) => ele.status === "Scheduled"
    );
    const inProgressArray = tableData.filter(
      (ele) => ele.status === "In Progress"
    );
    const completedArray = tableData.filter(
      (ele) => ele.status === "Completed"
    );
    const cancelledArray = tableData.filter(
      (ele) => ele.status === "Cancelled"
    );
    scheduledArray.sort((a, b) => {
      return Number(new Date(a.statusDate)) - Number(new Date(b.statusDate));
    });
    inProgressArray.sort((a, b) => {
      return Number(new Date(a.statusDate)) - Number(new Date(b.statusDate));
    });
    completedArray.sort((a, b) => {
      return Number(new Date(a.statusDate)) - Number(new Date(b.statusDate));
    });
    cancelledArray.sort((a, b) => {
      return Number(new Date(a.statusDate)) - Number(new Date(b.statusDate));
    });
    return [
      ...scheduledArray,
      ...inProgressArray,
      ...completedArray,
      ...cancelledArray,
    ];
  };

  useEffect(() => {
    getDataFromFirebase();
  }, []);

  const title = "All hospital";
  const number = 50;
  const description = "vs last month";

  const arr = [1, 2];

  return (
    <DashboardLayout sidebarItems={sidebarHospitalItems}>
      {loader && <Loader />}
      <div className="row m-0 left-text-box px-4 mt-4">
        <div className="col welcome-box  d-flex flex-column">
          <div className="welcome-text d-flex ">
            <h1 className="fw-bold mb-0 text-start">
              Welcome back, {user?.displayName}
            </h1>
          </div>
          <div className="muted-text d-flex ">
            <p className="text-muted mb-2 text-start">
              Track and manage your hospital on boarding and oppointment
            </p>
          </div>
        </div>
      </div>

      <div className="row m-0 status-card px-md-5 px-2 mt-2 p-3">
        <div className="col-6 m-0 p-0">
          <div className="row m-0 p-0">
            {arr.map((item) => {
              return (
                <StatusCrad
                  status={rightTick}
                  icon={buildingPink}
                  number={number}
                  title={title}
                  difference={diffGreen}
                  description={description}
                />
              );
            })}
          </div>
        </div>
        <div className="col-6 m-0 p-0">
          <div className="row m-0 p-0">
            {arr.map((item) => {
              return (
                <StatusCrad
                  status={rightTick}
                  icon={buildingPink}
                  number={number}
                  title={title}
                  difference={diffGreen}
                  description={description}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className="row m-0 suggestion-box px-md-5 px-2 mt-2">
        <div className="col-12 suggestion-content border">
          <div className="row m-0">
            <div className="suggest-text-up d-flex  p-2">
              <h2 className="text-start mb-0">Suggestion</h2>
            </div>
          </div>
          <div className="row m-0">
            <div className="col suggest-text-bottom d-flex px-2 mb-2  align-items-center flex-md-row flex-column">
              <span
                className="d-inline-block text-truncate text-start m-0 "
                style={{ maxWidth: "85%" }}
              >
                8 hospitals recently stopped being active, contact the hospitals
                to retain them. In this article, find the script to Lorem ipsum
                dolor sit amet consectetur adipisicing elit. Ipsam harum
                voluptatem omnis repudiandae ex vitae iusto vel placeat facere
                minima exercitationem velit nemo aspernatur est nobis, quaerat,
                voluptatum esse sapiente voluptas ullam enim? Nemo.
              </span>
              <div className="link-box d-flex ms-sm-4">
                <p className="m-0 fs-lg-5 fs-6">
                  <Link to className="link-to-article">
                    View this article →
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row m-0 my-5 table-booking-box px-md-5 px-2">
        <div
          className="col p-0"
          style={{
            border: "1px solid gray",
            borderRadius: "12px",
          }}
        >
          <div className="row mx-0 upcoming-main-box px-4 mt-4">
            <div className="col upcoming-box  d-flex mx-3">
              <div style={{ marginBottom: "20px" }}>
                <p
                  className="text-start mb-0"
                  style={{
                    fontWeight: "550",
                    fontSize: "25px",
                  }}
                >
                  Bookings
                </p>
              </div>
              <div className="link-box d-flex ms-sm-4 mt-2 ">
                <p className="m-0 fs-8">
                  <Link to="/facilitator/bookings" className="link-to-article">
                    View all →
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <div className="row m-0  mb-0 px-md-5 px-2">
            <div className="col-12 p-0 table-section-container d-flex justify-content-start ">
              <BookingsTable />
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default HospitalStatus;
