import React, { useState } from "react";
import "./AddBookingModal.css";
import { TextField } from "@mui/material";
import "react-phone-input-2/lib/style.css";
import FilterMultiSelect from "../InputTypes/FilterMultiSelect/FilterMultiSelect";
import { indianCities } from "../../assets/custom-data/indianCities";
const preferredHospitals = [
  "Apollo",
  "Bharat",
  "Cipla",
  "Fortis",
  "Max",
  "Medi",
  "Niramaya",
  "Piramal",
  "Sahara",
  "Sri",
  "Surya",
  "United",
  "Vijaya",
  "Vishwa",
];
const AddBookingModal = (props) => {
  return (
    <div className='add-patient-model-container'>
      <div className='add-patient-name-section add-patient-section'>
        <p className='model-section-title'>
          Patient Name (as seen in passport)
        </p>
        <TextField
          id='outlined-basic'
          label=''
          placeholder='Search Name'
          variant='outlined'
          size='small'
          InputProps={{
            style: {
              borderRadius: "0",
              // width: "27vw",
            },
          }}
          value={props.addPatientName}
          onChange={(e) => {
            props.setAddPatientName(e.target.value);
          }}
          sx={{
            width: {
              xs: "94vw",
              sm: "50vw",
              md: "35vw",
              lg: "28vw",
              xl: "28vw",
            },
            paddingLeft: "1rem",
            // marginTop: "-0.625rem",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "black", // Default border color
              },
              "&:hover fieldset": {
                borderColor: "#EF5EA2", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "#EF5EA2", // Border color when focused
              },
            },
          }}
        />
      </div>
      <div className='add-hospital-name-section add-hospital-section'>
        <p className='model-section-title'>Hospital Name</p>
        {/* <TextField
          id='outlined-basic'
          label=''
          placeholder='Search Name'
          variant='outlined'
          size='small'
          InputProps={{
            style: {
              borderRadius: "0",
              // width: "27vw",
            },
          }}
          value={props.addHospitalName}
          onChange={(e) => {
            props.setAddHospitalName(e.target.value);
          }}
          sx={{
            width: {
              xs: "94vw",
              sm: "50vw",
              md: "35vw",
              lg: "28vw",
              xl: "28vw",
            },
            paddingLeft: "1rem",
            // marginTop: "-0.625rem",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "black", // Default border color
              },
              "&:hover fieldset": {
                borderColor: "#EF5EA2", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "#EF5EA2", // Border color when focused
              },
            },
          }}
        /> */}
        <FilterMultiSelect
          selectedFilters={props.selectedFilters}
          onSelect={props.toggleChip}
          value={props.selectedFilters.preferredHospital}
          size='small'
          options={preferredHospitals}
          hospital
          category='preferredHospital'
        />
      </div>
      <div className='add-treatment-type-section add-treatment-type-section'>
        <p className='model-section-title'>Treatment Type</p>
        <TextField
          id='outlined-basic'
          label=''
          placeholder='Search Name'
          variant='outlined'
          size='small'
          InputProps={{
            style: {
              borderRadius: "0",
              // width: "27vw",
            },
          }}
          value={props.addTreatmentType}
          onChange={(e) => {
            props.setAddTreatmentType(e.target.value);
          }}
          sx={{
            width: {
              xs: "94vw",
              sm: "50vw",
              md: "35vw",
              lg: "28vw",
              xl: "28vw",
            },
            paddingLeft: "1rem",
            // marginTop: "-0.625rem",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "black", // Default border color
              },
              "&:hover fieldset": {
                borderColor: "#EF5EA2", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "#EF5EA2", // Border color when focused
              },
            },
          }}
        />
      </div>
      <div className='add-treatment-type-section add-treatment-type-section'>
        <p className='model-section-title'>Select a booking date</p>
        <TextField
          type='datetime-local'
          id='outlined-basic'
          label=''
          placeholder='Search Name'
          variant='outlined'
          size='small'
          InputProps={{
            style: {
              borderRadius: "0",
              // width: "27vw",
            },
          }}
          value={props.addBookingDate}
          onChange={(e) => {
            props.setAddBookingDate(e.target.value);
          }}
          sx={{
            width: {
              xs: "94vw",
              sm: "50vw",
              md: "35vw",
              lg: "28vw",
              xl: "28vw",
            },
            paddingLeft: "1rem",
            // marginTop: "-0.625rem",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "black", // Default border color
              },
              "&:hover fieldset": {
                borderColor: "#EF5EA2", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "#EF5EA2", // Border color when focused
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default AddBookingModal;
