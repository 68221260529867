import React from "react";
import "./ServiceBox.css";
import checkCircle from "../../assets/images/check-circle-small.svg";
import Button from "../Buttons/Button";
const ServiceInfo = ({ title, description }) => {
  return (
    <div className="service-info-box">
      <div>
        <img src={checkCircle} alt="" />
        <h3>{title}</h3>
      </div>
      <p>{description}</p>
    </div>
  );
};
const ServiceBox = ({ category, services, actionName, icon, openContact }) => {
  return (
    <div
      className={`${
        category === "For Partners"
          ? "portfolio-service-box2"
          : "portfolio-service-box"
      }`}
    >
      <div className={`${category === "For Partners" ? "orderChange" : ""}`}>
        <img
          src={icon}
          alt=""
          className={`${
            category === "For Partners"
              ? "portfolio-service-box-icon2"
              : "portfolio-service-box-icon"
          }`}
        />
      </div>
      <div
        className={`${
          category === "For Partners" ? "service-info2" : "service-info "
        }`}
      >
        <div
          className={` ${
            category === "For Partners"
              ? "service-category2"
              : "service-category"
          }`}
        >
          {category}
        </div>
        {services &&
          services.map((service) => (
            <ServiceInfo
              key={service?.title}
              title={service?.title}
              description={service?.description}
            />
          ))}
        <br />
        <div
          className={`${
            category === "For Partners"
              ? "portfolio-service-box-button2"
              : "portfolio-service-box-button"
          }`}
        >
          {" "}
          <Button
            text={actionName}
            fontSize={window.innerWidth < 300 ? "16px" : "20px"}
            openContact={openContact}
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceBox;
