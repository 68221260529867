import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import "./CustomerDashboard.css";
import Search from "../../assets/images/search-lg.svg";
import DashboardTable from "../../components/DashboardTable/DashboardTable.jsx";
import DashboardTablePagination from "../../components/DashboardTablePagination/DashboardTablePagination.jsx";
import { useUserAuth } from "../../context/UserAuthContext";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { API } from "../../constants/api";
import { Dropdown } from "react-bootstrap";
import Loading from "../../components/ProcedurePageComponents/Loading/Loading.jsx";
import { bookingStatus } from "../../constants.js";
import FilterPopup from "../../components/FilterPopup/FilterPopup.js";
import MultipleSelect from "../../components/InputTypes/MultiSelect/MultipleSelect.jsx";
import FilterMultiSelect from "../../components/InputTypes/FilterMultiSelect/FilterMultiSelect.js";
import moment from "moment/moment.js";
import { CustomerDashboardFilters } from "./CustomerDashboardFilters.js";

const CustomerDashboard = () => {
  const statusOptions = bookingStatus.map((x) => ({
    value: x.statusName,
    label: x.statusName,
  }));
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRowUid, setSelectedRowUid] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [diagnostics, setDiagnostics] = useState([]);
  const [hospitalData, setHospitalData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isVisibleFilter, setIsVisibleFilter] = useState(false);
  const [isClosingFilter, setIsClosingFilter] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [statusText, setStatusText] = useState([]);
  const [displayError, setDisplayError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [index, setIndex] = useState(0);
  const { user, logOut } = useUserAuth();
  const [searchedInput, setSearchedInput] = useState("");
  const [filterValues, setFilterValues] = useState({
    date: "",
    statusValue: [],
    diagnosticCenter: [],
  });
  const [size, setsize] = useState(1);
  const [updatedRows, setupdatedRows] = useState([]);
  const [numberOfPages, setnumberOfPages] = useState(1);
  // Function to handle status change for a specific row
  const handleStatusChange = async (selectedStatus, rowIndex, rowUid) => {
    try {
      // Send PUT request to update status
      await axios.put(`${API}/diagnostics/update/${rowUid}`, {
        status: selectedStatus.value,
        user,
        timestamp: new Date().toISOString(),
      });
      setOpenDialog(false);
      const response = await axios.get(`${API}/diagnostics/getalldiagnostics`);
      setDiagnostics(response?.data?.diagnostics);
      setTableData(response?.data?.diagnostics);
    } catch (error) {
      console.error("Error updating status:", error);
      // Handle error
    }
  };

  const handleRowClick = (uid) => {
    setSelectedRowUid(uid);
    setOpenDialog(true);
  };

  const renderStatusForRow = (item) => {
    const rowUid = item.uid;
    let colour = "rgb(152, 152, 7)";
    bookingStatus.forEach((st) => {
      if (item.status === st.statusName) {
        colour = st.statusColor;
      }
    });
    if (selectedRowUid === rowUid && openDialog)
      return (
        <div className="change-diag-status">
          <Dropdown
            size="lg"
            style={{ fontSize: "0.75rem", padding: "0.25rem 0.5rem" }}
          >
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              size="sm"
              style={{ fontSize: "0.75rem", padding: "0.25rem 0.5rem" }}
            >
              Select Button
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {statusOptions.map((option, rowIndex) => (
                <Dropdown.Item
                  key={rowIndex}
                  onClick={() => handleStatusChange(option, rowIndex, rowUid)}
                >
                  {option.value}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    else
      return (
        <div className="change-diag-status">
          <div
            style={{
              color: colour,
              border: `2px solid ${colour}`,
              borderRadius: "20px",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
              padding: "0 10px",
            }}
            onClick={() => handleRowClick(rowUid)}
          >
            {item?.status || "Select Status"}
          </div>
        </div>
      );
  };

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API}/diagnostics/getalldiagnostics`
        );
        const hospRes = await axios.get(`${API}/hospital/getallhospitals`);
        setDiagnostics(response?.data?.diagnostics);
        setHospitalData(hospRes?.data?.hospitals);
        setTableData(response?.data?.diagnostics);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching diagnostics:", error);
        setLoading(false);
      }
    };
    fetchData().catch();
  }, []);
  useEffect(() => {
    const filteredData = tableData.length
      ? tableData.filter((item) => {
          const currentDate = new Date();
          const itemDate = new Date(item.onBoardedOn);
          switch (selectedFilter) {
            case "today":
              return isSameDate(itemDate, currentDate);
            case "tomorrow":
              const tomorrowDate = new Date(currentDate);
              tomorrowDate.setDate(currentDate.getDate() + 1);
              return isSameDate(itemDate, tomorrowDate);
            case "yesterday":
              const yesterdayDate = new Date(currentDate);
              yesterdayDate.setDate(currentDate.getDate() - 1);
              return isSameDate(itemDate, yesterdayDate);
            case "this_week":
              return isSameWeek(itemDate, currentDate);
            case "this_month":
              return itemDate.getMonth() === currentDate.getMonth();
            case "all":
              return true; // Return true for all items
            default:
              return true;
          }
        })
      : [];
    let minsize = Math.min(filteredData.length, 5);
    let newupdatedRows = filteredData.slice(
      size * index + 0,
      size * index + size
    );
    let newNumberOfPages = filteredData.length
      ? Math.ceil(filteredData.length / size)
      : 1;
    setsize(minsize);
    setupdatedRows(newupdatedRows);
    setnumberOfPages(newNumberOfPages);
  }, [tableData, selectedFilter, index, size]);

  const isSameDate = (date1, date2) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  // Function to check if two dates are in the same week
  const isSameWeek = (date1, date2) => {
    const firstDayOfWeek = new Date(date2);
    firstDayOfWeek.setDate(date2.getDate() - date2.getDay());

    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

    return date1 >= firstDayOfWeek && date1 <= lastDayOfWeek;
  };

  const togglePopupFilter = (toClear) => {
    if (toClear === "clearFilters") {
      if (isVisibleFilter) {
        setIsClosingFilter(true);
        setTimeout(() => {
          setIsVisibleFilter(false);
          setIsClosingFilter(false);
          // handlePatientFilterClear();
        }, 300);
      } else {
        setIsVisibleFilter(true);
      }
    } else {
      if (isVisibleFilter) {
        setIsClosingFilter(true);
        setTimeout(() => {
          setIsVisibleFilter(false);
          setIsClosingFilter(false);
        }, 300);
      } else {
        setIsVisibleFilter(true);
      }
    }
  };
  const handleClick = (ind) => {
    setIndex(ind);
  };
  const handleFilterSubmit = () => {
    togglePopupFilter("dontClearFilters");
    let updatedRowData = diagnostics;
    if (filterValues.diagnosticCenter.length) {
      updatedRowData = updatedRowData.filter((x) =>
        filterValues.diagnosticCenter.includes(x.hospitalName)
      );
    }
    if (filterValues.statusValue.length) {
      updatedRowData = updatedRowData.filter((x) =>
        filterValues.statusValue.includes(x.status)
      );
    }
    setTableData(updatedRowData);
  };
  const diagnosticTableConfig = [
    {
      columnName: "Date and Time",
      value: "10:00 AM",
      style: {
        width: "120px",
        marginLeft: "20px",
        marginRight: "max(10px,3vw)",
      },
      render: (rowData) => {
        return (
          <div>
            <h5>{new Date(rowData.onBoardedOn).toLocaleDateString()}</h5>
            <span>{moment(rowData.onBoardedOn).format("h:mm a")}</span>
          </div>
        );
      },
    },
    {
      columnName: "Patient Name",
      style: { width: "140px", marginLeft: "20px" },

      render: (data) => {
        return (
          <div
            style={{
              background: "aliceblue",
              marginLeft: "100px",
              width: "140px",
            }}
          >
            <span>{data.patientName ? data.patientName : "Anonymous"}</span>
          </div>
        );
      },
    },
    {
      columnName: "Hospital Name",
      style: { width: "250px", marginLeft: "20px" },
      render: (data) => {
        return (
          <div style={{ marginLeft: "60px", width: "250px" }}>
            <div
              style={{
                padding: "0px",
                display: "flex",
                width: "210px",
                marginRight: "max(10px,3vw)",
                marginLeft: "10px",
              }}
            >
              <div className="dashboard-table-row-personal">
                <p className="dashboard-table-row-title h4">
                  {data.hospitalName}
                </p>
                <span>{data?.hospital?.Location}</span>
              </div>
            </div>
          </div>
        );
      },
    },

    {
      columnName: "Prodcedure",
      style: { width: "140px", marginLeft: "20px" },
      type: "date",
      render: (data) => {
        return (
          <div style={{ marginLeft: "90px", width: "100px" }}>
            <span>{data.nameOfDiagnostics}</span>
          </div>
        );
      },
    },
    {
      columnName: "Status",
      render: (item) => {
        return (
          <div style={{ width: "250px !important", marginLeft: "70px" }}>
            {renderStatusForRow(item)}
          </div>
        );
      },
    },
  ];
  if (loading) return <Loading />;
  else
    return (
      <div className="table-booking-box">
        <div className="col p-0">
          <div className="customer-support-dashboard-title-filter-container">
            <div className="dashboard-title">
              <p
                className="text-start mb-0"
                style={{
                  fontWeight: "550",
                  fontSize: "25px",
                }}
              >
                Diagnostic center bookings
              </p>
            </div>
            <div className="dashboard-search-and-filter">
              <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Filter"
                  value={selectedFilter}
                  onChange={(event) => setSelectedFilter(event.target.value)}
                >
                  <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={"today"}>Today</MenuItem>
                  <MenuItem value={"tomorrow"}>Tomorrow</MenuItem>
                  <MenuItem value={"yesterday"}>Yesterday</MenuItem>
                  <MenuItem value={"this_week"}>This Week</MenuItem>
                  <MenuItem value={"this_month"}>This Month</MenuItem>
                </Select>
              </FormControl>
              <div
                className="table-search-container"
                style={{ marginRight: "10px" }}
              >
                <img src={Search} alt="search" className="table-search-icon" />
                <input
                  type="text"
                  onChange={(event) => {
                    setSearchedInput(event.target.value);
                  }}
                  value={searchedInput}
                  className="searched-input-field"
                  placeholder="Search"
                />
              </div>
              <FilterPopup
                isVisible={isVisibleFilter}
                isClosing={isClosingFilter}
                setIsClosing={setIsClosingFilter}
                setIsVisible={setIsVisibleFilter}
                togglePopup={togglePopupFilter}
                handleFilterClear={() => {
                  console.log(filterValues);
                  setFilterValues({
                    date: "",
                    statusValue: [],
                    diagnosticCenter: [],
                  });
                }}
                handleFilterSubmit={handleFilterSubmit}
                name="Filter"
                buttonStyle="filter-button-style"
                displayError={displayError}
                errorText={errorText}
              >
                <CustomerDashboardFilters
                  filterValues={filterValues}
                  setFilterValues={setFilterValues}
                  statusOptions={statusOptions}
                  diagnosticCenterOptions={hospitalData.map(
                    (x) => x.Name_Of_Hospital
                  )}
                />
              </FilterPopup>
            </div>
          </div>

          <div className="row m-0  mb-0 px-0">
            <div className="col-12 p-0 table-section-container d-flex justify-content-start">
              <DashboardTable
                columns={diagnosticTableConfig}
                rows={updatedRows}
                size={size}
              />{" "}
            </div>
          </div>
          {numberOfPages > 1 && (
            <DashboardTablePagination
              numberOfPages={numberOfPages}
              index={index}
              handleClick={handleClick}
            />
          )}
        </div>
      </div>
    );
};

export default CustomerDashboard;
