import React, { useContext, useEffect, useState } from "react";
import ProcedureDetails from "../Sections/ProcedureDetails/ProcedureDetails";
import PatientReviews from "../Sections/PatientReviews/PatientReviews";
import RecommendedHospitals from "../Sections/RecommendedHospitals/RecommendedHospitals";
import "./ProcedurePage.css";
import {
  procedureFAQs,
  procedurePageProgressBarOptions,
} from "../../../constants";
import StickyProgressBarSection from "../../../components/StickyProgressBarSection/StickyProgressBarSection";
import TopDoctors from "../Sections/TopDoctors/TopDoctors";
import Footer from "../../../components/Footer/Footer";
import ProcedureFAQs from "../Sections/ProcedureFAQ/ProcedureFAQs.jsx";
import { ProcedureContext } from "../../../context/ProcedureContextApi.jsx";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import LandingPageNavbar from "../../../components/LandingPageNavbar/LandingPageNavbar.jsx";
import Loading from "../../../components/ProcedurePageComponents/Loading/Loading.jsx";
import AboutProcedure from "../Sections/About/AbouProcedure.jsx";
import CardSlider from "../Sections/CardSlider/CardSlider.jsx";
import PatientReviewCard from "../../../components/ProcedurePageComponents/PatientReviewCard/PatientReviewCard.jsx";
import { API } from "../../../constants/api.js";
import UserEndContactComponent from "../../../components/UserEndContactComponent/UserEndContactComponent.js";
import ContactUsSidebar from "../../../components/ContactUsSidebar/ContactUsSidebar.jsx";
import HospitalDetails from "../../../components/HospitalDetails/HospitalDetails.jsx";
import ReviewSlider from "../../../components/ReviewSlider/ReviewSlider.jsx";
import SectionHeading from "../../../components/ProcedurePageComponents/SectionHeading/SectionHeading.jsx";
import LandingPageAccordian from "../../../components/LandingPageAccordian/LandingPageAccordian.js";
import { ReviewsTranslation } from "../../../constants/Translation.js";

export const ProcedurePagePrimaryButton = ({
  text,
  action,
  width = "100%",
}) => {
  return (
    <button
      className="procedure-page-primary-button"
      onClick={action}
      style={{ width }}
    >
      {text}
    </button>
  );
};
// const procedureSteps = [
//   { stepName: "Overview", stepComponent: <Overview /> },
//   { stepName: "Symptoms" },
//   { stepName: "Diagonsis" },
//   { stepName: "Risk Factors" },
//   { stepName: "Post Operative Care" },
//   { stepName: "Treatments" },
//   { stepName: "Cost Factors" },
//   { stepName: "About Hospital" },
//   { stepName: "FAQ" },
// ];

const ProcedurePage = () => {
  const { procedureAndHospitalData, setProcedureAndHospitalData } =
    useContext(ProcedureContext);
  const [loading, setLoading] = useState(false);
  const [contactUsSidebarToggle, setContactUsSidebarToggle] = useState(false);
  const query = useLocation();
  const queryParams = new URLSearchParams(query.search);
  const name = queryParams.get("name");
  const hospital = queryParams.get("hospital");
  const location = queryParams.get("location");

  // const sectionRef = useRef(null);

  const getProcedureAndHospitalData = async () => {
    setLoading(true);
    try {
      // const url = `${process.env.REACT_APP_BACKEND_URL}/procedures/procedure?name=${name}&hospital=${hospital}&location=${location}`;
      const url = `${API}/procedures/procedure?name=${name}&hospital=${hospital}&location=${location}`;
      const { data } = await axios.get(url);
      const { procedure, hospitalForProcedure, leadingHospitalsIds } = data;
      console.log({ procedure, hospitalForProcedure });
      setProcedureAndHospitalData({
        ...procedure,
        hospital: hospitalForProcedure,
        leadingHospitalsIds,
      });
      setLoading(false);
    } catch (err) {
      if (err.response) {
        return toast.error(err.response.data.message);
      }
      return toast.error(err.message);
    }
  };
  useEffect(() => {
    getProcedureAndHospitalData();
  }, [name, hospital, location]);
  if (loading) {
    return <Loading />;
  }
  const openContactUsSidebarHandler = () => {
    setContactUsSidebarToggle(true);
  };
  const closeContactUsSidebarHandler = () => {
    document.getElementById("portfolio-contact-us-sidebar").style.transform =
      "translateX(100%)";
    setTimeout(() => setContactUsSidebarToggle(false), 500);
  };

  return (
    <div>
      <LandingPageNavbar />
      <ProcedureDetails
        treatmentName={procedureAndHospitalData?.name}
        hospitalLocation={procedureAndHospitalData?.hospital?.Location}
        hospitalName={procedureAndHospitalData?.hospital?.Name_Of_Hospital}
        startingAtPrice={procedureAndHospitalData?.hospital?.amount}
        stayInHospitalDays={procedureAndHospitalData?.hospital?.days_required}
        reviews={procedureAndHospitalData?.patient_reviews?.length}
        accreditation={procedureAndHospitalData?.hospital?.accreditation}
      />

      <StickyProgressBarSection optionsList={procedurePageProgressBarOptions}>
        <>
          <AboutProcedure data={procedureAndHospitalData} />
          <HospitalDetails
            openContactUsSidebarHandler={openContactUsSidebarHandler}
            hospitalLocation={procedureAndHospitalData?.hospital?.Location}
            hospitalName={procedureAndHospitalData?.hospital?.Name_Of_Hospital}
            hospitalImages={procedureAndHospitalData?.hospital?.Hospital_images}
          />
          <TopDoctors
            doctorsFor={procedureAndHospitalData?.name}
            hospitalId={procedureAndHospitalData?.hospital?.hospital_id}
            topDoctorsIds={procedureAndHospitalData?.hospital?.doctor_ids}
            location={procedureAndHospitalData?.hospital?.Location}
          />
          <div className="review progress-section" id="patient-reviews">
            <SectionHeading text={"Patient Reviews"} />
            <div
              style={{ backgroundColor: "var(--secondaryColor)" }}
              className="patient-reviews"
            >
              <ReviewSlider
                // reviews={procedureAndHospitalData?.patient_reviews}
                reviews={ReviewsTranslation.en}
              />
            </div>
          </div>
          {/* <PatientReviews
            patientReviews={procedureAndHospitalData?.patient_reviews}
          /> */}
          <RecommendedHospitals
            hospitalsFor={procedureAndHospitalData?.name}
            leadingHospitalsIds={procedureAndHospitalData?.leadingHospitalsIds}
          />
          {/* <ProcedureFAQs procedureFAQs={procedureFAQs} /> */}
          <div className="main-container progress-section" id="procedure-faqs">
            <div className="container">
              <SectionHeading text={"FAQs"} white />
              <LandingPageAccordian business />
            </div>
          </div>
        </>
      </StickyProgressBarSection>

      <UserEndContactComponent
        openContactUsSidebarHandler={openContactUsSidebarHandler}
      />

      {/* <CardSlider cards={[[<PatientReviewCard/>, <PatientReviewCard/>, <PatientReviewCard/>],[ <PatientReviewCard/>]]} /> */}
      <Footer />
      {contactUsSidebarToggle && (
        <ContactUsSidebar closeHandler={closeContactUsSidebarHandler} />
      )}
    </div>
  );
};

export default ProcedurePage;
