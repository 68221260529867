import React from "react";
import CommonComponent from "../../components/CommonComponent/CommonComponent";
import PrivacyPolicyComponent from "./PrivacyPolicyComponent/PrivacyPolicyComponent";

const PrivacyPolicyPage = () => {
  return (
    <CommonComponent>
      <PrivacyPolicyComponent />
    </CommonComponent>
  );
};

export default PrivacyPolicyPage;
