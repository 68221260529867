import React from "react";
import { Avatar } from "@mui/material";
import Tooltip from "../Tooltip/Tooltip";
import "./DashboardTableNameColumn.css";
import styled from "@emotion/styled";

const DashboardTableNameColumn = ({ title, subtitle, hospitalLogo, toShow = true }) => {
  let width = "150px";
  if (!toShow) width = "210px";
  const style = {
    width: width,
  };
  let temp = "";
  temp += title[0];
  for (let i = 1; i < title.length; i++) {
    if (title[i - 1] === " ") {
      temp += title[i];
    }
  }
  console.log(hospitalLogo);
  const circleString = temp.toUpperCase().slice(0, 3);
  return (
    <div
      style={{
        padding: "0px",
        display: "flex",
        width: "210px",
        marginRight: "max(10px,3vw)",
        marginLeft: "20px",
      }}
    >
      {/* {imgSrc && <Avatar src={imgSrc} />} */}
      {hospitalLogo !== undefined && hospitalLogo !== "" && (
        <Avatar src={hospitalLogo[0]} style={{ marginRight: "10px", height: "50px", width: "50px" }} />
      )}
      {toShow && (hospitalLogo === undefined || hospitalLogo === "") && (
        <div className="dashboard-name-column-circle ">{circleString}</div>
      )}

      <div className="dashboard-table-row-personal" style={style}>
        <Tooltip text={title}>
          <p className="dashboard-table-row-title" style={style}>
            {title}
          </p>
        </Tooltip>
        <Tooltip text={subtitle}>
          <p className="dashboard-table-row-subtitle">{subtitle}</p>
        </Tooltip>
        {/* <div
          className="dashboard-table-row-description-container"
          style={style}
        >
          <p className="dashboard-table-row-title" style={style}>
            {title}
          </p>
          <p className="rescheduele-appointment" style={style}>
            {title}
          </p>
        </div> */}
        {/* <div className="dashboard-table-row-description-container">
          <p className="dashboard-table-row-subtitle">{subtitle}</p>
          <p className="rescheduele-appointment">{subtitle}</p>
        </div> */}
        {/* <p className="dashboard-table-row-subtitle">{subtitle}</p> */}
      </div>
    </div>
  );
};

export default DashboardTableNameColumn;
