import React, { useEffect, useState } from "react";
import b1CIcon from "../../assets/images/b1c-icon-2.svg";
import "./Footer.css";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import {
  AiFillInstagram,
  AiFillTwitterSquare,
  AiFillFacebook,
} from "react-icons/ai";
import { footerTranslation } from "../../constants/Translation";
import { BOneChatbotPublic } from "../BOneChatbot/BOneChatbotPublic";

const SocialLinks = (props) => {
  return (
    <ul>
      <li>
        <a
          href="https://www.instagram.com/bharatonecare?igshid=MzRlODBiNWFlZA%3D%3D"
          target="_blank"
          rel="noreferrer"
        >
          <AiFillInstagram /> {props.footerData.instagram}
        </a>
      </li>
      {/* <li>
        <a href="#a" target="_blank" rel="noreferrer">
          <AiFillTwitterSquare /> {props.footerData.twitter}
        </a>
      </li> */}
      <li>
        <a
          href="https://www.facebook.com/Bharatonecare/"
          target="_blank"
          rel="noreferrer"
        >
          <AiFillFacebook /> {props.footerData.facebook}
        </a>
      </li>
    </ul>
  );
};

const Footer = ({ language }) => {
  const [footerData, setFooterData] = useState({});
  useEffect(() => {
    if (language === "English") {
      setFooterData(footerTranslation.en);
    } else if (language === "Hindi") {
      setFooterData(footerTranslation.hi);
    } else if (language === "Arabic") {
      setFooterData(footerTranslation.ar);
    } else {
      setFooterData(footerTranslation.en);
    }
  }, [language]);
  return (
    <div className="footer">
      <BOneChatbotPublic />

      <div className="portfolio-footer">
        <div className="portfolio-footer-icon">
          <img src={b1CIcon} alt="" />
          <small>
            {footerData.copyright} &copy; {footerData.rights}
          </small>
        </div>
        <div className="portfolio-footer-nav-links">
          <h3>{footerData.home}</h3>
          <ul>
            <li>
              <a href={"/hospitals"}>{footerData.hospitals ?? "Hospitals"}</a>
            </li>
            <li>
              <a href={"/doctors"}>{footerData.doctors ?? "Doctors"}</a>
            </li>
            <li>
              <a href="/procedures">{footerData.procedures ?? "Procedures"}</a>
            </li>
            <li>
              <a href="/blogs">{footerData.blog}</a>
            </li>
          </ul>
        </div>
        {window.innerWidth < "1024" && (
          <div className="portfolio-footer-social-links">
            <h3>{footerData.start}</h3>
            <SocialLinks footerData={footerData} />
          </div>
        )}

        <div className="portfolio-footer-contact-us">
          <h3>{footerData.contact}</h3>
          <div className="portfolio-contact-details">
            <div>
              <h4>
                <BsFillTelephoneFill /> {footerData.phone}
              </h4>
              <p>+91 9076557455</p>
            </div>
            <div>
              <h4>
                <MdEmail /> {footerData.email}
              </h4>
              <a href="mailto:info@bharatonecare.com">
                <p>info@bharatonecare.com</p>
              </a>
            </div>
          </div>
        </div>
        {window.innerWidth > "1024" && (
          <div className="portfolio-footer-social-links">
            <h3>{footerData.start}</h3>
            <SocialLinks footerData={footerData} />
          </div>
        )}
      </div>
      <div className="disclaimer container">
        <hr />
        <h6 className="disclaimer-heading">{footerData.disclaimer}</h6>
        <span className="disclaimer-text">{footerData.disclaimerText}</span>
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
            fontSize: "10px",
          }}
        >
          Images By Freepik
        </p>
      </div>
    </div>
  );
};

export default Footer;
