import React from "react";
import samplePhoto from "../../../assets/Procedure/601d12eebb2b7.webp";
import "./RecommendedHospitalCard.css";
import { useNavigate } from "react-router-dom";
const RecommendedHospitalCard = ({
  hospital_uid,
  hospitalName = "Apollo,Indraprasth",
  hospitalLocation,
  facilities,
  specialilty,
  hospitalPhoto,
  handleLoadMore,
}) => {
  const navigate = useNavigate();
  const facilityString =
    facilities && facilities.length
      ? facilities
          .map((specialty, index, array) => {
            if (index === array.length - 1) {
              return specialty.name;
            }
            return specialty.name + ", ";
          })
          .join("")
      : "No facilities";

  const genHospURL = (id) => {
    return `/hospital/info?hospitalId=${id}`;
  };

  return (
    <a href={genHospURL(hospital_uid)}>
      <div className="recommended-hospital-card" key={hospital_uid}>
        <div className="recommended-content">
          <div className="recommended-hospital-photo-box">
            <div className="recommended-hospital-photo">
              {/* <img src={hospitalPhoto} alt="" /> */}
              {hospitalPhoto ? (
                <img
                  src={hospitalPhoto[0]}
                  className="img-fluid"
                  alt={`Slide`}
                />
              ) : (
                <img src={samplePhoto} className="img-fluid" alt="Fallback" />
              )}
            </div>

            <div className="speciality-tag">
              {specialilty ? specialilty : "No specialiaty"}
            </div>
          </div>

          <div className="recommended-hospital-name-location mt-4 text-truncate">
            <h6 className="name mb-2 text-capitalize">{hospitalName}</h6>
            <h6 className="location text-capitalize">{hospitalLocation}</h6>
            <hr className="m-0" />
          </div>

          <div className="recommended-hospital-facilities-box mt-3 mb-4">
            <p className="mb-0 fw-light mb-1 overflow-auto">Facilities</p>
            <div className="facilities" title={facilityString}>
              <p className="mb-2 fw-bold p-0 text-truncate hospital-card-text-color-primary">
                {facilityString}
              </p>
            </div>
            <hr className="m-0" />
          </div>
        </div>
        <div className="recommended-btn">
          <button className="hospital-primary-button">{"View Details"}</button>
        </div>
      </div>
    </a>
  );
};

export default RecommendedHospitalCard;
