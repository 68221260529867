import React, { useState } from "react";
import "./DashboardTable.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { IconButton } from "@mui/material";
import Tooltip from "../Tooltip/Tooltip";
import { Link } from "react-router-dom";
import { numberMonthMapping } from "../DashboardTableStatusComponent/DashboardTableStatusColumn";
import DashboardTablePagination from "../DashboardTablePagination/DashboardTablePagination";

/* Function Decides what to show if column has a type */
const getRenderFromType = (column, row) => {
  switch (column.type) {
    case "link":
      return (
        <Tooltip text={row[column.value].email}>
          <div className="dashboard-table-row-description">
            <Link to={row[column.value].email}>{row[column.value].email}</Link>;
          </div>
        </Tooltip>
      );
    case "date":
      const day = row[column.value].slice(8, 10);
      const month = numberMonthMapping[+row[column.value].slice(5, 7)];
      const year = row[column.value].slice(0, 4);
      return (
        <Tooltip text={`${day} ${month} ${year}`}>
          <div className="dashboard-table-row-description">
            {`${day} ${month} ${year}`}
          </div>
        </Tooltip>
      );
    default:
      break;
  }
};

/* Function to decide what to show for 1 ele checks if render is present if render is persent run that 
and if type is present run getRenderFromType */
const renderColumn = (column, row) => {
  if (column.render) {
    return column.render(row);
  } else if (column.type) {
    return getRenderFromType(column, row);
  } else {
    return (
      <Tooltip text={row[column.value]}>
        <div className="dashboard-table-row-description">
          {row[column.value]}
        </div>
      </Tooltip>
    );
  }
};

//Component to print table
/* to use -> pass data of user,
pass column config and mapping of what and how to print for Each column 
a size property to number of rows in 1 page
action if u want pass buttons
actionHeaderTitle header for buttons column */
const DashboardTable = ({ columns, rows, size, actionHeaderTitle, action }) => {
  // const [index, setIndex] = useState(0);
  // const updatedRows = rows.slice(size * index + 0, size * index + size);

  // const handleClick = (ind) => {
  //   setIndex(ind);
  // };

  // const numberOfPages = Math.ceil(rows.length / size);
  // console.log(numberOfPages);
  return (
    <table
      className="dashboard-table w-100"
      style={{
        height: `${size * 80 + 25}px`,
      }}
    >
      {/*Head Of Table  *************For Columns             */}
      <thead>
        <tr className="dashboard-table-head-row">
          {columns.map((column) => (
            <td key={column.columnName}>
              <Tooltip text={column.columnName}>
                <div
                  className="dashboard-table-head-row-description"
                  style={column?.style}
                >
                  {column.columnName}
                </div>
              </Tooltip>
            </td>
          ))}
          {/*Columns for Button if buttons are present */}
          {action && action.length !== 0 && (
            <td>
              <Tooltip text={actionHeaderTitle}>
                <div className="dashboard-table-head-row-description">
                  {actionHeaderTitle}
                </div>
              </Tooltip>
            </td>
          )}
        </tr>
      </thead>

      {/*Body Of Table **********For Rows   */}

      <tbody>
        {rows.map((row, index) => {
          const isLast = index === rows.length - 1;
          const border = isLast ? "none" : "";

          return (
            <tr
              key={index}
              className="dashboard-table-row "
              style={{ border: border }}
            >
              {/* Putting various columns for 1 Single Row */}
              {columns.map((column, ind) => {
                return <td key={ind}>{renderColumn(column, row)}</td>;
              })}

              {/*1 Single Column for Button Passed Seperately to Table*/}
              {action && action.length !== 0 && (
                <td>
                  <div className="dashboard-table-row-images">
                    {action.map((ele, ind) => {
                      if (ele.image) {
                        return (
                          <img
                            className="dashboard-table-row-image"
                            key={ind}
                            src={ele.image}
                            alt={ele.title}
                            onClick={() => {
                              ele.onClick(row);
                            }}
                          />
                        );
                      }
                      if (ele.render) {
                        return ele.render(row);
                      }
                      if (ele.icon) {
                        const TempIcon = ele.icon;
                        return (
                          <TempIcon
                            className="dashboard-table-row-image"
                            key={ind}
                            src={ele.image}
                            alt={ele.title}
                            onClick={() => {
                              ele.onClick(row);
                            }}
                          />
                        );
                      }
                    })}
                  </div>
                </td>
              )}
            </tr>
          );

          {
            /*end of 1 Row */
          }
        })}
      </tbody>
    </table>
  );
};

export default DashboardTable;
