import React from "react";

const ConfirmationPopup = ({ handleClick, data = null }) => {
  console.log(data);
  return (
    <div className="modal-background-1">
      <div className="modal-section">
        <div className="modal-body">
          <h5
            className="modal-title  add-appointment-model-title"
            id="exampleModalLabel"
            style={{ color: "black" }}
          >
            Are you sure you want to delete {data && data.hospitalName}?
          </h5>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className={`my-btn-1`}
            onClick={() => handleClick("Yes")}
          >
            Yes
          </button>
          <button
            type="button"
            className={`my-btn-1`}
            onClick={() => handleClick("No")}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
