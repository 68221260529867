import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { RiContactsFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import b1CLogo from "../../assets/images/b1c-logo.svg";
import { AiOutlineMenu } from "react-icons/ai";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import ContactUsSidebar from "../ContactUsSidebar/ContactUsSidebar";
import ActionButton from "../FAB/ActionButton";
const Navbar = () => {
  const [navbarMenuToggle, setNavbarMenuToggle] = useState(false);
  const [contactUsSidebarToggle, setContactUsSidebarToggle] = useState(false);
  const openMenuHandler = () => {
    setNavbarMenuToggle(true);
  };
  const openContactUsSidebarHandler = () => {
    setContactUsSidebarToggle(true);
  };
  const closeMenuHandler = () => {
    document.getElementById("portfolio-navbar-menu").style.transform =
      "translateX(100%)";
    setTimeout(() => setNavbarMenuToggle(false), 500);
  };
  const closeContactUsSidebarHandler = () => {
    document.getElementById("portfolio-contact-us-sidebar").style.transform =
      "translateX(100%)";
    setTimeout(() => setContactUsSidebarToggle(false), 500);
  };
  const history = useNavigate();
  window.addEventListener("scroll", () => {
    let navbarElement = document.getElementById("portfolio-navbar");
    if (navbarElement) {
      if (window.scrollY >= 80) {
        navbarElement.style.boxShadow = "0 1px 20px rgb(229, 229, 229)";
      } else {
        navbarElement.style.boxShadow = "";
      }
    }
  });

  return (
    <>
      <div className="portfolio-navbar" id="portfolio-navbar">
        <Link to={"/"}>
          <img src={b1CLogo} alt="" className="portfolio-b1c-logo" />
        </Link>
        <div>
          {window.location.pathname == "/business" ? (
            <ul className="nav-links">
              {/* <motion.li
                initial={{ y: "-100%", opacity: 0 }}
                whileInView={{ y: "0%", opacity: 1 }}
                transition={{ delay: 0 }}
              >
                <a href={"#features"}>Features</a>
              </motion.li> */}
              <motion.li
                initial={{ y: "-100%", opacity: 0 }}
                whileInView={{ y: "0%", opacity: 1 }}
                transition={{ delay: 0.1 }}
              >
                <a href={"#services"}>Services</a>
              </motion.li>
              <motion.li
                initial={{ y: "-100%", opacity: 0 }}
                whileInView={{ y: "0%", opacity: 1 }}
                transition={{ delay: 0.2 }}
              >
                <a href="#FAQs">FAQs</a>
              </motion.li>
              <motion.li
                initial={{ y: "-100%", opacity: 0 }}
                whileInView={{ y: "0%", opacity: 1 }}
                transition={{ delay: 0.3 }}
              >
                <a href="/blogs">Blogs</a>
              </motion.li>
            </ul>
          ) : (
            <ul className="nav-links"></ul>
          )}
          <div className="portfolio-navbar-buttons-box">
            <button
              className="portfolio-navbar-button "
              onClick={openContactUsSidebarHandler}
            >
              Contact Us
            </button>
            <button
              className="portfolio-navbar-button"
              onClick={() => history("/login")}
            >
              Login
            </button>
          </div>
          <AiOutlineMenu
            className="portfolio-navbar-menu-button"
            onClick={openMenuHandler}
          />
          {navbarMenuToggle && (
            <NavbarMenu
              closeHandler={closeMenuHandler}
              openContactUsSideBar={openContactUsSidebarHandler}
            />
          )}
          {contactUsSidebarToggle && (
            <ContactUsSidebar closeHandler={closeContactUsSidebarHandler} />
          )}
        </div>
      </div>
      {window.innerWidth <= 1024 && (
        <ActionButton
          text={"Contact us"}
          icon={<RiContactsFill />}
          task={openContactUsSidebarHandler}
          styling={{
            position: "fixed",
            bottom: "25px",
            right: "25px",
            borderRadius: "12px",
            backgroundColor: "var(--secondaryColor)",
            fontWeight: 600,
            zIndex: 1,
          }}
        />
      )}
    </>
  );
};

export default Navbar;
