import React from 'react'

const StatusCrad = ({ icon,
  status,
  number,
  title,
  difference,
  description }) => {
  return (

    <div className="col hospital-status-box border py-2  m-md-3 m-2 ">
      <div className="status-card  d-flex justify-content-center">
        <div className="icon-text-box  d-flex py-1 align-items-end position-relative">
          <div className="img-box d-flex justify-content-center">
            <img src={icon} alt="" className='icon-main d-none d-sm-block' />
            <img src={status} alt="" className='icon-status d-none d-sm-block position-absolute' />
          </div>
          <div className="text-title ms-3 ">
            <p className='m-0 text-start fs-3 fw-bold'>{number}</p>
            <p className='m-0  text-start'>{title}</p>
          </div>
        </div>
      </div>
      <div className="arrow-text-box  mt-3 d-flex justify-content-center flex-sm-row flex-column">
        <div className="img-box d-flex justify-content-center">

          <img src={difference} alt="" className='icon-main-diff' />
          <p className='m-0 ms-1 percent'>40%</p>
        </div>
        <p className='m-0 ms-2 fw-bold text-center'>{description}</p>

      </div>
    </div>

  )
}

export default StatusCrad