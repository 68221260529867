import React, { useContext, useEffect, useState } from "react";
import "./AdminBlogs.css";
import AdminBlogCard from "../../../components/BlogCard/AdminBlogCard";
import axios from "axios";
import { BlogContext } from "../../../context/BlogContextApi";
import { useNavigate } from "react-router-dom";
const AdminBlogs = () => {
  const { blogs, getBlogs } = useContext(BlogContext);
  const navigate = useNavigate();
  useEffect(() => {
    getBlogs();
  }, []);
  return (
    <>
      <div className="blogs-admin-console d-flex">
        <h4 className="blogs-admin-console-heading">Blogs Console</h4>
        <div className="blogs-btn">
          <button
            className="Add-Appointments-button"
            onClick={() => navigate("/console/create/blog")}
          >
            + Add Blogs
          </button>
        </div>
      </div>
      <div className="admin-blogs">
        {blogs &&
          blogs.map((blog) => (
            <AdminBlogCard
              id={blog?.id}
              title={blog?.title}
              description={blog?.description}
            />
          ))}
      </div>
    </>
  );
};

export default AdminBlogs;
