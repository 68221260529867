import React from "react";
import "./StatsCard.css";
const StatsCard = ({ icon, number, title, difference, description }) => {
  return (
    <div className="stats-cards">
      <div className='card d-flex'>
        <div className='iconAndTextContainer'>
          <div className='iconContainer'>
            <img src={icon} alt='icon' className='icon' />
          </div>
          <div className='textContainer'>
            <div className='number'>{number}</div>
            <div className='title'>{title}</div>
          </div>
        </div>
        <div className='description d-flex'>
          <span className='difference'>
            <img src={difference} alt='icon' className='diff-icon me-1 mb-1' />40%
          </span>
          <p className="ms-2 m-0 fw-bold">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default StatsCard;
