import { createChatBotMessage } from "react-chatbot-kit";
import DogPicture from "../DogPicture";
import { BOneAvatar } from "../ChatbotComponents/BOneAvatar";
import { StartingOptions } from "../ChatbotComponents/StartingOptions";
import { ChatLoader } from "../ChatbotComponents/ChatLoader";

const botName = "a-b1c-bot";

const config = {
  initialMessages: [
    createChatBotMessage(`Hello! Welcome to BharatOne Care. How can I help?`),
  ],
  botName: botName,
  widgets: [
    {
      widgetName: "startingOptions",
      widgetFunc: (props) => <StartingOptions {...props} />,
    },
    {
      widgetName: "dogPicture",
      widgetFunc: (props) => <DogPicture {...props} />,
    },
  ],
  customStyles: {
    botMessageBox: {
      backgroundColor: "#ef5ea2",
    },
    chatButton: {
      backgroundColor: "transparent",
    },
  },
  customComponents: {
    botAvatar: (props) => <BOneAvatar {...props} />,
  },
  customMessages: {
    loader: (props) => <ChatLoader {...props} />,
  },
};

export default config;
