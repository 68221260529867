import OpenAI from "openai";

const openai_apikey =
  "sk-proj-TrHFEC10Uk79T4zXbWMJT3BlbkFJhXIRrAD9Jrzk0PlaYU6E";
const assistant_id = "asst_T4dKFR2hHXvGllcqChmqweyl";

const openai = new OpenAI({
  apiKey: openai_apikey,
  dangerouslyAllowBrowser: true,
});

let assistant = null;
let thread = null;

try {
  assistant = await openai.beta.assistants.retrieve(assistant_id);
  thread = await openai.beta.threads.create();
} catch (error) {
  console.log(error);
}

export const initChatBot = () => {
  return { openai, assistant, thread };
};

export const sendMessage = async (i_thread, i_input, i_assistant) => {
  try {
    await openai.beta.threads.messages.create(i_thread.id, {
      role: "user",
      content: i_input,
    });
    const run = await openai.beta.threads.runs.create(thread.id, {
      assistant_id: i_assistant.id,
    });
    let response = await openai.beta.threads.runs.retrieve(thread.id, run.id);
    while (response.status === "in_progress" || response.status === "queued") {
      await new Promise((resolve) => setTimeout(resolve, 5000));
      response = await openai.beta.threads.runs.retrieve(thread.id, run.id);
    }
    // Get the messages for the thread
    const messageList = await openai.beta.threads.messages.list(thread.id);

    // Find the last message for the current run
    const lastMessage = messageList.data
      .filter(
        (message) => message.run_id === run.id && message.role === "assistant"
      )
      .pop();

    // Print the last message coming from the assistant
    if (lastMessage && lastMessage.content.length > 0) {
      return {
        type: "response",
        message: lastMessage.content[0]?.["text"]?.value?.split("【")?.[0],
        response: lastMessage,
      };
    } else {
      return { type: "response", message: "" };
    }
  } catch (error) {
    return { type: "error", error };
  }
};
