import React, { useEffect, useState } from "react";
import SectionHeading from "../../../../components/ProcedurePageComponents/SectionHeading/SectionHeading";
import HotelCard from "../../../../components/HotelCard/HotelCard";
import "./VerifiedHotelsNearHospital.css";
import { API } from "../../../../constants/api";
import axios from "axios";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
const VerifiedHotelsNearHospital = ({ verifiedHotels, nearByHospitalId }) => {
  const [hotels, setHotels] = useState([]);
  const getHotel = async (hotelId) => {
    try {
      const url = `${API}/verified/hotel/${hotelId}`;
      const { data } = await axios.get(url);
      return data;
    } catch (err) {
      let msg;
      if (err?.response) msg = err.response.data.message;
      else msg = err.message;
      toast.error(msg);
    }
  };
  const getAllHotels = async () => {
    let promises = [];
    verifiedHotels.forEach((hotelId) => {
      promises.push(getHotel(hotelId));
    });
    const data = await Promise.all(promises);
    setHotels(data.map(({ hotel }) => hotel));
  };
  useEffect(() => {
    if (verifiedHotels) {
      getAllHotels();
    }
  }, [verifiedHotels]);
  return (
    <div
      className="verified-hotel-hotels-container progress-section"
      id="hospital-details-hotels"
    >
      <div className="heading-with-view-more-button">
        <SectionHeading
          text={"Verified Hotels near Hospital"}
          textAlign={"left"}
          textColor={"var(--primaryColor"}
        />
        <Link
          to={`/verified/hotels?hospitalId=${nearByHospitalId}`}
          className="view-more-button"
        >
          View more
        </Link>
      </div>

      <div className="hotels-near-hospital">
        {hotels &&
          hotels.map((hotel) => {
            return (
              <HotelCard
                key={hotel?.id}
                hotelName={hotel?.hotelName}
                hotelImage={hotel?.images?.[0]}
                startingPricePerNight={hotel?.minimumPricePerNightUSD}
                facilities={hotel?.facilities}
                starStatus={hotel?.starStatus}
                hospitalId={hotel?.id}
              />
            );
          })}
      </div>
    </div>
  );
};

export default VerifiedHotelsNearHospital;
