// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyA75Guxl3sGMss07e-FQ5MKTQieYzvaBfc",
  authDomain: "portal-8d197.firebaseapp.com",
  databaseURL: "https://portal-8d197-default-rtdb.firebaseio.com",
  projectId: "portal-8d197",
  storageBucket: "portal-8d197.appspot.com",
  messagingSenderId: "218516057069",
  appId: "1:218516057069:web:6d529788908be7992855e4",
  measurementId: "G-QM38K36Y9Z"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export const database = getAuth(app)
export const googleProvider = new GoogleAuthProvider();
export { app, auth }
export const db = getFirestore(app);
export const storage = getStorage(app);
export default app