import React, { useEffect, useState } from 'react'
import './OverviewComponent.css'
import { Chip } from '@mui/material';
import { ChevronLeft, ChevronRight, Tune } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import { overviewOnbaordByAgent } from '../../constants/formconfig';
import SimpleInput from '../InputTypes/SimpleInput/SimpleInput';
import DisabledAutoComplete from '../InputTypes/DisabledAutoComplete/DisabledAutoComplete';

const OverviewComponent = ({
  facilityData,
  tableData,
  section,
  images,
  moveToPrev,
  finishHome }) => {

  //image controls======>
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [touchPosition, setTouchPosition] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    // Update form data when overviewOnboardByAgent changes
    if (facilityData) {
      const updatedFormData = {};
      overviewOnbaordByAgent[0].fields.forEach((field) => {
        updatedFormData[field[0].title] = {
          value: facilityData[field[0].title] || "", // Use facilityData or an empty string if not available
          isTouched: false,
        };
      });
      setFormData(updatedFormData);
    }
  }, [overviewOnbaordByAgent]);

  let fixedOptions = [];




  const handleChange = (field, newValue) => {
    setFormData((obj) => {
      let tempObj = { ...obj };
      const tempField = { ...tempObj[field], value: newValue };
      tempObj[field] = tempField;
      return tempObj;
    });
  };

  const handleTouch = (field) => {
    setFormData((obj) => {
      const tempObj = { ...obj };
      tempObj[field] = { ...tempObj[field], isTouched: true };
      return tempObj;
    });
  };

  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      handleNext();
    }

    if (diff < -5) {
      handlePrev();
    }

    setTouchPosition(null);
  };

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  //image controls===========>

  console.log('Overview me Data :::   ::: ', facilityData, tableData, section, images);
  console.log('updated data in overview :: :: ', formData);
  console.log('Docssss:: :: ', fixedOptions);



  return (

    <div className='d-flex flex-column mt-4'>

      <div className="facility-box mb-4">
        {facilityData ? (
          <>
            <form>
              {overviewOnbaordByAgent[0].fields.map((field, index) => {
                // console.log("fetch", formData);
                if (
                  field[0].type === "text" ||
                  field[0].type === "number" ||
                  field[0].type === "email"
                ) {
                  return (
                    <SimpleInput
                      key={index}
                      input={field[0]}
                      state={formData[field[0].title]}
                      handleChange={handleChange}
                      handleTouch={handleTouch}
                      disabled={true}
                    />
                  );
                }
                return null;
              })}
            </form>
          </>
        ) : (
          <div className="labels-main">
            <div className="lable-field-box d-flex border p-3 mb-3">
              <p>No Data Available....</p>
            </div>
          </div>
        )}
      </div>

      <div className="docto-information-box mb-4">
        <div className="heading d-flex justify-content-start">
          <p>Doctor Information</p>
        </div>
        {tableData.length > 0 ? (

          <div className="table-main-box table-wrapper-scroll-y my-custom-scrollbar shadow shadow-sm mb-3">

            <table className="table align-middle mb-0 bg-white table-hover border">
              <thead className="bg-light">
                <tr>
                  <th>Name</th>
                  <th>Qualification</th>
                  <th>Experience</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((doctor) => (
                  <tr key={doctor.id}>
                    <td>
                      <div className="d-flex align-items-center">
                        <img src={doctor.imageLinks} alt="Doctor" style={{ width: 45, height: 45 }} className="rounded-circle img-fluid" />
                        <div className="ms-3">
                          <p className="fw-bold mb-1">{doctor?.name}</p>
                          <p className="text-muted mb-0">{doctor?.email}</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p className="fw-normal mb-1">{doctor?.qualification}</p>
                    </td>
                    <td>
                      <p className="fw-normal mb-1">{doctor?.experience}</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="labels-main">
            <div className="lable-field-box d-flex border p-3 mb-3">
              <p>No Doctor Information Available</p>
            </div>
          </div>
        )}

      </div>

      <div className="add-treatment-box mb-4">
        <div className="heading d-flex justify-content-start">
          <p>Added Treatments</p>
        </div>

        {section && section.length > 0 ? (
          <div className="treatment-doctor-box border p-3">
            {
              section.map((speciality, index) => (

                <div className="labels-main" key={index}>
                  <label className="form-label d-flex mb-2 ms-1 treatment-label">
                    {speciality?.speciality}
                  </label>
                  <div className="lable-field-box d-flex border p-3 mb-3">

                    <p className='me-2'>Doctors:</p>
                    <DisabledAutoComplete
                      disabledValue={speciality?.selectedDoctors.map((doctor) => ({ title: doctor.name }))} />
                  </div>
                </div>
              ))
            }
          </div>
        ) : (
          <div className="labels-main">
            <div className="lable-field-box d-flex border p-3 mb-3">

              <p>No Data Available</p>
            </div>
          </div>
        )}
      </div>

      <div className="uploads-media-box mb-4">
        <div className="heading d-flex justify-content-start">
          <p>Uploaded Media</p>
        </div>

        {images.length > 0 ? (
          <div className="image-container border">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
              }}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
            >
              <img
                className='img-fluid'
                src={images[currentImageIndex]}
                alt={`uploaded`}
              />
              {images.length > 1 && (
                <>
                  <ChevronLeft
                    onClick={handlePrev}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "80px",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                    fontSize='small'
                    color='primary'
                  />

                  <ChevronRight
                    sx={{
                      position: "absolute",
                      top: "50%",
                      right: "80px",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                    onClick={handleNext}
                    fontSize='small'
                    color='primary'
                  />
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="image-container border ">
            <p className='m-0 p-3'>No New Media Uploaded</p>
          </div>
        )}
      </div>


      <div className="check-box">
        <div className="check-action d-flex justify-content-start align-items-center mt-2">
          <Checkbox
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
            inputProps={{ 'aria-label': 'checkbox' }}
          />
          <label>I have read all the content and above information is correct</label>
        </div>
      </div>



      <div className="button-box py-4">
        <div className='btn-action d-flex justify-content-end btn-container'>
          <button
            onClick={moveToPrev}
            className={`btn btn-back ms-3`}
          >
            Back
          </button>
          <button
            onClick={finishHome}
            className={`btn btn-next ms-3 ${isChecked ? '' : 'disabled'}`}
            disabled={!isChecked}
          >
            Finish
          </button>
        </div>
      </div>

    </div>
  )
}

export default OverviewComponent