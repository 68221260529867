import React, { useEffect, useState } from "react";
import FeatureBox from "../FeatureBox/FeatureBox";
import previous from "../../assets/images/previous.svg";
import next from "../../assets/images/next.svg";
import "./FeaturesSlider.css";
const FeatureSlider = ({ featureList }) => {
  const [slideNumber, setSlideNumber] = useState(1);
  const nextSlideHandler = () => {
    setSlideNumber(slideNumber < featureList.length ? slideNumber + 1 : 1);
  };
  const previousSlideHandler = () => {
    setSlideNumber(slideNumber > 1 ? slideNumber - 1 : featureList.length);
  };
  useEffect(() => {
    let tid = setTimeout(
      () =>
        setSlideNumber(slideNumber < featureList.length ? slideNumber + 1 : 1),
      6000
    );
    document
      .getElementById("portfolio-features-slider")
      .addEventListener("mouseover", () => {
        clearTimeout(tid);
      });
    document
      .getElementById("portfolio-features-slider")
      .addEventListener("mouseleave", () => {
        tid = setTimeout(
          () =>
            setSlideNumber(
              slideNumber < featureList.length ? slideNumber + 1 : 1
            ),
          6000
        );
      });
    return () => {
      clearTimeout(tid);
    };
  }, [slideNumber, featureList]);

  return (
    <div className="portfolio-features-slider">
      <button
        className="portfolio-features-slider-button  portfolio-features-slider-button-prev"
        onClick={previousSlideHandler}
      >
        {" "}
        <img src={previous} alt="" />
      </button>
      <button
        className="portfolio-features-slider-button portfolio-features-slider-button-next"
        onClick={nextSlideHandler}
      >
        <img src={next} alt="" />
      </button>

      <div
        className="portfolio-features-slider-box"
        id="portfolio-features-slider"
      >
        {featureList.map((list, i) => {
          return (
            <div
              key={i}
              className={`portfolio-features-slide ${
                slideNumber === i + 1
                  ? "portfolio-features-slide-type2"
                  : (slideNumber - 1 === 0
                      ? featureList.length
                      : slideNumber - 1) ===
                    i + 1
                  ? "portfolio-features-slide-type3"
                  : "portfolio-features-slide-type1"
              }`}
            >
              {" "}
              {list.map((feature) => (
                <FeatureBox
                  key={feature?.name}
                  title={feature?.name}
                  description={feature.description}
                  icon={feature.icon}
                />
              ))}{" "}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FeatureSlider;
