import React, { useState, useEffect } from "react";
import "./DoctorInfoModel.css";
import img from "../../assets/images/template_doctor.svg";
import { doctorPlaceholderURL } from "../../constants/api";

const DoctorInfoModel = (props) => {
  const [convertedMonths, setConvertedMonths] = useState("");

  useEffect(() => {
    const formattedMonths = convertMonths(props.data?.experience);
    setConvertedMonths(formattedMonths);
  }, [props.data]);

  const convertMonths = (months) => {
    const years = Math.floor(months / 12);
    const remainingMonths = months % 12;

    const yearsText =
      years > 0 ? `${years} ${years === 1 ? "year" : "years"}` : "";
    const monthsText =
      remainingMonths > 0
        ? `${remainingMonths} ${remainingMonths === 1 ? "month" : "months"}`
        : "";

    const result = `${yearsText}${
      yearsText && monthsText ? " " : ""
    }${monthsText}`;

    return result.length > 0 ? result : "0 months";
  };

  return (
    <div className="doctor-info-model-container">
      <div className="doctor-info-model">
        <img
          src={
            props?.data?.imageLinks?.length > 0 && props.data.imageLinks[0]
              ? props.data.imageLinks[0]
              : doctorPlaceholderURL
          }
          className="doctor-info-model-image"
          alt=""
        />
        <div className="doctor-info-model-info">
          <div className="doctor-info-model-text">
            {props.data?.name ? props.data.name : ""}
          </div>
          <div className="doctor-info-model-subtext">
            {props.data?.qualification ? props.data.qualification : ""}
          </div>
          <div className="doctor-info-model-title">Name and Education</div>
        </div>
        <div className="doctor-info-model-speciality">
          <div className="doctor-info-model-text">
            {props.data?.specialization ? props.data.specialization : ""}
          </div>
          <div className="doctor-info-model-subtext">
            {props.data?.experience ?? ""}
          </div>
          <div className="doctor-info-model-title">
            Expertise and Experience
          </div>
        </div>
        <div className="doctor-info-model-hospital">
          <div className="doctor-info-model-text">
            {props.data2?.Name_Of_Hospital ? props.data2.Name_Of_Hospital : ""}
          </div>
          <div className="doctor-info-model-subtext">
            {props.data2?.Location ? props.data2.Location : ""}
          </div>
          <div className="doctor-info-model-title">Hospital and location</div>
        </div>
        <button className="doctor-info-model-book-button">
          Book Appointment
        </button>
      </div>
    </div>
  );
};

export default DoctorInfoModel;
