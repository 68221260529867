import React from "react";
import { createChatBotMessage, createClientMessage } from "react-chatbot-kit";

export const StartingOptions = (props) => {
  const arr = ["Hi", "Hello", "Bye", "Thank you"];
  const onBtnClick = (key) => {
    const userMessage = createClientMessage(key);
    const botMessage = createChatBotMessage(`${key} to you too.`, {
      widget: "startingOptions",
    });
    props.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, userMessage, botMessage],
    }));
  };
  return (
    <div className="container-fluid">
      {arr.map((x) => (
        <button
          key={x}
          className={`btn btn-default`}
          onClick={() => onBtnClick(x)}
        >
          {x}
        </button>
      ))}
    </div>
  );
};
