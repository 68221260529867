import React from "react";
import "./SectionHeading.css";
const SectionHeading = ({ text, subText, white, textAlign, textColor }) => {
  return (
    <>
      <h2
        style={{
          textAlign: textAlign && textAlign,
          color: textColor && textColor,
        }}
        className={`${white ? "white" : "procedure-page-section-heading"}`}
      >
        {text}
      </h2>
      {subText && (
        <h5 className="procedure-page-section-sub-heading">{subText}</h5>
      )}
    </>
  );
};

export default SectionHeading;
