import React, { useEffect, useState } from "react";
import "./Accounts.css";
import DashboardTable from "../../../components/DashboardTable/DashboardTable";
import { AddAccountsTableConfig } from "../../../constants/AccountsTableConfig";
import AddAccounts from "../../../components/AddAccounts/AddAccounts";
import axios from "axios";
import TablePen from "../../../assets/images/table-pen-button.svg";
import CrossIcon from "../../../assets/images/red-cross.png";
import GreenTick from "../../../assets/images/Status-Completed-Tick.svg";
// import GreenTick from "../../../assets/images/green-tick.png";
import { DeleteForeverRounded } from "@mui/icons-material";
import DashboardTablePagination from "../../../components/DashboardTablePagination/DashboardTablePagination";
import Tooltip from "../../../components/Tooltip/Tooltip";
import toast from "react-hot-toast";
import EditAccounts from "../../../components/EditAccounts/EditAccounts";
import AccountAlertPopup from "../../../components/AccountAlertPopup/AccountAlertPopup";

const Accounts = () => {
  const [addAccountsModalOpen, setAddAccountsModalOpen] = useState(false);
  const [editAccountsModalOpen, setEditAccountsModalOpen] = useState(false);
  const [addedOpen, setAddedOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [editData, setEditData] = useState({});
  const [accountToDelete, setAccountToDelete] = useState("");
  const [accountUidToDelete, setAccountUidToDelete] = useState("");
  const [index, setIndex] = useState(0);
  const [approve, setApprove] = useState(false);
  const size = 5;
  const updatedRows = tableData.slice(size * index + 0, size * index + size);
  const numberOfPages = Math.ceil(tableData.length / size);
  let buttons;

  if (!approve) {
    buttons = [
      {
        title: "tick",
        render: (row) => {
          return (
            <Tooltip text={"Approve Account"}>
              <img
                className="account-table-row-image"
                // key={ind}
                src={GreenTick}
                alt="Approve"
                onClick={() => {
                  setApprove(true);
                }}
              // onClick={() => {
              //   setOpen(true);
              //   setAccountToDelete(row.name);
              //   setAccountUidToDelete(row.uid);
              //   setAddedOpen(true);
              // }}
              />
            </Tooltip>
          );
        },
      },
      {
        title: "cross",
        render: (row) => {
          return (
            <Tooltip text={"Reject Account"}>
              <img
                className="account-table-row-image"
                // key={ind}
                src={CrossIcon}
                alt="Reject"
              // onClick={() => {
              //   setOpen(true);
              //   setAccountToDelete(row.name);
              //   setAccountUidToDelete(row.uid);
              //   setAddedOpen(true);
              // }}
              />
            </Tooltip>
          );
        },
      },
    ];
  } else {
    buttons = [
      {
        title: "edit",
        render: (row) => {
          return (
            <Tooltip text={"Edit Account"}>
              <img
                className="account-table-row-image"
                src={TablePen}
                alt="Edit"
                onClick={() => {
                  handleEditClick(row);
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        title: "delete",
        render: (row) => {
          return (
            <Tooltip text={"Delete Account"}>
              <DeleteForeverRounded
                sx={{
                  color: "#475466",
                  cursor: "pointer",
                  marginRight: "10px",
                  height: "25px",
                }}
                fontSize="medium"
                onClick={() => {
                  setOpen(true);
                  setAccountToDelete(row.name);
                  setAccountUidToDelete(row.uid);
                  setAddedOpen(true);
                }}
              />
            </Tooltip>
          );
        },
      },
    ];
  }

  const handleClick = (ind) => {
    setIndex(ind);
  };

  const handleModalClose = () => {
    setAddAccountsModalOpen(false);
    setEditAccountsModalOpen(false);
    setOpen(false);
  };

  const CalculateExpiry = (date) => {
    const currentDate = new Date();
    const timeDifference = date.getTime() - currentDate.getTime();

    const millisecondsInDay = 1000 * 3600 * 24;
    const millisecondsInWeek = millisecondsInDay * 7;
    const millisecondsInMonth = millisecondsInDay * 30;

    const remainingTimeInDays =
      Math.floor(timeDifference / millisecondsInDay) + 1;
    const remainingTimeInWeeks = Math.floor(
      timeDifference / millisecondsInWeek
    );
    const remainingTimeInMonths = Math.floor(
      timeDifference / millisecondsInMonth
    );
    const expiry =
      remainingTimeInDays - 1 < 0
        ? "Expired"
        : remainingTimeInDays > 7 && remainingTimeInDays < 30
          ? `${remainingTimeInWeeks} weeks`
          : remainingTimeInWeeks > 4
            ? `${remainingTimeInMonths} months`
            : `${remainingTimeInDays} days`;

    return expiry;
  };

  const getAllAccountsData = async () => {
    try {
      const accounts = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getallaccounts`
      );
      for (let i in accounts.data.accounts) {
        if (accounts.data.accounts[i].validity === "Never Expires") {
          accounts.data.accounts[i]["expiry_status"] = "Never Expires";
          continue;
        }
        const expiry = CalculateExpiry(
          new Date(accounts.data.accounts[i].validity)
        );
        if (expiry === "Expired") {
          accounts.data.accounts[i]["expiry_status"] = expiry;
          continue;
        }
        accounts.data.accounts[i]["expiry_status"] = expiry + " to go";
      }
      setTableData(accounts.data.accounts);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getAllAccountsData();
  }, [addedOpen]);

  // useEffect(() => {
  //   console.log(tableData[5]);
  //   for (let i in tableData) {
  //     console.log(i);
  //     if (tableData[i].validity === "Never Expires") {
  //       setTableData((prev) => {
  //         const current = prev;
  //         current[i]["expiry_status"] = "Never Expires";
  //         return current;
  //       });
  //       return;
  //     }
  //     const expiry = CalculateExpiry(new Date(tableData[i].validity));
  //     setTableData((prev) => {
  //       const current = prev;
  //       current[i]["expiry_status"] = expiry + " to go";
  //       return current;
  //     });
  //   }
  // }, [tableData, updatedRows]);

  const handleSubmit = async (payload) => {
    try {
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/admin/addaccount`, payload)
        .then((res) => {
          console.log(res);
          toast.success("Account added successfully");
        });
    } catch (error) {
      console.error(error);
    }
    setAddedOpen(false);
  };

  const handleEditSubmit = async (payload) => {
    try {
      await axios
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/admin/updateaccount/${payload.uid}`,
          payload
        )
        .then((res) => {
          console.log(res);
          toast.success("Account edited successfully");
        });
    } catch (error) {
      console.error(error);
    }
    setAddedOpen(false);
  };

  const handleDeleteAccount = async (uid) => {
    try {
      await axios
        .delete(
          `${process.env.REACT_APP_BACKEND_URL}/admin/removeaccount/${uid}`
        )
        .then((res) => {
          toast.error("Account deleted successfully");
          setAddedOpen(false);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const deleteAction = () => {
    handleDeleteAccount(accountUidToDelete);
    setOpen(false);
  };

  const handleEditClick = async (row) => {
    setEditData(row);
    setEditAccountsModalOpen(true);
  };

  return (
    <>
      {" "}
      <div className="acounts-admin-console d-flex">
        <h4 className="accounts-admin-console-heading">Accounts Console</h4>
        <div className="account-btn">
          <button
            className="Add-Appointments-button"
            onClick={() => {
              setAddAccountsModalOpen(true);
            }}
          >
            + Add Accounts
          </button>
        </div>
      </div>
      <div className="row m-0  mb-4 px-4">
        <div className="col-12 table-section-container d-flex ms-4">
          <DashboardTable
            columns={AddAccountsTableConfig}
            rows={updatedRows}
            size={size}
            action={buttons}
            actionHeaderTitle="Actions"
          />{" "}
        </div>
        {numberOfPages > 1 && (
          <DashboardTablePagination
            numberOfPages={numberOfPages}
            index={index}
            handleClick={handleClick}
          />
        )}
      </div>
      {addAccountsModalOpen && (
        <AddAccounts
          handleModalClose={handleModalClose}
          handleSubmit={(payload) => {
            handleSubmit(payload);
          }}
          setAddedOpen={setAddedOpen}
        />
      )}
      {editAccountsModalOpen && (
        <EditAccounts
          handleModalClose={handleModalClose}
          handleSubmit={(payload) => {
            handleEditSubmit(payload);
          }}
          setAddedOpen={setAddedOpen}
          editData={editData}
        />
      )}
      <AccountAlertPopup
        open={open}
        handleClose={handleModalClose}
        action={deleteAction}
        accountName={accountToDelete}
        accountuid={accountUidToDelete}
      />
    </>
  );
};

export default Accounts;
