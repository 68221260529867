import React, { useRef, useState } from "react";
import { addTreatmentSection } from "../../constants/formconfig";
import axios from "axios";
import SimpleInput from "../InputTypes/SimpleInput/SimpleInput";
import FileInput from "../InputTypes/FileInput/FileInput";

import "./AddTreatment.css";
import cross from "../../assets/images/red-cross.png";
import { API } from "../../constants/api";
import { uploadAndReturnUrls } from "../../constants/UploadeImageUrl";
import { ref } from "firebase/storage";
import { storage } from "../../config/testFirebaseConfig";

const AddTreatment = ({ onDoctorAdded, setLoader }) => {
  //ref for close
  const closeButtonRef = useRef(null);
  const searchParams = new URLSearchParams(window.location.search);
  const hospitalId = searchParams.get("q");

  const initialFormState = {}; // Set initial state based on form configuration
  addTreatmentSection[0].fields.forEach((field) => {
    initialFormState[field[0].title] = {
      value: "",
      isTouched: false,
    };
  });

  const [formData, setFormData] = useState(initialFormState);

  const requiredFields = addTreatmentSection[0].fields.filter(
    (field) => field[0].required
  );

  const emptyFields = requiredFields.filter(
    (field) => !formData[field[0].title].value
  );

  const isFormValid = emptyFields.length === 0;

  const handleChange = (field, newValue) => {
    setFormData((obj) => {
      let tempObj = { ...obj };
      const tempField = { ...tempObj[field], value: newValue };
      tempObj[field] = tempField;
      return tempObj;
    });
  };

  const handleTouch = (field) => {
    setFormData((obj) => {
      const tempObj = { ...obj };
      tempObj[field] = { ...tempObj[field], isTouched: true };
      return tempObj;
    });
    console.log(formData);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if any required field is empty
    const requiredFields = addTreatmentSection[0].fields.filter(
      (field) => field[0].required
    );

    const emptyFields = requiredFields.filter(
      (field) => !formData[field[0].title].value
    );

    if (emptyFields.length > 0) {
      // If any required field is empty, show an alert
      alert("Please fill out all required fields before submitting.");
      return;
    }

    console.log("Form wala data :", formData);

    const nonImageData = {
      name: formData.name.value,
      qualification: formData.qualification.value,
      experience: formData.experience.value,
      email: formData.email.value,
    };

    const imageArray = []; // Array to hold image data

    // Extract image data
    if (formData.image && formData.image.value) {
      imageArray.push(formData.image.value);
    }

    console.log("Data image is : ", imageArray);
    console.log("Data text is : ", hospitalId);

    try {
      setLoader(true);
      const doctorImageRef = ref(
        storage,
        `onboard_hospital/${hospitalId}/doctors_Image`
      );
      const imageLinks = await uploadAndReturnUrls(imageArray, doctorImageRef);
      nonImageData.imageLinks = imageLinks;

      const url = `${API}/agenthospital/docteradd/${hospitalId}`;
      const result = await axios.post(url, nonImageData);
      // alert("Doctor added");
      console.log("setDoc:::::::::", result.data.doctor);
      onDoctorAdded(formData);
      setFormData(initialFormState);
      closeButtonRef.current.click();
      setLoader(false);
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div className="add-doctor-modal  d-flex justify-content-end py-2 mb-2">
      <button
        type="button"
        className="btn  me-2 add-doctor-btn w-25"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Add Doctor
      </button>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Doctor
              </h5>
              {/* <button type="button" className="btn-close cross" data-bs-dismiss="modal" aria-label="Close" ref={closeButtonRef} /> */}
              <img
                src={cross}
                alt="cross"
                className="red-cross-button"
                data-bs-dismiss="modal"
                ref={closeButtonRef}
              />
            </div>

            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                {addTreatmentSection[0].fields.map((field, index) => {
                  if (
                    field[0].type === "text" ||
                    field[0].type === "number" ||
                    field[0].type === "email"
                  ) {
                    return (
                      <SimpleInput
                        key={index}
                        input={field[0]}
                        state={formData[field[0].title]}
                        handleChange={handleChange}
                        handleTouch={handleTouch}
                      />
                    );
                  } else if (field[0].type === "file") {
                    return (
                      <FileInput
                        key={index}
                        input={field[0]}
                        state={formData[field[0].title]}
                        handleChange={handleChange}
                        handleTouch={handleTouch}
                      />
                    );
                  }
                  return null;
                })}
              </form>
            </div>
            <div className="modal-footer border border-0 d-flex justify-content-center align-items-center">
              {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" ref={closeButtonRef}>Close</button> */}
              <button
                type="button"
                className="btn my-btn"
                onClick={handleSubmit}
                disabled={!isFormValid}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTreatment;
