import React, { useEffect } from "react";
import { AiFillHome } from "react-icons/ai";
import { BsFillPeopleFill, BsQuestion } from "react-icons/bs";
import { MdMiscellaneousServices, MdArticle } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import "./NavbarMenu.css";
import Button from "../Buttons/Button";
import { useNavigate } from "react-router-dom";
const NavbarMenu = ({ closeHandler }) => {
  const history = useNavigate();
  useEffect(() => {
    setTimeout(
      () =>
        (document.getElementById("portfolio-navbar-menu").style.transform =
          "translateX(0)"),
      100
    );
  }, []);
  return (
    <>
      <div className="portfolio-navbar-menu-dark-overlay"></div>
      <div className="portfolio-navbar-menu" id="portfolio-navbar-menu">
        <div className="portfolio-navbar-menu-box">
          <div className="portfolio-navbar-menu-close-button-box">
            <IoMdClose
              className="portfolio-navbar-menu-close-button"
              onClick={closeHandler}
            />
          </div>
          {/* <h4 className="portfolio-navbar-menu-heading">BharatOne Care</h4> */}
          <ul className="portfolio-navbar-menu-nav-links">
            <li>
              <a href="/#home" onClick={closeHandler}>
                <AiFillHome className="portfolio-navbar-menu-nav-links-icon" />{" "}
                Home
              </a>
            </li>
            {/* <li>
              <a href="/#about" onClick={closeHandler}>
                <BsFillPeopleFill className="portfolio-navbar-menu-nav-links-icon" />{" "}
                About
              </a>
            </li> */}
            <li>
              <a href="/#services" onClick={closeHandler}>
                <MdMiscellaneousServices className="portfolio-navbar-menu-nav-links-icon" />{" "}
                Services
              </a>
            </li>
            <li>
              <a href="/#faqs" onClick={closeHandler}>
                <BsQuestion className="portfolio-navbar-menu-nav-links-icon" />{" "}
                FAQs
              </a>
            </li>
            <li>
              <a href="/blogs" onClick={closeHandler}>
                <MdArticle className="portfolio-navbar-menu-nav-links-icon" />{" "}
                Blogs
              </a>
            </li>
          </ul>
        </div>
        <Button text={"Login"} task={() => history("/home")} />
      </div>
    </>
  );
};

export default NavbarMenu;
