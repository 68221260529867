import React from "react";
import "./DashboardTablePagination.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { IconButton } from "@mui/material";

const DashboardTablePagination = ({ numberOfPages, index, handleClick }) => {
  let positionArray = [];

  const style = {
    background: "rgb(57, 80, 130)",
    color: "white",
  };

  if (numberOfPages <= 7) {
    for (let i = 1; i <= numberOfPages; i++) {
      positionArray.push(i);
    }
  } else {
    if (index < 4) {
      positionArray = [1, 2, 3, 4, 5, "...", numberOfPages];
    } else if (numberOfPages - index < 5) {
      positionArray = [
        1,
        "...",
        numberOfPages - 4,
        numberOfPages - 3,
        numberOfPages - 2,
        numberOfPages - 1,
        numberOfPages,
      ];
    } else {
      positionArray.push(1);
      positionArray.push("...");
      for (
        let i = Math.max(0, index - 1);
        i < Math.min(index + 2, numberOfPages);
        i++
      ) {
        positionArray.push(i + 1);
      }
      if (positionArray[positionArray.length - 1] < numberOfPages - 1) {
        positionArray.push("...");
        positionArray.push(numberOfPages);
      } else if (
        positionArray[positionArray.length - 1] ===
        numberOfPages - 1
      ) {
        positionArray.push(numberOfPages);
      }
    }
  }

  return (
    <div className="pagination-container-div">
      <div className="forward-and-backward-icons">
        <IconButton
          onClick={() => {
            if (index == 0) handleClick(index);
            else handleClick(index - 1);
          }}
          style={{ width: "50px", height: "50px" }}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        <div className="pagination-position-select-div">
          {positionArray.map((item) => {
            if (item === "...") {
              return <p className={`pagination-dots-div`} >{`${item}`}</p>;
            }
            return (
              <p
                className={`pagination-number-div`}
                style={item - 1 === index ? style : {}}
                onClick={() => {
                  handleClick(item - 1);
                }}
              >{`${item}`}</p>
            );
          })}
        </div>
        <IconButton
          onClick={() => {
            if (index < numberOfPages - 1) {
              handleClick(index + 1);
            } else {
              handleClick(index);
            }
          }}
          style={{ width: "50px", height: "50px" }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default DashboardTablePagination;
