import React, { useEffect, useState } from "react";
import { AiFillDownCircle } from "react-icons/ai";
import "./FAQBox.css";
import { motion } from "framer-motion";
const FAQBox = ({ question, answer, id }) => {
  const [answerBoxToggle, setAnwerBoxToggle] = useState(false);
  const openCloseAnswerBoxHandler = () => {
    let answerButton = document.getElementById(`faq-box-answer-button-${id}`);
    if (!answerBoxToggle) {
      answerButton.style.transform = "rotate(180deg)";
    } else {
      answerButton.style.transform = "rotate(0deg)";
    }
    setAnwerBoxToggle(!answerBoxToggle);
  };
  useEffect(() => {
    let faqBox = document.getElementById(`faq-box-${id}`);

    if (answerBoxToggle) {
      faqBox.classList.add("faq-answer-box-expand");
    } else {
      faqBox.classList.remove("faq-answer-box-expand");
    }
  }, [answerBoxToggle]);
  return (
    <motion.div
      initial={{ scale: "0", opacity: 0 }}
      whileInView={{ scale: "1", opacity: 1 }}
      viewport={{ once: true }}
      className="portfolio-faq-box"
      id={`faq-box-${id}`}
      onClick={openCloseAnswerBoxHandler}
    >
      <div className="portfolio-faq-box-qusetion-button">
        <h4 className="faq-box-question">{question}</h4>
        <AiFillDownCircle
          className="faq-box-answer-button"
          id={`faq-box-answer-button-${id}`}
        />
      </div>
      <div className="portfolio-faq-box-answer-box">{answer}</div>
    </motion.div>
  );
};

export default FAQBox;
