import React, { useEffect, useState } from "react";
import data_1 from "../../assets/images/Glyph.svg";
import data_2 from "../../assets/images/Glyph_1.svg";
import data_3 from "../../assets/images/Glyph_2.svg";
import data_4 from "../../assets/images/Glyph_3.svg";
import CountUp from "react-countup";
import Button from "../../components/Buttons/Button.jsx";
import "./HospitalDetails.css";
import ImageSlider from "../ImageSlider/ImageSlider.jsx";

export default function HospitalDetails({
  hospitalName,
  hospitalLocation,
  hospitalImages,
  openContactUsSidebarHandler,
}) {
  const [hospitalImg, setHospitalImg] = useState([]);
  useEffect(() => {
    setHospitalImg(hospitalImages);
  }, [hospitalImages]);
  const scrollToHandler = (sectionId) => {
    let section = document.getElementById(sectionId);
    window.scrollTo({ top: section.offsetTop - 100, behavior: "smooth" });
  };
  return (
    <div className="hospital-detail container">
      <div className="hospital-main">
        <div className="row">
          <div className="col-md-6 col-sm-12 hospital-name">
            <h2 className="hospital-heading">
              {hospitalName}
              {/* Indraprastha Apollo Hospital */}
            </h2>
            <h5 className="hospital-sub-heading">{hospitalLocation},India</h5>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="hospital-button">
              <button
                className="portfolio-button"
                onClick={openContactUsSidebarHandler}
              >
                Book Appointment
              </button>
              <button
                className="need-button"
                onClick={() => scrollToHandler("need-help")}
              >
                Need Help
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="hospital-images">
        <div className="row">
          <ImageSlider images={hospitalImages} />
          {/* <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="hospital-image">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZoTGTYyjkiQkIObigZizX_3S7-tfnWE_Qfg&usqp=CAU"
                alt="hospital"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="hospital-image">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSX22sanYpwTrh9mjirUKbWtjZIpBi00anCcA&usqp=CAU"
                alt="hospital"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="hospital-image">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSX22sanYpwTrh9mjirUKbWtjZIpBi00anCcA&usqp=CAU"
                alt="hospital"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="hospital-image">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSX22sanYpwTrh9mjirUKbWtjZIpBi00anCcA&usqp=CAU"
                alt="hospital"
              />
            </div>
          </div> */}
        </div>
      </div>
      <div className="text-center">
        <div className="data-container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="ml-2 mt-4">
                <img
                  src={data_1}
                  className="icon-image mb-2"
                  alt="landingPage"
                />
                <p className="landingPage-data">
                  <CountUp
                    start={0}
                    end={200}
                    duration={5}
                    style={{
                      width: "60px",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                    suffix="+"
                  />{" "}
                  Experienced doctors
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="ml-2 mt-4">
                <img
                  src={data_2}
                  className="icon-image mb-2"
                  alt="landingPage"
                />
                <p className="landingPage-data">Established in: 1996</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="ml-2 mt-4">
                <img
                  src={data_3}
                  className="icon-image mb-2"
                  alt="landingPage"
                />
                <p className="landingPage-data">
                  <CountUp
                    start={0}
                    end={1000}
                    duration={5}
                    style={{
                      width: "60px",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                    suffix="+"
                  />{" "}
                  beds
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="ml-2 mt-4">
                <img
                  src={data_4}
                  className="icon-image mb-2"
                  alt="landingPage"
                />
                <p className="landingPage-data">
                  <CountUp
                    start={0}
                    end={25000}
                    duration={5}
                    style={{
                      width: "60px",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                    suffix="+"
                  />{" "}
                  patients assisted
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
