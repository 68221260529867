import React from 'react'
import './PatientManagement.css'
import DashboardLayout from '../DashboardSidebar/DashboardLayout'
import { sidebarAgencyItems } from '../../constants/SidebarItems'

const PatientManagement = () => {
  return (
    <DashboardLayout sidebarItems={sidebarAgencyItems}>
      <h1>PatientManagement</h1>
      <div className="container-fluid p-0 m-0 border border-2 border-dark">
        <div className="row m-0 p-0 ">
          <div className="col border border-danger d-flex">
            <div className="text-box border align-self-center">
              <p class="fs-3 p-0 m-0">Patient Management</p>
              {/* <h2>PatientManagement</h2> */}
            </div>
            <div className="button-addAgent border align-self-center">
              <button type="button" class="btn btn-light">Add Agent</button>
            </div>

          </div>
          <div className="col border border-danger d-flex">

            <input className="form-control" type="search" placeholder="Search" aria-label="Search" />
            <div className="button-sort border align-self-center">
              <button type="button" class="btn btn-light">Sort</button>
            </div>
            <div className="button-sort border align-self-center">
              <button type="button" class="btn btn-light">Filter</button>
            </div>

          </div>

        </div>

      </div>
    </DashboardLayout>
  )
}

export default PatientManagement