import React, { useEffect, useState } from "react";
import "./AllHospitals.css";
import LandingPageNavbar from "../../components/LandingPageNavbar/LandingPageNavbar";
import RecommendedHospitalCard from "../../components/ProcedurePageComponents/RecommendedHospitalCard/RecommendedHospitalCard";
import { API } from "../../constants/api";
import axios from "axios";
import Lottie from "lottie-react";
import coming_soon_lottie from "../../assets/lottie/coming-soon.json";
import Footer from "../../components/Footer/Footer";

const AllHospitals = () => {
  const [allhospitals, setAllHospitals] = useState([]);
  useEffect(() => {
    const fetchHospitals = async () => {
      try {
        const response = await axios.get(`${API}/hospital/getallhospitals`);
        console.log(response.data);
        if (response.data.hospitals && response.data.hospitals.length > 0) {
          let hospArr = response.data.hospitals;
          hospArr = hospArr.filter((x) => x.agentUid);
          setAllHospitals(hospArr);
        } else {
          console.log("No hospitals found");
        }
      } catch (error) {
        console.error("Error fetching hospitals:", error);
      }
    };

    fetchHospitals();
  }, []);

  // console.log('All hospital data  ::::::', allhospitals);

  // Function to chunk hospitals into groups of 4
  const chunkArray = (array, size) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  };

  let chunkedHospitals = chunkArray(allhospitals, 4);
  console.log("All hospital 4 ka data  ::::::", chunkedHospitals);

  const initialDisplayCount = 2; // Number of hospitals to display initially
  const [displayCount, setDisplayCount] = useState(initialDisplayCount);
  chunkedHospitals = chunkedHospitals
    ? chunkedHospitals.slice(0, displayCount)
    : [];

  const handleLoadMore = () => {
    setDisplayCount(displayCount + 2);
  };

  return (
    <div className="allhospital-container">
      <LandingPageNavbar />
      <div className="container-fluid">
        {chunkedHospitals.map((chunk, index) => (
          <div key={index} className="row mb-3 ">
            {chunk.map((hospital) => (
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 d-flex justify-content-evenly ">
                <RecommendedHospitalCard
                  key={hospital.id}
                  hospitalName={hospital.Name_Of_Hospital}
                  hospitalLocation={hospital.Location}
                  specialilty={hospital.facility_overview.specialityTypes}
                  facilities={hospital.facility_overview.specialties}
                  hospital_uid={hospital.uid}
                  hospitalPhoto={
                    hospital.Hospital_images &&
                      hospital.Hospital_images.length > 0
                      ? hospital.Hospital_images
                      : null
                  }
                />
              </div>
            ))}
          </div>
        ))}
        <div className="button-loade-more d-flex justify-content-center">
          {allhospitals.length >= 8 &&
            Math.round(allhospitals.length / 4) > chunkedHospitals.length ? (
            <button
              className="btn btn-lg btn-secondary mb-4"
              onClick={handleLoadMore}
            >
              Load More
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AllHospitals;
