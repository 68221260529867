import React, { useState } from "react";
import "./SignUpPage.css";
import CreateAccountButton from "../../../components/CreateAccountButton/CreateAccountButton";
import { Link } from "react-router-dom";
import TextInputComponent from "../../../components/TextInputComponent/TextInputComponent";
import PasswordInputComponent from "../../../components/PasswordInputComponent/PasswordInputComponent";
import AlertComponent from "../../../components/AlertComponent/AlertComponent";
const SignUpPage = (props) => {
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <div className="login-page-form">
      <h2>Create your Account</h2>
      <div className="login-page-form-content">
        <form>
          <TextInputComponent
            label="Email"
            placeholder="johnsondoe@gmail.com"
            className="login-page-form-input-text"
            name="email"
            value={props.user.email}
            onChange={props.handleChange}
          />
          <PasswordInputComponent
            label="Password"
            placeholder="******"
            className="login-page-form-input-password"
            name="password"
            value={props.user.password}
            onChange={props.handleChange}
            passwordShown={passwordShown}
            togglePasswordVisibility={togglePasswordVisibility}
          />
          <div>
            {props.displayError && (
              <AlertComponent errorText={props.errorText} />
            )}
            <div style={{ marginTop: "24px" }}>
              <CreateAccountButton
                handleSubmit={props.handleSubmit}
                text="Create Account"
                buttonType="simple"
              />
            </div>
          </div>
          <div className="login-page-form-account-login">
            Already have an account?
            <Link to="/login" className="login-page-form-account-login-link">
              Login
            </Link>
          </div>
          <div className="login-page-form-or">
            <hr className="login-page-form-or-line"></hr>
            <p className="login-page-form-or-text">OR</p>
            <hr className="login-page-form-or-line"></hr>
          </div>
          <CreateAccountButton
            handleSubmit={props.handleSubmit}
            text="Sign up with Google"
            buttonType="google"
          />
        </form>
      </div>
    </div>
  );
};

export default SignUpPage;
