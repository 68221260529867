import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import AddTreatment from "../AddTreatment/AddTreatment";
import "./TableComponent.css";
import EditModal from "../EditModal/EditModal";
import Button from "@mui/material/Button";
import DeleteModal from "../DeleteModal/DeleteModal";
import { API } from "../../constants/api";

const TableComponent = ({
  input,
  setTableData,
  tableData,
  moveToPrev,
  moveToNext,
  setLoader,
}) => {
  const [data, setData] = useState([]);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [deleteDoctor, setDeleteDoctor] = useState(null);
  const searchParams = new URLSearchParams(window.location.search);
  const hospitalId = searchParams.get('q');

  const handleDeleteClick = (doctorId) => {
    setDeleteDoctor(doctorId);
    setOpenDelete(true);
  };

  const handleEditClick = (doctorId) => {
    const selectedDoctor = data.find((doctor) => doctor.id === doctorId);
    setSelectedDoctor(selectedDoctor);
    console.log("in table", selectedDoctor);
    setOpen(true);
    setIsEditModalVisible(true);
  };
  const disabled = tableData.length === 0 ? "disabled" : "";

  //dynamic data render methods=============================================>
  const fetchData = useCallback(async () => {
    try {
      setLoader(true)
      const response = await axios.get(
        `${API}/agenthospital/getalldoctor/${hospitalId}`
      );
      if (response) {
        const fetchedData = response.data.doctors;
        setData(fetchedData); // Update the local state with fetched data
        setTableData(fetchedData); // Update the parent component's state with fetched data
        setLoader(false)
      } else {
        setData();
        setTableData();
        setLoader(false)
      }
      console.log("Getalldoc", response.data.doctors);


    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [isEditModalVisible]);

  useEffect(() => {
    fetchData(); // Call fetchData function on mount
  }, [fetchData]);

  const handleDoctorAdded = useCallback(
    async (newDoctorData) => {
      // Logic to handle the new doctor data, e.g., updating state, refetching data, etc.
      // For example, you can refetch data after adding a new doctor:
      await fetchData();
    },
    [fetchData]
  );

  const handleDoctorUpdated = useCallback(
    async (updateDoctorData) => {
      // Logic to handle the new doctor data, e.g., updating state, refetching data, etc.
      // For example, you can refetch data after adding a new doctor:
      await fetchData();
    },
    [fetchData]
  );
  //dynami data render methods=============================================>

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  return (
    <div className='table-main treatment-container'>
      <div className='add-treatment-box'>
        <AddTreatment onDoctorAdded={handleDoctorAdded}
          setLoader={setLoader} />
        {open && selectedDoctor && (
          <EditModal
            open={open}
            handleClose={handleClose}
            handleOpen={handleOpen}
            onDoctorUpdated={handleDoctorUpdated}
            selectedDoctor={selectedDoctor}
            docid={selectedDoctor.id}
            onClose={() => setIsEditModalVisible(false)}
            onUpdate={(updatedDoctor) => {
              console.log("Updated Doctor Data:", updatedDoctor);
              setIsEditModalVisible(false);
            }}
            setLoader={setLoader}
          />
        )}

        {openDelete && (
          <DeleteModal
            openDelete={openDelete}
            deleteDoctor={deleteDoctor}
            handleCloseDelete={handleCloseDelete}
            handleOpenDelete={handleOpenDelete}
            setData={setData}
            setTableData={setTableData}
            setDeleteDoctor={setDeleteDoctor}
            tableData={tableData}
            setLoader={setLoader}
          />
        )}
      </div>
      <div className='table-main-box table-wrapper-scroll-y my-custom-scrollbar shadow shadow-sm mb-2'>
        <table className='table align-middle mb-0 bg-white table-hover'>
          <thead className='bg-light'>
            <tr>
              <th>Name</th>
              <th>Qualification</th>
              <th>Experience</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((doctor) => (
              <tr key={doctor.id}>
                <td>
                  <div className='d-flex align-items-center'>
                    <img
                      src={doctor.imageLinks}
                      alt='Doctor'
                      style={{ width: 45, height: 45 }}
                      className='rounded-circle img-fluid'
                    />
                    <div className='ms-3'>
                      <p className='fw-bold mb-1'>{doctor.name}</p>
                      <p className='text-muted mb-0'>{doctor.email}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <p className='fw-normal mb-1'>{doctor.qualification}</p>
                </td>
                <td>
                  <p className='fw-normal mb-1'>{doctor.experience}</p>
                </td>
                <td>
                  <button
                    type='button'
                    className='btn btn-md action-button-edit mx-2'
                    onClick={() => handleEditClick(doctor.id)}
                  >
                    Edit
                  </button>

                  <button
                    type='button'
                    className='btn btn-md action-button-delete mx-2'
                    onClick={() => handleDeleteClick(doctor.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className='treatment-button-container btn-action d-flex justify-content-end btn-container'>
        <button onClick={moveToPrev} className={`btn btn-back ms-3`}>
          Back
        </button>
        <button
          onClick={moveToNext}
          className={`btn btn-next ms-3 ${disabled}`}
          disabled={tableData.length === 0}
        >
          Next
        </button>
      </div>
    </div>

  );
};

export default TableComponent;
