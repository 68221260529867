import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import {
  AddAppointmentsColumnConfig,
  AddAppointmentsDummyData,
} from "../../constants/AddAppointmentsTableConfig";
import Search from "../../assets/images/search-lg.svg";
import DashboardTable from "../../components/DashboardTable/DashboardTable";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { columnConfig, Data } from "../../constants/DummyDashboardTableData";
import AddAppointments from "../../components/AddAppointments/AddAppointments";
import DashboardLayout from "../DashboardSidebar/DashboardLayout";
import TablePen from "../../assets/images/table-pen-button.svg";
import ThreeDots from "../../assets/images/dots-vertical.svg";
import axios from "axios";
import EditAppointments from "../../components/EditAppointments/EditAppointments";
import MenuDropdown from "../../components/MenuDropdown/MenuDropdown";
import FilterPopup from "../../components/FilterPopup/FilterPopup";
import ConfirmationPopup from "../../components/ConfirmationPopup/ConfirmationPopup";
import { useNavigate } from "react-router-dom";
import DashboardTablePagination from "../../components/DashboardTablePagination/DashboardTablePagination";
import { API } from "../../constants/api";
import AppointmentFilterModel from "../../components/AppointmentFilterModel/AppointmentFilterModel";
import Loader from "../../components/Loader/Loader";
import { AppointmentContext } from "../../context/AppointmentContext";
import Tooltip from "../../components/Tooltip/Tooltip";
import { useContext } from "react";

//assets
import dashboardIcon from "../../assets/DashboardAssets/dashboard.svg";
import hospitalmangement from "../../assets/DashboardAssets/hospitalmanagement.svg";
import appointments from "../../assets/DashboardAssets/appointments.svg";
import profile from "../../assets/DashboardAssets/profile.svg";
import settings from "../../assets/DashboardAssets/settings.svg";
import support from "../../assets/DashboardAssets/support.svg";

import { sidebarAgentItems } from "../../constants/SidebarItems";
const Dashboard = () => {
  const { appointmentIdHandler, appointmentDateHandler } =
    useContext(AppointmentContext);
  const [originalTableData, setOriginalTableData] = useState([]);
  const [searchedInput, setSearchedInput] = useState("");
  const [tableData, setTableData] = useState([]);
  const [addAppointmentModalOpen, setAddAppointmentModalOpen] = useState(false);
  const [EditAppointmentsModelOpen, setEditAppointmentsModelOpen] =
    useState(null);
  const navigate = useNavigate();
  const [hospitalData, setHospitalData] = useState([]);
  const [editId, setEditId] = useState([]);
  const [loader, setLoader] = useState(false);
  const [deleteModal, setDeleteModal] = useState(null);
  const [index, setIndex] = useState(0);
  const size = 5;
  const updatedRows = tableData.slice(size * index + 0, size * index + size);

  useEffect(() => {
    // setTableData((tableData) =>
    //   tableData.filter((item) => item.name.includes(searchedInput))
    // );
    const filteredData = originalTableData.filter((item) => {
      return Object.entries(selectedFilters).every(
        ([filterKey, filterValues]) => {
          if (filterValues.length > 0) {
            if (filterKey === "totalStaff") {
              return filterValues.some((filterValue) => {
                const [min, max] = filterValue.split("-");
                console.log(item);
                const staffCount = parseInt(item[filterKey], 10);
                console.log(staffCount);
                if (min && max === "max") {
                  return staffCount >= parseInt(min, 10);
                } else if (min && max) {
                  return (
                    staffCount >= parseInt(min, 10) &&
                    staffCount <= parseInt(max, 10)
                  );
                } else if (min) {
                  return staffCount >= parseInt(min, 10);
                } else if (max) {
                  return staffCount <= parseInt(max, 10);
                }

                return false;
              });
            }
            return filterValues.includes(item[filterKey]);
          }
          return true;
        }
      );
    });
    const filteredData2 = filteredData.filter((item) =>
      item.name.includes(searchedInput)
    );
    setTableData(filteredData2);
  }, [searchedInput]);

  const handleClick = (ind) => {
    setIndex(ind);
  };

  const numberOfPages = Math.ceil(tableData.length / size);

  useEffect(() => {
    const getDataFromFirebase = async () => {
      try {
        // const response = await axios.get(
        //   `${API}/hospitalOfFacilitator/getallhospitalOfAgent/-Nh0qC7JnKbRlwJoBbua`
        // );
        // const data = await response.data;
        // const items = data.items;
        // const keys = Object.keys(items);
        // const tempHospitalData = keys.map((item) => {
        //   const tempObj = items[item];
        //   tempObj.uid = item;
        //   return tempObj;
        // });
        setLoader(true);
        const response2 = await axios.get(`${API}/hospital/getallhospitals`);
        const data2 = await response2.data;
        // console.log(data2);
        const items2 = data2.hospitals;
        console.log(items2);

        const tempHospitalData2 = items2.map((item, index) => {
          const tempObj = {};
          const tempId = `B1C12${index}`;
          tempObj.hospitalName = item.Name_Of_Hospital;
          tempObj.contact = item.Contact_Number;
          tempObj.hospitalId = item?.hospitalId || tempId;
          tempObj.speciality = [];
          tempObj.addedOn = item?.onBoardedOn || " ";
          tempObj.totalStaff = item?.totalStaff || " ";
          tempObj.uid = item?.uid;
          tempObj.location = item?.Location;
          tempObj.hospitalLogo = item?.Hospital_Logo;
          return tempObj;
        });

        console.log(items2);
        setLoader(false);

        setHospitalData(tempHospitalData2);
        setLoader(false);
      } catch (error) {
        console.log(error);
        setLoader(false);
      }
    };
    getDataFromFirebase();
  }, []);

  const getDataFromFirebase = async () => {
    try {
      console.log("bharatonecare");
      const response = await axios.get(
        `${API}/appointment/getallappointments/-NhLGB5XwncQ6Keg88vT`
      );
      const data = await response.data;
      console.log(data.appointments);
      const keys = Object.keys(data.appointments);
      const tempTableData = keys.map((key) => {
        const payload = data.appointments[key];
        payload.uid = key;
        return payload;
      });
      console.log(tempTableData);
      // tempTableData.sort((a, b) => {
      //   return Number(new Date(a.statusDate)) - Number(new Date(b.statusDate));
      // });
      const sortedTableData = statusDataSort(tempTableData);
      console.log(tempTableData);
      setTableData((ele) => [...sortedTableData]);
      setOriginalTableData((ele) => [...sortedTableData]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fun = async () => {
      setLoader(true);
      await getDataFromFirebase();
      setLoader(false);
    };
    fun();
  }, []);
  console.log(tableData);

  const handleEditClick = (data) => {
    setEditAppointmentsModelOpen(data);
  };

  const handleCrossClick = (id) => {
    console.log(id);
  };

  const handleModalClose = () => {
    setAddAppointmentModalOpen(false);
  };
  const handleDeleteAppointment = async (item) => {
    setDeleteModal(item);
  };
  console.log(deleteModal);

  const handleAddDetail = async (item) => {
    const filteredHospital = hospitalData.filter((ele) => {
      return ele.hospitalName === item.name;
    });
    const payload = { status: "In Progress", statusDate: item.statusDate };
    try {
      setLoader(true);
      await axios.patch(
        `${API}/appointment/updateappointmentstatus/${item.uid}`,
        payload
      );
      // setEditAppointmentsModelOpen(null);
      await getDataFromFirebase();
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
    console.log(hospitalData);

    appointmentIdHandler(item.uid);
    appointmentDateHandler(item.statusDate);
    navigate(
      `/agent/appointments/hospital-details?page=agent&q=${item.hospitalUid}`
    );
  };
  // `const handleMarkComplete = async (item) => {
  //   console.log("3", item);
  //   const payload = { status: "Completed", statusDate: item.statusDate };
  //   try {
  //     await axios.patch(
  //       `${API}/appointment/updateappointmentstatus/${item.uid}`,
  //       payload
  //     );
  //     // setEditAppointmentsModelOpen(null);
  //     await getDataFromFirebase();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };`

  //handleDeleteAppointment,handleAddDetail,handleMarkComplete,item

  const buttons = [
    {
      title: "cross",
      // image: TablePen,
      render: (row) => {
        return (
          <Tooltip text={"Reschedule-Appointment"}>
            <img
              className="dashboard-table-row-image"
              // key={ind}
              src={TablePen}
              alt="Edit"
              onClick={() => {
                handleEditClick(row);
              }}
            />
          </Tooltip>
          // <div className="reschedule-appointment-button">
          //   <img
          //     className="dashboard-table-row-image"
          //     // key={ind}
          //     src={TablePen}
          //     alt="Edit"
          //     onClick={() => {
          //       handleEditClick(row);
          //     }}
          //   />
          //   <p className="reschedule-appointment">Reschedule Appointment</p>
          // </div>
        );
      },
      // onClick: handleEditClick,
    },
    {
      title: "icon",
      render: (item) => {
        return (
          <>
            {item.status === "Cancelled" ? null : (
              <MenuDropdown
                handleDeleteAppointment={handleDeleteAppointment}
                handleAddDetail={handleAddDetail}
                // handleMarkComplete={handleMarkComplete}
                item={item}
              />
            )}
          </>
        );
      },
    },
  ];

  const handleSubmit = async (payload) => {
    console.log(payload);
    try {
      setLoader(true);
      await axios.post(
        `${API}/appointment/register/-NhLGB5XwncQ6Keg88vT`,
        payload
      );
      const response = await axios.get(
        `${API}/appointment/getallappointments/-NhLGB5XwncQ6Keg88vT`
      );
      const data = await response.data;
      console.log(data.appointments);
      const keys = Object.keys(data.appointments);
      const tempTableData = keys.map((key) => {
        const payload = data.appointments[key];
        payload.uid = key;
        return payload;
      });
      // tempTableData.sort((a, b) => {
      //   return Number(new Date(a.statusDate)) - Number(new Date(b.statusDate));
      // });

      // setTableData((ele) => [...tempTableData]);
      // setOriginalTableData((ele) => [...tempTableData]);
      const sortedTableData = statusDataSort(tempTableData);
      console.log(tempTableData);
      setTableData((ele) => [...sortedTableData]);
      setOriginalTableData((ele) => [...sortedTableData]);
      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };
  const editAppointmentSubmitHandler = async (date) => {
    const prevData = EditAppointmentsModelOpen;
    let status = prevData.status;
    if (status !== "In Progress") {
      status = "Scheduled";
    }
    const payload = { statusDate: date, status: status };
    // payload.statusDate = date;

    try {
      setLoader(true);
      await axios.patch(
        `${API}/appointment/updateappointmentstatus/${prevData.uid}`,
        payload
      );
      setEditAppointmentsModelOpen(null);
      await getDataFromFirebase();
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }

    console.log(payload);
  };

  const handleDeleteClick = async (item) => {
    console.log(item);
    if (item === "Yes") {
      const payload = {
        status: "Cancelled",
        statusDate: deleteModal.statusDate,
      };
      setLoader(true);
      try {
        await axios.patch(
          `${API}/appointment/updateappointmentstatus/${deleteModal.uid}`,
          payload
        );
        // setEditAppointmentsModelOpen(null);
        setDeleteModal(null);
        await getDataFromFirebase();
        setLoader(false);
      } catch (error) {
        console.log(error);
        setLoader(false);
      }
    } else {
      setDeleteModal(null);
    }
  };

  const statusDataSort = (tableData) => {
    const scheduledArray = tableData.filter(
      (ele) => ele.status === "Scheduled"
    );
    const inProgressArray = tableData.filter(
      (ele) => ele.status === "In Progress"
    );
    const completedArray = tableData.filter(
      (ele) => ele.status === "Completed"
    );
    const cancelledArray = tableData.filter(
      (ele) => ele.status === "Cancelled"
    );
    scheduledArray.sort((a, b) => {
      return Number(new Date(a.statusDate)) - Number(new Date(b.statusDate));
    });
    inProgressArray.sort((a, b) => {
      return Number(new Date(a.statusDate)) - Number(new Date(b.statusDate));
    });
    completedArray.sort((a, b) => {
      return Number(new Date(a.statusDate)) - Number(new Date(b.statusDate));
    });
    cancelledArray.sort((a, b) => {
      return Number(new Date(a.statusDate)) - Number(new Date(b.statusDate));
    });
    return [
      ...scheduledArray,
      ...inProgressArray,
      ...completedArray,
      ...cancelledArray,
    ];
  };

  //////////////////////// FILTER POPUP CONTENT START ////////////////////////////

  const [isVisible, setIsVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState({
    status: [],
    speciality: [],
    totalStaff: [],
    location: [],
  });

  const [checkboxes, setCheckboxes] = useState({
    lessThan500: false,
    between500And1000: false,
    between1000And2000: false,
    above2000: false,
  });

  const toggleChip = (label, category) => {
    console.log(label, category);

    if (category === "location") {
      setSelectedFilters({
        ...selectedFilters,
        [category]: label,
      });
    } else {
      const currentSelection = selectedFilters[category];
      console.log(currentSelection);

      if (currentSelection.includes(label)) {
        setSelectedFilters({
          ...selectedFilters,
          [category]: currentSelection.filter((chip) => chip !== label),
        });
      } else {
        setSelectedFilters({
          ...selectedFilters,
          [category]: [...currentSelection, label],
        });
      }
    }
  };

  const handleFilterSubmit = async () => {
    console.log(selectedFilters);

    const filteredData = originalTableData.filter((item) => {
      return Object.entries(selectedFilters).every(
        ([filterKey, filterValues]) => {
          if (filterValues.length > 0) {
            if (filterKey === "totalStaff") {
              return filterValues.some((filterValue) => {
                const [min, max] = filterValue.split("-");
                console.log(item);
                const staffCount = parseInt(item[filterKey], 10);
                console.log(staffCount);
                if (min && max === "max") {
                  return staffCount >= parseInt(min, 10);
                } else if (min && max) {
                  return (
                    staffCount >= parseInt(min, 10) &&
                    staffCount <= parseInt(max, 10)
                  );
                } else if (min) {
                  return staffCount >= parseInt(min, 10);
                } else if (max) {
                  return staffCount <= parseInt(max, 10);
                }

                return false;
              });
            }
            return filterValues.includes(item[filterKey]);
          }
          return true;
        }
      );
    });
    console.log(filteredData);
    setTableData(filteredData);
    setSearchedInput("");

    togglePopup("dontClearFilters");
  };

  const togglePopup = (toClear) => {
    if (toClear === "clearFilters") {
      if (isVisible) {
        setIsClosing(true);
        setTimeout(() => {
          setIsVisible(false);
          setIsClosing(false);
          handleFilterClear();
        }, 300);
      } else {
        setIsVisible(true);
      }
    } else {
      if (isVisible) {
        setIsClosing(true);
        setTimeout(() => {
          setIsVisible(false);
          setIsClosing(false);
        }, 300);
      } else {
        setIsVisible(true);
      }
    }
  };

  const handleFilterClear = () => {
    setSelectedFilters({
      status: [],
      speciality: [],
      totalStaff: [],
      location: [],
    });

    setCheckboxes({
      lessThan500: false,
      between500And1000: false,
      between1000And2000: false,
      above2000: false,
    });
  };

  const handleCheckboxChange = (key) => {
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [key]: !prevCheckboxes[key],
    }));
  };

  console.log(searchedInput);

  //////////////////////// FILTER POPUP CONTENT END ////////////////////////////

  //sidebar items
  // const sidebarItems = [
  //   {
  //     link: "/agent/dashboard",
  //     icon: dashboardIcon,
  //     altText: "Dashboard Icon",
  //     label: "Dashboard",
  //     display: "all", // 'all' means it's always displayed
  //   },
  //   {
  //     link: "/agent/hospital-management",
  //     icon: hospitalmangement,
  //     altText: "Hospital Management Icon",
  //     label: "Hospital Management",
  //     display: "all",
  //   },
  //   {
  //     link: "/agent/appointments",
  //     icon: appointments,
  //     altText: "Appointments Icon",
  //     label: "Appointments",
  //     display: "all",
  //   },
  //   {
  //     link: "/agent/settings",
  //     icon: settings,
  //     altText: "Settings Icon",
  //     label: "Settings",
  //     display: "lg", // 'lg' means displayed only on large screens
  //   },
  //   {
  //     link: "/agent/help-support",
  //     icon: support,
  //     altText: "Help & Support Icon",
  //     label: "Help & Support",
  //     display: "lg",
  //   },
  //   {
  //     link: "/agent/my-profile",
  //     icon: profile,
  //     altText: "My Profile Icon",
  //     label: "My Profile",
  //     display: "all",
  //   },
  // ];

  return (
    <DashboardLayout sidebarItems={sidebarAgentItems}>
      {loader && <Loader />}
      <div>
        <div className="button-div flex-xl-row flex-column">
          <div className="button-div-left-side">
            <h1 className="appointment-header">Appointments</h1>
            <button
              className="Add-Appointments-button"
              onClick={() => {
                setAddAppointmentModalOpen(true);
              }}
            >
              + Add Appointments
            </button>
          </div>
          <div className="button-div-right-side">
            <div className="dashboard-search-container">
              <img
                src={Search}
                alt="search"
                className="dashboard-search-icon"
              />
              <input
                type="text"
                onChange={(event) => {
                  setSearchedInput(event.target.value);
                }}
                value={searchedInput}
                className="searched-input-field"
                placeholder="Search"
              />
            </div>
            <FilterPopup
              isVisible={isVisible}
              isClosing={isClosing}
              setIsClosing={setIsClosing}
              setIsVisible={setIsVisible}
              togglePopup={togglePopup}
              handleFilterClear={handleFilterClear}
              handleFilterSubmit={handleFilterSubmit}
              name="Filter"
              buttonStyle="filter-button-style"
            >
              <AppointmentFilterModel
                selectedFilters={selectedFilters}
                checkboxes={checkboxes}
                handleCheckboxChange={handleCheckboxChange}
                toggleChip={toggleChip}
              />
            </FilterPopup>
          </div>
        </div>
        {EditAppointmentsModelOpen !== null && (
          <EditAppointments
            handleModalClose={() => {
              setEditAppointmentsModelOpen(null);
            }}
            submitHandler={editAppointmentSubmitHandler}
          />
        )}
        {deleteModal && (
          <ConfirmationPopup
            handleClick={handleDeleteClick}
            data={{ hospitalName: deleteModal.name }}
          />
        )}
        {addAppointmentModalOpen && (
          <AddAppointments
            handleModalClose={handleModalClose}
            hospitalData={hospitalData}
            handleSubmit={(payload) => {
              handleSubmit(payload);
            }}
          />
        )}
        <div
          style={{
            padding: "0px",
            marginBottom: "30px",
            marginTop: "30px",
            width: "100%",
            overflow: "auto",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          <DashboardTable
            columns={AddAppointmentsColumnConfig}
            rows={updatedRows}
            size={6}
            action={buttons}
            actionHeaderTitle="Actions"
          />{" "}
          {/* <div className="row m-0  mb-0 px-0">
            <div className=" d-flex justify-content-start ms-4">
              <div>
               
              </div>
            </div>
          </div> */}
        </div>
        {numberOfPages > 1 && (
          <DashboardTablePagination
            numberOfPages={numberOfPages}
            index={index}
            handleClick={handleClick}
          />
        )}
        <button
          className="Add-Appointments-button-small"
          onClick={() => {
            setAddAppointmentModalOpen(true);
          }}
        >
          +
        </button>
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
