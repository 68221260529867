import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { API } from "../../constants/api";
import axios from "axios";
import RecommendedHospitalCard from "../../components/ProcedurePageComponents/RecommendedHospitalCard/RecommendedHospitalCard";
import "./SimilarHospitalsPage.css";
import SectionHeading from "../../components/ProcedurePageComponents/SectionHeading/SectionHeading";
import LandingPageNavbar from "../../components/LandingPageNavbar/LandingPageNavbar";
import Footer from "../../components/Footer/Footer";
const SimilarHospitalsPage = () => {
  const city = useSearchParams()[0].get("city");
  const hospitalId = useSearchParams()[0].get("hospitalId");
  const [similarHospitals, setSimilarHospitals] = useState([]);
  const getSimilarHospitals = async () => {
    const url = `${API}/hospital/similar/hospitals?city=${city}&hospitalId=${hospitalId}`;
    const {
      data: { hospitals },
    } = await axios.get(url);
    setSimilarHospitals(hospitals);
  };
  useEffect(() => {
    getSimilarHospitals();
  }, []);
  return (
    <>
      {" "}
      <LandingPageNavbar />{" "}
      <div className="similar-hospitals-page">
        <SectionHeading text={"Similar Hospitals"} />
        <div className="similar-hospitals">
          {similarHospitals &&
            similarHospitals.map((hospital) => {
              return (
                <RecommendedHospitalCard
                  key={hospital?.id}
                  hospitalName={hospital?.Name_Of_Hospital}
                  hospital_uid={hospital?.id}
                  facilities={hospital?.facilities}
                  hospitalLocation={`${hospital?.locationDetails?.address?.city} , ${hospital?.locationDetails?.address?.country}`}
                  specialilty={hospital?.speciality}
                />
              );
            })}
        </div>
      </div>{" "}
      <Footer />
    </>
  );
};

export default SimilarHospitalsPage;
