import React from "react";
import ImageSlider from "../../components/ImageSlider/ImageSlider";

const ImageSliderTest = () => {
  return (
    <div>
      <ImageSlider />
    </div>
  );
};

export default ImageSliderTest;
