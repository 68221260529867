import React from "react";
import "./ProcedureDetails.css";
import CountUp from "react-countup";
import { MdNavigateNext } from "react-icons/md";
import priceIcon from "../../../../assets/Procedure/Average Price.svg";
import durationIcon from "../../../../assets/Procedure/Empty Hourglass.svg";
import { ReactComponent as HospitalBedIcon } from "../../../../assets/Procedure/Hospital Room.svg";
import { AiFillDollarCircle } from "react-icons/ai";
import { FaBed } from "react-icons/fa";
const ProcedureDetails = ({
  treatmentName,
  hospitalName,
  hospitalLocation,
  reviews,
  startingAtPrice,
  accreditation,
  currency = "INR",
  stayInHospitalDays,
  stayInCountryDays = 3,
  procedureDuration = "30-50 mins",
  procedureDurationFromSeconds = 1800,
  procedureDurationToSeconds = 3000,
}) => {
  return (
    <section className="procedures-treatment-details">
      <div className="procedures-treatment-details-box1">
        <p className="procedures-treatment-heading-box">
          Procedures
          <MdNavigateNext />
          {treatmentName}
        </p>
        <h1 className="treatment-name-heading">{treatmentName}</h1>
        <h4>
          {hospitalName},{hospitalLocation}
        </h4>
        <div className="procedures-treatment-details-box1-icons">
          {accreditation &&
          // && accreditation.length > 1 && accreditation.map((acc) => {
          accreditation === "NABH" ? (
            <img
              src="https://www.itoozhiayurveda.in/wp-content/uploads/2019/05/NABH-LOGO.png"
              alt=""
            />
          ) : accreditation === "JCI" ? (
            <img
              src="https://www.jointcommissioninternational.org/-/media/jci/images/jci-gold-seal.png"
              alt=""
            />
          ) : accreditation === "NABL" ? (
            <img
              src="https://5.imimg.com/data5/SELLER/Default/2021/1/QO/MW/IE/120792812/nabl-awareness-training-500x500.jpg"
              alt=""
            />
          ) : (
            <></>
          )}
          {/* )} */}
        </div>
        <br />
        <div>
          <CountUp
            startVal={0}
            end={reviews}
            duration={5}
            suffix="+"
            style={{
              width: "60px",
              fontSize: "15px",
              fontWeight: "bold",
            }}
          />{" "}
          reviews
        </div>
      </div>
      <div className="procedures-treatment-details-box2-container">
        <div className="row-1">
          <div className="icon-value-detail">
            <h5 className="starting-at-price">
              <img src={priceIcon} alt="" />
              &#36;{startingAtPrice}
            </h5>
            <small>Procedure starting at</small>
          </div>
          <div className="icon-value-detail">
            <h5>
              <img src={durationIcon} alt="" />{" "}
              {procedureDurationFromSeconds / 60}-
              {procedureDurationToSeconds / 60} mins
            </h5>
            <small>Procedure duration</small>
          </div>
        </div>
        <div className="row-2">
          {" "}
          <div className="icon-value-detail">
            <h5>
              <HospitalBedIcon /> {stayInHospitalDays}{" "}
              {stayInHospitalDays === 1 ? "Day" : "Days"}
            </h5>
            <small>Stay in hospital</small>
          </div>
          <div className="icon-value-detail">
            <h5>
              <img src={priceIcon} alt="" /> {stayInCountryDays}{" "}
              {stayInCountryDays === 1 ? "Day" : "Days"}
            </h5>
            <small>Stay in Country</small>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProcedureDetails;
