import React from "react";
import BOneCharetWhite from "../../assets/images/b1c-caret-white.svg";
import { BOneChatbot } from "./BOneChatbot";

export const BOneChatbotPublic = () => {
  const [ChatbotToggle, setChatbotToggle] = React.useState(false);
  return (
    <div className="b1c-chatbot">
      {!ChatbotToggle && (
        <div
          className="b1c-chatbot-icon"
          onClick={() => setChatbotToggle(true)}
        >
          <img src={BOneCharetWhite} alt="icon" style={{ height: "30px" }} />
        </div>
      )}
      {ChatbotToggle && (
        <BOneChatbot
          closeIcon={true}
          onClickCloseIcon={() => setChatbotToggle(false)}
        />
      )}
    </div>
  );
};
