import React, { useEffect, useState } from "react";
import Loader from "../Loader/Loader";

const DogPicture = () => {
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch("https://dog.ceo/api/breeds/image/random")
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        setImageUrl(data.message);
      });
  }, []);

  if (loading) {
    return <Loader />;
  } else
    return (
      <div style={{ marginLeft: "20px" }}>
        <img src={imageUrl} alt="a dog" style={{ width: "100px" }} />
      </div>
    );
};

export default DogPicture;
