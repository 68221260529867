import React from "react";
import "./ImageUploaderButton.css";
const ImageUploaderButton = ({ text, onChange,isRequired }) => {
  return (
    <div class="upload-btn-wrapper">
      <button class="ImageUploader-btn">{text}</button>
      <input type="file" name="myfile" onChange={onChange} required={isRequired} />
    </div>
  );
};

export default ImageUploaderButton;
