import React from 'react'
import './LeaderTable.css'

const LeaderTableComponent = () => {
  return (
    <section className=" w-100 table-responsive table-wrapper-scroll-y my-custom-scrollbar ">


      <table className="table align-middle mb-0 bg-white">
        <thead>
          <tr>
            <th className="text-muted">Rank</th>
            <th className="text-muted">Agent</th>
            <th className="text-muted">Total revenue</th>

          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p className="text-muted mb-0">1st</p>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <img src="https://mdbootstrap.com/img/new/avatars/8.jpg" alt style={{ width: 45, height: 45 }} className="rounded-circle" />
                <div className="ms-3">
                  <p className="fw-bold mb-1">John Doe</p>
                  <p className="text-muted mb-0">25 Calls</p>
                </div>
              </div>
            </td>
            <td>
              <p className="fw-normal mb-1">2.8L</p>
            </td>
          </tr>


          <tr>
            <td>
              <p className="text-muted mb-0">1st</p>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <img src="https://mdbootstrap.com/img/new/avatars/8.jpg" alt style={{ width: 45, height: 45 }} className="rounded-circle" />
                <div className="ms-3">
                  <p className="fw-bold mb-1">John Doe</p>
                  <p className="text-muted mb-0">20 Calls</p>
                </div>
              </div>
            </td>
            <td>
              <p className="fw-normal mb-1">2.8L</p>
            </td>
          </tr>



          <tr>
            <td>
              <p className="text-muted mb-0">1st</p>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <img src="https://mdbootstrap.com/img/new/avatars/8.jpg" alt style={{ width: 45, height: 45 }} className="rounded-circle" />
                <div className="ms-3">
                  <p className="fw-bold mb-1">John Doe</p>
                  <p className="text-muted mb-0">18 Calls</p>
                </div>
              </div>
            </td>
            <td>
              <p className="fw-normal mb-1">2.8L</p>
            </td>
          </tr>



          <tr>
            <td>
              <p className="text-muted mb-0">1st</p>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <img src="https://mdbootstrap.com/img/new/avatars/8.jpg" alt style={{ width: 45, height: 45 }} className="rounded-circle" />
                <div className="ms-3">
                  <p className="fw-bold mb-1">John Doe</p>
                  <p className="text-muted mb-0">10 Calls</p>
                </div>
              </div>
            </td>
            <td>
              <p className="fw-normal mb-1">2.8L</p>
            </td>
          </tr>


          <tr>
            <td>
              <p className="text-muted mb-0">1st</p>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <img src="https://mdbootstrap.com/img/new/avatars/8.jpg" alt style={{ width: 45, height: 45 }} className="rounded-circle" />
                <div className="ms-3">
                  <p className="fw-bold mb-1">John Doe</p>
                  <p className="text-muted mb-0">10 Calls</p>
                </div>
              </div>
            </td>
            <td>
              <p className="fw-normal mb-1">2.8L</p>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  )
}

export default LeaderTableComponent