import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import "./EditBlog.css";
import "../EditorStyle/EditorStyle.css";
import { InputBox } from "../../ContactUs/ContactUs";
import toast from "react-hot-toast";
import ImageUploaderButton from "../../../components/ImageUploaderButton/ImageUploaderButton";
import { BlogContext } from "../../../context/BlogContextApi";
import { blogRelatedTags } from "../../../constants";
import { API } from "../../../constants/api";
const EditBlog = () => {
  const { title } = useParams();
  const { setBlog, blog } = useContext(BlogContext);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [relatedTags, setRelatedTags] = useState([]);
  const [blogTitle, setBlogTitle] = useState("");
  const [blogId, setBlogId] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [thumbnailPreview, setThumbnailPreview] = useState("");
  const [thumbnailFile, setThumbnailFile] = useState("");
  const [currentThumbnail, setCurrentThumbnail] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [createdAt, setCreatedAt] = useState();
  const editBlog = async () => {
    try {
      let data = convertToRaw(editorState.getCurrentContent());
      let textContent = data.blocks.map((block) => block.text + "\n").join("");
      console.log({ textContent });
      let htmlContent = draftToHtml(data);
      htmlContent = htmlContent.replace(
        /font-family:\s*[^;]*/gi,
        "font-family: Proxima Nova"
      );
      const url = `${API}/blog/update/${blogId}`;
      const {
        data: { message, blog },
      } = await axios.put(url, {
        title: blogTitle,
        relatedTags,
        description: metaDescription,
        htmlContent,
        textContent,
      });
      setBlog(blog);
      return message;
    } catch (err) {
      if (err.response) {
        return err.response.data.message;
      }
      return err.message;
    }
  };
  const editBlogHandler = (e) => {
    e.preventDefault();
    let data = convertToRaw(editorState.getCurrentContent());
    let textContent = data.blocks.map((block) => block.text + "\n").join("");
    console.log({ textContent });
    let htmlContent = draftToHtml(data);
    htmlContent = htmlContent.replace(
      /font-family:\s*[^;]*/gi,
      "font-family: Proxima Nova"
    );
    setBlog({
      title: blogTitle,
      relatedTags,
      description: metaDescription,
      htmlContent,
      textContent,
      thumbnail: thumbnailPreview ? thumbnailPreview : currentThumbnail,
      thumbnailFile,
      createdAt,
      id: blogId,
    });
  };

  const changeThumbnailHandler = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setThumbnailPreview("");
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setThumbnailFile(file);
      setThumbnailPreview(reader.result);
    };
  };
  const getBlogDetails = async () => {
    try {
      const url = `${API}/blog/${title}`;
      const {
        data: { success, blog },
      } = await axios.get(url);
      if (success) {
        const blocksFromHtml = convertFromHTML(blog.htmlContent);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        setEditorState(EditorState.createWithContent(contentState));
        setCurrentThumbnail(blog.thumbnail);
        setBlogTitle(blog.title);
        setImageUrl(blog.thumbnail);
        setMetaDescription(blog.description);
        setCreatedAt(blog.createdAt);
        setRelatedTags(JSON.parse(blog.relatedTags));
        setBlogId(blog.id);
        console.log({ blog });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const autoCompleteChangeHandler = (e, data) => {
    console.log(data);
    setRelatedTags(data);
  };
  function editorStateChangeHandler(state) {
    setEditorState(state);
  }
  useEffect(() => {
    setBlog({});
    getBlogDetails();
  }, []);
  return (
    <div className="edit-blog-page">
      <h4 className="portfolio-hospitals-admin-console-heading">
        Upadate And Preview Blog
      </h4>
      <div className="edit-blog-inputs-and-editor">
        {" "}
        <div className="edit-blog-page-blog-details">
          <form onSubmit={editBlogHandler}>
            <div className="blog-input-box">
              <p>Title</p>
              <input
                type="text"
                name="title"
                value={blogTitle}
                onChange={(e) => setBlogTitle(e.target.value)}
              />
            </div>
            <div className="blog-input-box">
              <p>Meta Description</p>
              <input
                type="text"
                name="title"
                value={metaDescription}
                onChange={(e) => setMetaDescription(e.target.value)}
              />
            </div>
            <div className="blog-input-box">
              <p>Meta Tags</p>
              <Autocomplete
                multiple
                id="tags-outlined"
                options={blogRelatedTags}
                getOptionLabel={(option) => option}
                value={relatedTags}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Tags"
                    placeholder="Related"
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={index}
                      variant="filled"
                      label={option}
                      {...getTagProps({ index })}
                      style={{ margin: "4px" }}
                    />
                  ))
                }
                onChange={autoCompleteChangeHandler}
                style={{ width: "100%", marginTop: "16px" }}
              />
            </div>
            {thumbnailPreview ? (
              <div className="edit-blog-thumbnail-preview-box">
                <img src={thumbnailPreview && thumbnailPreview} alt={""} />{" "}
              </div>
            ) : (
              <div className="edit-blog-thumbnail-preview-box">
                <img src={currentThumbnail && currentThumbnail} alt={""} />{" "}
              </div>
            )}
            <ImageUploaderButton
              text={"CHOOSE"}
              onChange={changeThumbnailHandler}
              isRequired={false}
            />
            <div className="edit-blog-button-box">
              <button type="submit" className="edit-blog-button">
                Update
              </button>
            </div>
          </form>
          {blog?.title && (
            <Link to={`/preview/blog/${title}`}>
              <button className="edit-blog-button">Preview</button>
            </Link>
          )}
        </div>
        <div>
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClass"
            wrapperClassName="wrapperClass"
            editorClassName="editorClass"
            onEditorStateChange={editorStateChangeHandler}
          />
        </div>
      </div>

      {/* <AllBlogs setEditorState={setEditorState} /> */}
    </div>
  );
};

export default EditBlog;
