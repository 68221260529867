//Hospitals Icons
import medanta from "./assets/images/medanta.svg";
import apollo from "./assets/images/apollo.svg";
import artemis from "./assets/images/artimes.svg";
import fortis from "./assets/images/fortis.svg";
import manipal from "./assets/images/manipal.svg";
import maxHealthCare from "./assets/images/max-health-care.svg";
import { FaCartShopping, FaHotel, FaInternetExplorer } from "react-icons/fa6";
import { IoBedSharp } from "react-icons/io5";
//Features Icons
import worldIcon from "./assets/images/world-icon.svg";
import personDoneIcon from "./assets/images/person-done-icon.svg";
import percentIcon from "./assets/images/percent-icon.svg";
import advanceTech from "./assets/images/features/Group 22.svg";
import travelVisa from "./assets/images/features/icon _airplane_.svg";
import healthcareExp from "./assets/images/features/icon _healthcare_.svg";
import yoga from "./assets/images/features/icon _yoga_.svg";
import servant from "./assets/images/features/Servant.svg";
import treatment from "./assets/images/features/feature-treatment.svg";

//Services Icons
import hospitalServiceIcon from "./assets/images/serviceSvgs/OBJECTS.svg";
import partnerServiceIcon from "./assets/images/serviceSvgs/Group.svg";
import conveyIdeasIcon from "./assets/images/serviceSvgs/Group 6243.svg";
//Health Care Easy Simple Steps Icons
import searchIcon from "./assets/images/search.svg";
import sendIcon from "./assets/images/send.svg";
import checkCircleIcon from "./assets/images/check-circle.svg";
import selectIcon from "./assets/images/select.svg";
//Blogs Images
import blog1 from "./assets/images/blogs/blog1.svg";
import blog2 from "./assets/images/blogs/blog2.svg";

// ACCREDITATION LOGO SVGS;
import JCI from "./assets/Accreditation/JCI.svg";
import ISO from "./assets/Accreditation/ISO.svg";
import NABH from "./assets/Accreditation/NABH.svg";
//Facilities Icons
import Visa from "./assets/facilitiesIcons/Visa.svg";
import CallMale from "./assets/facilitiesIcons/Call male.svg";
import Coordination from "./assets/facilitiesIcons/Coordinate System.svg";
import Shedule from "./assets/facilitiesIcons/Schedule.svg";
import Translator from "./assets/facilitiesIcons/Translator.svg";
import Accommodation from "./assets/facilitiesIcons/Bedroom.svg";
export const hospitalNamesAndIcons = [
  {
    hospitalName: "Medanta",
    icon: medanta,
  },
  {
    hospitalName: "Fortis",
    icon: fortis,
  },
  {
    hospitalName: "Artemis",
    icon: artemis,
  },
  {
    hospitalName: "Apollo",
    icon: apollo,
  },
  {
    hospitalName: "Manipal",
    icon: manipal,
  },
  {
    hospitalName: "Max Health Care",
    icon: maxHealthCare,
  },
];
export const hospitalArray = [
  [
    {
      hospitalName: "Medanta",
      icon: medanta,
    },
    {
      hospitalName: "Fortis",
      icon: fortis,
    },
    {
      hospitalName: "Artemis",
      icon: artemis,
    },
    {
      hospitalName: "Apollo",
      icon: apollo,
    },
    {
      hospitalName: "Manipal",
      icon: manipal,
    },
    {
      hospitalName: "Max Health Care",
      icon: maxHealthCare,
    },
  ],
  [
    {
      hospitalName: "Medanta",
      icon: medanta,
    },
    {
      hospitalName: "Fortis",
      icon: fortis,
    },
    {
      hospitalName: "Artemis",
      icon: artemis,
    },
    {
      hospitalName: "Apollo",
      icon: apollo,
    },
    {
      hospitalName: "Manipal",
      icon: manipal,
    },
    {
      hospitalName: "Max Health Care",
      icon: maxHealthCare,
    },
  ],
  [
    {
      hospitalName: "Medanta",
      icon: medanta,
    },
    {
      hospitalName: "Fortis",
      icon: fortis,
    },
    {
      hospitalName: "Artemis",
      icon: artemis,
    },
    {
      hospitalName: "Apollo",
      icon: apollo,
    },
    {
      hospitalName: "Manipal",
      icon: manipal,
    },
    {
      hospitalName: "Max Health Care",
      icon: maxHealthCare,
    },
  ],
];
export const smallScreenHospitalArray = [
  [
    {
      hospitalName: "Medanta",
      icon: medanta,
    },
    {
      hospitalName: "Fortis",
      icon: fortis,
    },
  ],
  [
    {
      hospitalName: "Artemis",
      icon: artemis,
    },
    {
      hospitalName: "Apollo",
      icon: apollo,
    },
  ],
  [
    {
      hospitalName: "Max Health Care",
      icon: maxHealthCare,
    },
    {
      hospitalName: "Manipal",
      icon: manipal,
    },
  ],
];

export const featureList = [
  [
    {
      name: "World-Class Healthcare",
      description:
        "Access top-tier medical facilities in India, renowned for their advanced treatments.",
      icon: worldIcon,
    },
    {
      name: "Skilled Doctors and Staff",
      description:
        "Expert care from highly qualified doctors and medical staff who prioritize your well-being.",
      icon: personDoneIcon,
    },
    {
      name: "Cost-Effective",
      description:
        "Medical solutions that offer high-quality treatment options without breaking the bank",
      icon: percentIcon,
    },
  ],
  [
    {
      name: "Advanced Technology",
      description:
        "India embraces cutting-edge medical technology and procedures, ensuring the best possible outcomes for patients.",
      icon: advanceTech,
    },
    {
      name: "Wide Range of Treatments",
      description:
        "India offers a comprehensive range of medical treatments, covering various specialties and complex procedures.",
      icon: treatment,
    },
    {
      name: "Ease of Travel and Visa Procedures",
      description:
        "The Indian government has streamlined visa processes for medical tourists , making travel convenient and hassle- free.",
      icon: travelVisa,
    },
  ],
  [
    {
      name: "Holistic Healthcare Experience",
      description:
        "Beyond medical treatment, India offers a holistic experience with traditional therapies like Ayurveda, Yoga, and meditation, promoting overall well-being.",
      icon: yoga,
    },
    {
      name: "Modern Infrastructure",
      description:
        "The country has invested significantly in modern healthcare infrastructure, ensuring top-notch facilities for patients.",
      icon: healthcareExp,
    },
    {
      name: "Supportive Tourism Industry",
      description:
        "India's thriving tourism industry provides ample support for medical tourists,offering accommodation,transportation,and sightseeing options.",
      icon: servant,
    },
  ],
];

export const serviceList = [
  {
    category: "For Patients",
    icon: partnerServiceIcon,
    services: [
      {
        title: "Seamless Registration",
        description:
          "User-friendly registration process,making it easy to create an account and access our services.",
      },
      {
        title: "Treatment Guidance",
        description:
          "Explore various treatment options available in India, along with detailed information about hospitals and healthcare providers.",
      },
      {
        title: "Personalised Care",
        description:
          "We provide personalized assistance throughout the journey, addressing individual needs and concerns.",
      },
    ],
    actionName: "Get a Free Quote",
  },
  {
    category: "For Hospitals",
    icon: hospitalServiceIcon,
    services: [
      {
        title: "Efficient Workflow",
        description:
          "Our plateform facilitates smooth communication and collaboration  between medical tourism facilitators and hospitals,optimizing patient's journey.",
      },
      {
        title: "Digital Documentation",
        description:
          "Streamlining paper work and documentation, we ensure that the necessary information is easily accessible for all stackeholders.",
      },
      {
        title: "Appointment Management",
        description:
          "Simplified appointment scheduling to avoid any delay or confusion.",
      },
    ],
    actionName: "Join Our Healthcare Network",
  },
  {
    category: "For Partners",
    icon: conveyIdeasIcon,
    services: [
      {
        title: "Comprehensive Partner",
        description:
          "User-friendly registration process,making it easy to create an account and access our services.",
      },
      {
        title: "Transparent Communication",
        description:
          "Real-time updates and transparent communication channels help medical tourism facilitators stay informed about their patient's healthcare journey.",
      },
      {
        title: "Collaborative Workflow",
        description:
          "The streamlined workflow optimizes the patenit's overall experience and ensures that the necessary information is accessible to all relevant stakeholders.",
      },
    ],
    actionName: "Partner with Us",
  },
];
export const simpleSteps = [
  {
    stepIcon: searchIcon,
    stepName: "Search",
    stepDescription: "Search procedure and Hospital",
  },
  {
    stepIcon: selectIcon,
    stepName: "Select",
    stepDescription: "Choose your Ideal treatment",
  },
  {
    stepIcon: checkCircleIcon,
    stepName: "Book",
    stepDescription: "Book your Procedure",
  },
  {
    stepIcon: sendIcon,
    stepName: "Flyyy",
    stepDescription: "Relax, we’ll take care of the rest!",
  },
];
export const userTypes = ["Partner", "Patient", "Hospital"];

export const blogs = [
  {
    title: "Why India is the heartbeat of healing ?",
    image: blog1,
    description:
      "World-Class Healthcare: India boasts top-tier medical facilities and well-qualified healthcare professionals, providing world-class treatments at affordable prices. Advanced Technology: India embraces cutting-edge medical...",
  },
  {
    title: "Why India is the heartbeat of healing ?",
    image: blog2,
    description:
      "World-Class Healthcare: India boasts top-tier medical facilities and well-qualified healthcare professionals, providing world-class treatments at affordable prices. Advanced Technology: India embraces cutting-edge medical...",
  },
  {
    title: "Why India is the heartbeat of healing ?",
    image: blog1,
    description:
      "World-Class Healthcare: India boasts top-tier medical facilities and well-qualified healthcare professionals, providing world-class treatments at affordable prices. Advanced Technology: India embraces cutting-edge medical...",
  },
];

export const blogRelatedTags = [
  "advance facilities",
  "affordability",
  "cultural experience",
  "destination healthcare",
];
export const procedurePageProgressBarOptions = [
  {
    optionName: "About",
    linkToSection: "#about",
  },
  {
    optionName: "Top Doctors",
    linkToSection: "#top-doctors",
  },
  {
    optionName: "Patient Reviews",
    linkToSection: "#patient-reviews",
  },
  {
    optionName: "Recommended Hospitals",
    linkToSection: "#recommended-hospitals",
  },
  {
    optionName: "FAQs",
    linkToSection: "#procedure-faqs",
  },
];
export const hospitalDetailsPageProgressBarOptions = [
  {
    optionName: "About",
    linkToSection: "#hospital-details-about",
  },
  {
    optionName: "Procedures",
    linkToSection: "#hospital-details-procedures",
  },
  {
    optionName: "Infrastructure",
    linkToSection: "#hospital-details-infrastructure",
  },
  {
    optionName: "Facilities",
    linkToSection: "#hospital-details-facilities",
  },
  {
    optionName: "Doctors",
    linkToSection: "#top-doctors",
  },
  {
    optionName: "Hotels",
    linkToSection: "#hospital-details-hotels",
  },
  {
    optionName: "Hospitals",
    linkToSection: "#hospital-details-similar-hospitals",
  },
  {
    optionName: "Patients stories",
    linkToSection: "#procedures",
  },
];
export const accordionsList = [
  {
    summary: "Overview",
    key: "about",
    details: "No data available",
  },
  {
    summary: "Symptoms",
    key: "symptoms",
    details: "No data available",
  },
  {
    summary: "Diagnosis",
    key: "diagnosis",
    details: "No data available",
  },
  {
    summary: "Risk Factors",
    key: "risk_factors",
    details: "No data available",
  },
  {
    summary: "Cost Factors",
    key: "cost_factors",
    details: "No data available",
  },
  {
    summary: "Post-Operative Care",
    key: "post_procedure_care",
    details: "No data available",
  },
];
export const procedureFAQs = [
  {
    summary: "1 What is Chemotheraphy? How does it work? ",
  },
  {
    summary: "2 What is Chemotheraphy? How does it work? ",
  },
  {
    summary: "3 What is Chemotheraphy? How does it work? ",
  },
  {
    summary: "4 What is Chemotheraphy? How does it work? ",
  },
];
export const accreditationLogos = {
  ISO,
  NABH,
  NABL: null,
  JCI,
};
export const hospitalNearbyResourcesLogos = {
  Hotels: <FaHotel />,
  Food: <FaCartShopping />,
};
export const hospitalFacilitiesLogos = {
  Accommodation: Accommodation,
  Interpreter: Translator,
  "Follow up": Shedule,
  Coordination: Coordination,
  Visa: Visa,
  "Dedicated Support": CallMale,
};
export const bookingStatus = [
  {
    statusName: "Preliminary call",
    statusColor: "#60B3DD",
  },
  {
    statusName: "Requested quote",
    statusColor: "#60B3DD",
  },
  {
    statusName: "Shared quote",
    statusColor: "#6938EF",
  },
  {
    statusName: "Follow up",
    statusColor: "#DC6803",
  },
  {
    statusName: "Treatment finalised",
    statusColor: "#DC6803",
  },
  {
    statusName: "Visa",
    statusColor: "#DC6803",
  },
  {
    statusName: "Travel",
    statusColor: "#60B3DD",
  },
  {
    statusName: "Accommodation",
    statusColor: "#60B3DD",
  },
  {
    statusName: "Medical Investigation",
    statusColor: "#60B3DD",
  },
  {
    statusName: "Treatment Planning",
    statusColor: "#60B3DD",
  },
  {
    statusName: "Treatment In Progress",
    statusColor: "#60B3DD",
  },
  {
    statusName: "Treatment Completed",
    statusColor: "#42B140",
  },
];
