import React from "react";
import "./BookingFilterModel.css";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import FilterMultiSelect from "../InputTypes/FilterMultiSelect/FilterMultiSelect";
import { FormControl } from "@mui/material";
import { Select } from "@mui/material";
import { InputLabel } from "@mui/material";
import { MenuItem } from "@mui/material";
import { worldCountries } from "../../assets/custom-data/worldCountries";
import { indianCities } from "../../assets/custom-data/indianCities";

const BookingFilterModel = (props) => {

  const medicalConditions = [
    "Diabetes",
    "Hypertension",
    "Cancer",
    "Heart Disease",
    "Kidney Disease",
    "Covid",
    "Liver Disease",
    "Dialysis",
    "COPD",
    "Asthma",
    "Cerebrovascular Disease",
    "Chronic Kidney Disease",
  ]

  const preferredHospitals = [
    "Apollo",
    "Bharat",
    "Cipla",
    "Fortis",
    "Max",
    "Medi",
    "Niramaya",
    "Piramal",
    "Sahara",
    "Sri",
    "Surya",
    "United",
    "Vijaya",
    "Vishwa",
  ]

  return (
    <div className="model-container">
      <div className="patient-names-section patient-filter-section">
        <p className="model-section-title">Name</p>
        <TextField
          id="outlined-basic"
          label=""
          placeholder="Search Name"
          variant="outlined"
          size="small"
          InputProps={{
            style: {
              borderRadius: "0",
              // width: "27vw",
            },
          }}
          value={props.filterPatientName}
          onChange={(e) => {
            props.handlePatientFilterChange(e.target.value, "name");
          }}
          sx={{
            width: {
              xs: "95vw",
              sm: "50vw",
              md: "35vw",
              lg: "28vw",
              xl: "28vw",
            },
            paddingLeft: "1rem",
            // marginTop: "-0.625rem",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "black", // Default border color
              },
              "&:hover fieldset": {
                borderColor: "#EF5EA2", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "#EF5EA2", // Border color when focused
              },
            },
          }}
        />
      </div>
      <div className="patient-gender-section patient-filter-section">
        <p className="model-section-title">Gender</p>
        <div className="status-chip-container">
          <Chip
            sx={{
              // width: "5vw",
              borderColor: props.selectedFilters.gender.includes("Male")
                ? "transparent"
                : "#787785",
              color: props.selectedFilters.gender.includes("Male")
                ? "white"
                : "black",
              backgroundColor: props.selectedFilters.gender.includes("Male")
                ? "var(--primaryColor)"
                : "transparent",
              "&:hover": {
                backgroundColor: "var(--primaryColor)",
                color: "black",
              },
            }}
            label="Male"
            variant="outlined"
            onClick={() => props.toggleChip("Male", "gender")}
          />
          <Chip
            sx={{
              // width: "5vw",
              borderColor: props.selectedFilters.gender.includes("Female")
                ? "transparent"
                : "#787785",
              color: props.selectedFilters.gender.includes("Female")
                ? "white"
                : "black",
              backgroundColor: props.selectedFilters.gender.includes("Female")
                ? "var(--primaryColor)"
                : "transparent",
              "&:hover": {
                backgroundColor: "var(--primaryColor)",
                color: "black",
              },
            }}
            label="Female"
            variant="outlined"
            onClick={() => props.toggleChip("Female", "gender")}
          />
        </div>
      </div>
      <div className="patient-country-section patient-filter-section">
        <p className="model-section-title">Patient's Country</p>
        <FilterMultiSelect
          selectedFilters={props.selectedFilters}
          onSelect={props.toggleChip}
          value = {props.selectedFilters.patientCountry}
          size="small"
          options={worldCountries}
          category = "patientCountry"
        />
      </div>
      <div className="patient-medical-condition-section patient-filter-section">
        <p className="model-section-title">Medical Condition</p>
        <FilterMultiSelect
          selectedFilters={props.selectedFilters}
          onSelect={props.toggleChip}
          value = {props.selectedFilters.medicalCondition}
          size="small"
          options={medicalConditions}
          category = "medicalCondition"
        />
        {/* <select
          className='patient-filter-section-select'
          // defaultValue=""
          value={props.filterPatientMedicalCondition}
          onChange={(e) => {
            props.handlePatientFilterChange(e.target.value, "medicalCondition");
          }}
        >
          <option className='greyColor' value='' disabled>
            Search
          </option>
          <option value='Heart Transplant'>Heart Transplant</option>
          <option value='Kidney Stones'>Kidney Stones</option>
          <option value='Brain Surgery'>Brain Surgery</option>
          <option value='Lung Cancer'>Lung Cancer</option>
          <option value='Prostate Cancer'>Prostate Cancer</option>
        </select> */}
      </div>
      <div className="patient-preferred-hospital-section patient-filter-section">
        <p className="model-section-title">Preferred Hospital</p>
        <FilterMultiSelect
          selectedFilters={props.selectedFilters}
          onSelect={props.toggleChip}
          value = {props.selectedFilters.preferredHospital}
          size="small"
          options={preferredHospitals}
          category = "preferredHospital"
        />
        {/* <select
          className='patient-filter-section-select'
          // defaultValue=""
          value={props.filterPatientPreferredHospital}
          onChange={(e) => {
            props.handlePatientFilterChange(
              e.target.value,
              "preferredHospital"
            );
          }}
        >
          <option value='' disabled>
            Enter Hospital Name
          </option>
          <option value='Max Hospital'>Max Hospital</option>
          <option value='Amrita Hospital'>Amrita Hospital</option>
          <option value='Apollo Hospital'>Apollo Hospital</option>
          <option value='AIIMS Hospital'>AIIMS Hospital</option>
          <option value='Prostate Cancer'>Prostate Cancer</option>
        </select> */}
      </div>
      <div className="patient-hospital-location-section patient-filter-section">
        <p className="model-section-title">Hospital Location</p>
        <FilterMultiSelect
          selectedFilters={props.selectedFilters}
          onSelect={props.toggleChip}
          size="small"
          value = {props.selectedFilters.hospitalLocation}
          options={indianCities}
          category = "hospitalLocation"
        />
        {/* <select
          className='patient-filter-section-select'
          // defaultValue=""
          value={props.filterPatientHospitalLocation}
          onChange={(e) => {
            props.handlePatientFilterChange(e.target.value, "hospitalLocation");
          }}
        >
          <option value='' disabled>
            Search Location
          </option>
          {indianCities.map((city) => {
            return <option value={city}>{city}</option>;
          })}
        </select> */}
      </div>
      <div className="patient-visa-assistance-section patient-filter-section">
        <p className="model-section-title">Visa Assistance</p>
        <div className="status-chip-container">
          <Chip
            sx={{
              // width: "5vw",
              borderColor:
                props.filterPatientVisaAssistance === "Yes"
                  ? "transparent"
                  : "#787785",
              color:
                props.filterPatientVisaAssistance === "Yes" ? "white" : "black",
              backgroundColor:
                props.filterPatientVisaAssistance === "Yes"
                  ? "var(--primaryColor)"
                  : "transparent",
              "&:hover": {
                backgroundColor: "var(--primaryColor)",
                color: "black",
              },
            }}
            label="Yes"
            variant="outlined"
            onClick={() =>
              props.handlePatientFilterChange("Yes", "visaAssistance")
            }
          />
          <Chip
            sx={{
              // width: "5vw",
              borderColor:
                props.filterPatientVisaAssistance === "No"
                  ? "transparent"
                  : "#787785",
              color:
                props.filterPatientVisaAssistance === "No" ? "white" : "black",
              backgroundColor:
                props.filterPatientVisaAssistance === "No"
                  ? "var(--primaryColor)"
                  : "transparent",
              "&:hover": {
                backgroundColor: "var(--primaryColor)",
                color: "black",
              },
            }}
            label="No"
            variant="outlined"
            onClick={() =>
              props.handlePatientFilterChange("No", "visaAssistance")
            }
          />
        </div>
      </div>
      <div className="patient-airport-transport-assistance-section patient-filter-section">
        <p className="model-section-title">Airport Transfers Assistance</p>
        <div className="status-chip-container">
          <Chip
            sx={{
              // width: "5vw",
              borderColor:
                props.filterPatientAirportTransfersAssistance === "Yes"
                  ? "transparent"
                  : "#787785",
              color:
                props.filterPatientAirportTransfersAssistance === "Yes"
                  ? "white"
                  : "black",
              backgroundColor:
                props.filterPatientAirportTransfersAssistance === "Yes"
                  ? "var(--primaryColor)"
                  : "transparent",
              "&:hover": {
                backgroundColor: "var(--primaryColor)",
                color: "black",
              },
            }}
            label="Yes"
            variant="outlined"
            onClick={() =>
              props.handlePatientFilterChange(
                "Yes",
                "airportTransfersAssistance"
              )
            }
          />
          <Chip
            sx={{
              // width: "5vw",
              borderColor:
                props.filterPatientAirportTransfersAssistance === "No"
                  ? "transparent"
                  : "#787785",
              color:
                props.filterPatientAirportTransfersAssistance === "No"
                  ? "white"
                  : "black",
              backgroundColor:
                props.filterPatientAirportTransfersAssistance === "No"
                  ? "var(--primaryColor)"
                  : "transparent",
              "&:hover": {
                backgroundColor: "var(--primaryColor)",
                color: "black",
              },
            }}
            label="No"
            variant="outlined"
            onClick={() =>
              props.handlePatientFilterChange(
                "No",
                "airportTransfersAssistance"
              )
            }
          />
        </div>
      </div>
      <div className="patient-accommodation-assistance-section patient-filter-section">
        <p className="model-section-title">Accommodation Assistance</p>
        <div className="status-chip-container">
          <Chip
            sx={{
              // width: "5vw",
              borderColor:
                props.filterPatientAccommodationAssistance === "Yes"
                  ? "transparent"
                  : "#787785",
              color:
                props.filterPatientAccommodationAssistance === "Yes"
                  ? "white"
                  : "black",
              backgroundColor:
                props.filterPatientAccommodationAssistance === "Yes"
                  ? "var(--primaryColor)"
                  : "transparent",
              "&:hover": {
                backgroundColor: "var(--primaryColor)",
                color: "black",
              },
            }}
            label="Yes"
            variant="outlined"
            onClick={() =>
              props.handlePatientFilterChange("Yes", "accommodationAssistance")
            }
          />
          <Chip
            sx={{
              // width: "5vw",
              borderColor:
                props.filterPatientAccommodationAssistance === "No"
                  ? "transparent"
                  : "#787785",
              color:
                props.filterPatientAccommodationAssistance === "No"
                  ? "white"
                  : "black",
              backgroundColor:
                props.filterPatientAccommodationAssistance === "No"
                  ? "var(--primaryColor)"
                  : "transparent",
              "&:hover": {
                backgroundColor: "var(--primaryColor)",
                color: "black",
              },
            }}
            label="No"
            variant="outlined"
            onClick={() =>
              props.handlePatientFilterChange("No", "accommodationAssistance")
            }
          />
        </div>
      </div>
      {props.filterPatientAccommodationAssistance === "Yes" && (
        <div className="patient-accommodation-location-section patient-filter-section">
          <p className="model-section-title">Accommodation Location</p>
          <FilterMultiSelect
            selectedFilters={props.selectedFilters}
            onSelect={props.toggleChip}
            value = {props.selectedFilters.accommodationLocation}
            size="small"
            options={indianCities}
            category="accommodationLocation"
          />
          {/* <select
            className='patient-filter-section-select'
            // defaultValue=""
            value={props.filterPatientAccommodationLocation}
            onChange={(e) => {
              props.handlePatientFilterChange(
                e.target.value,
                "accommodationLocation"
              );
            }}
          >
            <option value='' disabled>
              Search Location
            </option>
            {indianCities.map((city) => {
              return <option value={city}>{city}</option>;
            })}
          </select> */}
        </div>
      )}
    </div>
  );
};

export default BookingFilterModel;
