import React, { useState, useEffect } from "react";
import "./PatientOverview.css";
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

const PatientOverview = (props) => {
  console.log("Formdata: ", props.formData);
  console.log("Medical_History :::", props.formData.Photo.value);

  const [images, setImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const imgArray = [
    props.formData.Photo.value,
    props.formData.Passport.value,
    props.formData.Visa.value,
    props.formData.Medical_History.value,
  ];

  useEffect(() => {
    const fileArray = Array.from(imgArray)
      .slice(0, 4)
      .map((file) => URL.createObjectURL(file));
    setImages(fileArray);
  }, []);


  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  console.log("Images: ", images);

  return (
    <div className="patient-overview-container">
      <div className="patient-upload-documents patient-overview-margin-top">
        <h2 className="patient-overview-header">Uploaded Documents</h2>
        <div className="patient-overview-carousel-container">
          <ChevronLeft onClick={handlePrev} className="patient-overview-chevron-icon" />
          <div className="patient-overview-image-container">
            {images && (
              <img
                className="patient-overview-image"
                src={images[currentImageIndex]}
                alt="Medical History"
                key={currentImageIndex}
              />
            )}
          </div>
          <ChevronRight onClick={handleNext} className="patient-overview-chevron-icon" />
        </div>
      </div>
    </div>
  );
};

export default PatientOverview;
