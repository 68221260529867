import React from "react";
import "./PartnerCardComponent.css";
import hospitalPartner from "../../assets/images/hospitalPartner.png";
import userPartner from "../../assets/images/userPartner.png";
import diagnostic from "../../assets/images/diagnostic.png";

const PartnerCardComponent = (props) => {
  return (
    <div className="partner-card-container">
      <div
        className={
          props.title === `${props.partner}`
            ? "partner-card-active"
            : "partner-card"
        }
        onClick={() => props.handlePartnerClick(props.title)}
      >
        <img
          src={
            props.illustration === "hospitalPartner"
              ? hospitalPartner
              : props.illustration === "diagnostic"
              ? diagnostic
              : userPartner
          }
          alt={props.illustration}
        ></img>
      </div>
      <h2
        className={
          props.title === `${props.partner}`
            ? "partner-card-title-active"
            : "partner-card-title"
        }
      >
        {props.title}
      </h2>
    </div>
  );
};

export default PartnerCardComponent;
