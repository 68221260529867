import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
// import { AddAppointmentsColumnConfigMain } from "../../constants/AddAppointmentsTableConfig";
import {
  suprvisorTableConfig,
  dummySupervisorTableData,
} from "../../constants/SupervisorTableConfig";
// import "./WelcomeStatus.css";
import DashboardLayout from "../DashboardSidebar/DashboardLayout";
import buildingPink from "../../assets/DashboardAssets/buildingPink.svg";
import diffGreen from "../../assets/DashboardAssets/diffGreen.svg";
import DashboardTable from "../../components/DashboardTable/DashboardTable.jsx";
import DashboardTablePagination from "../../components/DashboardTablePagination/DashboardTablePagination.jsx";
import { Link } from "react-router-dom";
import StatusCrad from "../../components/StatsCard/StatsCard";
import DashboardChartsContainer from "../../components/DashboardChartsContainer/DashboardChartsContainer";
import {
  agentTableConfig,
  dummyagentTableData,
} from "../../constants/AgentTableConfig";
import indianStatesData from "../../assets/custom-data/data";
import { indianCities } from "../../assets/custom-data/indianCities";
import rightTick from "../../assets/DashboardAssets/rightTick.svg";
import { sidebarFacilitatorItems } from "../../constants/SidebarItems";
import { useUserAuth } from "../../context/UserAuthContext";
// import { API } from "../../constants/api";

const SupervisorDashboard = () => {
  ///////////////////////// Dashboard Charts Container Data Start///////////////////////////////

  const [performanceData, setPerformanceData] = useState({
    labels: indianStatesData.map((state) => state.state),
    datasets: [
      {
        label: "Appointments Booked",
        data: indianStatesData.map((state) => state.totalHospitals),
        backgroundColor: ["#2D416B"],
        barPercentage: 0.5,
      },
    ],
  });

  const [topHospitalData, setTopHospitalData] = useState({
    // labels: indianStatesData.map((state) => state.state),
    labels: [
      "Apollo Indraprastha",
      "Fortis Malar Hospital",
      "Billroth Hospital",
      "Vijay Hospital",
      "Prashant Hospital",
    ],
    datasets: [
      {
        label: "No. of Beds",
        data: indianStatesData.map((state) => state.totalHospitals),
        backgroundColor: ["#2D416B"],
        barPercentage: 1,
        categoryPercentage: 0.6,
      },
    ],
  });

  const hospitalPerformanceValues = {
    title: "Hospital Performance",
    hospital: [
      "Apollo Hospital, Chennai",
      "Apollo Hospital, Delhi",
      "Apollo Hospital, Faridabad",
      "Max Hospital, Delhi",
    ],
    sortBy: ["Monthly", "Quarterly", "Yearly"],
  };

  const topHospitalValues = {
    title: "Top 5 Hospitals",
    city: indianCities,
  };

  ///////////////////////// Dashboard Charts Container Data End///////////////////////////////

  const tableData = dummySupervisorTableData;

  const [index, setIndex] = useState(0);
  const size = Math.min(tableData.length, 5);
  const updatedRows = tableData.slice(size * index + 0, size * index + size);
  const { user, logOut } = useUserAuth();
  const handleClick = (ind) => {
    setIndex(ind);
  };
  const numberOfPages = Math.ceil(tableData.length / size);

  const title = "All hospital";
  const number = 50;
  const description = "vs last month";

  const arr = [1, 2];

  return (
    <DashboardLayout sidebarItems={sidebarFacilitatorItems}>
      <div className="row m-0 left-text-box px-4 mt-4">
        <div className="col welcome-box  d-flex flex-column">
          <div className="welcome-text d-flex ">
            <h1 className="fw-bold mb-0 text-start">
              Welcome back, {user?.displayName}
            </h1>
          </div>
          <div className="muted-text d-flex ">
            <p className="text-muted mb-2 text-start">
              Track and manage your hospital on boarding and oppointment
            </p>
          </div>
        </div>
      </div>

      <div className="row m-0 status-card px-md-5 px-2 mt-2 p-3">
        <div className="col-6 m-0 p-0">
          <div className="row m-0 p-0">
            {arr.map((item) => {
              return (
                <StatusCrad
                  status={rightTick}
                  icon={buildingPink}
                  number={number}
                  title={title}
                  difference={diffGreen}
                  description={description}
                />
              );
            })}
          </div>
        </div>
        <div className="col-6 m-0 p-0">
          <div className="row m-0 p-0">
            {arr.map((item) => {
              return (
                <StatusCrad
                  status={rightTick}
                  icon={buildingPink}
                  number={number}
                  title={title}
                  difference={diffGreen}
                  description={description}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className="row m-0 suggestion-box px-5 mt-2">
        <div className="col-12 suggestion-content border">
          <div className="row m-0">
            <div className="suggest-text-up d-flex  p-2">
              <h2 className="text-start mb-0">Suggestion</h2>
            </div>
          </div>
          <div className="row m-0">
            <div className="col suggest-text-bottom d-flex px-2 mb-2  align-items-center">
              <span
                className="d-inline-block text-truncate text-start m-0 "
                style={{ maxWidth: "85%" }}
              >
                8 hospitals recently stopped being active, contact the hospitals
                to retain them. In this article, find the script to Lorem ipsum
                dolor sit amet consectetur adipisicing elit. Ipsam harum
                voluptatem omnis repudiandae ex vitae iusto vel placeat facere
                minima exercitationem velit nemo aspernatur est nobis, quaerat,
                voluptatum esse sapiente voluptas ullam enim? Nemo.
              </span>
              <div className="link-box d-flex ms-sm-4">
                <p className="m-0 fs-lg-5 fs-6">
                  <Link to className="link-to-article">
                    View this article →
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row m-0 my-5 table-booking-box px-md-5 px-2">
        <div
          className="col p-0"
          style={{
            border: "1px solid gray",
            borderRadius: "12px",
          }}
        >
          <div
            className="row mx-0 upcoming-main-box px-md-5 px-2 "
            style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
          >
            <div className="col upcoming-box  d-flex mx-3">
              <div style={{ marginBottom: "30px" }}>
                <p
                  className="text-start mb-0"
                  style={{
                    fontWeight: "550",
                    fontSize: "25px",
                  }}
                >
                  Upcoming Appointments
                </p>
              </div>
              <div className="link-box d-flex ms-sm-4 mt-2 ">
                <p className="m-0 fs-8">
                  <Link to className="link-to-article">
                    View all →
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <div className="row m-0  mb-0 px-0">
            <div className="col-12 p-0 table-section-container d-flex justify-content-start">
              <DashboardTable
                columns={suprvisorTableConfig}
                rows={updatedRows}
                size={size}
              />{" "}
            </div>
          </div>
          {numberOfPages > 1 && (
            <DashboardTablePagination
              numberOfPages={numberOfPages}
              index={index}
              handleClick={handleClick}
            />
          )}
        </div>
      </div>

      <DashboardChartsContainer
        performanceData={performanceData}
        topHospitalData={topHospitalData}
        hospitalPerformanceValues={hospitalPerformanceValues}
        topHospitalValues={topHospitalValues}
      />
    </DashboardLayout>
  );
};

export default SupervisorDashboard;
