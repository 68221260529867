import React, { useEffect, useState } from "react";
import axios from "axios";
import DashboardLayout from "../DashboardSidebar/DashboardLayout";
import { sidebarFacilitatorItems } from "../../constants/SidebarItems";
import { useUserAuth } from "../../context/UserAuthContext";
import { API } from "../../constants/api";
import getAccessToken from "../../utils/getAccessToken";
import FormGeneratorStore from "../../components/FormGenearatorAndStepper/FormGenearatorAndStepper";
import { format } from "date-fns";
import { facilitatorProfile } from "../../constants/profileConfig";
import Loader from "../../components/Loader/Loader";
import { uploadAndReturnUrls } from "../../constants/UploadeImageUrl";
import { ref } from "firebase/storage";
import { storage } from "../../config/testFirebaseConfig";
import { useNavigate } from "react-router-dom";
import { deleteAllImagesRef } from "../../constants/DeleteUrlsImages";

const FacilitatorProfile = () => {
  const { user } = useUserAuth();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [loader, setLoader] = useState(false);
  const [facilitatorId, setFacilitatorId] = useState(null);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const token = getAccessToken();
      // console.log(token);
      const { data } = await axios.get(`${API}/facilitator/user/profile`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setFacilitatorId(data.id);

      if (data.item) {
        const id = Object.keys(data.item);
        // Access the corresponding value
        const userProfileData = data.item[id];

        // console.log("USER DATA", userProfileData);

        var pattern = /\+(\d+)(\d+)/;
        var match = userProfileData.Contact_Number.match(pattern);

        var dialCode = match[1];
        var phone = match[2];

        setUserData({
          // Contact_Number: {
          //   value: { phone: userProfileData.Contact_Number || "" },
          //   isTouched: true,
          // },
          Name_Of_Agency: {
            value: userProfileData.Name_Of_Agency || "",
            isTouched: true,
          },
          Representative: {
            value: userProfileData.Representative || "",
            isTouched: true,
          },
          Website: {
            value: userProfileData.Website || "",
            isTouched: true,
          },
          Address: {
            value: userProfileData.Address || "",
            isTouched: true,
          },
          Company_Registration_Number: {
            value: userProfileData.Company_Registration_Number || "",
            isTouched: true,
          },
          Upload_GST_Certificate: {
            value: userProfileData.Upload_GST_Certificate || "",
            isTouched: true,
          },
          Pan_Card: {
            value: userProfileData.Pan_Card || "",
            isTouched: true,
          },
          Company_License: {
            value: userProfileData.Company_License || "",
            isTouched: true,
          },
          IFSC_Code: {
            value: userProfileData.IFSC_Code || "",
            isTouched: true,
          },
          Account_Number: {
            value: userProfileData.Account_Number || "",
            isTouched: true,
          },
          Name_Of_Bank: {
            value: userProfileData.Name_Of_Bank || "",
            isTouched: false,
          },
        });

        setLoading(false);
      } else {
        console.error("Error");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    // if (user && Object.keys(userData).length > 0) {
    fetchData();
    // }
  }, [user]);

  //submit the user profile
  const onSubmit = async (formData) => {
    console.log(formData);

    setLoader(true);

    const keys = Object.keys(formData);

    const imgData = [
      formData.Company_License.value,
      formData.Pan_Card.value,
      formData.Upload_GST_Certificate.value,
    ];

    const data = new FormData();

    keys.forEach((key) => {
      if (key === "Contact_Number") {
        const number = formData[key].value.phone.slice(
          formData[key].value.dialCode.length
        );
        data.append(key, `+${formData[key].value.dialCode}${number}`);
      } else if (
        key !== "Company_License" &&
        key !== "Pan_Card" &&
        key !== "Upload_GST_Certificate"
      ) {
        data.append(key, formData[key].value);
      }
    });
    console.log("Data is : ", data);
    const url = `${API}/facilitator/update-facilitator/${facilitatorId}`;
    try {
      const token = getAccessToken();
      const result = await axios.put(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      console.log(result.data);

      const facilitatorImageRef = ref(storage, `facilitator/${facilitatorId}`);

      const structuredLinks = {
        Company_License: null,
        Pan_Card: null,
        Upload_GST_Certificate: null,
      };

      const deletedUrls = await deleteAllImagesRef(facilitatorImageRef);

      await Promise.all(
        imgData.map(async (image, index) => {
          const uploadedFacilitatorUrls = await uploadAndReturnUrls(
            [image],
            facilitatorImageRef
          );
          console.log(uploadedFacilitatorUrls);
          structuredLinks[Object.keys(structuredLinks)[index]] =
            uploadedFacilitatorUrls[0];
        })
      );

      console.log("Final structuredLinks:", structuredLinks);

      const response = await axios.post(
        `${API}/facilitator/upload/${facilitatorId}`,
        structuredLinks
      );

      await axios.patch(`${API}/user/updateuser/${user.uid}`, {
        isOnboarded: "true",
      });

      navigate("/facilitator/dashboard");
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };

  console.log("iam userData comp::::::::::", userData);
  return (
    <DashboardLayout sidebarItems={sidebarFacilitatorItems}>
      {loader && <Loader />}
      <div
        className="edit-patient-details-container"
        style={{
          marginTop: "5rem",
        }}
      >
        {/* {!loading && ( */}
        <FormGeneratorStore
          agentTypes={facilitatorProfile}
          submitHandler={onSubmit}
          classnames="mx-auto mt-5"
          updatedFormData={userData}
        />
        {/* )} */}
      </div>
    </DashboardLayout>
  );
};

export default FacilitatorProfile;
