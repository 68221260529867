import React, { useEffect, useState } from "react";
import "./DashboardChartsContainer.css";
import BarGraphComponent from "../GraphComponents/BarGraphComponent/BarGraphComponent";
import { Chart } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";

Chart.register(annotationPlugin);

const DashboardChartsContainer = (props) => {
  const [selectedSortingOption, setSelectedSortingOption] = useState("Monthly");

  function average(ctx) {
    const values = ctx.chart.data.datasets[0].data;
    return values.reduce((a, b) => a + b, 0) / values.length;
  }

  // Define demo data for each hospital
  const hospitalData = {
    "Apollo Hospital, Chennai": [
      50, 70, 60, 80, 75, 90, 85, 95, 70, 80, 85, 90,
    ], // Sample data for each month
    "Apollo Hospital, Delhi": [45, 65, 55, 75, 70, 85, 80, 90, 65, 75, 80, 85], // Sample data for each month
    "Apollo Hospital, Faridabad": [
      40, 60, 50, 70, 65, 80, 75, 85, 60, 70, 75, 80,
    ], // Sample data for each month
    "Max Hospital, Delhi": [55, 75, 65, 85, 80, 95, 90, 100, 75, 85, 90, 95], // Sample data for each month
  };

  // State to store hospital data
  const [hospitalChartData, setHospitalChartData] = useState(null);
  const [selectedHospital, setSelectedHospital] = useState(
    props.hospitalPerformanceValues.hospital[0]
  );
  const [selectedSortBy, setSelectedSortBy] = useState(
    props.hospitalPerformanceValues.sortBy[0]
  );

  useEffect(() => {
    // Filter data based on selected hospital and sorting option
    const filteredData = props.hospitalPerformanceValues.hospital.map(
      (hospital) => ({
        hospital: hospital,
        data: hospitalData[hospital], // Uncomment this line
      })
    );

    switch (selectedSortingOption) {
      case "Monthly":
        // No need for further filtering, data is already monthly
        break;
      case "Quarterly":
        // Apply logic to filter data quarterly
        const quarterlyData = filteredData.map((hospital) => ({
          ...hospital,
          // data: groupDataIntoQuarters(hospital.data),
        }));
        setHospitalChartData(quarterlyData);
        break;
      case "Yearly":
        // Apply logic to filter data yearly
        const yearlyData = filteredData.map((hospital) => ({
          ...hospital,
          // data: groupDataIntoYears(hospital.data),
        }));
        setHospitalChartData(yearlyData);
        break;
      default:
        break;
    }
  }, [selectedHospital, selectedSortingOption]);

  const verticalBarOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
          drawTicks: false,
        },
        border: {
          color: "#757575",
        },
        ticks: {
          color: "#000000",
        },
      },
      y: {
        border: {
          dash: [5, 5],
          color: "transparent",
        },
        grid: {
          color: "#E8E8E8",
          drawTicks: false,
        },
        ticks: {
          color: "#000000",
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          color: "#757575",
        },
      },
      annotation: {
        annotations: [
          {
            drawTime: "beforeDatasetsDraw",
            type: "line",
            yMin: (ctx) => average(ctx),
            yMax: (ctx) => average(ctx),
            borderColor: "#EF5EA2",
            borderDash: [5, 5],
            borderWidth: 2,
            // label: {
            //   drawTime: "afterDatasetsDraw",
            //   display: true,
            //   content: "Average",
            //   position: "end",
            // }
          },
        ],
      },
    },
  };

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
          drawTicks: false,
        },
        border: {
          color: "#757575",
        },
        ticks: {
          color: "#000000",
        },
      },
      y: {
        border: {
          dash: [5, 5],
          color: "transparent",
        },
        grid: {
          color: "#E8E8E8",
          drawTicks: false,
        },
        ticks: {
          color: "#000000",
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          color: "#757575",
        },
      },
    },
  };

  const horizontalBarOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "y",
    layout: {
      padding: 5,
    },

    scales: {
      y: {
        grid: {
          display: false,
          drawTicks: false,
        },
        border: {
          color: "transparent",
        },
        ticks: {
          color: "#000000",
        },
      },
      x: {
        border: {
          dash: [5, 5],
          color: "#757575",
        },
        grid: {
          color: "#E8E8E8",
          drawTicks: false,
        },
        ticks: {
          color: "#000000",
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          color: "#757575",
        },
      },
    },
  };

  return (
    <div className="dashboard-charts-container">
      <div className="hospital-performance-chart-container">
        <h1 className="hospital-performance-chart-title">
          {props.hospitalPerformanceValues.title}
        </h1>
        <div className="hospital-performance-chart-dropdown-container">
          <select
            className="wide-select"
            name={Object.keys(props.hospitalPerformanceValues)[1]}
            id={Object.keys(props.hospitalPerformanceValues)[1]}
          >
            {props.hospitalPerformanceValues.hospital.map((city) => {
              return <option value={city}>{city}</option>;
            })}
          </select>
          <select
            name={Object.keys(props.hospitalPerformanceValues)[2]}
            id={Object.keys(props.hospitalPerformanceValues)[2]}
          >
            {props.hospitalPerformanceValues.sortBy.map((option) => {
              return <option value={option}>{option}</option>;
            })}
          </select>
        </div>
        <div className="hospital-performance-chart-graph">
          <BarGraphComponent
            chartData={props.performanceData}
            options={verticalBarOptions}
            chartWidth="70vw"
            chartHeight="35vh"
          />
        </div>
      </div>
      <div className="tops-hospitals-chart-container">
        <div className="top-hospitals-title-select-container">
          <h1 className="top-hospitals-title">
            {props.topHospitalValues.title}
          </h1>
          <select
            className="top-hospitals-select"
            name={Object.keys(props.topHospitalValues)[1]}
            id={Object.keys(props.topHospitalValues)[1]}
          >
            {props.topHospitalValues.city.map((city) => {
              return <option value="{city}">{city}</option>;
            })}
          </select>
        </div>
        <div className="top-hospitals-chart-graph">
          <BarGraphComponent
            chartData={props.topHospitalData}
            options={horizontalBarOptions}
            // chartWidth="30vw"
            chartHeight="35vh"
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardChartsContainer;
