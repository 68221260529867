import React from 'react'
import './Heading.css';
const Heading = ({title,content}) => {
  return (
    <div className='portfolio-section-heading'>
      <p>{title}</p>
      <h1>{content}</h1>
    </div>
  )
}

export default Heading
