import React, { useEffect, useState } from "react";
import DashboardLayout from "../../pages/DashboardSidebar/DashboardLayout";
import { useNavigate } from "react-router-dom";
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationPopup";
import axios from "axios";
import { API } from "../../constants/api";
import DashboardTable from "../DashboardTable/DashboardTable";
import DashboardTablePagination from "../DashboardTablePagination/DashboardTablePagination";
import TablePen from "../../assets/images/table-pen-button.svg";
import Loader from "../Loader/Loader";
import AppointmentFilterModel from "../../components/AppointmentFilterModel/AppointmentFilterModel";
import { format } from "date-fns";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { hospitalTableConfig } from "./../../constants/HospitaltableConfig";
import { Delete, DeleteForeverOutlined, Edit } from "@mui/icons-material";
import FilterPopup from "../FilterPopup/FilterPopup";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Search from "../../assets/images/search-lg.svg";
import { sidebarAgentItems } from "../../constants/SidebarItems";

const HospitalManagement = () => {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [loader, setLoader] = useState(false);
  const [hospitalData, setHospitalData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(null);
  const [searchedInput, setSearchedInput] = useState("");
  // Menu Dropdown
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleMenuItemClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const size = 5;
  const updatedRows = hospitalData.slice(size * index + 0, size * index + size);

  const handleDeleteHospitalModal = (item) => {
    setDeleteModal(item);
  };

  const handleDeleteClick = async (item) => {
    if (item === "Yes") {
      setLoader(true);
      try {
        await axios.delete(`${API}/hospital/deletehospital/${deleteModal.uid}`);
        getDataFromFirebase();
        setDeleteModal(null);

        setLoader(false);
      } catch (error) {
        setLoader(false);
        setDeleteModal(null);
      }
    } else {
      setDeleteModal(null);
    }
  };

  const buttons = [
    {
      title: "cross",
      render: (item) => {
        return (
          <div
            className="rescheduele-appointment-button"
            onClick={() => {
              console.log(item.uid);
              navigate(
                `/agent/hospital-management/add-hospital-details?page=agent&q=${item.uid}`
              );
            }}
          >
            <img src={TablePen} alt="Edit icon" />
            <p className="rescheduele-appointment">Edit Hospital Information</p>
          </div>
        );
      },
    },
    {
      title: "cross",
      render: (item) => {
        return (
          <div
            className="delete-appointment-button"
            onClick={() => {
              handleDeleteHospitalModal(item);
              handleClose();
            }}
          >
            <DeleteForeverOutlined
              sx={{
                color: "#475466",
                cursor: "pointer",
              }}
              fontSize="medium"
            />

            <p className="rescheduele-appointment">Delete Hospital</p>
          </div>
        );
      },
    },
  ];

  const handleClick = (ind) => {
    setIndex(ind);
  };

  const numberOfPages = Math.ceil(hospitalData.length / size);

  const getDataFromFirebase = async () => {
    try {
      setLoader(true);
      const { data } = await axios.get(`${API}/hospital/getallhospitals`);
      console.log(data.hospitals);
      const items = data.hospitals;
      const tempHospitalData = items.map((item, index) => {
        console.log(item?.onBoardedOn);
        const tempObj = {};
        const tempId = `B1C12${index}`;
        tempObj.hospitalName = item.Name_Of_Hospital;
        tempObj.contactNumber = item.Contact_Number;
        tempObj.hospitalId = item?.hospitalId || tempId;
        tempObj.speciality = item?.speciality || " ";
        tempObj.location = item?.Location;
        tempObj.nationality = item?.Nationality;
        tempObj.accreditation = item?.Accreditation;
        tempObj.agentUid = item?.agentUid;
        tempObj.agentWhoOnboarded = item?.agentName;
        tempObj.email = item?.agentEmail;
        tempObj.dateOfOnboarding = item?.onBoardedOn
          ? format(new Date(item.onBoardedOn), "dd MMM yyyy")
          : "N/A";
        tempObj.totalStaff = item?.totalStaff;
        tempObj.uid = item?.uid;
        tempObj.hospitalLogo = item?.Hospital_Logo;
        return tempObj;
      });

      setLoader(false);
      setHospitalData(tempHospitalData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDataFromFirebase();
  }, []);

  //////////////////////// FILTER POPUP CONTENT START ////////////////////////////

  const [isVisible, setIsVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    status: [],
    speciality: [],
    totalStaff: [],
    location: [],
  });
  const [checkboxes, setCheckboxes] = useState({
    lessThan500: false,
    between500And1000: false,
    between1000And2000: false,
    above2000: false,
  });

  const toggleChip = (label, category) => {
    console.log(label, category);

    if (category === "location") {
      setSelectedFilters({
        ...selectedFilters,
        [category]: label,
      });
    } else {
      const currentSelection = selectedFilters[category];
      console.log(currentSelection);

      if (currentSelection.includes(label)) {
        setSelectedFilters({
          ...selectedFilters,
          [category]: currentSelection.filter((chip) => chip !== label),
        });
      } else {
        setSelectedFilters({
          ...selectedFilters,
          [category]: [...currentSelection, label],
        });
      }
    }
  };

  const handleFilterSubmit = async () => {
    console.log(selectedFilters);

    const filteredData = hospitalData.filter((item) => {
      return Object.entries(selectedFilters).every(
        ([filterKey, filterValues]) => {
          if (filterValues.length > 0) {
            if (filterKey === "totalStaff") {
              return filterValues.some((filterValue) => {
                const [min, max] = filterValue.split("-");
                console.log(item);
                const staffCount = parseInt(item[filterKey], 10);
                console.log(staffCount);
                if (min && max === "max") {
                  return staffCount >= parseInt(min, 10);
                } else if (min && max) {
                  return (
                    staffCount >= parseInt(min, 10) &&
                    staffCount <= parseInt(max, 10)
                  );
                } else if (min) {
                  return staffCount >= parseInt(min, 10);
                } else if (max) {
                  return staffCount <= parseInt(max, 10);
                }

                return false;
              });
            }
            return filterValues.includes(item[filterKey]);
          }
          return true;
        }
      );
    });
    console.log(filteredData);
    setHospitalData(filteredData);

    togglePopup("dontClearFilters");
  };

  const togglePopup = (toClear) => {
    if (toClear === "clearFilters") {
      if (isVisible) {
        setIsClosing(true);
        setTimeout(() => {
          setIsVisible(false);
          setIsClosing(false);
          handleFilterClear();
        }, 300);
      } else {
        setIsVisible(true);
      }
    } else {
      if (isVisible) {
        setIsClosing(true);
        setTimeout(() => {
          setIsVisible(false);
          setIsClosing(false);
        }, 300);
      } else {
        setIsVisible(true);
      }
    }
  };

  const handleFilterClear = () => {
    setSelectedFilters({
      status: [],
      speciality: [],
      totalStaff: [],
      location: [],
    });

    setCheckboxes({
      lessThan500: false,
      between500And1000: false,
      between1000And2000: false,
      above2000: false,
    });
  };

  const handleCheckboxChange = (key) => {
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [key]: !prevCheckboxes[key],
    }));
  };

  return (
    <DashboardLayout sidebarItems={sidebarAgentItems}>
      {loader && <Loader />}
      <Breadcrumb
        breadcrumbs={[
          { label: "Hospital Management", url: "/agent/hospital-management" },
        ]}
        divider={"/"}
        styles={{
          paddingTop: "1rem",
        }}
      />

      <div>
        <div className="button-div">
          <div className="button-div" style={{ justifyContent: "flex-end" }}>
            {/* <div className='button-div-left-side'>
              <h1 className='appointment-header'>Hospitals</h1>
            </div> */}
            <div className="button-div-right-side">
              <img src={Search} alt="search" className="search-icon" />
              <input
                type="text"
                onChange={(event) => {
                  setSearchedInput(event.target.value);
                }}
                value={searchedInput}
                className="searched-input-field"
                placeholder="Search"
              />
              <FilterPopup
                isVisible={isVisible}
                isClosing={isClosing}
                setIsClosing={setIsClosing}
                setIsVisible={setIsVisible}
                togglePopup={togglePopup}
                handleFilterClear={handleFilterClear}
                handleFilterSubmit={handleFilterSubmit}
                name="Filter"
                buttonStyle="filter-button-style"
              >
                <AppointmentFilterModel
                  selectedFilters={selectedFilters}
                  checkboxes={checkboxes}
                  handleCheckboxChange={handleCheckboxChange}
                  toggleChip={toggleChip}
                />
              </FilterPopup>

              <button
                className="Add-Appointments-button"
                onClick={() =>
                  navigate("/agent/hospital-management/add-hospital")
                }
              >
                + Add Hospital
              </button>
            </div>
          </div>
          {/* <FilterPopup
            isVisible={isVisible}
            isClosing={isClosing}
            setIsClosing={setIsClosing}
            setIsVisible={setIsVisible}
            togglePopup={togglePopup}
            handleFilterClear={handleFilterClear}
            handleFilterSubmit={handleFilterSubmit}
          >
            <AppointmentFilterModel
              selectedFilters={selectedFilters}
              checkboxes={checkboxes}
              handleCheckboxChange={handleCheckboxChange}
              toggleChip={toggleChip}
            />
          </FilterPopup>
          <button
            className='Add-Appointments-button'
            onClick={() => navigate("/agent/hospital-management/add-hospital")}
          >
            + Add Hospital
          </button> */}
        </div>

        {deleteModal && (
          <ConfirmationPopup
            handleClick={handleDeleteClick}
            data={deleteModal}
          />
        )}

        <div className="table-section-container">
          <DashboardTable
            columns={hospitalTableConfig}
            rows={updatedRows}
            size={6}
            action={buttons}
            actionHeaderTitle="Actions"
          />{" "}
        </div>
        {numberOfPages > 1 && (
          <DashboardTablePagination
            numberOfPages={numberOfPages}
            index={index}
            handleClick={handleClick}
          />
        )}
      </div>
    </DashboardLayout>
  );
};

export default HospitalManagement;
