import React from "react";
import "./CommonComponent.css";
// import logo from "../../assets/images/logo.png";
import logo2 from "../../assets/images/new_logo_png.png";
// import illustration from "../../assets/images/Illustration.png";
import illustration from "../../assets/images/Illustration-2.svg";

const CommonComponent = (props) => {
  return (
    <div className="login-container">
      <div className="left-side">
        <img
          src={logo2}
          alt="logo"
          className="login-page-logo d-sm-block d-none"
        />
        <img
          src={illustration}
          alt="illustration"
          className="login-page-illustration"
        />
        <div className="right-side">
          <div className="right-content">{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default CommonComponent;
