import React, { useRef, useState, useEffect } from "react";
import "./SimpleButton.css";
import { addSpecialityPopup as addTreatmentSection } from "../../constants/formconfig";
import axios from "axios";
import SimpleInput from "../InputTypes/SimpleInput/SimpleInput";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
// import FileInput from "../InputTypes/FileInput/FileInput";
import cross from "../../assets/images/red-cross.png";

import ChipComponent from "./ChipComponent";

const SimpleButton = ({ doctors, handleSubmit }) => {
  const closeButtonRef = useRef(null);

  const initialFormState = {}; // Set initial state based on form configuration
  addTreatmentSection[0].fields.forEach((field) => {
    initialFormState[field[0].title] = {
      value: "",
      isTouched: false,
    };
  });

  const [formData, setFormData] = useState(initialFormState);
  const [selectedDoctors, setSelectedDoctors] = useState([]);

  // const [doctors, setDoctors] = useState([]);
  // useEffect(() => {
  //   const getDoctorData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${API}/agenthospital/getalldoctor/-Ngy86nBlGlvPH5eODNh`
  //       );
  //       const fetchedData = response.data.doctors;
  //       //  setData(fetchedData); // Update the local state with fetched data
  //       // setTableData(fetchedData); // Update the parent component's state with fetched data
  //       console.log("Getalldoc", response.data.doctors);
  //       setDoctors(response.data.doctors);
  //       // setTableData(data)
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };
  //   getDoctorData();
  // }, []);

  const clickHandler = (id) => {
    setSelectedDoctors((ele) => ele.filter((ele1) => ele1.id !== id));
  };

  const handleSelectChange = (event, input) => {
    if (!input) return;
    const selectedDoctor = doctors?.filter((ele) => ele.id === input?.id);
    if (
      selectedDoctor &&
      selectedDoctors?.findIndex((ele) => ele.id === input.id) !== -1
    ) {
      return;
    }
    setSelectedDoctors((prev) => [...prev, selectedDoctor[0]]);
  };

  const handleChange = (field, newValue) => {
    setFormData((obj) => {
      let tempObj = { ...obj };
      const tempField = { ...tempObj[field], value: newValue };
      tempObj[field] = tempField;
      return tempObj;
    });
  };

  const handleTouch = (field) => {
    setFormData((obj) => {
      const tempObj = { ...obj };
      tempObj[field] = { ...tempObj[field], isTouched: true };
      return tempObj;
    });
  };

  const optionArray = doctors.map((ele) => {
    return { label: ele.name, id: ele.id };
  });

  // const handleDoctors = () => {
  //   const speciality = formData["Speciality Name"].value;
  //   const description = formData["Description"].value;
  //   const payload = {
  //     speciality,
  //     description,
  //     selectedDoctors: selectedDoctors,
  //   };
  //   handleSubmit(payload);
  // };

  const isFormInValid =
    !addTreatmentSection[0].fields[0][0].validation(
      formData["Speciality Name"].value
    ) ||
    !addTreatmentSection[0].fields[1][0].validation(
      formData["Description"].value
    );
  console.log(isFormInValid);
  const disabled = isFormInValid ? "disabled" : "";

  const handleDoctors = async () => {
    // Your existing logic for handling doctors
    const speciality = formData["Speciality Name"].value;
    const description = formData["Description"].value;
    const payload = {
      speciality,
      description,
      selectedDoctors: selectedDoctors,
    };

    // console.log('You stored', payload);

    // Extracting doctorIds
    // const doctorIds = payload.selectedDoctors.map(doctor => doctor.id);
    // // Creating the output object
    // const outputData = {
    //   specializationName: speciality,
    //   description: description,
    //   doctorIds: doctorIds
    // };

    // try {
    //   const response = await axios.post(`${API}/agenthospital/addtreatment/-Ngy86nBlGlvPH5eODNh/specialties`, outputData);
    //   // Handle the API response here
    //   console.log(response.data);
    //   alert("added the treatment")
    // } catch (error) {
    //   // Handle errors here
    //   console.error(error);
    // }

    // Log the output data for verification
    // console.log("Json::::::::", outputData);

    handleSubmit(payload);

    setFormData(initialFormState);
    setSelectedDoctors([]);

    // Close the modal after handling doctors
    // const modal = new bootstrap.Modal(document.getElementById("exampleModal"));
    // modal.hide();
  };

  const closeHandler = () => {
    setFormData(initialFormState);
    setSelectedDoctors([]);
  };

  return (
    <div>
      {/* <button className="btn btn-primary me-2" data-bs-toggle="modal" data-bs-target="#exampleModal">Add treatment</button> */}
      <button
        className='simple-button'
        type='button'
        data-bs-toggle='modal'
        data-bs-target='#exampleModal'
      >
        Add treatment
      </button>
      <div
        className='modal fade'
        id='exampleModal'
        tabIndex={-1}
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Add Treatment
              </h5>
              {/* <button
                type="button"
                className="btn-close cross"
                data-bs-dismiss="modal"
                aria-label="Close"
              /> */}
              <img
                src={cross}
                alt='cross'
                className='red-cross-button'
                data-bs-dismiss='modal'
                onClick={closeHandler}
              />
            </div>
            <div className='modal-body'>
              <form onSubmit={handleDoctors}>
                {addTreatmentSection[0].fields.map((field, index) => {
                  if (field[0].type === "text") {
                    return (
                      <SimpleInput
                        key={index}
                        input={field[0]}
                        state={formData[field[0].title]}
                        handleChange={handleChange}
                        handleTouch={handleTouch}
                      />
                    );
                  }
                  if (field[0].type === "searchable-select") {
                    return (
                      <div className='multi-select-box  mb-3' key={index}>
                        <label className='form-label d-flex mb-1 ms-1'>
                          Add Doctors
                        </label>
                        <Autocomplete
                          className='multiple-select-field'
                          isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                          }
                          onChange={handleSelectChange}
                          id='combo-box-demo'
                          options={optionArray || []}
                          // getOptionLabel={(option) => option.label}
                          renderInput={(params) => (
                            <TextField {...params} label='Doctors' />
                          )}
                          style={{ marginTop: "15px" }}
                        />
                      </div>
                    );
                  }
                  return null;
                })}
                <div className='chip-div'>
                  {selectedDoctors.map((doctor) => (
                    <ChipComponent
                      key={doctor.id}
                      id={doctor.id}
                      doctor={doctor}
                      clickHandler={clickHandler}
                    />
                  ))}
                </div>
              </form>
            </div>
            <div className='modal-footer'>
              {/* <button
                type="button"
                className="btn btn-secondary my-btn"
                data-bs-dismiss="modal"
              >
                Close
              </button> */}
              {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" ref={closeButtonRef}>Close</button> */}
              <button
                type='button'
                className={`my-btn ${disabled}`}
                data-bs-dismiss='modal'
                onClick={handleDoctors}
                disabled={isFormInValid}
              >
                Save changes
              </button>
              {/* <button type="button" className="btn btn-primary" onClick={handleSubmit}>Save changes</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleButton;
