import React from "react";
import { useNavigate } from "react-router-dom";
import "./HotelCard.css";
const HotelCard = ({
  hotelName,
  startingPricePerNight,
  facilities,
  starStatus = 5,
  hotelImage,
  hospitalId,
}) => {
  const navigate = useNavigate();
  const navigateHandler = () => {
    const route = `/verfied/hotel?hospitalId=${hospitalId}`;
    navigate(route);
  };
  console.log({hotelImage})
  return (
    <div className="hotel-card">
      <div className="recommended-content">
        <div className="hotel-photo-box">
          <div className="hotel-photo">
            <img
              src={hotelImage&&hotelImage}
              className="img-fluid"
              alt={`Slide`}
            />
          </div>

          <div className="star-status">{starStatus} star</div>
        </div>

        <div className="hotel-name-pricing mt-4 text-truncate">
          <h6 className="name mb-2 text-capitalize">{hotelName}</h6>
          <h6 className="pricing text-capitalize">
            {startingPricePerNight} USD per night stay
          </h6>
          <hr className="m-0" />
        </div>

        <div className="hotel-facilities-box mt-3 mb-4">
          <p className="mb-0 fw-light mb-1 overflow-auto">Facilities</p>
          <div className="facilities">
            <p className="mb-2 fw-bold p-0 text-wrap  text-capitalize">
              {facilities && facilities.join(" , ")}
            </p>
          </div>
          <hr className="m-0" />
        </div>
      </div>
      <div className="recommended-btn">
        <button className="hospital-primary-button" onClick={navigateHandler}>View Details</button>
      </div>
    </div>
  );
};

export default HotelCard;
