import DashboardTableNameColumn from "../components/DashboardTableNameColumn/DashboardTableNameColumn";
const chips = [
  { label: "Preliminary call", color: "#0086C9" },
  { label: "Requested quote", color: "#0086C9" },
  { label: "Shared quote", color: "#6938EF" },
  { label: "Follow up", color: "#FF5733" },
  { label: "Treatment finalised", color: "#DC6803" },
  { label: "Visa", color: "#DC6803" },
  { label: "Travel", color: "#DC6803" },
  { label: "Accommodation", color: "#0086C9" },
];
const getBorderColor = (label) => {
  const chip = chips.find((chip) => chip.label === label);
  return chip ? chip.color : "cyan";
};
export const patientManagementTableConfig = [
  {
    columnName: "Patient",
    style: { width: "210px", marginLeft: "20px", marginRight: "max(10px,3vw)" },
    render: (data) => {
      return (
        <DashboardTableNameColumn
          title={data.Patient_Name}
          subtitle={data.Email}
          hospitalLogo={data.Email}
        />
      );
    },
  },
  { columnName: "Contact Number", value: "Contact_Number" },
  {
    columnName: "Medical Condition",
    // value: "medicalCondition",
    style: { width: "150px", marginLeft: "20px", marginRight: "max(10px,3vw)" },
    render: (data) => {
      return (
        <div
          style={{
            width: "150px",
            marginLeft: "20px",
            marginRight: "max(10px,3vw)",
            // padding: "5px",
          }}
        >
          {data.Medical_Issue}
        </div>
      );
    },
  },
  // {
  //   columnName: "Stage",
  //   // value: "stage",
  //   style: { width: "150px", marginLeft: "20px", marginRight: "max(10px,2vw)" },
  //   render: (data) => {
  //     const borderColor = getBorderColor(data.stage);
  //     return (
  //       <div
  //         style={{
  //           width: "150px",
  //           marginLeft: "20px",
  //           marginRight: "max(10px,2vw)",
  //           // padding: "5px",
  //         }}
  //       >
  //         <p
  //           style={{
  //             //   width: "210px",
  //             //   marginLeft: "20px",
  //             //   marginRight: "max(10px,3vw)",

  //             border: `2px solid ${borderColor}`,
  //             borderRadius: "100px",
  //             padding: "2px 10px",
  //             fontSize: "12px",
  //             fontWeight: "bold",
  //             textAlign: "left",
  //             width: "fit-content",
  //           }}
  //         >
  //           {data.stage}
  //         </p>
  //       </div>
  //     );
  //   },
  // },
  {
    columnName: "Scheduled On",
    value: "scheduledOn",
    style: { width: "250px", marginLeft: "20px", marginRight: "max(10px,2vw)" },
    render: (data) => {
      return (
        <div
          style={{
            width: "250px",
            marginLeft: "20px",
            marginRight: "max(10px,2vw)",
          }}
        >
          {data.scheduledOn}
        </div>
      );
    },
  },
];

export const dummyPatientTableData = [
  {
    agentName: "John Doe",
    agentEmail: "john.doe@example.com",
    contactNumber: "123-456-7890",
    medicalCondition: "None",
    stage: "Initial",
    scheduledOn: "2023-12-01 09:00 AM",
  },
  {
    agentName: "Jane Smith",
    agentEmail: "jane.smith@example.com",
    contactNumber: "987-654-3210",
    medicalCondition: "High Blood Pressure",
    stage: "Follow-up",
    scheduledOn: "2023-12-02 02:30 PM",
  },
  {
    agentName: "Bob Johnson",
    agentEmail: "bob.johnson@example.com",
    contactNumber: "555-123-4567",
    medicalCondition: "Diabetes",
    stage: "Consultation",
    scheduledOn: "2023-12-03 11:15 AM",
  },
  {
    agentName: "John Doe",
    agentEmail: "john.doe@example.com",
    contactNumber: "123-456-7890",
    medicalCondition: "None",
    stage: "Initial",
    scheduledOn: "2023-12-01 09:00 AM",
  },
  {
    agentName: "Jane Smith",
    agentEmail: "jane.smith@example.com",
    contactNumber: "987-654-3210",
    medicalCondition: "High Blood Pressure",
    stage: "Follow-up",
    scheduledOn: "2023-12-02 02:30 PM",
  },
  {
    agentName: "Bob Johnson",
    agentEmail: "bob.johnson@example.com",
    contactNumber: "555-123-4567",
    medicalCondition: "Diabetes",
    stage: "Consultation",
    scheduledOn: "2023-12-03 11:15 AM",
  },
  {
    agentName: "John Doe",
    agentEmail: "john.doe@example.com",
    contactNumber: "123-456-7890",
    medicalCondition: "None",
    stage: "Initial",
    scheduledOn: "2023-12-01 09:00 AM",
  },
  {
    agentName: "Jane Smith",
    agentEmail: "jane.smith@example.com",
    contactNumber: "987-654-3210",
    medicalCondition: "High Blood Pressure",
    stage: "Follow-up",
    scheduledOn: "2023-12-02 02:30 PM",
  },
  {
    agentName: "Bob Johnson",
    agentEmail: "bob.johnson@example.com",
    contactNumber: "555-123-4567",
    medicalCondition: "Diabetes",
    stage: "Consultation",
    scheduledOn: "2023-12-03 11:15 AM",
  },
  {
    agentName: "John Doe",
    agentEmail: "john.doe@example.com",
    contactNumber: "123-456-7890",
    medicalCondition: "None",
    stage: "Initial",
    scheduledOn: "2023-12-01 09:00 AM",
  },
  {
    agentName: "Jane Smith",
    agentEmail: "jane.smith@example.com",
    contactNumber: "987-654-3210",
    medicalCondition: "High Blood Pressure",
    stage: "Follow-up",
    scheduledOn: "2023-12-02 02:30 PM",
  },
  {
    agentName: "Bob Johnson",
    agentEmail: "bob.johnson@example.com",
    contactNumber: "555-123-4567",
    medicalCondition: "Diabetes",
    stage: "Consultation",
    scheduledOn: "2023-12-03 11:15 AM",
  },
  {
    agentName: "John Doe",
    agentEmail: "john.doe@example.com",
    contactNumber: "123-456-7890",
    medicalCondition: "None",
    stage: "Initial",
    scheduledOn: "2023-12-01 09:00 AM",
  },
  {
    agentName: "Jane Smith",
    agentEmail: "jane.smith@example.com",
    contactNumber: "987-654-3210",
    medicalCondition: "High Blood Pressure",
    stage: "Follow-up",
    scheduledOn: "2023-12-02 02:30 PM",
  },
  {
    agentName: "Bob Johnson",
    agentEmail: "bob.johnson@example.com",
    contactNumber: "555-123-4567",
    medicalCondition: "Diabetes",
    stage: "Consultation",
    scheduledOn: "2023-12-03 11:15 AM",
  },
];
