import React, { useEffect, useState } from "react";
import SectionHeading from "../../../../components/ProcedurePageComponents/SectionHeading/SectionHeading";
import RecommendedHospitalCard from "../../../../components/ProcedurePageComponents/RecommendedHospitalCard/RecommendedHospitalCard";
import "./RecommendedHospitals.css";
import axios from "axios";
import MiniLoading from "../../../../components/ProcedurePageComponents/Loading/MiniLoading";
import { API } from "../../../../constants/api";
import { Carousel } from "primereact/carousel";

const RecommendedHospitals = ({ leadingHospitalsIds, hospitalsFor }) => {
  const [leadingHospitals, setLeadingHospitals] = useState([]);
  const [loading, setLoading] = useState(false);
  const getSingleHospital = async (id) => {
    // const url = `${process.env.REACT_APP_BACKEND_URL}/hospital/getsinglehospital/${id}`;
    const url = `${API}/hospital/getsinglehospital/${id}`;
    const { data } = await axios.get(url);
    return data.hospital;
  };
  const getAllHospitals = async () => {
    setLoading(true);
    try {
      let promises = [];
      console.log({ leadingHospitalsIds });
      for (let id of leadingHospitalsIds) {
        promises.push(getSingleHospital(id));
      }
      let results = await Promise.all([...promises]);
      console.log({ results });
      setLeadingHospitals(results);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (leadingHospitalsIds) getAllHospitals();
  }, [leadingHospitalsIds]);
  return (
    <div
      className="recommended-hospitals-section progress-section"
      id="recommended-hospitals"
    >
      <SectionHeading text={`Leading Hospitals for ${hospitalsFor}`} />
      {loading ? (
        <MiniLoading />
      ) : (
        <div>
          <Carousel
            value={leadingHospitals}
            itemTemplate={(hospital) => (
              <RecommendedHospitalCard
                key={hospital?.Name_Of_Hospital}
                hospitalName={hospital.Name_Of_Hospital}
                hospitalLocation={hospital.Location}
                specialilty={hospital.facility_overview.specialityTypes}
                facilities={hospital.facility_overview.specialties}
                hospital_uid={hospital.uid}
                hospitalPhoto={
                  hospital.Hospital_images &&
                  hospital.Hospital_images.length > 0
                    ? hospital.Hospital_images
                    : null
                }
              />
            )}
            numVisible={4}
            numScroll={2}
            autoplayInterval={3000}
            circular={true}
            responsiveOptions={[
              {
                breakpoint: "1400px",
                numVisible: 4,
                numScroll: 2,
              },
              {
                breakpoint: "1199px",
                numVisible: 3,
                numScroll: 1,
              },
              {
                breakpoint: "767px",
                numVisible: 2,
                numScroll: 1,
              },
              {
                breakpoint: "575px",
                numVisible: 1,
                numScroll: 1,
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default RecommendedHospitals;
