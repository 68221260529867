import React from "react";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
// import Button from '@mui/material/Button';
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { API } from "../../constants/api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  borderRadius: 2,
  p: 0,
};
const DeleteModal = ({
  openDelete,
  handleCloseDelete,
  setTableData,
  setDeleteDoctor,
  setData,
  tableData,
  deleteDoctor,
  setLoader,
}) => {
  const searchParams = new URLSearchParams(window.location.search);
  const hospitalId = searchParams.get('q');



  const handleYesClick = async () => {
    try {
      setLoader(true)
      // Call the deleteSingleDcotortController API endpoint
      const response = await axios.delete(
        `${API}/agenthospital/deletesingledoctor/${hospitalId}/${deleteDoctor}`
      );

      if (response) {
        console.log(
          "Doctor deleted successfully:",
          response.data.existingDoctor
        );
        const updatedTableData = tableData.filter(
          (doctor) => doctor.id !== deleteDoctor
        );

        // Update the state with the filtered tableData

        setData(updatedTableData);
        setTableData(updatedTableData);
        setLoader(false)

        // alert("Doctor deleted successfully!");
      } else {
        console.error("Error deleting doctor:", response.data.error);
        alert("Failed to delete doctor. Please try again later.");
      }
    } catch (error) {
      // Handle network errors or any other errors that occurred during the request
      console.error("Request error:", error);
      alert("Failed to delete doctor. Please check your network connection.");
    }

    handleCloseDelete();
  };

  const handleNoClick = () => {
    handleCloseDelete();
  };
  return (
    <div className='delete-box-modal'>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        keepMounted
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby='keep-mounted-modal-title'
        aria-describedby='keep-mounted-modal-description'
      >


        <Box sx={style}>

          {/* Modal Header */}
          <div className='modal-header '>
            <h5 className='modal-title'>Delete Doctor</h5>
            <button
              type='button'
              className='btn-close cross'
              onClick={handleNoClick}
            />
          </div>

          <Typography id='keep-mounted-modal-title' variant='h6' component='h2' className="p-3">
            Are You Sure to Delete Doctor
          </Typography>
          <div className='px-2 py-3' >
            <Button
              variant='outlined'
              color="error"
              startIcon={<DeleteIcon />}
              onClick={handleYesClick}
              className='me-2'
            >
              Delete
            </Button>
            <Button
              variant='contained'
              color='success'
              onClick={handleNoClick}
              className='me-3'
            >
              No
            </Button>
          </div>

        </Box>
      </Modal>
    </div>
  );
};

export default DeleteModal;
