import axios from "axios";

const getCurrencyConversion = async ({ from, to, amount }) => {
  const options = {
    method: "GET",
    url: "https://currency-converter241.p.rapidapi.com/convert",
    params: {
      amount,
      from,
      to,
    },
    headers: {
      // 'X-RapidAPI-Key': process.env.REACT_APP_RAPID_API_KEY,
      "X-RapidAPI-Key": "2cae0f585amshb492f768cb8851cp12ca8ajsn452c300ab148",
      "X-RapidAPI-Host": "currency-converter241.p.rapidapi.com",
    },
  };
  const {
    data: { total },
  } = await axios.request(options);
  return total;
};
export default getCurrencyConversion;
