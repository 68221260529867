import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
// import { AddAppointmentsColumnConfigMain } from "../../constants/AddAppointmentsTableConfig";
// import { diagnosticTableConfig } from "../../constants/DiagnosticConfig.js";
import "./DiagnosticDashboard.css";
import DashboardLayout from "../DashboardSidebar/DashboardLayout";
import buildingPink from "../../assets/DashboardAssets/buildingPink.svg";
import buildingActive from "../../assets/DashboardAssets/Actives.png";
import buildingCancelled from "../../assets/DashboardAssets/Cancelled.png";
import buildingComplated from "../../assets/DashboardAssets/Complated.png";

import diffGreen from "../../assets/DashboardAssets/diffGreen.svg";
import DashboardTable from "../../components/DashboardTable/DashboardTable.jsx";
import DashboardTablePagination from "../../components/DashboardTablePagination/DashboardTablePagination.jsx";
import { Link, useParams } from "react-router-dom";
import StatusCrad from "../../components/StatsCard/StatsCard";
import DashboardChartsContainer from "../../components/DashboardChartsContainer/DashboardChartsContainer";
import indianStatesData from "../../assets/custom-data/data";
import { indianCities } from "../../assets/custom-data/indianCities";
import rightTick from "../../assets/DashboardAssets/rightTick.svg";
import { sidebarDiagnosticItems } from "../../constants/SidebarItems";
import { useUserAuth } from "../../context/UserAuthContext";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { API } from "../../constants/api";
import { Dropdown } from "react-bootstrap";
import { bookingStatus } from "../../constants.js";
import Loading from "../../components/ProcedurePageComponents/Loading/Loading.jsx";

const DiagnosticDashboard = () => {
  const statusOptions = bookingStatus.map((x) => ({
    value: x.statusName,
  }));

  const [statusValues, setStatusValues] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRowUid, setSelectedRowUid] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [diagnostics, setDiagnostics] = useState([]);
  const tableData = diagnostics;

  const [loading, setLoading] = useState(true);

  // Function to handle status change for a specific row
  const handleStatusChange = async (selectedStatus, rowIndex, rowUid) => {
    try {
      // Send PUT request to update status
      await axios.put(`${API}/diagnostics/update/${rowUid}`, {
        status: selectedStatus.value,
      });
      setStatusValues((prevStatusValues) => ({
        ...prevStatusValues,
        [rowIndex]: selectedStatus.value,
      }));
      setOpenDialog(false);

      // Fetch updated data after status update
      const response = await axios.get(`${API}/diagnostics/getalldiagnostics`);
      setDiagnostics(response?.data?.diagnostics);
    } catch (error) {
      console.error("Error updating status:", error);
      // Handle error
    }
  };

  const handleRowClick = (uid) => {
    setSelectedRowUid(uid);
    setOpenDialog(true);
  };

  const renderStatusForRow = (item) => {
    const rowUid = item.uid;
    let colour = "rgb(152, 152, 7)";
    bookingStatus.forEach((st) => {
      if (item.status === st.statusName) {
        colour = st.statusColor;
      }
    });
    if (selectedRowUid === rowUid && openDialog)
      return (
        <div className="change-diag-status">
          <Dropdown
            size="lg"
            style={{ fontSize: "0.75rem", padding: "0.25rem 0.5rem" }}
          >
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              size="sm"
              style={{ fontSize: "0.75rem", padding: "0.25rem 0.5rem" }}
            >
              Select Button
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {statusOptions.map((option, rowIndex) => (
                <Dropdown.Item
                  key={rowIndex}
                  onClick={() => handleStatusChange(option, rowIndex, rowUid)}
                >
                  {option.value}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    else
      return (
        <div className="change-diag-status">
          <div
            style={{
              color: colour,
              border: `2px solid ${colour}`,
              borderRadius: "20px",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
              padding: "0 10px",
            }}
            onClick={() => handleRowClick(rowUid)}
          >
            {item?.status || "Select Status"}
          </div>
        </div>
      );
  };

  const [performanceData, setPerformanceData] = useState({
    labels: indianStatesData.map((state) => state.state),
    datasets: [
      {
        label: "Appointments Booked",
        data: indianStatesData.map((state) => state.totalHospitals),
        backgroundColor: ["#2D416B"],
        barPercentage: 0.5,
      },
    ],
  });

  const [topHospitalData, setTopHospitalData] = useState({
    labels: [
      "Apollo Indraprastha",
      "Fortis Malar Hospital",
      "Billroth Hospital",
      "Vijay Hospital",
      "Prashant Hospital",
    ],
    datasets: [
      {
        label: "No. of Beds",
        data: indianStatesData.map((state) => state.totalHospitals),
        backgroundColor: ["#2D416B"],
        barPercentage: 1,
        categoryPercentage: 0.6,
      },
    ],
  });

  const hospitalPerformanceValues = {
    title: "Hospital Performance",
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],

    hospital: [
      "Apollo Hospital, Chennai",
      "Apollo Hospital, Delhi",
      "Apollo Hospital, Faridabad",
      "Max Hospital, Delhi",
    ],
    sortBy: ["Monthly", "Quarterly", "Yearly"],
  };

  const topHospitalValues = {
    title: "Top 5 Hospitals",
    city: indianCities,
  };

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API}/diagnostics/getalldiagnostics`
        );
        setDiagnostics(response?.data?.diagnostics);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching diagnostics:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const isSameDate = (date1, date2) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  // Function to check if two dates are in the same week
  const isSameWeek = (date1, date2) => {
    const firstDayOfWeek = new Date(date2);
    firstDayOfWeek.setDate(date2.getDate() - date2.getDay());

    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

    return date1 >= firstDayOfWeek && date1 <= lastDayOfWeek;
  };

  const filteredData = tableData.filter((item) => {
    const currentDate = new Date();
    const itemDate = new Date(item.onBoardedOn);

    switch (selectedFilter) {
      case "today":
        return isSameDate(itemDate, currentDate);
      case "tomorrow":
        const tomorrowDate = new Date(currentDate);
        tomorrowDate.setDate(currentDate.getDate() + 1);
        return isSameDate(itemDate, tomorrowDate);
      case "yesterday":
        const yesterdayDate = new Date(currentDate);
        yesterdayDate.setDate(currentDate.getDate() - 1);
        return isSameDate(itemDate, yesterdayDate);
      case "this_week":
        return isSameWeek(itemDate, currentDate);
      case "this_month":
        return itemDate.getMonth() === currentDate.getMonth();
      case "all":
        return true; // Return true for all items
      default:
        return true;
    }
  });

  ///////////////////////// Dashboard Charts Container Data End///////////////////////////////

  const [index, setIndex] = useState(0);
  const size = Math.min(filteredData.length, 5);
  const updatedRows = filteredData.slice(size * index + 0, size * index + size);
  const { user, logOut } = useUserAuth();
  const handleClick = (ind) => {
    setIndex(ind);
  };
  const numberOfPages = Math.ceil(filteredData.length / size);

  const title = "All hospital";
  const number = 50;
  const description = "vs last month";

  const arr = [1];

  // Function to check if two dates represent the same day

  const { role } = useParams();
  console.log(filteredData);

  const diagnosticTableConfig = [
    {
      columnName: "Date and Time",
      value: " 10:00 AM",
      style: {
        width: "120px",
        marginLeft: "20px",
        marginRight: "max(10px,3vw)",
      },
      render: (data) => {
        return (
          <div>
            <h5>Jan, 20 2024</h5>
            <span>10:00 AM</span>
          </div>
        );
      },
    },
    {
      columnName: "Patient Name",
      style: { width: "140px", marginLeft: "20px" },

      render: (data) => {
        return (
          <div
            style={{
              background: "aliceblue",
              marginLeft: "100px",
              width: "140px",
            }}
          >
            <span>{data.patientName ? data.patientName : "N/A"}</span>
          </div>
        );
      },
    },
    {
      columnName: "Hospital Name",
      style: { width: "250px", marginLeft: "20px" },
      render: (data) => {
        return (
          <div style={{ marginLeft: "60px", width: "250px" }}>
            <div
              style={{
                padding: "0px",
                display: "flex",
                width: "210px",
                marginRight: "max(10px,3vw)",
                marginLeft: "20px",
              }}
            >
              <div className="dashboard-table-row-personal">
                <p className="dashboard-table-row-title h4">
                  {data.nameOfDiagnostics}
                </p>
                <span>{data?.Location}</span>
              </div>
            </div>
          </div>
        );
      },
    },

    {
      columnName: "Prodcedure",
      style: { width: "140px", marginLeft: "20px" },
      type: "date",
      render: (data) => {
        return (
          <div style={{ marginLeft: "90px", width: "100px" }}>
            <span>{data.arrivalDate}</span>
          </div>
        );
      },
    },
    {
      columnName: "Status",

      render: (item) => {
        let colour = "rgb(152, 152, 7)";
        if (item.status === "Preliminary call") colour = "#60B3DD";
        if (item.status === "Requested quote") colour = "#60B3DD";
        if (item.status === "Shared quote") colour = "#6938EF";
        if (item.status === "Follow up") colour = "##DC6803";
        if (item.status === "Treatment finalised") colour = "##DC6803";
        if (item.status === "Visa") colour = "##DC6803";
        if (item.status === "Travel") colour = "#60B3DD";
        if (item.status === "Accommodation") colour = "#60B3DD";
        if (item.status === "Medical Investigation") colour = "#60B3DD";
        if (item.status === "Treatment Planning") colour = "#60B3DD";
        if (item.status === "Treatment In Progress") colour = "#60B3DD";
        if (item.status === "Treatment Completed") colour = "#42B140";

        return (
          <div
            style={{
              width: "250px !important",
              marginLeft: "70px",
            }}
          >
            {renderStatusForRow(item)}
          </div>
        );
      },
    },
  ];
  if (loading) return <Loading />;
  else
    return (
      <DashboardLayout sidebarItems={sidebarDiagnosticItems}>
        <div className="row m-0 left-text-box px-4 mt-4">
          <div className="col welcome-box  d-flex flex-column">
            <div className="welcome-text d-flex ">
              <h1 className="fw-bold mb-0 text-start">
                Welcome back, {user?.displayName}
              </h1>
            </div>
            <div className="muted-text d-flex ">
              <p className="text-muted mb-2 text-start">
                Track and manage your appointments
              </p>
            </div>
          </div>
        </div>
        <div className="row m-0 status-card px-md-5 px-2 mt-2 p-3">
          <div className="col-3 m-0 p-0">
            <div className="row m-0 p-0">
              {arr.map((item) => {
                return (
                  <div className="col-12 m-0 p-0">
                    <StatusCrad
                      status={rightTick}
                      icon={buildingPink}
                      number={number}
                      title={"All Patients"}
                      difference={diffGreen}
                      description={description}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-3 m-0 p-0">
            <div className="row m-0 p-0">
              {arr.map((item) => {
                return (
                  <div className="col-12 m-0 p-0">
                    <StatusCrad
                      status={rightTick}
                      icon={buildingActive}
                      number={number}
                      title={"Active"}
                      difference={diffGreen}
                      description={description}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-3 m-0 p-0">
            <div className="row m-0 p-0">
              {arr.map((item) => {
                return (
                  <div className="col-12 m-0 p-0">
                    <StatusCrad
                      status={rightTick}
                      icon={buildingCancelled}
                      number={number}
                      title={"Cancelled"}
                      difference={diffGreen}
                      description={description}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-3 m-0 p-0">
            <div className="row m-0 p-0">
              {arr.map((item) => {
                return (
                  <div className="col-12 m-0 p-0">
                    <StatusCrad
                      status={rightTick}
                      icon={buildingComplated}
                      number={"$2.8m"}
                      title={"Completed"}
                      difference={diffGreen}
                      description={description}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="row m-0 my-3 table-booking-box px-md-5 px-2">
          <div
            className="col p-0"
            style={{
              border: "1px solid gray",
              borderRadius: "12px",
            }}
          >
            <div
              className="row mx-0 upcoming-main-box px-md-5 px-2 "
              style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
            >
              <div className="col-9 upcoming-box  d-flex">
                <div style={{ marginBottom: "30px" }}>
                  <p
                    className="text-start mb-0"
                    style={{
                      fontWeight: "550",
                      fontSize: "25px",
                    }}
                  >
                    Upcoming Appointments
                  </p>
                </div>
                <div className="link-box d-flex ms-sm-4 mt-2 ">
                  <p className="m-0 fs-8">
                    <Link
                      to="/diagnostic/patient-management"
                      className="link-to-article"
                    >
                      View all →
                    </Link>
                  </p>
                </div>
              </div>
              <div className="col-3 upcoming-box  d-flex">
                <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                  <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Filter"
                    value={selectedFilter}
                    onChange={(event) => setSelectedFilter(event.target.value)}
                  >
                    <MenuItem value={"all"}>All</MenuItem>
                    <MenuItem value={"today"}>Today</MenuItem>
                    <MenuItem value={"tomorrow"}>Tomorrow</MenuItem>
                    <MenuItem value={"yesterday"}>Yesterday</MenuItem>
                    <MenuItem value={"this_week"}>This Week</MenuItem>
                    <MenuItem value={"this_month"}>This Month</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="row m-0  mb-0 px-0">
              <div className="col-12 p-0 table-section-container d-flex justify-content-start">
                <DashboardTable
                  columns={diagnosticTableConfig}
                  rows={updatedRows}
                  size={size}
                />{" "}
              </div>
            </div>
            {numberOfPages > 1 && (
              <DashboardTablePagination
                numberOfPages={numberOfPages}
                index={index}
                handleClick={handleClick}
              />
            )}
          </div>
        </div>

        <DashboardChartsContainer
          performanceData={performanceData}
          topHospitalData={topHospitalData}
          hospitalPerformanceValues={hospitalPerformanceValues}
          topHospitalValues={topHospitalValues}
        />
      </DashboardLayout>
    );
};

export default DiagnosticDashboard;
