import React from "react";
import { initChatBot, sendMessage } from "../../../utils/assistantOpenAI";
import { createCustomMessage } from "react-chatbot-kit";

// const input_text = "how many beds are avaiable in medanta gurgaon";
const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const fetchMessage = async (threadObject, input_text, assistantObject) => {
    try {
      const res = await sendMessage(threadObject, input_text, assistantObject);
      const botMessage = createChatBotMessage(res.message);
      setState((prev) => {
        let messages = prev.messages;
        if (messages.length > 0) {
          messages = prev.messages.filter((msg) => msg.type !== "loader");
        }
        return {
          ...prev,
          messages: [...messages, botMessage],
        };
      });
    } catch (err) {
      return err;
    }
  };
  const sendMessageToAssistant = (input_text) => {
    const loadingMsg = createCustomMessage("Test", "loader");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, loadingMsg],
    }));
    const initObject = initChatBot();
    fetchMessage(initObject.thread, input_text, initObject.assistant).catch();
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            sendMessageToAssistant,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
