import React, { useState, useEffect } from "react";
import "./TreatmentComponent.css";
import TreatmentCard from "../TreatmentCard/TreatmentCard";
import SimpleButton from "../SimpleButton/SimpleButton";
import axios from "axios";
import { API } from "../../constants/api";

const TreatmentComponent = ({
  section,
  handleChange,
  handleSubmit,
  doctors,
  optionArray,
  moveToPrev,
  moveToNext,
  setLoader,
}) => {
  const disabled =
    section.length === 0 ||
      section.every((ele) => ele.selectedDoctors.length === 0)
      ? "disabled"
      : "";

  const searchParams = new URLSearchParams(window.location.search);
  const hospitalId = searchParams.get('q');

  const nextHandle = async (event) => {
    console.log("You have", section);
    await section.map(async (payload) => {
      const doctorIds = payload.selectedDoctors.map((doctor) => doctor.id);
      // Creating the output object
      const outputData = {
        specializationName: payload.speciality,
        description: payload.description,
        doctorIds: doctorIds,
      };
      try {
        setLoader(true)
        const response = await axios.post(
          `${API}/agenthospital/addtreatment/${hospitalId}/specialties`,
          outputData
        );
        // Handle the API response here
        console.log(response.data);
        console.log("Iam Out ka put data:: ", outputData);
        setLoader(false)
        // alert("added the treatment")
      } catch (error) {
        // Handle errors here
        console.error(error);
      }
    });
    moveToNext(event);
  };

  const backHandle = (event) => {
    moveToPrev(event);
  };

  console.log("section in treatment component:", section);

  return (

    <div className='treatment-container'>
      <div className='treatment-button-container'>
        <SimpleButton doctors={doctors} handleSubmit={handleSubmit} />
      </div>
      <div className='treatment-section'>
        {section.length !== 0
          ? section.map((item, index) => (
            <TreatmentCard
              key={index}
              options={optionArray}
              item={item}
              handleChange={handleChange}
            />
          ))
          : null}
      </div>
      <div className='btn-action d-flex justify-content-end btn-container'>
        <button
          // onClick={props.moveToPrev}
          className={`btn btn-back ms-3`}
          onClick={backHandle}
        // disabled={props.disabled}
        >
          Back
        </button>
        <button
          // onClick={props.isLast ? props.onSubmit : props.moveToNext}
          // disabled={props.disabled}
          className={`btn btn-next ms-3 ${disabled}`}
          onClick={nextHandle}
          disabled={
            section.length === 0 ||
            !section.every((ele) => ele.selectedDoctors.length !== 0)
          }
        >
          Save and Next
        </button>
      </div>
    </div>
  );
};

export default TreatmentComponent;
