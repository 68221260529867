import axios from "axios";
import toast from "react-hot-toast";
import { API } from "../constants/api";
const consoleHospitalList = async (setHospitalList) => {
    try {
        const url = `${API}/hospital/getallhospitals`;
        const { data } = await axios.get(url);
        const { hospitals } = data;
        console.log({ hospitals });
        let hospitalList = [];
        for (let i of hospitals) {
            const { Name_Of_Hospital, Location } = i;
            hospitalList = [...hospitalList, { Name_Of_Hospital, Location }];
        }
        setHospitalList(hospitalList);
    }
    catch (err) {
        if (err.message) {
            if (err.message === "Network Error") {
                toast.error("Check your internet connection.");
            }
        }
        else {
            toast.error(err.response.data.message);
        }
    }
};
export default consoleHospitalList;