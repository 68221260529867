import React from "react";
import "./ReviewCard.css";

function ReviewCard({ description, name, designation }) {
  return (
    <div className="review-card">
      <div className="review-description">
        <p>{description}</p>
      </div>
      <div className="profile">
        <div className="profile-pic">
          <img
            src="https://www.w3schools.com/howto/img_avatar.png"
            alt="profile-pic"
          />
        </div>
        <div className="profile-name">
          <p>
            {name} <br /> {designation ? designation : "Patient"}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ReviewCard;
