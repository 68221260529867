import React from "react";
import CommonComponent from "../../components/CommonComponent/CommonComponent";
import TermsOfUseComponent from "./TermsOfUseComponent/TermsOfUseComponent";

const TermsAndConditionsPage = () => {
  return (
    <CommonComponent>
      <TermsOfUseComponent />
    </CommonComponent>
  );
};

export default TermsAndConditionsPage;
