import React from "react";
import "./Facilities.css";
import SectionHeading from "../../../../components/ProcedurePageComponents/SectionHeading/SectionHeading";
import { hospitalFacilitiesLogos } from "../../../../constants";
let facilities = [
  { facilityName: "Accommodation", available: true },
  { facilityName: "Interpreter", available: false },
  { facilityName: "Follow up", available: true },
  { facilityName: "Dedicated Support", available: true },
  { facilityName: "Coordination", available: true },
  { facilityName: "Visa", available: true },
];
const Facilities = ({ hospitalFacilities = facilities }) => {
  return (
    <div
      className="hospital-detail-page-facilities-container progress-section"
      id="hospital-details-facilities"
    >
      <SectionHeading
        text={"Facilities"}
        textAlign={"left"}
        textColor={"var(--primaryColor"}
      />
      <div className="hospital-detail-page-facilities">
        {hospitalFacilities &&
          hospitalFacilities.map((facility) => {
            if (facility?.available)
              return (
                <div className="hospital-facility-name-icon">
                  <img
                    src={hospitalFacilitiesLogos[facility?.facilityName]}
                    alt=""
                  />
                  <h5>{facility?.facilityName}</h5>
                </div>
              );
            else return;
          })}
      </div>
    </div>
  );
};

export default Facilities;
