import React, { useEffect } from "react";
import "./ReadBlog.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { AiFillFacebook } from "react-icons/ai";
import { FaTwitter, FaPinterestP, FaLinkedinIn } from "react-icons/fa";
import toast from "react-hot-toast";
import { API } from "../../constants/api";
export const TagChip = ({ tag }) => {
  return <h6 className="blog-tag-chip">{tag}</h6>;
};
export const StickyLinkBar = ({ blogUrl, text }) => {
  return (
    <div className="blog-sticky-side-bar">
      <div className="blog-sticky-side-bar-links">
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            blogUrl
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiFillFacebook />
        </a>
        <a
          href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
            blogUrl
          )}&text=${encodeURIComponent(text)}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTwitter />
        </a>
        <a
          href={`https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(
            blogUrl
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaPinterestP />
        </a>
        <a
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
            blogUrl
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedinIn />
        </a>
      </div>
    </div>
  );
};
const ReadBlog = () => {
  const { title } = useParams();

  const [blog, setBlog] = useState({});
  const previousMetaTitle = document.title;
  const previousMetaDescription =
    document.getElementById("meta-description").content;
  const getMetaDataAndBlog = async ({ isKeywordsExist }) => {
    try {
      const url = `${API}/blog/${title}`;
      let { data } = await axios.get(url);
      console.log({ data });
      if (data.success) {
        document.getElementById("read-blog-container").innerHTML =
          data.blog.htmlContent;
        const { title, description, relatedTags, thumbnail } = data.blog;
        document.title = title;
        document.getElementById("meta-description").content = description;
        let relatedTagsArray = JSON.parse(relatedTags);
        if (!isKeywordsExist) {
          const metaKeywords = document.createElement("meta");
          metaKeywords.name = "keywords";
          metaKeywords.id = "meta-keywords";
          metaKeywords.content = relatedTagsArray.join(",");
          document.head.append(metaKeywords);
        } else {
          const metaKeywords = document.getElementById("meta-keywords");
          metaKeywords.content = relatedTagsArray.join(",");
        }
        setBlog({ ...data.blog, relatedTags: relatedTagsArray });
      }
    } catch (err) {
      if (err.response) {
        return toast.error(err.response.data.message);
      }
      console.log(err.message);
    }
  };
  useEffect(() => {
    let previousMetaKeywords = "";
    let isExist = false;
    if (document.getElementById("meta-keywords")) {
      isExist = true;
      previousMetaKeywords = document.getElementById("meta-keywords");
    }
    getMetaDataAndBlog({ isKeywordsExist: isExist });
    return () => {
      document.title = previousMetaTitle;
      document.getElementById("meta-description").content =
        previousMetaDescription;
      if (isExist) {
        document.getElementById("meta-keywords").content = previousMetaKeywords;
      } else {
        if (document.getElementById("meta-keywords"))
          document.getElementById("meta-keywords").remove();
      }
    };
  }, []);

  return (
    <>
      <Navbar />

      <div className="blog-container">
        <StickyLinkBar
          blogUrl={`${process.env.REACT_APP_FRONTEND_URL}/read/blog/${blog?.title}`}
        />
        <div className="blog-container-box">
          <h1 className="blog-title">{blog?.title}</h1>
          <p className="blog-createdAt">
            BharatOne Care <span style={{ color: "black" }}>. </span>
            {blog?.createdAt}
          </p>
          <div className="blog-thumbnail">
            <img src={blog?.thumbnail} alt="" />
          </div>
          <div className="read-blog-container" id="read-blog-container"></div>
        </div>
        <hr />
        <div className="blog-tags-and-links">
          <div className="blog-tags">
            <h4>Tags:</h4>
            {blog.relatedTags &&
              blog.relatedTags.length > 0 &&
              blog.relatedTags.map((tag) => <TagChip key={tag} tag={tag} />)}
          </div>

          <div className="blog-share-links">
            <div className="blog-share">
              <p>SHARE ON</p>
              {/* <p></p> */}
              <div className="blog-link-icons">
                <div>
                  <AiFillFacebook />
                  <FaLinkedinIn />
                  <FaPinterestP />
                  <FaTwitter />
                </div>
                {/* <div>
              </div> */}
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ReadBlog;
// <div>
//       <Helmet>
//         <title>{blog?.title}</title>
//         <meta name="description" content={blog?.description} />
//         {/* Set keywords meta tag */}
//         <meta name="keywords" content={blog?.relatedTags} />
//         {/* Add other meta tags as needed */}
//       </Helmet>
//     </div>
