import React, { useEffect, useState } from "react";
import "./AboutProcedure.css";
import { accordionsList } from "../../../../constants.js";
import SectionHeading from "../../../../components/ProcedurePageComponents/SectionHeading/SectionHeading.jsx";
import AccordionControlled from "../../../../components/ProcedurePageComponents/Accordion/AccordionControlled.jsx";
const postProcedureCare = (ppc) => {
  return (
    <div>
      <div>{ppc["instructions"]}</div>
      <ol>
        {ppc.recommendations.map((x) => (
          <li>{x}</li>
        ))}
      </ol>
    </div>
  );
};
const AboutProcedure = (props) => {
  const [accordData, setaccordData] = useState(accordionsList);
  useEffect(() => {
    if (props.data) {
      const newData = accordData.map((ele) => {
        // if (ele.key == "post_procedure_care" && props.data[ele.key]) {
        //   return {
        //     ...ele,
        //     details: postProcedureCare(props.data[ele.key]),
        //   };
        // } else if (ele.key == "risk_factors" && props.data[ele.key]) {
        //   return {
        //     ...ele,
        //     details: props.data[ele.key].map((x) => `${x},`),
        //   };
        // } else if (ele.key == "symptoms" && props.data[ele.key]) {
        //   return {
        //     ...ele,
        //     details: props.data[ele.key].map((x) => `${x},`),
        //   };
        // } else
        if (props.data[ele.key]) {
          return { ...ele, details: props.data[ele.key] };
        } else {
          return ele;
        }
      });
      setaccordData(newData);
    }
  }, [props]);
  return (
    <div className="about-procedure-section progress-section" id="about">
      <SectionHeading text={"About"} />
      <AccordionControlled accordions={accordData} />
    </div>
  );
};

export default AboutProcedure;
