import React from "react";
import "./Console.css";
import ConsoleSidebar from "../../../components/AdminConsoleComponents/ConsoleSideBar/ConsoleSidebar";
import Topbar from "../../DashboardSidebar/Topbar";
import ProtectedRoute from "../../../components/ProtectedRoute/ProtectedRoute";
const Console = ({ children }) => {
  return (
    <ProtectedRoute>
      <div className="container-fluid">
        <div className="row p-0 sticky-top">
          <div className="left-side-box col p-0">
            <Topbar />
          </div>
        </div>
        <div className="row p-0 border flex-lg-row flex-column-reverse">
          <div className="sidebar-main-pink border border-md-top-0 col-lg-2 col min-vh-sm-100 d-flex justify-content-center justify-content-lg-start align-items-lg-start align-items-end p-0">
            <ConsoleSidebar />
          </div>
          <div className="col-lg-10 col">{children}</div>
        </div>
      </div>
    </ProtectedRoute>
  );
};

export default Console;
