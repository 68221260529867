import axios from "axios";
import React, { useEffect, useState } from "react";
import "./ContactUs.css";
import { RiStarSFill } from "react-icons/ri";
import { Input, TextField } from "@mui/material";
import { userTypes } from "../../constants";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import toast from "react-hot-toast";
import { API, SERVER } from "../../constants/api";
import FileInput from "../../components/InputTypes/FileInput/FileInput";
import FormGenerator from "../../components/FormGenerator/FormGenerator";
import { ref } from "firebase/storage";
import { storage } from "../../config/testFirebaseConfig";
import { uploadAndReturnUrls } from "../../constants/UploadeImageUrl";
import { ContactSideBar } from "../../constants/formconfig";
export const InputBox = ({
  label,
  type,
  placeholder = "",
  value,
  changeHandler,
}) => {
  return (
    <div className="portfolio-contact-us-input-box">
      <h6>
        {label}
        <RiStarSFill />
      </h6>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={changeHandler}
        required
      />
    </div>
  );
};
const ContactUs = ({ handleClickOutside }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userType, setUserType] = useState("");
  const [message, setMessage] = useState("");
  const [doctypeFalse, setDoctypeFalse] = useState(false);

  const form = {
    name: {
      value: "",
      isTouched: false,
    },
    contactNumber: {
      value: "",
      isTouched: false,
    },
    email: {
      value: "",
      isTouched: false,
    },
    report: {
      value: "",
      isTouched: false,
    },
    query: {
      value: "",
      isTouched: false,
    },
  };
  const [formData, setFormData] = useState(form);
  let formConfig = ContactSideBar[0];
  if (window.location.pathname === "/business") {
    let fields = ContactSideBar[0].fields;
    fields = fields.map((e) =>
      e[0].title === "report" ? [{ ...e[0], hidden: true }] : e
    );
    formConfig = { ...formConfig, fields: fields };
  }
  const submitContactDataHandler = async (e) => {
    e.preventDefault();
    if (!username || !email || !phoneNumber || !userType || !message) {
      alert("Please the required fields.");
      return;
    }
    const url = `${SERVER}/contact-us.json`;
    toast.promise(
      axios.post(url, {
        username,
        email,
        phoneNumber,
        userType,
        message,
      }),
      {
        loading: "Sending...",
        success: "Thanks for contacting us.",
        error: "Something went wrong.",
      }
    );
  };
  const phoneNumberStateChangeHandler = (e) => {
    let currentInput = e.target.value;
    console.log(currentInput);
    if (currentInput.length > 10 || currentInput < 0) return;
    setPhoneNumber(currentInput);
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: {
  //       ...formData[name],
  //       value: value,
  //     },
  //   });
  // };

  // const handleTouch = () => {
  //   setFormData({
  //     ...formData,
  //     isTouched: true,
  //   });
  // };

  const handleChange = (field, newValue) => {
    setFormData((obj) => {
      let tempObj = { ...obj };
      const tempField = { ...tempObj[field], value: newValue };
      tempObj[field] = tempField;
      return tempObj;
    });
    const tempObj = Object.create(formData);
    const tempField = { ...tempObj[field], value: newValue };
    tempObj[field] = tempField;
    console.log(formData);
  };
  const handleTouch = (field) => {
    setFormData((obj) => {
      const tempObj = { ...obj };
      tempObj[field] = { ...tempObj[field], isTouched: true };
      return tempObj;
    });
  };

  const handleClick = async (event) => {
    event.preventDefault();
    const nonFileData = {
      name: formData.name.value,
      contactNumber: formData.contactNumber.value,
      email: formData.email.value,
      query: formData.query.value,
    };
    try {
      const url = `${API}/admin/contact`;
      const result = await axios.post(url, nonFileData);

      const structuredLinks = {
        report: "",
      };
      const contactRef = ref(storage, `contact/${result.data.contactId}`);

      if (formData.report.value) {
        const fileData = [formData.report.value];
        await Promise.all(
          fileData.map(async (file, index) => {
            const uploadedFileUrls = await uploadAndReturnUrls(
              [file],
              contactRef
            );
            if (uploadedFileUrls.error) {
              // throw new Error(uploadedFileUrls.error);
              alert(uploadedFileUrls.error);
              setDoctypeFalse(true);
              await axios.delete(
                `${API}/admin/contact/${result.data.contactId}`
              );
            } else {
              structuredLinks[Object.keys(structuredLinks)[index]] =
                uploadedFileUrls[0];
            }
          })
        );
      }

      if (!doctypeFalse) {
        await axios.patch(
          `${API}/admin/upload/${result.data.contactId}`,
          structuredLinks
        );
        // console.log(response);
        setFormData(form);
        // console.log(response);
        handleClickOutside();
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <section className="portfolio-contact-us">
      <div className="portfolio-contact-us-heading">
        <h2>Contact Us</h2>
      </div>
      <p>
        If you have any questions or want to explore the opportunities of
        medical tourism with BharatOne Care, feel free to reach out to our
        dedicated support team.
      </p>
      <div className="portfolio-contact-us-input-box">
        <FormGenerator
          array={formConfig}
          handleChange={handleChange}
          handleTouch={handleTouch}
          formData={formData}
          landingPage
          handleClick={handleClick}
        />
      </div>
      {/* <div className="portfolio-contact-us-container">
        <form
          className="portfolio-contact-us-form"
          onSubmit={submitContactDataHandler}
        >
          <div className="contact-us-form-name-email-type">
            {" "}
            <InputBox
              label={"Full Name"}
              type={"text"}
              value={username}
              changeHandler={(e) => setUsername(e.target.value)}
            />
            <InputBox
              label={"Email"}
              type={"email"}
              value={email}
              changeHandler={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="contact-us-form-phone-user-type">
            <InputBox
              label={"Phone"}
              type={"number"}
              value={phoneNumber}
              changeHandler={phoneNumberStateChangeHandler}
            />
            <div className="portfolio-contact-us-input-box">
              <FileInput
                input={reports[0]}
                state={report}
                handleChange={handleChange}
                handleTouch={handleTouch}
              />
            </div>
            <div className="portfolio-contact-us-input-box">
              <h6>
                You are a
                <RiStarSFill />
              </h6>
              <select
                value={userType}
                className=""
                onChange={(e) => setUserType(e.target.value)}
                required
              >
                <option value="">Select</option>
                {userTypes &&
                  userTypes.map((val) => <option value={val}>{val}</option>)}
              </select>
            </div>
          </div>
          <div className="portfolio-contact-us-input-box">
            <h6>
              Message
              <RiStarSFill />
            </h6>
            <textarea
              cols={0}
              rows={0}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <button type="submit" className="contact-us-button">
            CONTACT US
          </button>
        </form>
      </div> */}
      <p>
        Begin your simplified healthcare journey with BharatOne Care. Click here
        to register and experience the best medical tourism services India has
        to offer.
      </p>
    </section>
  );
};

export default ContactUs;
