import React from 'react';
import { FcGoogle } from 'react-icons/fc';
import {HiOutlineMail} from 'react-icons/hi';
import { IconContext } from 'react-icons';
import './CreateAccountButton.css';

const CreateAccountButton = ({ handleSubmit, text, buttonType, icon }) => {
  const buttonClassName =
    buttonType === 'google'
      ? 'login-page-form-button-google'
      : 'login-page-form-button';

  const renderIcon = () => {
    if (buttonType === 'google') {
      return (
        <IconContext.Provider
          value={{
            size: '1.25rem',
            className: 'login-page-form-button-google-icon',
          }}
        >
          {icon === "HiOutlineMail" ? <HiOutlineMail /> : <FcGoogle />}
        </IconContext.Provider>
      );
    }
    return null;
  };

  return (
    <button className={buttonClassName} onClick={(e) => handleSubmit(e,buttonType,text)}>
      {renderIcon()}
      {text}
    </button>
  );
};

export default CreateAccountButton;



