import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./BookingMenuDropdown.css";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const BookingMenuDropdown = ({
  handleCancelBooking,
  handleDownloadInvoice,
  item,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  console.log(item);

  return (
    <div>
      <IconButton
        id='basic-button'
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup='true'
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => navigate(`/facilitator/bookings/${item.bookingId}`)}
        >
          View Booking
        </MenuItem>
        {item.status !== "Payment Completed" && (
          <MenuItem
            onClick={() => {
              handleCancelBooking(item);
              handleClose();
            }}
          >
            Cancel Booking
          </MenuItem>
        )}
        {item.status === "Payment Completed" && (
          <MenuItem
            onClick={() => {
              handleDownloadInvoice(item);
              handleClose();
            }}
          >
            Download Invoice
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default BookingMenuDropdown;
