import React, { useEffect } from "react";
import "./ContactUsSidebar.css";
import { IoMdClose } from "react-icons/io";
import ContactUs from "../../pages/ContactUs/ContactUs";
const ContactUsSidebar = ({ closeHandler }) => {
  useEffect(() => {
    let contactUsSideBarElement = document.getElementById(
      "portfolio-contact-us-sidebar"
    );
    if (contactUsSideBarElement) {
      // document.documentElement.style.overflow = "hidden";
      setTimeout(() => {
        contactUsSideBarElement.style.transform = "translateX(0%)";
      }, 50);
    }
    return () => {
      document.documentElement.style.overflow = "auto";
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleClose = () => {
    let contactUsSideBarElement = document.getElementById(
      "portfolio-contact-us-sidebar"
    );
    if (contactUsSideBarElement) {
      contactUsSideBarElement.style.transform = "translateX(100%)";
      setTimeout(() => closeHandler(), 100);
    }
  };
  const handleClickOutside = (event) => {
    let contactUsSideBarElement = document.getElementById(
      "portfolio-contact-us-sidebar"
    );
    if (
      contactUsSideBarElement &&
      !contactUsSideBarElement.contains(event.target)
    ) {
      contactUsSideBarElement.style.transform = "translateX(100%)";
      setTimeout(() => closeHandler(), 100);
    }
  };

  return (
    <>
      <div
        className="portfolio-contact-us-sidebar"
        id="portfolio-contact-us-sidebar"
      >
        <div className="portfolio-contact-us-sidebar-box">
          <div className="portfolio-contact-us-sidebar-close-button-box">
            <IoMdClose
              className="portfolio-contact-us-sidebar-close-button"
              onClick={closeHandler}
            />
          </div>
        </div>
        <ContactUs handleClickOutside={handleClose} />
      </div>
      {
        <div
          className="portfolio-contact-us-sidebar-dark-overlay"
          onClick={handleClickOutside}
        ></div>
      }
    </>
  );
};

export default ContactUsSidebar;
