import React from "react";
import { useState } from "react";
import "./AgentOnboarding.css";
import CommonComponent from "./../../components/CommonComponent/CommonComponent";
import FormGeneratorStore from "../../components/FormGenearatorAndStepper/FormGenearatorAndStepper";
import { agentTypes } from "../../constants/formconfig";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import getAccessToken from "../../utils/getAccessToken";
import { API } from "../../constants/api";
import Loader from "../../components/Loader/Loader";
import { useUserAuth } from "../../context/UserAuthContext";
import { ref, uploadBytes } from "firebase/storage";
import { storage } from "../../config/testFirebaseConfig";
import { uploadAndReturnUrls } from "../../constants/UploadeImageUrl";

export const AgentOnboarding = (_props) => {
  const { user } = useUserAuth();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (formData) => {
    console.log(formData);
    setLoader(true);
    const keys = Object.keys(formData);
    const imgData = [
      formData.Cancelled_cheque.value,
      formData.PAN_CARD.value,
      formData.Passport.value,
    ];
    const data = new FormData();
    keys.forEach((key) => {
      if (key === "Contact_Number") {
        const number = formData[key].value.phone.slice(
          formData[key].value.dialCode.length
        );
        data.append(key, `+${formData[key].value.dialCode}${number}`);
      } else if (
        key !== "Cancelled_cheque" &&
        key !== "Cancelled_cheque_2" &&
        key !== "PAN_CARD" &&
        key !== "Passport"
      ) {
        data.append(key, formData[key].value);
      }
      // }else {
      //   data.append(key, formData[key].value);
      // }
    });
    // console.log(keys);
    // for (const items in data) {
    //   console.log(items);
    // }
    console.log(data);
    // console.log(JSON.stringify(data));

    // console.log(data);
    const url = `${API}/agent/add`;

    try {
      const token = getAccessToken();
      const result = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(result);

      const agentImageRef = ref(storage, `agent/${result.data.id}`);

      const structuredLinks = {
        Cancelled_cheque: null,
        PAN_CARD: null,
        Passport: null,
      };

      await Promise.all(
        imgData.map(async (image, index) => {
          const uploadedAgentUrls = await uploadAndReturnUrls(
            [image],
            agentImageRef
          );
          console.log(uploadedAgentUrls);
          structuredLinks[Object.keys(structuredLinks)[index]] =
            uploadedAgentUrls[0];
        })
      );

      console.log("Final structuredLinks:", structuredLinks);

      const response = await axios.post(
        `${API}/agent/upload/${result.data.id}`,
        structuredLinks
      );

      await axios.patch(`${API}/user/updateuser/${user.uid}`, {
        isOnboarded: "true",
      });
      navigate("/agent/dashboard");
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };
  return (
    <div className="field-wrapper">
      <CommonComponent>
        {loader && <Loader />}
        <FormGeneratorStore
          agentTypes={agentTypes}
          submitHandler={(formData) => onSubmit(formData)}
        />
      </CommonComponent>
    </div>
  );
};
