import axios from "axios";
import toast from "react-hot-toast";
import { API } from "../constants/api";
export const dividePortfolioHospitalList = (data) => {
  let tempArr = data;
  let list = [];
  let subList = [];
  let x = window.innerWidth <= 600 ? 2 : 6;
  for (let i = 0; i < tempArr.length; i++) {
    subList.push(tempArr[i]);
    if (subList.length % x === 0) {
      list.push(subList);
      subList = [];
    }
  }
  if (subList.length > 0) {
    if (list.length >= 1 && subList.length < x) {
      let addedList = [];
      for (let i = 1; i < x - subList.length + 1; i++) {
        if (subList.length === x) break;
        const nextItem = list[0][i];
        addedList.push(nextItem);
      }
      for (let i = 0; i < addedList.length; i++) {
        subList.push(addedList[i]);
      }
    }
    list.push(subList);
  }

  return list;
};
const getPortfolioHospitals = async (setDynamicHospitals) => {
  try {
    const url = `${API}/portfolio/hospital/all`;
    const { data } = await axios.get(url);
    setDynamicHospitals(dividePortfolioHospitalList(data.portfolioHospitals));
  } catch (err) {
    const { message } = err;
    if (message === "Network Error")
      toast.error("Check your internent connection.");
    return;
  }
};
export default getPortfolioHospitals;
