import React, { useEffect } from "react";
import "./Button.css";
const Button = ({ text, fontSize, task = () => {}, openContact }) => {
  return (
    <button
      className="portfolio-button"
      onClick={() => (openContact ? openContact() : task())}
      style={{ fontSize: fontSize && fontSize }}
    >
      {text}
    </button>
  );
};

export default Button;
