import { listAll, deleteObject } from 'firebase/storage';

// Function to delete all images under a reference
export const deleteAllImagesRef = async (storageRef) => {
  try {
    const deletionResults = [];
    const imagesList = await listAll(storageRef);

    if (imagesList.items.length > 0) {
      for (const imageRef of imagesList.items) {
        await deleteObject(imageRef);
        deletionResults.push(true); // Push true if deletion was successful
      }

      console.log('All images under the reference deleted successfully');

    }


    return deletionResults;
  } catch (error) {
    console.error('Error deleting images:', error.message);
    return [];
  }
};