import React, { useEffect } from "react";
import { useState } from "react";
import BlogCard from "../../components/BlogCard/BlogCard";
import "./AllBlogs.css";
import axios from "axios";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { API } from "../../constants/api";
const AllBlogs = ({ setEditorState }) => {
  const [blogs, setBlogs] = useState([]);
  async function getAllBlogs() {
    const url = `${API}/blog/all`;
    const { data } = await axios.get(url);
    if (data.success) {
      setBlogs(data.blogs);
    }
  }
  useEffect(() => {
    getAllBlogs();
  }, []);
  return (
    <div>
      <Navbar />
      <h3 className="blogs-page-heading">BLOGS</h3>
      <div className="all-blogs">
        {blogs &&
          blogs.map((blog, i) => {
            return (
              <BlogCard
                setEditorState={setEditorState}
                key={blog?.id}
                title={blog?.title}
                htmlContent={blog?.htmlContent}
                blogImageUrl={blog?.thumbnail}
                description={blog?.description}
                id={blog?.id}
              />
            );
          })}
      </div>
      <Footer />
    </div>
  );
};

export default AllBlogs;
