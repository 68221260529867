import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import "./PartnerSelectionPage.css";
import PartnerCardComponent from "../../../components/PartnerCardComponent/PartnerCardComponent";
import img from "../../../assets/images/check-square.png";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { IconContext } from "react-icons";
import NextButtonComponent from "../../../components/NextButtonComponent/NextButtonComponent";
// import AlertComponent from "../../../components/AlertComponent/AlertComponent";

const PartnerSelectionComponent = (props) => {
  // const navigate = useNavigate();

  const [ticked, setTicked] = useState(false);
  const [partner, setPartner] = useState("");

  const handlePartnerSelection = (partnerType) => {
    if (partnerType === "Hospital") {
      if (
        partner === "" ||
        partner === "Facilitator" ||
        partner === "Hospital Onboarding by agent" ||
        partner === "Agent"
      ) {
        setPartner("Hospital");
      } else {
        setPartner("");
      }
    } else if (partnerType === "Facilitator") {
      if (
        partner === "" ||
        partner === "Hospital" ||
        partner === "Hospital Onboarding by agent" ||
        partner === "Agent"
      ) {
        setPartner("Facilitator");
      } else {
        setPartner("");
      }
    }
    // else if (partnerType === "Agent") {
    //   if (
    //     partner === "" ||
    //     partner === "Hospital" ||
    //     partner === "Hospital Onboarding by agent" ||
    //     partner === "Facilitator"
    //   ) {
    //     setPartner("Agent");
    //   } else {
    //     setPartner("");
    //   }
    // }
    else if (partnerType === "Diagnostic") {
      if (
        partner === "" ||
        partner === "Hospital" ||
        partner === "Hospital Onboarding by diagnostic center" ||
        partner === "Facilitator"
      ) {
        setPartner("Diagnostic");
      } else {
        setPartner("");
      }
    }
    // else if (partnerType === "Hospital Onboarding by agent"){
    //   if(partner === "" || partner === "Hospital" || partner === "Agent" || partner === "Facilitator"){
    //     setPartner("Hospital Onboarding by agent");
    //   }else{
    //     setPartner("");
    //   }
    // }
  };
  const handleTicked = () => {
    setTicked(!ticked);
  };

  return (
    <div className="partner-page-form">
      <h2>Select Partner Type</h2>
      <div className="partner-page-form-content row-flex">
        <PartnerCardComponent
          illustration="hospitalPartner"
          title="Hospital"
          partner={partner}
          handlePartnerClick={handlePartnerSelection}
        />
        <PartnerCardComponent
          illustration="userPartner"
          title="Facilitator"
          partner={partner}
          handlePartnerClick={handlePartnerSelection}
        />
        {/* <PartnerCardComponent
          illustration="userPartner"
          title="Agent"
          partner={partner}
          handlePartnerClick={handlePartnerSelection}
        /> */}
        <PartnerCardComponent
          illustration="diagnostic"
          title="Diagnostic"
          partner={partner}
          handlePartnerClick={handlePartnerSelection}
        />
        {/* <PartnerCardComponent illustration="hospitalPartner" title="Hospital Onboarding by agent" partner = {partner} handlePartnerClick = {handlePartnerSelection}/> */}
      </div>
      <div className="partner-page-form-content-resend-description">
        {ticked === false ? (
          <IconContext.Provider
            value={{ size: "1.5rem", className: "check-square-logo" }}
          >
            <MdCheckBoxOutlineBlank onClick={handleTicked} />
          </IconContext.Provider>
        ) : (
          <img
            src={img}
            alt="check-square"
            className="check-square-logo"
            onClick={handleTicked}
          ></img>
        )}
        I have read the
        <span
          className="partner-page-form-content-resend-description-link"
          onClick={() => props.openTermsAndConditions("Terms of Use")}
        >
          Terms of Use
        </span>
        <span className="left-margin">and</span>
        <span
          className="partner-page-form-content-resend-description-link"
          onClick={() => props.openTermsAndConditions("Privacy Policy")}
        >
          Privacy Policy
        </span>
      </div>
      <NextButtonComponent
        partner={partner}
        ticked={ticked}
        text="Next"
        onClick={props.submitPartner}
      />
    </div>
  );
};

export default PartnerSelectionComponent;
