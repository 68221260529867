import React from 'react'
import { FiEyeOff, FiEye } from "react-icons/fi";
import { IconContext } from "react-icons";

const PasswordInputComponent = (props) => {
    return (
      <div className = "login-page-form-input-container">
        <label htmlFor={props.name} className="login-page-form-label">
          {props.label}
        </label>
        <div className="login-page-form-input-password-container">
          <input
            type={props.passwordShown ? "text" : "password"}
            placeholder={props.placeholder}
            className={props.className}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
          />
          <IconContext.Provider
            value={{
              size: "1.25rem",
              className: "login-page-form-input-password-icon",
            }}
          >
            {props.passwordShown ? (
              <FiEye onClick={props.togglePasswordVisibility} />
            ) : (
              <FiEyeOff onClick={props.togglePasswordVisibility} />
            )}
          </IconContext.Provider>
        </div>
      </div>
    );
  };

export default PasswordInputComponent