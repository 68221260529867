//assests
import dashboardIcon from "../assets/DashboardAssets/dashboard.svg";
import hospitalmangement from "../assets/DashboardAssets/hospitalmanagement.svg";
import appointments from "../assets/DashboardAssets/appointments.svg";
import settings from "../assets/DashboardAssets/settings.svg";
import support from "../assets/DashboardAssets/support.svg";
import profile from "../assets/DashboardAssets/profile.svg";
import teamManagement from "../assets/DashboardAssets/teamManagement.svg";

//give your side bar content here

export const sidebarAgentItems = [
  {
    link: "/agent/dashboard",
    icon: dashboardIcon,
    altText: "Dashboard Icon",
    label: "Dashboard",
    display: "all", // 'all' means it's always displayed
  },
  {
    link: "/agent/hospital-management",
    icon: hospitalmangement,
    altText: "Hospital Management Icon",
    label: "Hospital Management",
    display: "all",
  },
  {
    link: "/agent/appointments",
    icon: appointments,
    altText: "Appointments Icon",
    label: "Appointments",
    display: "all",
  },
  {
    link: "/agent/settings",
    icon: settings,
    altText: "Settings Icon",
    label: "Settings",
    display: "lg", // 'lg' means displayed only on large screens
  },
  {
    link: "/agent/help-and-support",
    icon: support,
    altText: "Help & Support Icon",
    label: "Help & Support",
    display: "lg",
  },
  {
    link: "/agent/my-profile",
    icon: profile,
    altText: "My Profile Icon",
    label: "My Profile",
    display: "all",
  },
];

export const sidebarFacilitatorItems = [
  {
    link: "/facilitator/dashboard",
    icon: dashboardIcon,
    altText: "Dashboard Icon",
    label: "Dashboard",
    display: "all", // 'all' means it's always displayed
  },
  {
    link: "/facilitator/patient-management",
    icon: teamManagement,
    altText: "Patient Management Icon",
    label: "Patient Management",
    display: "lg", // Displayed only on large screens
  },
  {
    link: "/facilitator/bookings",
    icon: appointments,
    altText: "Bookings Icon",
    label: "Bookings",
    display: "all",
  },
  {
    link: "/facilitator/settings",
    icon: settings,
    altText: "Settings Icon",
    label: "Settings",
    display: "lg", // Displayed only on large screens
  },
  {
    link: "/facilitator/help-and-support",
    icon: support,
    altText: "Help & Support Icon",
    label: "Help & Support",
    display: "lg", // Displayed only on large screens
  },
  {
    link: "/facilitator/my-profile",
    icon: profile,
    altText: "My Profile Icon",
    label: "My Profile",
    display: "all",
  },
];

export const sidebarHospitalItems = [
  {
    link: "/hospital/dashboard",
    icon: dashboardIcon,
    altText: "Dashboard Icon",
    label: "Dashboard",
    display: "all", // 'all' means it's always displayed
  },
  // {
  //   link: "/agent/appointments/hospital-details?page=hospital&q=-NjbwNxYNfOmyinqWYXN", //static for now
  //   icon: hospitalmangement,
  //   altText: "Hospital Management Icon",
  //   label: "Hospital Management",
  //   display: "all",
  // },
  {
    link: "/hospital/bookings",
    icon: appointments,
    altText: "Bookings Icon",
    label: "Bookings",
    display: "all",
  },
  {
    link: "/hospital/settings",
    icon: settings,
    altText: "Settings Icon",
    label: "Settings",
    display: "lg", // Displayed only on large screens
  },
  {
    link: "/hospital/help-and-support",
    icon: support,
    altText: "Help & Support Icon",
    label: "Help & Support",
    display: "lg", // Displayed only on large screens
  },
  {
    link: "/hospital/my-profile",
    icon: profile,
    altText: "My Profile Icon",
    label: "My Profile",
    display: "all",
  },
];

export const sidebarAgencyItems = [
  {
    link: "/agency/dashboard",
    icon: dashboardIcon,
    altText: "Dashboard Icon",
    label: "Dashboard",
    display: "all", // 'all' means it's always displayed
  },
  {
    link: "#", // Replace '#' with the appropriate link
    icon: hospitalmangement,
    altText: "Hospital Management Icon",
    label: "Hospital Management",
    display: "all",
  },
  {
    link: "#", // Replace '#' with the appropriate link
    icon: appointments,
    altText: "Appointments Icon",
    label: "Appointments",
    display: "all",
  },
  {
    link: "/agency/settings", // Replace '#' with the appropriate link
    icon: settings,
    altText: "Settings Icon",
    label: "Settings",
    display: "lg", // Displayed only on large screens
  },
  {
    link: "/agency/help-and-support", // Replace '#' with the appropriate link
    icon: support,
    altText: "Help & Support Icon",
    label: "Help & Support",
    display: "lg", // Displayed only on large screens
  },
  {
    link: "#", // Replace '#' with the appropriate link
    icon: profile,
    altText: "My Profile Icon",
    label: "My Profile",
    display: "all",
  },
];

export const sidebarDiagnosticItems = [
  {
    link: "/diagnostic/dashboard",
    icon: dashboardIcon,
    altText: "Dashboard Icon",
    label: "Dashboard",
    display: "all", // 'all' means it's always displayed
  },
  {
    link: "/diagnostic/patient-management",
    icon: appointments,
    altText: "Bookings Icon",
    label: "Bookings",
    display: "all",
  },
  {
    link: "/diagnostic/settings",
    icon: settings,
    altText: "Settings Icon",
    label: "Settings",
    display: "lg", // Displayed only on large screens
  },
  {
    link: "/diagnostic/help-and-support",
    icon: support,
    altText: "Help & Support Icon",
    label: "Help & Support",
    display: "lg", // Displayed only on large screens
  },
  {
    link: "/diagnostic/my-profile",
    icon: profile,
    altText: "My Profile Icon",
    label: "My Profile",
    display: "all",
  },
];
