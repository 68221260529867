import { ref } from "firebase/storage";
import React, { useState } from "react";
import { storage } from "../../config/testFirebaseConfig";
import { uploadAndReturnUrls } from "../../constants/UploadeImageUrl";
import axios from "axios";
import { API } from "../../constants/api";

const LogoUploadTest = () => {
  const [hospitalId, setHospitalId] = useState("");
  const [Hospital_Id, setHospital_Id] = useState("");
  const [file, setFile] = useState("");
  const chooseHandler = (e) => {
    setFile(e.target.files[0]);
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    const imageData = [file];
    const hospitalLogoRef = ref(
      storage,
      `onboard_hospital/${Hospital_Id}/Hospital_Logo`
    );
    const uploadedLogoUrls = await uploadAndReturnUrls(
      imageData,
      hospitalLogoRef
    );
    console.log({ uploadedLogoUrls });
    const responseLogo = await axios.patch(
      `${API}/portfolio/hospital/upload/logo/${hospitalId}`,
      { Hospital_Icon: uploadedLogoUrls[0] }
    );
    console.log({ responseLogo });
  };
  return (
    <div>
          <form action="" onSubmit={submitHandler}>
              <p>portfolio</p>
        <input
          type="text"
          value={hospitalId}
          onChange={(e) => setHospitalId(e.target.value)}
              />
              <p>onboard</p>
        <input
          type="text"
          value={Hospital_Id}
          onChange={(e) => setHospital_Id(e.target.value)}
        />
        <input type="file" accept="/*" onChange={chooseHandler} />
        <input type="submit" />
      </form>
    </div>
  );
};

export default LogoUploadTest;
