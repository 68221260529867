import React, { useEffect, useState } from "react";
import "./Blogs.css";
import { blogs } from "../../constants";
import BlogCard from "../../components/BlogCard/BlogCard";
import { Link } from "react-router-dom";
import axios from "axios";
import { API } from "../../constants/api";
const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  async function getBlogs() {
    try {
      const url = `${API}/blog/portfolio`;
      const { data } = await axios.get(url);
      if (data.success) {
        setBlogs(data.blogs);
      }
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    getBlogs();
  }, []);
  return (
    <div className="portfolio-blogs-section" id="blogs">
      <div className="portfolio-blogs-section-heading">
        <h5 className="blogs-heading">BLOGS</h5>
        <h2>Find our Latest Articles Here</h2>{" "}
      </div>
      <div className="portfolio-blog-section-blogs">
        {blogs &&
          blogs.map((blog) => (
            <BlogCard
              title={blog?.title}
              blogImageUrl={blog?.thumbnail}
              description={blog?.description}
              id={blog?.id}
              key={blog?.id}
            />
          ))}
      </div>
      <p className="view-more-blogs">
        <Link to={"/blogs"}>View More</Link>
      </p>
    </div>
  );
};

export default Blogs;
