import React, { useState } from "react";
import BookingsTable from "../BookingsTable/BookingsTable";
import Search from "../../assets/images/search-lg.svg";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import DashboardLayout from "../../pages/DashboardSidebar/DashboardLayout";
import { sidebarHospitalItems } from "../../constants/SidebarItems";


const HospitalBookings = () => {
  const [searchedInput, setSearchedInput] = useState("");

  return (
    <DashboardLayout sidebarItems={sidebarHospitalItems}>
      <div className='p-4'>
        <Breadcrumb
          breadcrumbs={[{ label: "Bookings", url: "/hospital/bookings" }]}
          divider={"/"}
          styles={{
            paddingTop: "1rem",
          }}
        />

        <div>
          <div className='button-div'>
            <div
              className='button-div'
              style={{ justifyContent: "space-between", alignItems: "center" }}
            >
              {/* <div className='button-div-left-side'>
              <h1 className='appointment-header'>Patient Management</h1>
              <button
                className='Add-Appointments-button'
                onClick={() =>
                  navigate("/agent/hospital-management/add-hospital")
                }
              >
                + Add Patient
              </button>
            </div> */}

              <div className='button-div-right-side'>
                <img src={Search} alt='search' className='search-icon' />
                <input
                  type='text'
                  onChange={(event) => {
                    setSearchedInput(event.target.value);
                  }}
                  value={searchedInput}
                  className='searched-input-field'
                  placeholder='Search'
                />
                {/* <FilterPopup
                isVisible={isVisible}
                isClosing={isClosing}
                setIsClosing={setIsClosing}
                setIsVisible={setIsVisible}
                togglePopup={togglePopup}
              >
                <PatientFilterModel selectedFilters={selectedFilters} />
              </FilterPopup> */}
              </div>
            </div>
          </div>
        </div>

        <BookingsTable />
      </div>
    </DashboardLayout>
  );
};

export default HospitalBookings;
