import React, { useRef, useState, useEffect } from "react";
import { AddAppointmentFormConfig } from "../../constants/AppointmentsFormConfig";
import "./AddAppointments.css";
import cross from "../../assets/images/cross-icon-black.svg";
import FileInput from "../InputTypes/FileInput/FileInput";
import PhoneNumberInput from "../InputTypes/PhoneInput/PhoneInput";
import SingleSelectInput from "../InputTypes/SingleSelect/SingleSelectInput";
import SimpleInput from "../InputTypes/SimpleInput/SimpleInput";
import TextField from "@mui/material/TextField";
import { Autocomplete } from "@mui/material";

const AddAppointments = ({ handleModalClose, hospitalData, handleSubmit }) => {
  const today = new Date(); // get today's date
  const currentDate = new Date(today);
  currentDate.setDate(today.getDate() + 1);
  const year = `${currentDate.getFullYear()}`;
  let month = `${currentDate.getMonth() + 1}`;
  let date = "";
  if (currentDate.getMonth() + 1 < 10) {
    month = `0${currentDate.getMonth() + 1}`;
  }
  if (currentDate.getDate() < 10) {
    date = `0${currentDate.getDate()}`;
  }
  const minDate = `${year}-${month}-${date}`;
  console.log(minDate);
  const [hospitalInfo, setHospitalInfo] = useState({
    hospitalName: "",
    hospitalId: "",
  });

  const [appointmentDate, setAppointmentDate] = useState({
    value: "",
    isTouched: false,
  });
  useEffect(() => {
    setAppointmentDate({
      value: "",
      isTouched: false,
    });
  }, []);

  const disabled =
    hospitalInfo.hospitalId === "" ||
    hospitalInfo.hospitalName === "" ||
    appointmentDate.value === ""
      ? true
      : false;

  console.log(appointmentDate.value);

  const handleDateChange = (title, value) => {
    setAppointmentDate({
      value: value,
      isTouched: true,
    });
  };

  const handleDateTouch = (title) => {
    setAppointmentDate((prev) => {
      return {
        value: prev.value,
        isTouched: true,
      };
    });
  };

  const optionArray1 = hospitalData.map((item) => {
    return {
      value: item.hospitalId ?? "",
      label: item.hospitalId ?? "",
    };
  });

  const optionArray2 = hospitalData.map((item) => {
    return { value: item.hospitalName ?? "", label: item.hospitalName ?? "" };
  });

  const handleSelectChange1 = (event, value) => {
    const filteredData = hospitalData.filter(
      (item) => item.hospitalId === value?.value
    );
    setHospitalInfo({
      hospitalName: filteredData[0]?.hospitalName || "",
      hospitalId: filteredData[0]?.hospitalId || "",
    });
  };

  const handleSelectChange2 = (event, value) => {
    const filteredData = hospitalData.filter(
      (item) => item.hospitalName === value?.value
    );
    setHospitalInfo({
      hospitalName: filteredData[0]?.hospitalName || "",
      hospitalId: filteredData[0]?.hospitalId || "",
    });
  };

  const onSubmit = () => {
    const filteredData = hospitalData.filter(
      (item) => item.hospitalId === hospitalInfo.hospitalId
    );
    const tempPayload = filteredData[0];
    const payload = {
      name: tempPayload.hospitalName,
      id: tempPayload.hospitalId,
      contact: tempPayload.contact,
      speciality: tempPayload.speciality || [],
      totalStaff: tempPayload.totalStaff,
      status: "Scheduled",
      uid: tempPayload.uid,
      statusDate: appointmentDate.value,
      addedOn: tempPayload.addedOn,
      location: tempPayload.location,
      hospitalUid: tempPayload.uid,
      hospitalLogo: tempPayload.hospitalLogo,
    };

    handleSubmit(payload);

    handleModalClose();
    // setFormData(getIntitalFormDataState());
  };

  return (
    <div className="modal-background-1">
      <div className="modal-section">
        <div className="modal-header modal-header-1">
          <h5
            className="modal-title  add-appointment-model-title"
            id="exampleModalLabel"
          >
            Add Appointments
          </h5>
          <img
            src={cross}
            alt="cross"
            className="red-cross-button"
            onClick={() => {
              handleModalClose();
            }}
          />
        </div>
        <div className="modal-body">
          <div className="multi-select-box  mb-3">
            <label className="form-label d-flex mb-1 ms-1">Hospital Id</label>
            <Autocomplete
              className="multiple-select-field"
              isOptionEqualToValue={(option, value) =>
                option.id === value.id || value === ""
              }
              onChange={handleSelectChange1}
              value={hospitalInfo?.hospitalId || ""}
              id="combo-box-demo"
              options={optionArray1 || []}
              // getOptionLabel={(option) => option.label}
              renderInput={(params) => <TextField {...params} />}
              style={{
                marginTop: "15px",
                backgroundColor: "#F5F7F9",
              }}
            />
          </div>
          <div className="multi-select-box  mb-3">
            <label className="form-label d-flex mb-1 ms-1">Hospital Name</label>
            <Autocomplete
              className="multiple-select-field"
              isOptionEqualToValue={(option, value) =>
                option.id === value.id || value === ""
              }
              value={hospitalInfo?.hospitalName || ""}
              onChange={handleSelectChange2}
              id="combo-box-demo"
              options={optionArray2 || []}
              // getOptionLabel={(option) => option.label}
              renderInput={(params) => <TextField {...params} />}
              style={{ marginTop: "15px", backgroundColor: "#F5F7F9" }}
            />
          </div>
          <div className="multi-select-box  mb-3">
            <label htmlFor="date" className="form-label d-flex mb-1 ms-1">
              Appointment Date
            </label>
            <input
              type="date"
              name="date"
              placeholder="date"
              min={minDate}
              value={appointmentDate.value}
              onBlur={(event) => {
                handleDateTouch("date");
              }}
              onChange={(event) => {
                handleDateChange("date", event.target.value);
              }}
              className="form-control simple-field"
            ></input>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className={`my-btn-1 ${disabled === true ? "disabled" : ""}`}
            data-bs-dismiss="modal"
            disabled={disabled}
            onClick={onSubmit}
          >
            Add Appointment
          </button>
          <button
            type="button"
            className={`my-btn-1`}
            onClick={handleModalClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddAppointments;
