import React from 'react'
import './Loading.css';
const Loading = () => {
  return (
    <div className='business-page-loading'>
      <div className='business-page-loader'></div>
    </div>
  )
}

export default Loading
