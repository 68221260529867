import React from "react";
import B1cAvatarIcon from "../../../assets/images/b1c-caret-white.svg";
import "./style.css";

export const BOneAvatar = (_props) => {
  return (
    <div className={"bot-avatar"}>
      <img
        src={B1cAvatarIcon}
        className={"bot-avatar-icon"}
        alt="b1c-avatar-icon"
      />
    </div>
  );
};
