import React from "react";
import { useNavigate } from "react-router-dom";

const Breadcrumb = ({ breadcrumbs, divider, styles }) => {
  const navigate = useNavigate();

  const handleClick = (url) => {
    navigate(url);
  };
  const defaultStyles = {
    color: "#EF5EA2",
    fontWeight: "bolder",
    ...styles,
  };
  const combinedStyles = { ...defaultStyles, ...styles };
  return (
    <div style={combinedStyles}>
      {breadcrumbs.map((breadcrumb, index) => (
        <span key={breadcrumb.label}>
          {index > 0 && (
            <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
              {divider}
            </span>
          )}
          {index === breadcrumbs.length - 1 ? (
            <span
              style={{
                cursor: breadcrumb.disabled ? "default" : "pointer",
                fontWeight: "bold",
              }}
            >
              {breadcrumb.label}
            </span>
          ) : (
            <span
              style={{
                cursor: breadcrumb.disabled ? "default" : "pointer",
                fontWeight: "bold",
              }}
              onClick={
                breadcrumb.disabled
                  ? () => {}
                  : () => handleClick(breadcrumb.url)
              }
            >
              {breadcrumb.label}
            </span>
          )}
        </span>
      ))}
    </div>
  );
};

export default Breadcrumb;
