import React, { useState } from "react";
import "./ForgotPasswordPage.css";
import TextInputComponent from "../../../components/TextInputComponent/TextInputComponent";
import CreateAccountButton from "../../../components/CreateAccountButton/CreateAccountButton";
import { Link } from "react-router-dom";
import { auth, database } from "../../../config/testFirebaseConfig";
import { sendPasswordResetEmail } from "firebase/auth";
import toast from "react-hot-toast";

const ForgotPasswordPage = () => {
  const [resetPasswordEmail, setResetPasswordEmail] = useState("");

  const handleResetPassword = async () => {
    sendPasswordResetEmail(database, resetPasswordEmail)
      .then((data) => {
        toast.success("Check your mail for reset link");
      })
      .catch((error) => {
        console.log(error);
        if (error.code === "auth/invalid-email") {
          toast.error("Invalid email address");
        } else if (error.code === "auth/missing-email") {
          toast.error("Please provide an email");
        } else if (error.code === "auth/user-not-found") {
          toast.error("User not found.");
        } else {
          toast.error("An error occurred. Please try again later.");
        }
      });
  };

  const onEmailChange = (event) => {
    setResetPasswordEmail(event.target.value);
  };

  return (
    <div className="forgot-password-page-form">
      <h2>Forgot Password</h2>
      <div className="forgot-password-page-form-content">
        <TextInputComponent
          label="Email"
          placeholder="johnsondoe@gmail.com"
          className="forgot-password-page-form-input-text"
          name="email"
          value={resetPasswordEmail}
          onChange={(e) => onEmailChange(e)}
        />
        <CreateAccountButton
          handleSubmit={handleResetPassword}
          text="Reset Password"
        />
        <Link to="/login" className="forgot-password-page-form-back-to-login">
          Back to Login
        </Link>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
