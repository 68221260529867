import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import logo from "../../assets/images/b1c-logo.svg";
import { useUserAuth } from "../../context/UserAuthContext";

const Topbar = () => {
  //functions
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { user, logOut } = useUserAuth();
  const navigate = useNavigate();
  const urlSplit = window.location.pathname.split("/")
  const usertype = urlSplit && urlSplit.length > 1 ? urlSplit[1] : "";
  const settings = [
    { label: "Profile", link: usertype ? `/${usertype}/my-profile` : "/home" },
    { label: "Logout", action: () => { logOut(); navigate("/") } },
  ];
  console.log("iam user :::::::::::::", user?.displayName);
  const getInitials = (name) => {
    if (!name || typeof name !== "string" || !name.trim()) {
      return "U"; // Display 'U' when the name is not available or invalid
    }

    const nameArray = name.trim().split(" ");
    const initials = nameArray
      .map((part) => part.charAt(0))
      .join("")
      .toUpperCase();

    return initials || "U"; // Display 'U' when initials cannot be extracted from the name
  };
  const userInitials = getInitials(user?.displayName);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <div className='top-bar'>
      <AppBar
        position='sticky'
        style={{ backgroundColor: "#FDEEF5", boxShadow: "none" }}
      >
        <Container maxWidth='xxl' className=' d-flex justify-content-between '>
          <div className='logo-part d-flex py-2'>
            <Link
              to='/home'
              className='text-decoration-none d-flex justify-content-around align-items-center text-white'
            >
              <img src={logo} className='logo-img' alt='logo' />
            </Link>
          </div>
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 0 }} className=' d-flex align-items-center'>
              {/* <Link to='#' className='icon-bell-link mt-1'>
                <FontAwesomeIcon icon={faBell} className='me-4' />
              </Link> */}
              <Tooltip title='Open settings'>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={userInitials} src='/static/images/avatar/2.jpg'>
                    {userInitials}
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "55px" }}
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem
                    sx={{ minWidth: "10rem" }}
                    key={setting.label}
                    onClick={handleCloseUserMenu}
                  >
                    {setting.action ? (
                      <Typography textAlign='center' onClick={setting.action}>
                        {setting.label}
                      </Typography>
                    ) : (
                      <Link
                        to={setting.link}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Typography textAlign='center'>
                          {setting.label}
                        </Typography>
                      </Link>
                    )}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default Topbar;

//===================backup code =================>

// <nav className="navbar navbar-expand-lg p-2 justify-content-end">
//   <div className="container-fluid justify-content-end mx-2">
//     <ul className="navbar-nav d-flex flex-row align-items-center">
//       <Link className="icon-bell-link">
//         <FontAwesomeIcon icon={faBell} className=" me-4" />
//         {/* Bell Icon */}
//       </Link>
//       <li className="nav-item dropdown  dropstart">
//         <a
//           className="avatar"
//           href="#"
//           data-bs-toggle="dropdown"
//           aria-expanded="false"
//         >
//           <img
//             src="https://mdbcdn.b-cdn.net/img/new/avatars/1.webp"
//             className="rounded-circle shadow-4"
//             style={{ width: 40 }}
//             alt="Avatar"
//           />
//         </a>
//         <ul
//           className="dropdown-menu"
//           aria-labelledby="navbarDropdownMenuLink"
//         >
//           <li>
//             <a className="dropdown-item" href="#">
//               Settings
//             </a>
//           </li>
//           <li>
//             <a className="dropdown-item" href="#">
//               Profile
//             </a>
//           </li>
//           <li>
//             <a className="dropdown-item" href="#">
//               Logout
//             </a>
//           </li>
//         </ul>
//       </li>
//     </ul>
//   </div>
// </nav>
