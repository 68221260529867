import { ref, uploadBytes, getDownloadURL, listAll } from "firebase/storage";

export const BloguploadAndReturnUrls = async (files, storageRef, maxFiles = 8) => {
  try {
    // console.log('in uploader :::', files);
    const uploadedUrls = [];
    const filesToUpload = files.slice(0, maxFiles);

    for (const file of filesToUpload) {
      console.log("in uploader :::", files);
      const fileRef = ref(storageRef, file.name || "default.jpg");
      await uploadBytes(fileRef, file);
      const downloadUrl = await getDownloadURL(fileRef);
      uploadedUrls.push(downloadUrl);
    }
    // const listResult = await listAll(storageRef);
    // console.log(listResult);
    // for (const itemRef of listResult.items) {

    //   const downloadUrl = await getDownloadURL(itemRef);
    //   uploadedUrls.push(downloadUrl);
    // }

    console.log(
      "Files uploaded to Firebase Storage successfully",
      uploadedUrls
    );
    return uploadedUrls;
  } catch (error) {
    console.error("Error uploading files:", error.message);
    return [];
  }
};
