import React from "react";
import FilterMultiSelect from "../../components/InputTypes/FilterMultiSelect/FilterMultiSelect";
import { TextField } from "@mui/material";

export const CustomerDashboardFilters = (props) => {
  return (
    <div>
      <div className="dashboard-filter-container">
        {/* <p className="model-section-title">Select a booking date</p> */}
        {/* <TextField
          type="datetime-local"
          id="outlined-basic"
          label=""
          placeholder="Search Name"
          variant="outlined"
          size="small"
          InputProps={{
            style: {
              borderRadius: "0",
              // width: "27vw",
            },
          }}
          value={props.filterValues.date}
          onChange={(e) => {
            props.setFilterValues((prev) => ({
              ...prev,
              date: e.target.value,
            }));
          }}
          sx={{
            width: {
              xs: "94vw",
              sm: "50vw",
              md: "35vw",
              lg: "28vw",
              xl: "28vw",
            },
            paddingLeft: "1rem",
            // marginTop: "-0.625rem",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "black", // Default border color
              },
              "&:hover fieldset": {
                borderColor: "#EF5EA2", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "#EF5EA2", // Border color when focused
              },
            },
          }}
        /> */}
      </div>
      <div className="dashboard-filter-container">
        <FilterMultiSelect
          onSelect={(val) => {
            props.setFilterValues((prev) => ({
              ...prev,
              statusValue: val,
            }));
          }}
          value={props.filterValues.statusValue}
          size="small"
          options={props.statusOptions.map((x) => x.value)}
          hospital
          category="status"
          placeholder="Select status"
        />
      </div>
      <div className="dashboard-filter-container">
        <FilterMultiSelect
          onSelect={(val) => {
            props.setFilterValues((prev) => ({
              ...prev,
              diagnosticCenter: val,
            }));
          }}
          value={props.filterValues.diagnosticCenter}
          size="small"
          options={props.diagnosticCenterOptions}
          hospital
          category="hospital"
          placeholder="Select Diagnostic Center"
        />
      </div>
    </div>
  );
};
