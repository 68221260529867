// medicalTourismData.js

function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  
  function getRandomFloat(min, max) {
    return Math.random() * (max - min) + min;
  }
  
  const indianStatesData = [
    {
      state: 'Jan',
      totalHospitals: getRandomNumber(100, 300),
      averageTreatmentCost: getRandomNumber(5000, 15000),
      medicalTourists: getRandomNumber(5000, 20000),
      successRates: getRandomFloat(70, 95),
    },
    {
      state: 'Feb',
      totalHospitals: getRandomNumber(80, 250),
      averageTreatmentCost: getRandomNumber(6000, 18000),
      medicalTourists: getRandomNumber(4000, 18000),
      successRates: getRandomFloat(75, 92),
    },
    {
      state: 'Mar',
      totalHospitals: getRandomNumber(70, 200),
      averageTreatmentCost: getRandomNumber(5500, 16000),
      medicalTourists: getRandomNumber(3000, 15000),
      successRates: getRandomFloat(72, 94),
    },
    {
      state: 'Apr',
      totalHospitals: getRandomNumber(120, 350),
      averageTreatmentCost: getRandomNumber(4800, 14000),
      medicalTourists: getRandomNumber(6000, 22000),
      successRates: getRandomFloat(68, 90),
    },
    {
      state: 'May',
      totalHospitals: getRandomNumber(90, 220),
      averageTreatmentCost: getRandomNumber(5600, 17000),
      medicalTourists: getRandomNumber(3500, 16000),
      successRates: getRandomFloat(73, 93),
    },
    // Add more states as needed
  ];
  
  export default indianStatesData;
  