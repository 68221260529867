import React from "react";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import "./FAQs.css";
import FAQBox from "../../components/FAQBox.jsx/FAQBox";
import { BusinessPageFaqs } from "../../constants/faq";
const FAQs = ({ openContact }) => {
  return (
    <section className="portfolio-faqs-section">
      <div className="portfolio-faqs-heading">
        <h2 className="main-heading">
          Start Your Holistic Healthcare Journey in India Today
        </h2>
        <p className="sub-heading" id="FAQs">
          Explore Personalized Healthcare Solutions with Our Expert Guidance
        </p>
      </div>
      <SecondaryButton
        text={"Start Your Journey Now"}
        openContact={openContact}
      />

      <h5 className="faq-heading">FAQ</h5>
      <h3 className="question-answer-heading">Your Questions Answered here</h3>
      <div className="all-faqs-box">
        {BusinessPageFaqs.map((ele, id) => (
          <FAQBox question={ele.question} answer={ele.answer} id={id} />
        ))}
      </div>
    </section>
  );
};

export default FAQs;
