import React, { useEffect, useState } from "react";
import "./ProcedureCost.css";
import AveragePrice from "./AveragePrice.svg";
import Location from "./Location.svg";
import Skeleton from "@mui/material/Skeleton";
import axios from "axios";
import { API } from "../../constants/api";
import { procedureTranslation } from "../../constants/Translation";
import getCurrencyConversion from "../../utils/getCurrencyConversion";

const ProcedureCost = ({ language }) => {
  const [procedureData, setProcedureData] = useState([]);
  const [showCarousel, setShowCarousel] = useState(false);
  const [loadingData, setLoadingdata] = useState(false);
  const [searchword, setSearchWord] = useState("");
  const [procedureValue, setProcedureValue] = useState({});
  const [currency, setCurrency] = useState("INR");
  const [currentCurrency, setCurrentCurrency] = useState("INR");
  const arr1 = [0, 1, 2];

  // const changeCurrency = (e) => {
  //   if (e.target.value === "USD") {
  //     console.log("USD");
  //     setCurrency("USD");
  //     setProcedureData((prev) => {
  //       return {
  //         ...prev,
  //         procedures: prev.procedures.map((item) => {
  //           return {
  //             ...item,
  //             hospitalsData: item.hospitalsData.map((item) => {
  //               return { ...item, amount: item.amount / 83 };
  //             }),
  //           };
  //         }),
  //       };
  //     });
  //   }
  //   if (e.target.value === "INR") {
  //     setCurrency("INR");
  //     console.log("INR");
  //     setProcedureData((prev) => {
  //       return {
  //         ...prev,
  //         procedures: prev.procedures.map((item) => {
  //           return {
  //             ...item,
  //             hospitalsData: item.hospitalsData.map((item) => {
  //               return { ...item, amount: item.amount * 83 };
  //             }),
  //           };
  //         }),
  //       };
  //     });
  //   }
  // };
  const currencyChangeHandler = (e) => {
    setCurrency(e.target.value);
  };
  const handleSearchWords = async () => {
    try {
      console.log(searchword);
      if (searchword == "") return;
      setLoadingdata(true);
      setShowCarousel(true);
      const response = await axios.get(
        `${API}/procedures/getprocedure/${searchword}`
      );
      console.log(response);
      if (response) {
        if (currency !== "INR") {
          currencyConvertHandler(response.data, true);
        } else {
          setProcedureData(response.data);
          setLoadingdata(false);
        }
      } else {
        console.error("Error fetching data:");
      }
    } catch (error) {
      console.error("Error fetching data pf search word:", error);
    }
  };

  const goToProcedure = (name, hospital, location) => {
    window.location.href = `/procedure?name=${name}&hospital=${hospital}&location=${location}`;
  };
  const currencyConvertHandler = async (data, isNewDataFetched = false) => {
    setLoadingdata(true);
    try {
      if (data) {
        const { hospitalsData } = data.procedures[0];
        for (let i of hospitalsData) {
          if (i?.amount) {
            i.amount = Math.round(
              await getCurrencyConversion({
                from: isNewDataFetched ? "INR" : currentCurrency,
                to: currency,
                amount: i.amount,
              })
            );
          }
        }
        setProcedureData({
          ...data,
          procedures: [{ ...data.procedures[0], hospitalsData }],
        });
        // alert("changed");
        setCurrentCurrency(currency);
        setLoadingdata(false);
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    if (currentCurrency !== currency) {
      // alert("From " + currentCurrency + "to " + currency);
      currencyConvertHandler(procedureData);
    }
  }, [currency]);

  useEffect(() => {
    console.log({ procedureData });
    console.log(currency);
    if (language === "English") {
      setProcedureValue(procedureTranslation.en);
    } else if (language === "Hindi") {
      setProcedureValue(procedureTranslation.hi);
    } else if (language === "Arabic") {
      setProcedureValue(procedureTranslation.ar);
    } else {
      setProcedureValue(procedureTranslation.en);
    }
  }, [language, procedureData]);

  return (
    <div className="container compare-container">
      <div className="boxtext-1 row">
        <div className="col d-flex flex-column align-items-center text-container ">
          <div className="compare-top-heading mb-3 text-center">
            <h2>{procedureValue?.header}</h2>
          </div>

          <div className="compare-top-subheading text-center">
            <h4>{procedureValue?.subheader}</h4>
          </div>
        </div>
      </div>

      <div className="boxsearch-2 row  justify-content-md-between justify-content-center">
        <div className="col">
          <div className="row justify-content-center">
            <div className="col-md-4 col-10 d-flex">
              <div className="input-group mb-md-1 mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder={procedureValue?.searchPlaceholder}
                  aria-label="Recipient's username"
                  aria-describedby="button-addon2"
                  onChange={(e) => setSearchWord(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") handleSearchWords();
                  }}
                />
                <button
                  className="search-btn btn btn-md"
                  type="button"
                  id="button-addon2"
                  onClick={handleSearchWords}
                >
                  {procedureValue?.searchButton}
                </button>
              </div>
            </div>

            <div className="select-box col-md-2 col-auto mt-1 ms-2">
              <select
                id="inputState"
                className="form-select"
                onChange={currencyChangeHandler}
                value={currency}
                disabled={procedureData.length === 0 || loadingData}
              >
                {/* <option selected>{procedureValue?.currency}</option> */}
                <option value="USD">&#36; USD</option>
                <option value="INR">&#8377; INR</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`carousel-text-box row mb-4 ${
          showCarousel ? "" : "box-hidden"
        }`}
      >
        {/* <div className="skeleton-large-box d-flex flex-column col-md-3  p-1">

          <div className="money-skeleton d-flex align-items-center">
            <Skeleton variant="circular" style={{ width: '3rem', height: '3rem', marginLeft: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '3rem' }} style={{ width: '65%', marginLeft: '1rem' }} />
          </div>

          <Skeleton variant="rounded" style={{ width: '85%', height: '9rem', marginLeft: '1rem' }} className='mb-2' />
        </div> */}

        <div className="col d-flex justify-content-center">
          <div className="carousel-text-head text-center ">
            <h4>
              {procedureValue?.treatment}{" "}
              {procedureData.length !== 0
                ? procedureData.procedures[0]?.name
                : "No data"}
            </h4>
          </div>
        </div>
      </div>

      {/* Main carousel cards */}

      <div
        className={`carousel-box-card row mb-1 ${
          showCarousel ? "" : "box-hidden"
        }`}
      >
        <div className="col carousel-box">
          {/* Larger screen */}

          <div
            id="carouselExampleControls"
            className="carousel slide  d-none d-md-block"
            data-bs-ride="carousel"
          >
            {loadingData ? (
              <div className="carousel-inner">
                {arr1.map((val, index1) => {
                  return (
                    <div
                      key={index1}
                      className={`carousel-item ${
                        index1 === 0 ? "active" : ""
                      }`}
                    >
                      <div className="card-wrapper">
                        <div className="skeleton-large-box d-flex flex-column col-md-3  p-1">
                          <div className="money-skeleton d-flex align-items-center">
                            <Skeleton
                              variant="circular"
                              style={{
                                width: "3rem",
                                height: "3rem",
                                marginLeft: "1rem",
                              }}
                            />
                            <Skeleton
                              variant="text"
                              sx={{ fontSize: "3rem" }}
                              style={{ width: "65%", marginLeft: "1rem" }}
                            />
                          </div>

                          <Skeleton
                            variant="rounded"
                            style={{
                              width: "85%",
                              height: "9rem",
                              marginLeft: "1rem",
                            }}
                            className="mb-2"
                          />
                        </div>

                        <div className="skeleton-large-box d-flex flex-column col-md-3  p-1">
                          <div className="money-skeleton d-flex align-items-center">
                            <Skeleton
                              variant="circular"
                              style={{
                                width: "3rem",
                                height: "3rem",
                                marginLeft: "1rem",
                              }}
                            />
                            <Skeleton
                              variant="text"
                              sx={{ fontSize: "3rem" }}
                              style={{ width: "65%", marginLeft: "1rem" }}
                            />
                          </div>

                          <Skeleton
                            variant="rounded"
                            style={{
                              width: "85%",
                              height: "9rem",
                              marginLeft: "1rem",
                            }}
                            className="mb-2"
                          />
                        </div>

                        <div className="skeleton-large-box d-flex flex-column col-md-3  p-1">
                          <div className="money-skeleton d-flex align-items-center">
                            <Skeleton
                              variant="circular"
                              style={{
                                width: "3rem",
                                height: "3rem",
                                marginLeft: "1rem",
                              }}
                            />
                            <Skeleton
                              variant="text"
                              sx={{ fontSize: "3rem" }}
                              style={{ width: "65%", marginLeft: "1rem" }}
                            />
                          </div>

                          <Skeleton
                            variant="rounded"
                            style={{
                              width: "85%",
                              height: "9rem",
                              marginLeft: "1rem",
                            }}
                            className="mb-2"
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              procedureData?.procedures?.map((hospital, index0) => {
                const hospitalsData = hospital.hospitalsData.slice(); // Copy the hospitalsData array
                const dataLength = hospitalsData.length;

                // Duplicate data if it's less than 6
                if (dataLength < 9) {
                  const remaining = 9 - dataLength;
                  for (let i = 0; i < remaining; i++) {
                    hospitalsData.push(hospitalsData[i % dataLength]);
                  }
                }

                return (
                  <div className="carousel-inner" key={index0}>
                    {arr1.map((val, index1) => {
                      // Slice to display only 3 items for each iteration
                      const slicedData = hospitalsData.slice(
                        index1 * 3,
                        index1 * 3 + 3
                      );

                      return (
                        <div
                          key={index1}
                          className={`carousel-item ${
                            index1 === 0 ? "active" : ""
                          }`}
                        >
                          <div className="card-wrapper">
                            {slicedData.map((data, index2) => (
                              <div
                                className="card px-3 py-1"
                                key={index2}
                                onClick={() =>
                                  goToProcedure(
                                    procedureData.procedures[0]?.name,
                                    data.Name_Of_Hospital,
                                    data.Location
                                  )
                                }
                              >
                                <div className="card-body">
                                  <div className="currency-price-box row mb-2">
                                    <div className="col-auto p-0 d-flex align-items-center">
                                      <img
                                        src={AveragePrice}
                                        alt="AverageIcon"
                                      />
                                    </div>
                                    <div className="col text-content p-2">
                                      <h6 className="card-subtitle mb-1 text-muted">
                                        {procedureValue?.start}
                                      </h6>
                                      <h3 className="price-text mb-0">
                                        {currency === "USD" ? (
                                          <span>&#36;</span>
                                        ) : (
                                          <span>&#8377;</span>
                                        )}
                                        {data.amount}
                                      </h3>
                                    </div>
                                  </div>

                                  <div className="location-name-box row">
                                    <div className="col-auto d-flex align-items-start">
                                      <img
                                        src={Location}
                                        alt="LocationIcon"
                                        className="mt-2"
                                      />
                                    </div>

                                    <div className="col text-content p-1">
                                      <h4
                                        className="hospital-name-text text-truncate mb-2"
                                        style={{ maxWidth: "200px" }}
                                      >
                                        {data.Name_Of_Hospital}
                                      </h4>
                                      <h6 className="card-subtitle mb-1 text-muted">
                                        {data.Location}
                                      </h6>
                                      <a
                                        href="#"
                                        className="card-link text-dark"
                                      >
                                        {procedureValue?.viewDoctors}{" "}
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })
            )}
            <button
              className="pre-btn"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="next-btn"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="visually-hidden">Next</span>
            </button>
            {/* <button
              className="pre-btn"
              type="button"
              data-bs-target="#carouselExampleControlsSmallScreen"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
              <p>
                &lt;
              </p>
            </button>
            <button
              className="next-btn"
              type="button"
              data-bs-target="#carouselExampleControlsSmallScreen"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
              <p>
                &gt;
              </p>
            </button> */}
            {/* <button
              data-bs-target="#carouselExampleControlsSmallScreen"
              data-bs-slide="prev"
              className="pre-btn"
            >
              <p>&lt;</p>
            </button>
            <button
              data-bs-target="#carouselExampleControlsSmallScreen"
              data-bs-slide="next"
              className="next-btn"
            >
              <p>&gt;</p>
            </button> */}
          </div>

          {/* Small screen */}
          <div
            id="carouselExampleControlsSmallScreen"
            className="carousel slide d-md-none "
            data-bs-ride="carousel"
          >
            {procedureData?.procedures?.map((hospital, index0) => {
              return (
                <div className="carousel-inner">
                  {hospital.hospitalsData.map((data, index1) => {
                    return (
                      <div
                        className={`carousel-item ${
                          index1 === 0 ? "active" : ""
                        }`}
                        key={index1}
                      >
                        <div className="card px-3 py-1">
                          <div className="card-body">
                            <div className="currency-price-box row mb-2">
                              <div className="col-auto p-0 d-flex align-items-center">
                                <img src={AveragePrice} alt="AverageIcon" />
                              </div>
                              <div className="col text-content p-2">
                                <h6 className="card-subtitle mb-1 text-muted">
                                  {procedureValue?.start}
                                </h6>
                                <h3 className="price-text mb-0">
                                  {currency === "USD" ? (
                                    <span>&#36;</span>
                                  ) : (
                                    <span>&#8377;</span>
                                  )}
                                  {data.amount}
                                </h3>
                              </div>
                            </div>

                            <div className="location-name-box row">
                              <div className="col-auto d-flex align-items-start">
                                <img
                                  src={Location}
                                  alt="LocationIcon"
                                  className="mt-2"
                                />
                              </div>

                              <div className="col text-content p-1">
                                <h4
                                  className="hospital-name-text text-truncate mb-2"
                                  style={{ maxWidth: "200px" }}
                                >
                                  {data.Name_Of_Hospital}
                                </h4>
                                <h6 className="card-subtitle mb-1 text-muted">
                                  {data.Location}
                                </h6>
                                <a href="#" className="card-link text-dark">
                                  {procedureValue?.viewDoctors}{" "}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {/* <div className="carousel-item">

                      <div className="card px-3 py-1">
                        <div className="card-body">

                          <div className="currency-price-box row mb-2">
                            <div className="col-auto p-0 d-flex align-items-center">
                              <img src={AveragePrice} alt="AverageIcon" />
                            </div>
                            <div className="col text-content p-2">
                              <h6 className="card-subtitle mb-1 text-muted">Starting at</h6>
                              <h3 className="price-text mb-0">INR 1,20,000</h3>


                            </div>
                          </div>

                          <div className="location-name-box row">

                            <div className="col-auto d-flex align-items-start">
                              <img src={Location} alt="LocationIcon" className='mt-2' />
                            </div>

                            <div className="col text-content p-1">
                              <h4 className="hospital-name-text text-truncate mb-2" style={{ maxWidth: '200px' }}>Manipla Hospital</h4>
                              <h6 className="card-subtitle mb-1 text-muted">Chennai</h6>
                              <a href="#" className="card-link text-dark">View top doctors </a>

                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div className="carousel-item">

                      <div className="card px-3 py-1">
                        <div className="card-body">

                          <div className="currency-price-box row mb-2">
                            <div className="col-auto p-0 d-flex align-items-center">
                              <img src={AveragePrice} alt="AverageIcon" />
                            </div>
                            <div className="col text-content p-2">
                              <h6 className="card-subtitle mb-1 text-muted">Starting at</h6>
                              <h3 className="price-text mb-0">INR 1,20,000</h3>


                            </div>
                          </div>

                          <div className="location-name-box row">

                            <div className="col-auto d-flex align-items-start">
                              <img src={Location} alt="LocationIcon" className='mt-2' />
                            </div>

                            <div className="col text-content p-1">
                              <h4 className="hospital-name-text text-truncate mb-2" style={{ maxWidth: '200px' }}>Manipla Hospital</h4>
                              <h6 className="card-subtitle mb-1 text-muted">Chennai</h6>
                              <a href="#" className="card-link text-dark">View top doctors </a>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">

                      <div className="card px-3 py-1">
                        <div className="card-body">

                          <div className="currency-price-box row mb-2">
                            <div className="col-auto p-0 d-flex align-items-center">
                              <img src={AveragePrice} alt="AverageIcon" />
                            </div>
                            <div className="col text-content p-2">
                              <h6 className="card-subtitle mb-1 text-muted">Starting at</h6>
                              <h3 className="price-text mb-0">INR 1,20,000</h3>


                            </div>
                          </div>

                          <div className="location-name-box row">

                            <div className="col-auto d-flex align-items-start">
                              <img src={Location} alt="LocationIcon" className='mt-2' />
                            </div>

                            <div className="col text-content p-1">
                              <h4 className="hospital-name-text text-truncate mb-2" style={{ maxWidth: '200px' }}>Manipla Hospital</h4>
                              <h6 className="card-subtitle mb-1 text-muted">Chennai</h6>
                              <a href="#" className="card-link text-dark">View top doctors </a>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="carousel-item">

                      <div className="card px-3 py-1">
                        <div className="card-body">

                          <div className="currency-price-box row mb-2">
                            <div className="col-auto p-0 d-flex align-items-center">
                              <img src={AveragePrice} alt="AverageIcon" />
                            </div>
                            <div className="col text-content p-2">
                              <h6 className="card-subtitle mb-1 text-muted">Starting at</h6>
                              <h3 className="price-text mb-0">INR 1,20,000</h3>


                            </div>
                          </div>

                          <div className="location-name-box row">

                            <div className="col-auto d-flex align-items-start">
                              <img src={Location} alt="LocationIcon" className='mt-2' />
                            </div>

                            <div className="col text-content p-1">
                              <h4 className="hospital-name-text text-truncate mb-2" style={{ maxWidth: '200px' }}>Manipla Hospital</h4>
                              <h6 className="card-subtitle mb-1 text-muted">Chennai</h6>
                              <a href="#" className="card-link text-dark">View top doctors </a>

                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div className="carousel-item">

                      <div className="card px-3 py-1">
                        <div className="card-body">

                          <div className="currency-price-box row mb-2">
                            <div className="col-auto p-0 d-flex align-items-center">
                              <img src={AveragePrice} alt="AverageIcon" />
                            </div>
                            <div className="col text-content p-2">
                              <h6 className="card-subtitle mb-1 text-muted">Starting at</h6>
                              <h3 className="price-text mb-0">INR 1,20,000</h3>


                            </div>
                          </div>

                          <div className="location-name-box row">

                            <div className="col-auto d-flex align-items-start">
                              <img src={Location} alt="LocationIcon" className='mt-2' />
                            </div>

                            <div className="col text-content p-1">
                              <h4 className="hospital-name-text text-truncate mb-2" style={{ maxWidth: '200px' }}>Manipla Hospital</h4>
                              <h6 className="card-subtitle mb-1 text-muted">Chennai</h6>
                              <a href="#" className="card-link text-dark">View top doctors </a>

                            </div>
                          </div>
                        </div>
                      </div>

                    </div> */}
                </div>
              );
            })}
            <button
              className="pre-btn"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="next-btn"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="visually-hidden">Next</span>
            </button>
            {/* <button
              data-bs-target="#carouselExampleControlsSmallScreen"
              data-bs-slide="prev"
              className="pre-btn"
            >
              <p>&lt;</p>
            </button>
            <button
              data-bs-target="#carouselExampleControlsSmallScreen"
              data-bs-slide="next"
              className="next-btn"
            >
              <p>&gt;</p>
            </button> */}
            {/* <button
              className="pre-btn"
              type="button"
              data-bs-target="#carouselExampleControlsSmallScreen"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
              <p>
                &lt;
              </p>
            </button>
            <button
              className="next-btn"
              type="button"
              data-bs-target="#carouselExampleControlsSmallScreen"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
              <p>
                &gt;
              </p>
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcedureCost;
