import React, { useEffect, useState } from "react";
import "./ImageSlider.css";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import useImageSlider from "../../Hooks/useImageSlider";
const ImageSlider = ({ images }) => {
  const { slide, nextSlideHandler, previousSliderHandler, imagesSlideArr } =
    useImageSlider(images);

  useEffect(() => {}, [images, imagesSlideArr]);
  return (
    <div className="b1c-image-slider">
      <div className="b1c-image-slider-container">
        {imagesSlideArr &&
          imagesSlideArr.map((list, i) => {
            return (
              <div
                key={i}
                className="b1c-image-slider-slide"
                style={{
                  transform: `translateX(${100 * (i - slide)}%)`,
                }}
              >
                {list &&
                  list.map((image, ind) => {
                    return (
                      <div
                        key={ind}
                        className="b1c-image-slider-slide-image-box"
                      >
                        <img src={image} alt="" />
                      </div>
                    );
                  })}
              </div>
            );
          })}
        {/* <div className="b1c-image-slider-slide"></div> */}

        <button
          onClick={nextSlideHandler}
          disabled={slide === imagesSlideArr.length - 1}
          name="next"
        >
          <MdNavigateNext />
        </button>
        <button
          onClick={previousSliderHandler}
          name="prev"
          disabled={slide === 0}
        >
          <MdNavigateBefore />
        </button>
      </div>{" "}
      <div className="b1c-image-slider-dots">
        {imagesSlideArr &&
          imagesSlideArr.map((v, i) => {
            return (
              <div
                className={`b1c-image-slider-dot ${
                  slide === i && "b1c-image-slider-dot-active"
                }`}
              ></div>
            );
          })}
      </div>
    </div>
  );
};

export default ImageSlider;
