import React, { useCallback, useEffect, useState } from "react";
import "./HospitalOnboardByAgent.css";
import axios from "axios";
import Skeleton from "@mui/material/Skeleton";

import { hospitalOnbaordByAgent } from "../../constants/formconfig";
import DashboardFormGenerator from "../../components/DashboardFormGenerator/DashboardFormGenerator";
import { useNavigate } from "react-router-dom";
import getAccessToken from "../../utils/getAccessToken";
import HospitalDataComponent from "../../components/HospitalDataComponent/HospitalDataComponent";
import { useLocation } from "react-router-dom";
import DashboardLayout from "../DashboardSidebar/DashboardLayout";

import { API } from "../../constants/api";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import {
  sidebarAgentItems,
  sidebarHospitalItems,
} from "../../constants/SidebarItems";
import { format } from "date-fns";
import Loader from "../../components/Loader/Loader";
import { deleteAllImagesRef } from "../../constants/DeleteUrlsImages";
import { storage } from "../../config/testFirebaseConfig";
import { ref } from "firebase/storage";

const HospitalOnboardByAgent = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [hospitalData, setHospitalData] = useState(null);
  const [infoDetails, setInfoDetails] = useState([]);
  const [hospitalLogo, setHospitalLogo] = useState(null);
  const [shouldFetchData, setShouldFetchData] = useState(true);
  const [facilityData, setFacilitydata] = useState(null);
  const [dynamicFacilityData, setDynamicFacilitydata] = useState(null);
  const [loader, setLoader] = useState(false);

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const hospitalId = queryParams.get("q");
  const page = queryParams.get("page");
  // console.log("iam qurery", hospitalId);
  // console.log("iam qurery", page);

  let currentSidebarItems;
  if (page === "hospital") {
    currentSidebarItems = sidebarHospitalItems;
  } else if (page === "agent") {
    currentSidebarItems = sidebarAgentItems;
  } else {
    // Set default sidebar items
    currentSidebarItems = [];
  }

  useEffect(() => {
    const fetchHospitalData = async () => {
      try {
        // console.warn('called');
        const response = await axios.get(
          `${API}/hospital/getsinglehospital/${hospitalId}`
        );

        const transformData = (data) => {
          return [
            {
              title: "Name",
              value: data.Name_Of_Hospital,
              description: "Hospital Name",
            },
            {
              title: "Contact Number",
              value: `+91 ${data.Contact_Number.replace("_", "")}`,
              description: "Contact number",
            },
            {
              title: "Location",
              value: data.Location,
              description: "Location",
            },
            {
              title: "Email Id",
              value: data.agentEmail,
              description: "Email Id",
            },
            {
              title: "Created on",
              value: format(new Date(data.onBoardedOn), "dd MMM ''yy"),
              description: "Created on",
            },
          ];
        };

        const transformedInfoDetails = transformData(response.data.hospital);

        setHospitalData(response.data.hospital);
        setHospitalLogo(response.data.hospital.Hospital_Logo[0]);
        setInfoDetails(transformedInfoDetails);
      } catch (error) {
        console.error("Error fetching hospital data:", error);
      }
    };

    fetchHospitalData();
  }, [hospitalId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API}/agenthospital/getfacility/${hospitalId}`
        );

        if (response) {
          const { data } = response;
          setDynamicFacilitydata(data);
          setFacilitydata(data.facilityOverview);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Fetch data when the component mounts
    fetchData();
  }, [hospitalId]);

  console.log(":::::::::facillity data after use effect:::::", facilityData);
  console.log(
    ":::::::::Dynamic Facility in onbaording by agent:::::",
    hospitalData
  );

  //<============================helpers========================>

  const transformFormData = (formData) => {
    console.log("iam getting befor formdata transform", formData);
    const transformedData = {
      specialityTypes: formData.specialityTypes.value,
      numPatientRooms: parseInt(formData.numPatientRooms.value, 10),
      numOperationTheaters: parseInt(formData.numOperationTheater.value, 10),
    };
    return transformedData;
  };

  const transformSpecialities = (specialities) => {
    const result = [];
    for (const specializationName in specialities) {
      const doctors = specialities[specializationName].doctors;
      const doctorIds = Object.keys(doctors).filter(
        (key) => key !== "description"
      );
      const description =
        specialities[specializationName].description || specializationName;

      result.push({
        specializationName,
        description,
        doctorIds,
      });
    }
    return result;
  };

  // console.log("::::::::::inofdetails::::::::::", infoDetails);

  //=====================helpers end =============================>

  const onSubmit = async (formData, specialities) => {
    try {
      console.log("specialities", specialities);
      setLoader(true);
      const formattedData = transformFormData(formData); // Assuming you have a transformFormData function defined
      if (specialities !== undefined) {
        const keysToKeep = formData.treatmentOffers.value.map(
          (option) => option.value || option
        );
        Object.keys(specialities).forEach((key) => {
          if (!keysToKeep.includes(key)) {
            delete specialities[key];
          }
        });
        specialities = transformSpecialities(specialities);

        console.log("iam transformed specialities:::", specialities);
      }

      const url = `${API}/agenthospital/onboard/${hospitalId}`;
      const token = getAccessToken();
      console.log("::::::::::transform data :::::::::", formattedData);

      const result = await axios.post(url, formattedData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      setFacilitydata(formattedData);

      //adding existing specializations
      if (specialities !== undefined) {
        specialities.map(async (data) => {
          try {
            const response = await axios.post(
              `${API}/agenthospital/addtreatment/${hospitalId}/specialties`,
              data
            );
            if (response) {
              console.log("added specialties::", data);
            }

            // <============================================= delete images test =================================================================>

            // const reference = ref(storage, `onboard_hospital/${hospitalId}/doctors_Image`);
            // const deletionResults = await deleteAllImagesRef(reference);

            // console.log('Deletion results::::::::', deletionResults);
            // <============================================= delete images test =================================================================>

            setLoader(false);
          } catch (error) {
            console.error("Error submitting speciality:", error);
          }
        });
      }

      // navigate("/home");
      // alert("Added Speciality");

      console.log("Added specilaity:: ", result.data); // Assuming the API response contains data property
      setLoader(false);
    } catch (err) {
      console.error("Error submitting data:", err);
    }
  };

  return (
    <DashboardLayout sidebarItems={currentSidebarItems}>
      {loader && <Loader />}
      <div className="mian-facility-box" style={{ marginTop: "2rem" }}>
        {pathname === "/agent/hospital-management/add-hospital-details" && (
          <Breadcrumb
            breadcrumbs={[
              {
                label: "Hospital Management",
                url: "/agent/hospital-management",
              },
              {
                label: "Add Hospital Details",
                url: "/agent/hospital-management/add-hospitaldetails",
                disabled: true,
              },
            ]}
            divider={"/"}
            styles={{
              paddingBottom: "2rem",
              // paddingTop: "1rem",
            }}
          />
        )}
        <div className="row m-0">
          <div className="col d-flex justify-content-center flex-row">
            <div className="dynamic-details">
              {hospitalData ? (
                <HospitalDataComponent
                  infoDetails={infoDetails}
                  infoLogo={hospitalLogo}
                />
              ) : (
                <Skeleton variant="rounded" width="120vh" height="12vh" />
              )}
            </div>
          </div>
        </div>

        <div className="row m-0">
          <div className="offset-xl-2 col col-xl-8 mt-4 px-5 px-xl-0 ">
            <div className="inputs-main d-flex justify-content-center">
              <DashboardFormGenerator
                agentTypes={hospitalOnbaordByAgent}
                submitHandler={onSubmit}
                facilityData={facilityData}
                dynamicFacilityData={dynamicFacilityData}
                setLoader={setLoader}
                hospitalData={hospitalData}
              />
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default HospitalOnboardByAgent;
